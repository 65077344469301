import React, { useState } from 'react';
import { Button, Grid, Header, Tab } from 'semantic-ui-react';
import ProfileForm from '../profilePage/ProfileForm';
import ProfileFormWorker from './ProfileFormWorker';
import { isAgentF } from '../../../app/common/util/util';

export default function CustomerInfoView({
	profile,
	isCurrentUser,
	currentUserProfile,
}) {
	const [editMode, setEditMode] = useState(false);
	const [workerRegister, setWorkerRegister] = useState(false);
	const authLevel = currentUserProfile?.authLevel;
	const isWorker = currentUserProfile?.isWorker;
	const workerName = currentUserProfile?.workerName;

	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel,
		currentUserProfile?.isAgent
	);

	return (
		<>
			{authLevel >= 100 ? (
				<Tab.Pane>
					<Grid>
						<Grid.Column width={16}>
							<h3>기본 정보 등록</h3>
							<select
								style={{ width: '98%' }}
								onChange={() => setWorkerRegister(!workerRegister)}>
								<option value={!workerRegister}>회사가 관리하는 정보</option>
								<option value={workerRegister}>직원이 관리하는 정보</option>
							</select>
						</Grid.Column>
					</Grid>
				</Tab.Pane>
			) : null}
			{workerRegister ? (
				<Tab.Pane>
					<Grid>
						<Grid.Column width={16}>
							<Header
								floated='left'
								icon='user'
								content={'SafeHR : Welcome To Safe HR program for You'}
							/>
							{(isCurrentUser || authLevel >= 100) && (
								<>
									{isWorker ? null : (
										<Button
											onClick={() => setEditMode(!editMode)}
											floated='right'
											basic
											content={editMode ? '취소' : '직원정보 등록/수정'}
										/>
									)}
								</>
							)}
						</Grid.Column>
						<Grid.Column width={16}>
							{editMode ? (
								<ProfileFormWorker profile={profile} />
							) : (
								<>
									<div style={{ marginBottom: 10 }}>
										{/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
										<br />
										<div>
											회사명 : {profile?.workerInputCompanyName || null}
										</div>
										<div>회사코드 : {profile?.companyId || null}</div>
										<div>직원성명 : {workerName || null}</div>
										<div>직원코드 : {profile?.paydocuWorkerUid || null}</div>
									</div>
								</>
							)}
						</Grid.Column>
					</Grid>
				</Tab.Pane>
			) : (
				<Tab.Pane>
					<Grid>
						<Grid.Column width={16}>
							<Header
								floated='left'
								icon='user'
								content={
									'SafeHR : Welcome To Safe HR program for You and Your Employee'
								}
							/>
							{(isCurrentUser || authLevel >= 50 || isAgent) && (
								<>
									{isWorker ? null : (
										<Button
											onClick={() => setEditMode(!editMode)}
											floated='right'
											basic
											content={
												editMode
													? '취소'
													: isWorker
													? '직원정보 수정'
													: '회사정보 수정'
											}
										/>
									)}
								</>
							)}
						</Grid.Column>
						<Grid.Column width={16}>
							{editMode ? (
								<>
									{isWorker ? (
										<>
											<ProfileFormWorker profile={profile} />
										</>
									) : (
										<>
											<ProfileForm profile={profile} />
										</>
									)}
								</>
							) : (
								<>
									{isWorker ? (
										<div style={{ marginBottom: 10 }}>
											{/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
											<br />
											{/* <div>회사명 : {profile?.companyName || null}</div> */}
											<div>
												회사명 : {profile?.workerInputCompanyName || null}
											</div>
											<div>회사코드 : {profile?.companyId || null}</div>
											<div>직원성명 : {workerName || null}</div>
											<div>직원코드 : {profile?.paydocuWorkerUid || null}</div>
										</div>
									) : (
										<div style={{ marginBottom: 10 }}>
											<strong>
												{/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
												<br />
												<div>대표자 성명 : {profile?.ceoName || null}</div>
												<div>
													사업자등록번호 : {profile?.companyBizNumber || null}
												</div>
												<div>업태 : {profile?.bizType || null}</div>
												<div>종목 : {profile?.bizItem || null}</div>
												{/* <div>{`${profile?.companyName}(이)가 운영중인 `}근로시간 제도 : {profile.workTimeSystem || null}</div>
                          <div>{`${profile?.companyName}(이)가 운영중인 `}임금 제도 : {profile.wageComfirmBase || null}</div> */}
											</strong>
											<br />
											<div>전화번호 : {profile?.companyPhone || null}</div>
											<div>주소 : {profile?.companyAddress || null}</div>
											<div>담당자 이름 : {profile?.staffName || null}</div>
											<div>담당자 이메일 : {profile?.staffEmail || null}</div>
											{/* 11월21일 */}
											{isAgent && <div>전문가코드 : {profile?.id || null}</div>}
										</div>
									)}
								</>
							)}
						</Grid.Column>
					</Grid>
				</Tab.Pane>
			)}
		</>
	);
}
