import React, { useState, useEffect, useMemo } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && amended.length > 0 && (
						<div className='no-print'>
							{amended.map((amendment, i) => (
								<div key={i} style={{ marginBottom: '10px' }}>
									<strong style={{ color: 'red' }}>{amendment.date}</strong>
									<ul>
										{amendment.changes.map((change, j) => (
											<li
												key={j}
												style={{
													color:
														change.includes('변경전') ||
														change.includes('변경후')
															? 'blue'
															: 'black',
												}}>
												{change}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe1GeneralRules({
	company, // 이 부분 수정
	companyGa,
	companyUn,
	serviceStartDate,
	allOpen,
	setSectionCount, // 조항 수를 설정하는 함수 전달
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `목적`,
				content: [
					`본 노사협의회 규정은 "근로자 참여 및 협력 증진에 관한 법률"의 입법정신에 입각하여 노·사 쌍방간의 원활한 의사소통과 상호이해를 통하여 민주적이고 자주적인 산업평화의 구현과 ${company}의 생산성 향상 및 근로자의 복지증진 도모를 그 목적으로 한다.`,
				],
			},

			{
				title: `명칭`,
				content: [
					`본 규정에 의해 설치된 노사협의회의 명칭은 ${company} 노사협의회(이하 “협의회”라 함)라고 칭한다.`,
				],
			},
			{
				title: `소재지`,
				content: [
					`본 협의회는 ${company}의 주된 사업장 소재지 주소로 하며 관할기관은 소재지 지역의 지방관서로 한다.`,
				],
			},
			{
				title: `신의성실의무`,
				content: [
					`사원과 ${companyUn} 상호신의를 바탕으로 성실하게 협의회의 운영에 임하여야 한다.`,
				],
			},
			{
				title: `노동조합과의 관계`,
				content: [
					`노동조합의 단체교섭 기타 모든 활동은 이 규정에 의하여 영향을 받지 아니한다.`,
				],
			},
			{
				title: `${company}의 의무`,
				content: [
					`${companyUn} 근로자(사원)위원의 선출에 개입하거나 방해해서는 안된다.`,
					`${companyUn} 근로자위원의 업무를 위하여 장소제공 등 기본적인 편의를 제공한다.`,
				],
			},
		];

		// if (['대한민', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, companyUn]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제1장 총 칙</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + 1} // 동적으로 number 생성
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
