import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../companyActions';
import PaydocuShortCut from './PaydocuShortCut';
import PaydocusTableList from './PaydocusTableList';
import PaydocuFilters from './PaydocuFilters';
import { isAgentF } from '../../../app/common/util/util';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocusTableDashboard() {
	const dispatch = useDispatch();
	const { paydocus, filter, yearMonth, clientInfo } = useSelector(
		(state) => state.companyReducer
	);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const isWorker = currentUserProfile?.isWorker;
	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel
	);
	const paydocuWorkerUids = currentUserProfile?.paydocuWorkerUid;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	useEffect(() => {
		const fetchPaydocus = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						filter,
						yearMonth,
						paydocuWorkerUids,
						clientInfo
					)
				);
			} catch (error) {
				console.error('Error fetching paydocus:', error);
			}
		};

		fetchPaydocus();

		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, filter, yearMonth, paydocuWorkerUids, clientInfo]);

	const year = yearMonth.substr(0, 4);
	const month = yearMonth.substr(4, 2);

	const titleOfTable = `${companyName} ${year}년 ${month}월 월급직 임금대장`;
	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<h1 style={{ textAlign: 'center', margin: '50px' }}>{titleOfTable}</h1>
			<PaydocuFilters loading={loading} isWorker={isWorker} isAgent={isAgent} />
			<></>
			<Grid>
				<Grid.Column width={16}>
					<>
						{authenticated && (
							<PaydocusTableList
								paydocus={paydocus}
								loading={loading}
								titleOfTable={titleOfTable}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
