import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import PaydocuShortCut from '../../../../companyManage/paydocuDashboard/PaydocuShortCut';

// import PaydocuFilters from '../../../../companyManage/paydocuDashboard/PaydocuFilters';
// import { isAgentF } from '../../../../../app/common/util/util';

import ClientReqDashboard4InCalendarCompany from './ClientReqDashboard4InCalendarCompany';

export default function ClientReqDashboard4InCalendarCompanyDashboard() {
	const { filter, clientInfo } = useSelector((state) => state.companyReducer);

	const { currentUserProfile } = useSelector((state) => state.profile);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const isWorker = currentUserProfile?.isWorker;
	// const isAgent = isAgentF(
	// 	currentUserProfile?.agentType,
	// 	currentUserProfile?.authLevel
	// );

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			{/* <PaydocuFilters loading={loading} isWorker={isWorker} isAgent={isAgent} />
			<></> */}
			<Grid>
				<Grid.Column width={16}>
					<>
						{authenticated && (
							<ClientReqDashboard4InCalendarCompany
								clientInfo={clientInfo}
								filter={filter}
								companyId={clientInfo?.id || currentUserProfile?.id}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
