import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Sidebar, Menu } from 'semantic-ui-react';
import SignedInMenu from './SignedInMenu';
import SignedOutMenu from './SignedOutMenu';
import { getWorkerUserProfile } from '../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../profiles/profileActions';
import { dataFromSnapshotIdDate } from '../../app/firestore/firestoreService';
import CalculatorMenu from './CalculatorMenu';
import { CiCalculator1 } from 'react-icons/ci';
import { FaWonSign } from 'react-icons/fa';
import { PiUserListFill } from 'react-icons/pi';
import PaydocuMenuWorker from './PaydocuWorkerMenu';
import ContractWorkerMenu from './ContractWorkerMenu';
import PaydocuAnnualLeaveWorkerMenu from './PaydocuAnnualLeaveWorkerMenu';
import { FcLeave } from 'react-icons/fc';
// import RuleOfEmployeeMenu from './RuleOfEmployeeMenu';
// import { BsFillFileEarmarkRuledFill } from 'react-icons/bs';
// import CompetencyMenu from './CompetencyMenu';
// import RiskSuspectMenu from './RiskSuspectMenu';
// import { GrVulnerability } from 'react-icons/gr';
// import { FaHelmetSafety } from 'react-icons/fa6';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../app/config/firebase';
import {
	clearWorkerUserCompanyData,
	loadWorkerData,
} from '../companyManage/companyActions';

const MySidebarWorker = ({ isWorker }) => {
	const dispatch = useDispatch();
	const [companyData, setCompanyData] = useState(null);
	const { currentUserProfile } = useSelector((state) => state.profile);

	const { workerUserCompanyData } = useSelector(
		(state) => state.companyReducer
	);
	const { authenticated } = useSelector((state) => state.auth);

	const isDayOrTimeWorker =
		currentUserProfile?.isDayWorker || currentUserProfile?.isTimeWorker
			? true
			: false;
	const isBizWorker = currentUserProfile?.isBizWorker ? true : false;
	const companyId = currentUserProfile?.companyId;
	// const companyViewId = companyData?.id;
	// const isCoWorkSemusa = false;
	// const freeComClient5 = false;

	// const roeConsulting = companyData?.roeConsulting;
	// const rlcExcuteDueDate = companyData?.rlcExcuteDueDate;
	// const rOEBizSecretConsulting = companyData?.rOEBizSecretConsulting;

	// const roeExcuteDueDate = companyData?.roeExcuteDueDate;
	// const ruleLaborCouncilConsulting = companyData?.ruleLaborCouncilConsulting;
	// const bizSecretExcuteDueDate = companyData?.bizSecretExcuteDueDate;

	const retiredWorker = workerUserCompanyData[0]?.retiredDate ? true : false;
	// 퇴사한 직원이 연차를 현재 상태로 보면 안되서 workers에 있는 근로자수 정보로
	const numOfWorkers = retiredWorker
		? workerUserCompanyData[0]?.numOfWorkers
		: companyData?.numOfWorkers;

	useEffect(() => {
		if (!currentUserProfile?.paydocuWorkerUid) return;

		dispatch(loadWorkerData(currentUserProfile.paydocuWorkerUid));

		return () => {
			dispatch(clearWorkerUserCompanyData()); // 언마운트 시 데이터 초기화
		};
	}, [dispatch, currentUserProfile?.paydocuWorkerUid]);

	useEffect(() => {
		if (companyId) {
			const companyDataFirestore = async () => {
				try {
					// Firestore의 users 컬렉션에서 companyId와 일치하는 문서 가져오기
					const companyDocRef = doc(db, 'users', companyId);
					const companyDocSnap = await getDoc(companyDocRef);

					if (companyDocSnap.exists()) {
						setCompanyData(companyDocSnap.data());
					} else {
						console.log('해당 회사 데이터가 없습니다.');
					}
				} catch (error) {
					console.error('회사 데이터를 가져오는 중 오류 발생:', error);
				}
			};

			companyDataFirestore();
		}
	}, [companyId]);

	useEffect(() => {
		let isMounted = true;
		const unSubscribe = getWorkerUserProfile({
			next: (snapshot) => {
				if (isMounted) {
					dispatch(
						listenToWorkerUsers(
							snapshot.docs.map((docSnapshot) =>
								dataFromSnapshotIdDate(docSnapshot)
							)
						)
					);
				}
			},
			error: (error) => console.log(error),
		});

		return () => {
			unSubscribe();
			isMounted = false;
		};
	}, [dispatch]);

	return (
		<Sidebar
			as={Menu}
			// animation='overlay'
			icon='labeled'
			inverted
			vertical
			visible
			// width='wide'
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				width: '15%',
				height: '100vh',
				overflowY: 'auto',
			}}>
			<Menu.Item header as={NavLink} exact to='/'>
				<img src='SafeHR_shield_with_transparent_background.png' alt='logo' />
			</Menu.Item>
			<div
				style={{
					flex: 1,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}>
				{currentUserProfile?.authLevel >= 10 ? (
					<>
						<PaydocuMenuWorker
							FaWonSign={FaWonSign}
							isBizWorker={isBizWorker}
						/>
						{isBizWorker ? null : (
							<>
								<ContractWorkerMenu
									isWorker={isWorker}
									isDayOrTimeWorker={isDayOrTimeWorker}
									currentUserProfile={currentUserProfile}
									FaWonSign={FaWonSign}
									PiUserListFill={PiUserListFill}
								/>
								{/* <RuleOfEmployeeMenu
									BsFillFileEarmarkRuledFill={BsFillFileEarmarkRuledFill}
									isCoWorkSemusa={isCoWorkSemusa}
									freeComClient5={freeComClient5}
									viewId={companyViewId}
									isWorker={isWorker}
									roeConsulting={roeConsulting}
									rlcExcuteDueDate={rlcExcuteDueDate}
									rOEBizSecretConsulting={rOEBizSecretConsulting}
									roeExcuteDueDate={roeExcuteDueDate}
									ruleLaborCouncilConsulting={ruleLaborCouncilConsulting}
									bizSecretExcuteDueDate={bizSecretExcuteDueDate}
								/> 
								 <CompetencyMenu GrVulnerability={GrVulnerability} />
								<RiskSuspectMenu FaHelmetSafety={FaHelmetSafety} /> */}
								<CalculatorMenu
									CiCalculator1={CiCalculator1}
									isWorker={isWorker}
								/>
								{isDayOrTimeWorker ? null : (
									<PaydocuAnnualLeaveWorkerMenu
										numOfWorkers={numOfWorkers}
										FcLeave={FcLeave}
									/>
								)}
							</>
						)}
					</>
				) : null}
			</div>
			<div>{authenticated ? <SignedInMenu /> : <SignedOutMenu />}</div>
		</Sidebar>
	);
};

export default MySidebarWorker;
