import React, { useState, useEffect, useMemo } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content?.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item?.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && amended.length > 0 && (
						<div className='no-print'>
							{amended?.map((amendment, i) => (
								<div key={i} style={{ marginBottom: '10px' }}>
									<strong style={{ color: 'red' }}>{amendment.date}</strong>
									<ul>
										{amendment.changes?.map((change, j) => (
											<li
												key={j}
												style={{
													color:
														change.includes('변경전') ||
														change.includes('변경후')
															? 'blue'
															: 'black',
												}}>
												{change}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeForChildCompany({
	company,
	companyUn,
	companyGa,
	companyWa,
	roeExcuteDueDate,
	allOpen,
	setSectionCount,
	serviceStartDate,
	profile,
	finBaseDateMonth,
	finBaseDateDate,
	privateSchool,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `목적`,
				content: [
					`이 규칙은 ${company}에 근무하는 교직원의 채용ㆍ복무 및 근로조건에 관한 사항을 정함으로써 안정된 직장의 보장 및 원아의 안전한 보육과 질서 있는 ${company}의 운영을 도모함을 목적으로 한다.`,
				],
			},
			{
				title: `적용`,
				content: [
					`이 규칙은 ${company}에 근무하는 교직원에게 적용된다. 단, 기간제 및 단시간근로, 파견 교직원에 대하여는 개별근로계약 등으로 그 근로조건 등을 따로 정할 수 있다.`,
				],
			},
			{
				title: `교직원의 정의`,
				content: [
					`이 규칙에서 “교직원”이라 함은 제2장에서 정하는 채용원칙, 기준, 절차 등에 따라 채용되어 ${company}에서 근로하는 교직원을 말하며, 그 자격은 ${company}에 첫 출근하는 날부터 발생한다.`,
				],
			},
			{
				title: `차별의 금지`,
				content: [
					`${companyUn} 모집과 채용, 임금, 임금 외 금품, 교육배치 및 승진, 정년, 퇴직 및 해고에 있어서 남녀의 성, 혼인ㆍ임신ㆍ출산, 나이 등을 이유로 차별하지 아니하고, 국적ㆍ신앙 또는 사회적 신분을 이유로 근로조건에 대한 차별적 처우를 하지 아니한다.`,
					`${companyUn} 비정규직이라는 이유로 동종의 정규직 교직원에 비해 차별적 처우를 하지 아니한다.`,
				],
			},
			{
				title: `채용원칙`,
				content: [
					`${companyUn} 공개전형(서류, 면접, 신체ㆍ적성검사 등) 등 필요한 절차를 거쳐 교직원을 채용한다. 단, ${company}의 필요에 따라 시기ㆍ방법 등에 변화를 줄 수 있고, 절차를 감하거나 생략할 수 있다.`,
					`전형에 합격한 자는 ${companyGa} 요구하는 구비서류를 제출하고 채용 발령을 받음으로써 채용이 확정된다.`,
				],
			},
			{
				title: `채용결격사유`,
				content: [
					`다음 각 호의 어느 하나에 해당하는 자는 교직원으로 채용하지 않으며 채용된 후라도 그 사유에 해당하게 되면 자동 면직되며, 그 사실이 발견되면 채용을 취소 또는 해고할 수 있다.`,
					[
						`영유아보육[어린이집] / 유아교육법[유치원] 관련 법령이 정한 자격을 갖추지 못하거나 결격사유에 해당하는 자`,
						`당해 직무에 필요한 자격 또는 면허를 소지하지 아니한 자`,
						`신체검사결과 직무수행에 지장이 있다고 판정된 자`,
						`전형서류에 허위의 사실을 기재하거나 이력사항을 고의로 누락한 자`,
						`법령이나 법${company}의 판결 등으로 직무수행에 지장이 있는 자`,
						`기타 위 각 호에 준하는 사유로 채용이 심히 부적당한 자`,
					],
				],
			},
			{
				title: `전형 및 채용서류`,
				content: [
					`${company}에 입사를 지원하는 자는 다음 각 호의 서류를 제출하여야 한다.`,
					[
						`이력서 1통(1개월 이상의 경력, 학력사항을 빠짐없이 기록)`,
						`기타 ${companyGa} 요구하는 자료`,
					],
					`${companyUn} 서류 전형에 합격한 자에게 다음 각 호 서류의 제출을 요구할 수 있고, 해당자는 그 요구에 따라야 한다.`,
					[
						`면허증ㆍ자격증(해당자) 사본 1통`,
						`학력증명서(해당자)`,
						`기타 영유아보육법령[어린이집] / 유아교육법령[유치원]에 명시하거나 ${companyGa} 요구하는 자료`,
					],
					`서류와 면접에 합격한 사람은 다음 각 호의 자료를 제출한다.`,
					[
						`근로계약서와 부대서류 1부`,
						`기타 영유아보육법령[어린이집] / 유아교육법령[유치원]에 명시하거나 ${companyGa} 요구하는 자료`,
					],
				],
			},
			{
				title: `수습기간`,
				content: [
					`신규채용자는 수습개시일로부터 원칙적으로 3개월간의 수습기간을 갖되, 상황에 따라 감하거나 면할 수 있다.`,
					`수습기간 중인 자로 직무수행 적합성이 없다고 판정되거나 또는 수습기간이 만료된 자로서 계속 근무가 부적당하다고 인정된 자에 대하여는 본채용을 거절하거나 해지할 수 있다.`,
					`정식채용한 자에 대하여 수습기간을 근속년수에 포함한다.`,
				],
			},
			{
				title: `근로계약과 근로조건 명시`,
				content: [
					`근로계약은 기간의 정함이 없는 계약체결을 원칙으로 하되, 기간을 명시한 계약을 체결할 수 있다.`,
					`${companyWa} 채용이 확정된 자는 근로계약서에 서명 또는 날인하여 근로계약을 체결하고 그 사본 1부를 해당자에게 교부한다. 이 경우 그 서면에는 근로기준법이 정한 근로조건이 명시되어야 한다. 단, 법정 명시 사항이 적시된 취업규칙을 제시하거나 교부함으로써 그 서면명시 의무를 대신할 수 있다.`,
				],
			},
			{
				title: `신의성실의 원칙`,
				content: [
					`${companyUn} 이 규칙에서 정한 근로조건으로 교직원을 근로케 하며 교직원은 ${company}의 제 규정을 준수하여 부여된 직무를 성실히 완수하여야 한다.`,
					`교직원은 신의와 성실로써 이 규칙 등 제 규정을 지키며 상사의 지시에 따라 직장질서를 유지하는 동시에 상사는 소속원의 인격을 존중함으로써 상호 신뢰하여 ${company}의 발전을 위해 주어진 직책을 수행하여야 한다.`,
				],
			},
			{
				title: `복무규율`,
				content: [
					`교직원은 신의성실의 원칙에 입각하여 다음의 사항을 엄수하여야 한다.`,
					`① 교직원은 다음 각 호의 권고의무를 성실히 준수하여야 한다.`,
					[
						`항상 건강에 유의하고 명랑·활발한 태도로 근무한다.`,
						`담당직무의 권한 외의 독단적인 행동을 하지 않는다.`,
						`${company}의 시설, 기구 기타 비품을 조심하여 다루며, 기타 소모품을 절약한다.`,
						`${company}의 청결에 힘쓰고 화재나 도난방지 및 안전사고의 방지에 적극 협조한다.`,
						`교직원은 근무시간 중 그 품위를 유지할 수 있도록 단정한 복장을 착용하여야 한다.`,
						`교직원은 동료교직원 또는 고객 등 제3자에게 불쾌감을 줄 수 있는 염색, 문신, 피어싱, 네일 부착물 등은 착용하지 아니한다.`,
					],
					`② 교직원은 다음 각 호의 강행의무를 반드시 준수하여야 한다.`,
					[
						`${company}의 허락을 얻지 아니하고 근무장소를 이탈하거나 태만한 행위를 하여서는 안 된다.`,
						`교직원은 근무시간 동안에는 담당업무 이외의 사유로 교직원 개인의 휴대폰, 인터넷을 사용하지 않는다.`,
						`교직원은 근무시간 동안에는 직무상 허용된 경우를 제외하고 주식관련 일체의 행위(주식 거래 및 주식 가격 모니터링 등)를 하지 아니한다.`,
						`교직원은 근무시간 동안 온라인 쇼핑을 하지 아니한다.`,
						`교직원은 휴게시간을 포함한 점심시간을 무단으로 연장하지 아니한다.`,
						`교직원은 근무시간 동안 취침(책상에 엎드려 자는 행위 포함)을 하지 아니한다.`,
						`교직원은 이력사항에 변경이 있을 때에는 그 사유 발생일로부터 일주일 이내에 신고하여야 한다.`,
						`교직원은 업무의 원활한 수행 및 평가와 업무공유 등을 위해 업무일지 작성 지시를 받는 경우 이를 이행해야 한다.`,
						`교직원은 ${company}의 본 복무규정을 포함하여 제반 규정을 준수하고 상사의 정당한 직무상 지시에 따라야 한다.`,
						`교직원은 원 소속 교직원으로서의 명예와 품위를 손상하는 행위를 하지 않는다.`,
						`교직원은 재직 중은 물론 퇴직 후라도 직무상 취득한 영업비밀, 기업기밀, 대외비 등의 기밀사항을 누설하지 않는다.`,
						`허가 없이 직무 이외의 목적으로 ${company}의 시설, 기계기구 기타 물품을 사용하지 않는다.`,
						`다른 교직원의 작업을 방해하거나, 직장의 풍기와 질서를 문란시키는 행위를 하지 않는다.`,
						`교직원은 청렴∙결백하여야 하며, 직무와 관련하여 직 · 간접으로 사례, 증여 또는 향응 등을 제공받지 않는다.`,
						`교직원은 직무 이외의 영리를 목적으로 하는 다른 업무에 종사할 수 없으며, ${company}의 허가 없이는 다른 직무를 겸할 수 없다.`,
						`교직원은 직무상의 직권을 이용하여 타인으로부터 부당한 금품을 차용하거나, 증여 또는 뇌물을 받아서는 아니 된다.`,
						`교직원은 ${company}의 허락 없이 사업장 내에서 선동집회, 연설, 토론, 집단행위, 시위운동, 유인물의 배포, 게시 등을 하거나 정치활동을 하여서는 아니 된다.`,
					],
					`③ 교직원은 다음 각 호의 금지행위를 하여서는 아니 된다.`,
					[
						`${company}의 허가 없이 휴대폰 등으로 음성녹음을 하거나 시도하는 행위`,
						`${company}의 허가 없이 휴대폰 등으로 영상촬영을 하거나 시도하는 행위`,
						`${company}의 허가 없이 영업비밀, 기업기밀, 대외비 등을 취득하거나 시도 하는 행위`,
					],
					`④ 기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `인사권, 배치, 전직`,
				content: [
					`교직원에 대한 인사권은 원장에게 있고, 교직원은 정당한 사유 없이 이를 거부할 수 없다.`,
					`${companyUn} 원아의 안전하고 건강한 보육, 교직원의 자격, 능력, 적성, 경력 등을 고려하여 부서의 배치, 전직 등 인사발령을 한다.`,
				],
			},
			{
				title: `공민권행사 및 공의 직무 수행`,
				content: [
					`${companyUn} 교직원이 근무시간 중 선거권, 그 밖의 공민권을 행사하거나 공(公)의 직무(예비군 소집 등)를 수행하기 위하여 필요한 시간을 청구할 경우 이를 거부할 수 없으며, 그 시간은 유급으로 처리한다.`,
					`교직원은 공의 직무를 수행하여야 할 경우 ${company}에 그 시기와 장소 등을 미리 알려야 하며, ${companyUn} 제1항의 권리 행사나 공(公)의 직무를 수행하는데 지장이 없는 범위 내에서 교직원이 청구한 시간을 변경할 수 있다.`,
				],
			},
			{
				title: `잠정적 직위해제 등`,
				content: [
					`${companyUn} 교직원이 다음 각 호의 어느 하나에 해당하는 경우 그 직위를 해제하고 일정 장소에 대기를 인사명령 할 수 있다.`,
					[
						`징계여부 논의를 위한 조사 내지 면담에 앞서 필요한 경우`,
						`계속 근로 시 안전을 담보하기 어려운 경우`,
						`원아 및 보호자, 거래처 등의 고객으로부터 민원이 반복된 경우`,
						`횡령, 배임 등으로 직무수행 계속이 곤란한 경우`,
					],
					`제1항의 대기기간은 3개월을 한도로 하며, 그 사유 해소 시 즉시 원직 또는 원직에 상응하는 직을 부여한다.`,
					`대기기간 중에 필요한 경우 특별과제를 부여하거나 교육훈련에 임하도록 할 수 있다.`,
					`대기발령기간 동안 제3항에 따른 과제가 부여되거나 교육훈련에 임하는 경우에는 임금의 100%를 지급하고 그렇지 않은 경우에는 휴업급여(평균임금의 70%)를 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `손해배상`,
				content: [
					`교직원이 고의 또는 과실로 ${company}의 명예 또는 신용을 손상케 하거나 재산상 손해를 입혔을 때에는 변상해야 한다. 이 경우 취업규칙에 따른 징계는 교직원의 손해배상 책임을 당연히 면해주지는 않고, 퇴사로서 면책되지 않는다.`,
				],
			},
			{
				title: `출입`,
				content: [
					`다음 각 호의 어느 하나에 해당하는 자에 대하여는 ${companyUn} 입장을 거부하거나 퇴거를 명할 수 있다.`,
					[
						`${company} 내의 질서유지 또는 안전ㆍ위생상 유해하다고 인정되는 자`,
						`음주나 안전 미조치 등으로 복무 준비가 불량한 자`,
						`교직원이라도 근무 이외의 목적으로 출입하려는 것이 명백한 자`,
					],
				],
			},
			{
				title: `근무형태, 근로시간 및 시간외근로`,
				content: [
					`${companyUn} 교직원의 직무에 따라 근무형태(주간근무 원칙, 필요시 합의로 시차교대제근무 가능) 및 근무시간을 근로계약서에 명시한다.`,
					`교직원의 근로시간은 휴게시간을 제하고 1일 8시간(09:00~18:00 원칙, ${company}의 사정에 따라 시차출퇴근 가능), 1주 40시간을 원칙으로 한다. 단, ${companyUn} 소정 근로시간 외에 연장, 야간, 휴일근무를 명할 수 있고 교직원은 특별한 사유가 없는 한 이에 따른다.`,
					`휴게시간(13:00~15:00 사이에 1시간 원칙, 사정에 따라 교대로 휴게 가능)은 ${company}의 질서와 규율을 문란케 하지 않는 한도 내에서 자유로이 이용할 수 있다.`,
					`단시간교직원은 휴게시간을 제외하고 1일 3시간 내지 6시간의 근로시간을 계약할 수 있고, 시업종업 및 휴게시간 등 구체적인 내용은 해당 근로계약서로 정한다.`,
					`${companyUn} 학기, 계절적 요인 또는 경영상의 상황 변화 등에 따라 필요한 경우 제1항 내지 제2항을 변화를 주어 운용할 수 있다.`,
				],
			},
			{
				title: `유연근로시간제`,
				content: [
					`출장 그 밖의 사유로 근로시간의 전부 또는 일부를 원 밖에서 근로하여 근로시간을 산정하기 어려운 경우에는 소정근로시간을 근로한 것으로 본다.`,
					`제1항에도 불구하고 원 밖에서 일하는 시간이 소정근로시간을 초과하는 것이 명백한 경우에는 ${companyWa} 교직원 대표는 그 업무수행에 통상 필요한 시간을 서면으로 정하여 그 시간을 근로한 것으로 보는 서면합의를 할 수 있다.`,
					`${companyUn} 2주간 이내의 일정한 단위기간을 평균하여 1주간의 근로시간이 40시간을 초과하지 아니하는 범위 안에서 특정주에 40시간을, 특정일에 8시간을 초과하여 근로하게 할 수 있다. 다만, 특정주의 근로시간은 48시간을 초과할 수 없으며, 18세 미만과 임신 중인 여성교직원에게는 적용하지 않는다.`,
					`${companyUn} 교직원 대표와의 서면 합의에 따라 다음 각 호의 사항을 정하면 3개월 이내의 단위기간을 평균하여 1주간의 근로시간이 40시간을, 특정한 날에 8시간을 초과하여 근로하게 할 수 있다. 다만, 특정한 주의 근로시간은 52시간을, 특정한 날의 근로시간은 12시간을 초과할 수 없다.`,
					[
						`대상교직원의 범위`,
						`단위기간(3개월 이내의 일정한 기간으로 정하여야 한다)`,
						`단위기간의 근로일과 그 근로일별 근로시간`,
						`기타 근로기준법시행령이 정하는 사항`,
					],
					`${companyUn} 교직원 대표와의 서면 합의에 따라 3개월 초과 6개월 이내 단위의 탄력적 근로시간제를 도입할 수 있다. 이 경우 서면 합의의 내용은 근로기준법령이 정한 내용을 포함해야 하고 그 구체적인 요건과 효과는 근로기준법령에 따른다.`,
				],
			},
			{
				title: `근태관리`,
				content: [
					`교직원의 근태는 중간관리자가 관리하고 원장이 총괄한다.`,
					`${companyUn} 교직원의 근태를 고과 또는 승진ㆍ승급 등에 반영할 수 있고, 근태관리에 대한 기타 사항은 별도의 원 규정으로 정할 수 있다.`,
				],
			},
			{
				title: `출퇴근`,
				content: [
					`교직원은 출근 시 출근부에 서명ㆍ날인(또는 출퇴근 체크)하고 업무지시를 받아야 하며, 시업시간과 동시에 정상적인 업무를 시작할 수 있도록 해야 한다.`,
					`교직원은 종업 시 주변을 정돈하고 원아 및 시설의 안전을 담보한 후 퇴근한다.`,
					`업무개시의 준비는 시업시각 10분 전까지 완료한다.`,
					`업무의 정리정돈은 종업시각 후 10분 이내에 완료한다.`,
					`${companyUn} 제3항의 작업준비시간 및 제4항의 정리정돈 시간 동안 교직원에게 업무명령을 내릴 수 없고, 해당 시간을 엄수하지 않은 것을 이유로 징계하거나, 인사평가 등 불이익을 주는 행위를 할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `결근`,
				content: [
					`교직원이 질병 기타 부득이한 사유로 결근코자 할 때에는 전일 종업시간 전까지 결근사유를 증명하는 내용을 첨부하여 ${company}에 결근계(질병ㆍ부상으로 인한 결근이 3일 이상일 때에는 의사의 진단서를 첨부)를 제출해야 한다. 단, 부득이한 사유로 인해 결근할 경우에는 당일이라도 전화연락 또는 결근계를 제출하여야 한다.`,
					`승인 또는 당일 통보가 없는 결근은 무단결근으로 처리하고 징계할 수 있다.`,
					`결근이 불가항력적인 사유로 발생했음을 사후에 소명했을 때에는 교직원이 동의할 경우 연차로 처리할 수 있다.`,
				],
			},
			{
				title: `지각ㆍ조퇴ㆍ외출`,
				content: [
					`교직원이 질병 및 기타 부득이한 사유로 지각 또는 조퇴하고자 하는 자는 ${company}에 신청하여 승인을 받아야 한다.`,
					`사용 외출을 하고자 하는 자는 ${company}의 허가를 얻어야 한다.`,
					`제1항 및 제2항의 규정에 의한 승인을 받지 아니한 지각ㆍ조퇴ㆍ외출은 무단이탈로 보아 징계할 수 있다.`,
					`지각, 조퇴, 외출, 이탈시간은 근무시간으로 인정하지 않는다. 단 교직원이 동의하는 경우 누계 8시간을 연차 1일로 처리할 수 있다.`,
				],
			},
			{
				title: `휴일 및 토요일의 처리`,
				content: [
					`${company}의 유급휴일은 다음 각 호와 같다.`,
					[
						`주휴일(매주 일요일을 주휴일로 하고 그 주 개근 자에 한하여 유급으로 부여한다)`,
						...(privateSchool !== '사립학교법 적용'
							? [`근로자의 날(사립학교법 적용 대상자에게는 적용하지 아니한다.)`]
							: []),
						`공휴일`,
						`기타 ${company}에서 정한 날`,
					],
					`토요일은 무급휴무일로 처리한다.`,
				],
			},
			{
				title: `비상출근명령 및 휴일의 대체`,
				content: [
					`교직원은 천재지변, 기타 이에 준하는 재해가 발생한 때에는 휴일 또는 휴가 중이라도 ${company}의 비상출근 명령을 받은 자는 출근하여 ${company}의 지시에 따라 업무 처리하여야 한다.`,
					`${companyUn} 사정에 따라 전조의 휴일 중 주휴일의 경우 해당 주휴일 48시간 전에 해당 주휴일에 근무할 것을 명하고, 해당일로부터 7일 이내 대체 주휴일을 부여할 수 있고, 교직원은 특별한 사유가 없는 한 이에 따른다.`,
					`전조 제1항 제3호의 공휴일의 경우 교직원 대표와의 서면합의가 있는 경우에 한해 공휴일을 다른 소정근로일로 대체할 수 있다.`,
				],
			},
			{
				title: `보상휴가`,
				content: [
					`${companyUn} 교직원 대표와의 서면합의에 의해 연장근로ㆍ야간근로 및 휴일근로에 대하여 임금을 지급하는 것에 갈음하여 휴가를 부여할 수 있다.`,
				],
			},
			{
				title: `연차유급휴가`,
				content: [
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? `${companyUn} 회계년도(매년 ${finBaseDateMonth}월 ${finBaseDateDate}일, 매월 ${finBaseDateDate}일) 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`
						: `${companyUn} 교직원의 입사일을 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 법정휴일, 소정휴일을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 교직원에게 15일의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 약정육아휴직, 본 규정에 의한 법정휴가를 초과하는 1주일간의 업무외 부상 질병 휴직, 교직원 개인 건강 휴직 등 ${company}의 승인받아 근로제공의무가 정지된 날이 속한 경우는 근로제공의무가 정지된 날을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 교직원에게는 15일의 연차휴가를 모두 부여하지 아니하고 해당 공제 일수만큼 비율을 공제한 갯수의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 계속하여 1년 미만인 교직원 또는 1년간 80퍼센트 미만 출근한 교직원에게 1개월 개근시 1일의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 3년 이상 계속 근로한 교직원에게 제2항의 규정에 의한 연차유급휴가에 최초 1년을 초과하는 계속 근로 연수 매 2년에 대하여 1일을 가산한 연차유급휴가를 부여한다.`,
					`제2항 내지 제5항에 의하여 발생된 총 연차유급휴가의 일수는 25일을 초과할 수 없다.`,
					`제2항부터 제5항까지의 규정을 적용하는 경우 다음 각 호의 어느 하나에 해당하는 기간은 출근한 것으로 본다.`,
					[
						`업무상 재해로 인한 휴업기간`,
						`임산부보호휴가기간 또는 유산∙사산보호휴가기간`,
						`예비군훈련기간`,
						`민방위훈련 또는 동원기간`,
						`공민권행사를 위한 휴무일`,
						`연차유급휴가기간`,
						`생리휴가기간`,
						`육아휴직기간`,
						`육아기 근로시간 단축을 사용하여 단축된 근로시간`,
						`임신기 근로시간 단축을 사용하여 단축된 근로시간`,
					],
					`${companyUn} 교직원이 연차휴가를 사용하기 최소 1주일전까지 신청한 연차에 대해 청구한 시기에 연차휴가를 부여하는 것을 원칙으로 하되 교직원의 직속상관이 공식적으로 인정하는 경우 연차휴가를 사용하기 3일전까지 신청한 연차에 대해서도 청구한 시기에 연차휴가를 부여하고, 동 기간에 대해 통상임금을 지급하는 것을 원칙으로 하되, 동 기간에 약정연장, 약정야간, 약정휴일근로 수당이 포함된 경우에는 해당 금액을 포함하여 지급할 수 있다.`,
					`제8항에 따라 교직원이 청구한 시기에 연차휴가를 부여하는 것이 사업상 막대한 지장이 있을 경우에 그 시기를 변경할 수 있다.`,
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? `제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 교직원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다. 다만 회사의 연차부여 단위가 회계년도 기준이므로 2년차에 발생한 제3항 연차는 회계년도 말까지의 기간을 말한다.) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`
						: `제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 교직원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`,
					`제10항에 따라 소멸한 연차유급휴가일수 중 제14조의 연차사용촉진이 되지 않은 미사용 연차일수는 연차유급휴가미사용 수당(통상임금)으로 전환되고 소멸일이 속한 월의 다음달의 임금지급일에 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 02월 23일 개정',
									changes: [
										`변경전 : `,
										`① 1년간 8할 이상 출근한 교직원에게는 15일의 유급휴가를 준다. 다만, 1년간 8할 미만 출근한 교직원에게도 개근한 1월에 대하여 1일의 유급휴가를 준다.`,
										`② 계속하여 근로한 기간이 1년 미만인 교직원에게는 1개월 개근 시 1일의 유급휴가를 준다.`,
										`③ 3년 이상 근속한 교직원에 대하여는 제1항 규정에 의한 휴가에 최초 1년을 초과하는 계속 근로연수 매 2년에 대하여 1일을 가산한 유급휴가를 주며, 가산휴가를 포함한 총 휴가일수는 25일을 한도로 한다.`,
										`④ 제1항 내지 제2항의 계산에 있어 업무상 재해로 휴업한 기간, 출산전후휴가로 휴업한 기간, 육아휴직으로 휴업한 기간은 출근한 것으로 본다.`,
										`변경후 :`,
										profile.annualLeaves === `회계년도기준` ||
										profile.annualLeaves === `특정일(회계년도 등)기준`
											? `① ${companyUn} 회계년도(매년 ${finBaseDateMonth}월 ${finBaseDateDate}일, 매월 ${finBaseDateDate}일) 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`
											: `① ${companyUn} 교직원의 입사일을 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`,
										`② ${companyUn} 제1항의 기산일을 기준으로 법정휴일, 소정휴일을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 교직원에게 15일의 연차유급휴가를 부여한다.`,
										`③ ${companyUn} 제1항의 기산일을 기준으로 약정육아휴직, 본 규정에 의한 법정휴가를 초과하는 1주일간의 업무외 부상 질병 휴직, 교직원 개인 건강 휴직 등 ${company}의 승인받아 근로제공의무가 정지된 날이 속한 경우는 근로제공의무가 정지된 날을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 교직원에게는 15일의 연차휴가를 모두 부여하지 아니하고 해당 공제 일수만큼 비율을 공제한 갯수의 연차유급휴가를 부여한다.`,
										`④ ${companyUn} 제1항의 기산일을 기준으로 계속하여 1년 미만인 교직원 또는 1년간 80퍼센트 미만 출근한 교직원에게 1개월 개근시 1일의 연차유급휴가를 부여한다.`,
										`⑤ ${companyUn} 제1항의 기산일을 기준으로 3년 이상 계속 근로한 교직원에게 제2항의 규정에 의한 연차유급휴가에 최초 1년을 초과하는 계속 근로 연수 매 2년에 대하여 1일을 가산한 연차유급휴가를 부여한다.`,
										`⑥ 제2항 내지 제5항에 의하여 발생된 총 연차유급휴가의 일수는 25일을 초과할 수 없다.`,
										`⑦ 제2항부터 제5항까지의 규정을 적용하는 경우 다음 각 호의 어느 하나에 해당하는 기간은 출근한 것으로 본다.`,
										`1. 업무상 재해로 인한 휴업기간`,
										`2. 임산부보호휴가기간 또는 유산∙사산보호휴가기간`,
										`3. 예비군훈련기간`,
										`4. 민방위훈련 또는 동원기간`,
										`5. 공민권행사를 위한 휴무일`,
										`6. 연차유급휴가기간`,
										`7. 생리휴가기간`,
										`8. 육아휴직기간`,
										`9. 육아기 근로시간 단축을 사용하여 단축된 근로시간`,
										`10. 임신기 근로시간 단축을 사용하여 단축된 근로시간`,
										`⑧ ${companyUn} 교직원이 연차휴가를 사용하기 최소 1주일전까지 신청한 연차에 대해 청구한 시기에 연차휴가를 부여하는 것을 원칙으로 하되 교직원의 직속상관이 공식적으로 인정하는 경우 연차휴가를 사용하기 3일전까지 신청한 연차에 대해서도 청구한 시기에 연차휴가를 부여하고, 동 기간에 대해 통상임금을 지급하는 것을 원칙으로 하되, 동 기간에 약정연장, 약정야간, 약정휴일근로 수당이 포함된 경우에는 해당 금액을 포함하여 지급할 수 있다.`,
										`⑨ 제8항에 따라 교직원이 청구한 시기에 연차휴가를 부여하는 것이 사업상 막대한 지장이 있을 경우에 그 시기를 변경할 수 있다.`,
										profile.annualLeaves === `회계년도기준` ||
										profile.annualLeaves === `특정일(회계년도 등)기준`
											? `⑩ 제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 교직원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다. 다만 회사의 연차부여 단위가 회계년도 기준이므로 2년차에 발생한 제3항 연차는 회계년도 말까지의 기간을 말한다.) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`
											: `⑩ 제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 교직원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`,
										`⑪ 제10항에 따라 소멸한 연차유급휴가일수 중 제14조의 연차사용촉진이 되지 않은 미사용 연차일수는 연차유급휴가미사용 수당(통상임금)으로 전환되고 소멸일이 속한 월의 다음달의 임금지급일에 지급한다.`,
										`⑫ 기타 필요한 사항은 별도로 정할 수 있다.`,
										`개정이유 : 2025년 2월 23일 시행되는 근로기준법 개정에 따른 내용 및 해당 법률의 자세한 내용 반영.`,
									],
								},
						  ]
						: null,
			},
			{
				title: `연차휴가의 사용`,
				content: [
					`연차유급휴가를 사용하고자 하는 교직원은 관리부서를 경유하여 사전에 승인을 받아야 한다.`,
					`교직원이 청구한 시기에 휴가를 주는 것이 업무상 지장이 있다고 인정되는 경우 ${companyUn} 그 시기를 변경하거나 나누어 사용하게 할 수 있다.`,
					`교직원의 연차유급휴가는 1년간 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`,
					`${companyUn} 근로기준법에 따라 연차유급휴가 사용을 촉진할 수 있다. ${company}의 사용촉진조치에도 불구하고 교직원이 사용하지 아니한 연차유급휴가에 대하여는 금전으로 보상하지 아니한다.`,
				],
			},
			{
				title: `연차유급휴가의 사전대체`,
				content: [
					`${companyUn} 교직원 대표와의 서면합의에 의하여 특정 근로일에 교직원을 휴무케 하고 그 휴무를 연차유급휴가를 사용한 것으로 할 수 있다.`,
				],
			},
			{
				title: `생리휴가`,
				content: [`여성교직원이 청구하면 월 1일의 무급생리휴가를 준다.`],
			},
			{
				title: `난임치료휴가의 부여`,
				content: [
					`${companyUn} 난임치료(인공수정 또는 체외수정 등)를 위하여 난임치료휴가를 청구한 교직원에게 연간 6일 이내의 난임치료휴가를 부여하여야 한다. 이 경우 최초 2일은 유급으로 한다.`,
					`교직원이 청구한 시기에 난임치료휴가를 부여하는 것이 정상적인 사업 운영에 중대한 지장을 초래한 경우에는 교직원과 협의하여 그 시기를 변경할 수 있다.`,
					`${companyUn} 난임치료휴가를 이유로 해고, 징계 등 불리한 처우를 하지 아니한다.`,
					`${companyUn} 난임치료휴가를 신청한 교직원이 난임치료를 받을 사실을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					`${companyUn} 난임치료휴가 청구 업무를 처리하는 과정에서 알게 된 사실을 난임치료휴가를 신청한 교직원의 의사에 반하여 다른 사람에게 누설하지 않는다`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										'- 변경전 :',
										`별도 조항 없음.`,
										'- 변경후 :',
										`① ${companyUn} 난임치료(인공수정 또는 체외수정 등)를 위하여 난임치료휴가를 청구한 교직원에게 연간 6일 이내의 난임치료휴가를 부여하여야 한다. 이 경우 최초 2일은 유급으로 한다.`,
										`② 교직원이 청구한 시기에 난임치료휴가를 부여하는 것이 정상적인 사업 운영에 중대한 지장을 초래한 경우에는 교직원과 협의하여 그 시기를 변경할 수 있다.`,
										`③ ${companyUn} 난임치료휴가를 이유로 해고, 징계 등 불리한 처우를 하지 아니한다.`,
										`④ ${companyUn} 난임치료휴가를 신청한 교직원이 난임치료를 받을 사실을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
										`⑤ ${companyUn} 난임치료휴가 청구 업무를 처리하는 과정에서 알게 된 사실을 난임치료휴가를 신청한 교직원의 의사에 반하여 다른 사람에게 누설하지 않는다`,
										`⑥ 기타 필요한 사항은 별도로 정할 수 있다.`,
										'개정이유 : 2025년 2월 23일 남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률 개정',
									],
								},
						  ]
						: null,
			},
			{
				title: `임산부의 보호`,
				content: [
					`${companyUn} 임신 중의 여성 교직원에게 출산 전과 출산 후를 통하여 90일(미숙아로서 출생 후 24시간 이내에 법률요건에 따라 신생아 중환자실에 입원하는 경우에는 100일, 한 번에 둘 이상 자녀를 임신한 경우에는 120일)의 출산전후휴가를 주어야 한다. 이 경우 휴가 기간의 배정은 출산 후에 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
					`${companyUn} 임신 중인 여성 교직원이 유산의 경험 등 다음 각 호로 정하는 사유로 제1항의 휴가를 청구하는 경우 출산 전 어느 때라도 휴가를 나누어 사용할 수 있도록 하여야 한다. 이 경우 출산 후의 휴가 기간은 연속하여 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
					[
						`임신한 교직원에게 유산ㆍ사산의 경험이 있는 경우`,
						`임신한 교직원이 출산전후휴가를 청구할 당시 연령이 만 40세 이상인 경우`,
						`임신한 교직원이 유산ㆍ사산의 위험이 있다는 의료기관의 진단서를 제출한 경우`,
					],
					`${companyUn} 임신 중인 여성 교직원이 유산 또는 사산한 경우로서 그 교직원이 유산 또는 사산한 교직원이 휴가 청구 사유, 유산ㆍ사산 발생일 및 임신기간 등을 적은 유산ㆍ사산휴가 신청서에 의료기관의 진단서를 첨부하여 ${companyUn}에게 청구하면 다음 각 호로 정하는 바에 따라 유산ㆍ사산 휴가를 주어야 한다. 다만, 인공 임신중절 수술(「모자보건법」제14조제1항에 따른 경우는 제외한다)에 따른 유산의 경우는 그러하지 아니하다.`,
					[
						`15주 이내인 경우: 유산 또는 사산한 날부터 10일까지`,
						`임신기간이 16주 이상 21주 이내인 경우: 유산 또는 사산한 날부터 30일까지`,
						`임신기간이 22주 이상 27주 이내인 경우: 유산 또는 사산한 날부터 60일까지`,
						`임신기간이 28주 이상인 경우: 유산 또는 사산한 날부터 90일까지`,
					],
					`제1항부터 제3항까지의 규정에 따른 휴가 중 최초 60일(한 번에 둘 이상 자녀를 임신한 경우에는 75일)은 유급(통상임금)으로 한다. 다만, 「남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률」제18조에 따라 출산전후휴가급여 등이 지급된 경우에는 그 금액의 한도에서 지급의 책임을 면한다.`,
					`${companyUn} 임신 중의 여성 교직원에게 시간외근로를 하게 하여서는 아니 되며, 그 교직원의 요구가 있는 경우에는 쉬운 종류의 근로로 전환하여야 한다.`,
					`${companyUn} 제1항에 따른 출산전후휴가 종료 후에는 휴가 전과 동일한 업무 또는 동등한 수준의 임금을 지급하는 직무에 복귀시켜야 한다.`,
					`${companyUn}  임신 후 12주 이내 또는 32주 이후에 있는 여성 교직원(고용노동부령으로 정하는 유산, 조산 등 위험이 있는 여성 교직원의 경우 임신 전 기간)이 1일 2시간의 근로시간 단축을 신청하는 경우 이를 허용하여야 한다. 다만, 1일 근로시간이 8시간 미만인 교직원에 대하여는 1일 근로시간이 6시간이 되도록 근로시간 단축을 허용할 수 있다.`,
					`${companyUn} 제7항에 따른 근로시간 단축을 이유로 해당 교직원의 임금을 삭감하여서는 아니 된다.`,
					`제8항에 따라 근로시간 단축을 신청하려는 여성 교직원은 근로시간 단축 개시 예정일의 3일 전까지 임신기간, 근로시간 단축 개시 예정일 및 종료 예정일, 근무 개시 시각 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 의사의 진단서(같은 임신에 대하여 근로시간 단축을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출하여야 한다.`,
					`${companyUn} 임신 중인 여성 교직원이 1일 소정근로시간을 유지하면서 업무의 시작 및 종료 시각의 변경을 신청하는 경우 이를 허용하여야 한다. 다만, 정상적인 사업 운영에 중대한 지장을 초래하는 경우 등 본 조항 제12조에서 정하는 경우에는 그러하지 아니하다.`,
					`제10조의 본문에 따라 업무의 시작 및 종료 시각의 변경을 신청하려는 여성 교직원은 그 변경 예정일의 3일 전까지 임신기간, 업무의 시작 및 종료 시각의 변경 예정 기간, 업무의 시작 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 임신 사실을 증명하는 의사의 진단서(같은 임신에 대해 업무의 시작 및 종료 시각 변경을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출해야 한다.`,
					`제10조의 단서의 “정상적인 사업 운영에 중대한 지장을 초래하는 경우”란 다음 각 호의 어느 하나에 해당하는 경우를 말한다.`,
					[
						`정상적인 사업 운영에 중대한 지장을 초래하는 경우`,
						`업무의 시작 및 종료 시각을 변경하게 되면 임신 중인 여성 교직원의 안전과 건강에 관한 관계 법령을 위반하게 되는 경우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-17'
						? [
								{
									date: '2025년 2월 17일 개정',
									changes: [
										'- 변경전 :',
										`① ${companyUn} 임신 중의 여성 교직원에게 출산 전과 출산 후를 통하여 90일(한 번에 둘 이상 자녀를 임신한 경우에는 120일)의 보호휴가를 준다. 이 경우 산후에 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상을 확보하여 사용하도록 한다.`,
										`② ${companyUn} 임신 중인 여성 교직원이 유산의 경험 등 근로기준법 시행령이 정하는 사유로 제1항의 휴가를 청구하는 경우 출산 전 어느 때라도 휴가를 나누어 사용할 수 있도록 한다.`,
										`③ ${companyUn} 임신 중인 여성 교직원이 유산 또는 사산한 경우로서 해당 교직원이 청구하는 경우에는 남녀고용평등 및 일ㆍ가정 양립지${company}에 관한 법률에 따른 휴가를 부여 한다. 단, 모자보건법에서 허용되지 않는 인공중절 수술은 제외한다.`,
										`④ ${companyUn} 교직원이 출산전후휴가 급여 등을 신청할 경우 고용보험 관련 법령에 따라 출산전후휴가 급여 등을 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다.`,
										`⑤ ${companyUn} 임신 중의 여성 교직원에게 연장근로를 시키지 아니하며, 요구가 있는 경우 쉬운 종류의 근로로 전환시킨다.`,
										'- 변경후 :',
										`① ${companyUn} 임신 중의 여성 교직원에게 출산 전과 출산 후를 통하여 90일(미숙아로서 출생 후 24시간 이내에 법률요건에 따라 신생아 중환자실에 입원하는 경우에는 100일, 한 번에 둘 이상 자녀를 임신한 경우에는 120일)의 출산전후휴가를 주어야 한다. 이 경우 휴가 기간의 배정은 출산 후에 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
										`② ${companyUn} 임신 중인 여성 교직원이 유산의 경험 등 다음 각 호로 정하는 사유로 제1항의 휴가를 청구하는 경우 출산 전 어느 때라도 휴가를 나누어 사용할 수 있도록 하여야 한다. 이 경우 출산 후의 휴가 기간은 연속하여 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
										`1. 임신한 교직원에게 유산ㆍ사산의 경험이 있는 경우`,
										`2. 임신한 교직원이 출산전후휴가를 청구할 당시 연령이 만 40세 이상인 경우`,
										`3. 임신한 교직원이 유산ㆍ사산의 위험이 있다는 의료기관의 진단서를 제출한 경우`,
										`③ ${companyUn} 임신 중인 여성 교직원이 유산 또는 사산한 경우로서 그 교직원이 유산 또는 사산한 교직원이 휴가 청구 사유, 유산ㆍ사산 발생일 및 임신기간 등을 적은 유산ㆍ사산휴가 신청서에 의료기관의 진단서를 첨부하여 ${companyUn}에게 청구하면 다음 각 호로 정하는 바에 따라 유산ㆍ사산 휴가를 주어야 한다. 다만, 인공 임신중절 수술(「모자보건법」제14조제1항에 따른 경우는 제외한다)에 따른 유산의 경우는 그러하지 아니하다.`,
										`1. 15주 이내인 경우: 유산 또는 사산한 날부터 10일까지`,
										`2. 임신기간이 16주 이상 21주 이내인 경우: 유산 또는 사산한 날부터 30일까지`,
										`3. 임신기간이 22주 이상 27주 이내인 경우: 유산 또는 사산한 날부터 60일까지`,
										`4. 임신기간이 28주 이상인 경우: 유산 또는 사산한 날부터 90일까지`,
										`④ 제1항부터 제3항까지의 규정에 따른 휴가 중 최초 60일(한 번에 둘 이상 자녀를 임신한 경우에는 75일)은 유급(통상임금)으로 한다. 다만, 「남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률」제18조에 따라 출산전후휴가급여 등이 지급된 경우에는 그 금액의 한도에서 지급의 책임을 면한다.`,
										`⑤ {companyUn} 임신 중의 여성 교직원에게 시간외근로를 하게 하여서는 아니 되며, 그 교직원의 요구가 있는 경우에는 쉬운 종류의 근로로 전환하여야 한다.`,
										`⑥ ${companyUn} 제1항에 따른 출산전후휴가 종료 후에는 휴가 전과 동일한 업무 또는 동등한 수준의 임금을 지급하는 직무에 복귀시켜야 한다.`,
										`⑦ ${companyUn}  임신 후 12주 이내 또는 32주 이후에 있는 여성 교직원(고용노동부령으로 정하는 유산, 조산 등 위험이 있는 여성 교직원의 경우 임신 전 기간)이 1일 2시간의 근로시간 단축을 신청하는 경우 이를 허용하여야 한다. 다만, 1일 근로시간이 8시간 미만인 교직원에 대하여는 1일 근로시간이 6시간이 되도록 근로시간 단축을 허용할 수 있다.`,
										`⑧ ${companyUn} 제7항에 따른 근로시간 단축을 이유로 해당 교직원의 임금을 삭감하여서는 아니 된다.`,
										`⑨ 제8항에 따라 근로시간 단축을 신청하려는 여성 교직원은 근로시간 단축 개시 예정일의 3일 전까지 임신기간, 근로시간 단축 개시 예정일 및 종료 예정일, 근무 개시 시각 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 의사의 진단서(같은 임신에 대하여 근로시간 단축을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출하여야 한다.`,
										`⑩ ${companyUn} 임신 중인 여성 교직원이 1일 소정근로시간을 유지하면서 업무의 시작 및 종료 시각의 변경을 신청하는 경우 이를 허용하여야 한다. 다만, 정상적인 사업 운영에 중대한 지장을 초래하는 경우 등 본 조항 제12조에서 정하는 경우에는 그러하지 아니하다.`,
										`⑪ 제10조의 본문에 따라 업무의 시작 및 종료 시각의 변경을 신청하려는 여성 교직원은 그 변경 예정일의 3일 전까지 임신기간, 업무의 시작 및 종료 시각의 변경 예정 기간, 업무의 시작 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 임신 사실을 증명하는 의사의 진단서(같은 임신에 대해 업무의 시작 및 종료 시각 변경을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출해야 한다.`,
										`⑫ 제10조의 단서의 “정상적인 사업 운영에 중대한 지장을 초래하는 경우”란 다음 각 호의 어느 하나에 해당하는 경우를 말한다.`,
										`1. 정상적인 사업 운영에 중대한 지장을 초래하는 경우`,
										`2. 업무의 시작 및 종료 시각을 변경하게 되면 임신 중인 여성 교직원의 안전과 건강에 관한 관계 법령을 위반하게 되는 경우`,
										`⑬ 기타 필요한 사항은 별도로 정할 수 있다.`,
										'개정이유 : 2025년 2월 23일 근로기준법 개정',
									],
								},
						  ]
						: null,
			},
			{
				title: `태아검진 시간, 육아시간의 허용 등`,
				content: [
					`${companyUn} 임신한 여성 교직원이 다음 각 호에 따른 임산부 정기건강진단을 받는데 필요한 시간을 청구하는 경우 이를 부여한다.`,
					[
						`임신 28주까지: 4주마다 1회`,
						`임신 29주에서 36주까지: 2주마다 1회`,
						`임신 37주 이후: 1주마다 1회`,
						`임신한 여성 교직원이 「장애인복지법」에 따른 장애인인 경우, 만 35세 이상인 경우, 다태아를 임신한 경우 또는 의사가 고위험 임신으로 판단한 경우에는 1호부터 3호까지에 따른 건강진단 횟수를 넘어 건강진단을 실시할 수 있다.`,
					],
					`전항의 규정에 의한 정기건강진단에 필요한 시간은 유급(통상임금)으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										'- 변경전 :',
										`① ${companyUn} 임신한 여성 교직원이 모자보건법 제10조에 따른 임산부 정기건강진단을 받는데 필요한 시간을 청구하는 경우 이를 허용한다.`,
										`② ${companyUn} 생후 1년 미만의 유아를 가진 여성교직원이 청구가 있는 때에는 1일 2회에 각각 30분 이상의 유급 수유시간을 준다.`,
										`③ ${companyUn} 임신 후 12주 이내 또는 36주 이후에 있는 여성 교직원이 1일 2시간의 근로시간 단축을 신청하는 경우 이를 허용하여야 한다. 다만, 1일 근로시간이 8시간 미만인 교직원에 대하여는 1일 근로시간이 6시간이 되도록 근로시간 단축을 허용할 수 있다.`,
										`④ ${companyUn} 제1항 내지 제3항에 따른 건강진단 시간 등을 이유로 교직원의 임금을 삭감하지 않는다.`,
										`⑤ ${companyUn} 교직원이 인공수정 또는 체외수정 등 난임치료를 받기 위하여 휴가를 청구하는 경우에 연간 3일 이내의 휴가를 주어야 하며, 이 경우 최초 1일은 유급으로 한다. 다만, 교직원이 청구한 시기에 휴가를 주는 것이 정상적인 사업 운영에 중대한 지장을 초래하는 경우에는 교직원과 협의하여 그 시기를 변경할 수 있다.`,
										`⑥ ${companyUn} 난임치료휴가를 이유로 해고, 징계 등 불리한 처우를 하여서는 아니 된다.`,
										`⑦ 난임치료휴가의 신청방법 및 절차 등은 「남녀고용평등 및 일ㆍ가정 양립지${company}에 관한 법률」 시행령이 정한 바에 따른다.`,
										'- 변경후 :',
										`① ${companyUn} 임신한 여성 교직원이 다음 각 호에 따른 임산부 정기건강진단을 받는데 필요한 시간을 청구하는 경우 이를 부여한다.`,
										`1. 임신 28주까지: 4주마다 1회`,
										`2. 임신 29주에서 36주까지: 2주마다 1회`,
										`3. 임신 37주 이후: 1주마다 1회`,
										`4. 임신한 여성 교직원이 「장애인복지법」에 따른 장애인인 경우, 만 35세 이상인 경우, 다태아를 임신한 경우 또는 의사가 고위험 임신으로 판단한 경우에는 1호부터 3호까지에 따른 건강진단 횟수를 넘어 건강진단을 실시할 수 있다.`,
										`② 전항의 규정에 의한 정기건강진단에 필요한 시간은 유급(통상임금)으로 한다.`,
										`③ 기타 필요한 사항은 별도로 정할 수 있다.`,
										'개정이유 : 태아검진 시간만 별도로 분리후 난임치료휴가 등은 별도 조항으로 분리',
									],
								},
						  ]
						: null,
			},
			{
				title: `하계휴가`,
				content: [
					`교직원은 ${companyGa} 부여하는 하계휴가를 사용할 수 있다.`,
					`${companyUn} 교직원 대표와의 서면합의에 따라 이 휴가기간은 연차휴가를 사용한 것으로 할 수 있다.`,
				],
			},
			{
				title: `경조유급휴가 등`,
				content: [
					`${companyUn} 교직원의 경조 및 특별한 경우에 다음 각 호와 같이 유급휴가를 부여 한다(당일제외, 휴일포함). 다만, 사업운영에 지장이 없도록 ${companyWa} 해당 교직원이 협의하여 변경 운용할 수 있다.`,
					[
						`본인 결혼 : 5일`,
						`자녀의 결혼 : 1일`,
						`배우자 사망 : 5일`,
						`본인 및 배우자의 부모 사망 : 5일`,
						`본인 및 배우자의 조부모ㆍ외조부모 사망 : 1일`,
						`자녀 및 자녀의 배우자의 사망 : 5일`,
					],
					`${companyUn} 교직원이 배우자 출산을 이유로 그 배우자가 출산한 날로부터 90일 이내에 청구하는 경우 최대 10일의 유급휴가를 주고, 교직원은 ${company}의 동의하에 1회 분할 사용할 수 있다. 배우자출산휴가 중 휴일 또는 휴무일이 있는 경우에는 이를 휴가 일수에 넣지 아니한다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										'- 변경전 :',
										`${companyUn} 교직원이 배우자 출산을 이유로 그 배우자가 출산한 날로부터 90일 이내에 청구하는 경우 최대 10일의 유급휴가를 주고, 교직원은 ${company}의 동의하에 1회 분할 사용할 수 있다. 배우자출산휴가 중 휴일 또는 휴무일이 있는 경우에는 이를 휴가 일수에 넣지 아니한다.`,

										'- 변경후 :',
										`배우자 출산휴가 아래 조항으로 분리`,
										'개정이유 : 2025년 2월 23일 남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률 개정',
									],
								},
						  ]
						: null,
			},
			{
				title: `배우자 출산 휴가`,
				content: [
					`${companyUn} 교직원이 배우자의 출산을 이유로 휴가를 청구하는 경우에 20일(유급)의 배우자출산휴가를 부여하여야 한다.`,
					`교직원은 배우자 출산휴가를 3회 분할하여 사용할 수 있다.`,
					`제1항에 따른 휴가는 교직원의 배우자가 출산한 날부터 120일이 지나면 청구할 수 없다.`,
					`${companyUn} 배우자 출산휴가를 이유로 교직원을 해고하거나 그 밖의 불리한 처우를 하여서는 아니 된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										'- 변경전 :',
										`별도 조항 없음.`,
										'- 변경후 :',
										`① ${companyUn} 교직원이 배우자의 출산을 이유로 휴가를 청구하는 경우에 20일(유급)의 배우자출산휴가를 부여하여야 한다.`,
										`② 교직원은 배우자 출산휴가를 3회 분할하여 사용할 수 있다.`,
										`③ 제1항에 따른 휴가는 교직원의 배우자가 출산한 날부터 120일이 지나면 청구할 수 없다.`,
										`④ ${companyUn} 배우자 출산휴가를 이유로 교직원을 해고하거나 그 밖의 불리한 처우를 하여서는 아니 된다.`,
										`⑤ 기타 필요한 사항은 별도로 정할 수 있다.`,
										'개정이유 : 2025년 2월 23일 남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률 개정',
									],
								},
						  ]
						: null,
			},
			{
				title: `병가`,
				content: [
					`${companyUn} 교직원이 업무 외 질병․부상 등으로 병가를 신청하는 경우에는 연간 30일을 초과하지 않는 범위 내에서 병가를 허가할 수 있다. 이 경우 병가기간은 무급으로 한다.`,
					`상해나 질병 등으로 5일 이상 계속 결근 시에는 검진의사의 진단서를 첨부하여야 한다.`,
				],
			},
			{
				title: `휴가의 사용 승인`,
				content: [
					`이 규칙에 의한 휴가를 얻고자 하는 교직원은 사전에 서면으로 신청하여야 한다. 단, 업무를 마치고 퇴근한 후에 휴가사유가 발생하거나 안 경우에는 유선으로 허가를 받을 수 있다.`,
				],
			},
			{
				title: `휴가일수의 계산`,
				content: [
					`이 규칙에 의한 경조휴가를 비롯한 휴가기간 중에 휴일 또는 휴무일이 있는 경우에는 이를 이중으로 사용할 수 없다(즉, 따로 계산할 수 없다). 단, 연차유급휴가, 배우자출산휴가에 대하여는 그러하지 아니하다.`,
				],
			},
			{
				title: `일반휴직`,
				content: [
					`${companyUn} 교직원이 다음 각 호의 어느 하나에 해당하는 경우에는 그 해당 교직원은 그 사유를 소명하여 ${company}에 휴직을 신청할 수 있고, ${companyUn} 인력 상황 등을 종합하여 그 기간을 정하여 휴직을 명할 수 있다.`,
					[
						`일신상의 사정으로 휴직을 청원할 때`,
						`업무 외 부상이나 질병으로 14일 이상 가료 또는 휴양이 필요한 때`,
						`형사사건으로 구속 또는 기소된 자가 휴직을 청원한 때`,
						`${company}의 형편에 의한 때`,
					],
					`제1항에 따라 교직원이 휴직을 신청할 경우에는 휴직 5일 전에 증빙서류를 첨부하여 휴직원을 제출하여야 한다. 단, 불가능한 경우 휴직사유 발생 후 지체 없이 제출하여야 한다.`,
				],
			},
			{
				title: `휴직기간 및 의무ㆍ처우`,
				content: [
					`전조의 휴직은 2개월을 한도로 하여 그 필요한 기간으로 한다.`,
					`제1항의 규정에도 불구하고 휴직의 사유가 해소되지 아니하여 해당 교직원이 그 증빙서류를 첨부하여 연장을 신청하고 ${companyGa} 승인한 경우에 한하여 2개월 이내의 기간 내에서 휴직기간을 연장할 수 있다.`,
					`제2항의 휴직기간의 연장이 없거나 휴직기간의 연장에도 불구하고 휴직의 사유가 해소되지 아니한 경우 퇴직처리할 수 있다.`,
					`교직원은 휴직기간 중에도 이 규칙을 준수할 의무를 지기에, ${company}의 허락 없이 타사에 근무하거나 다른 사업을 하여서는 아니 된다.`,
					`제1항의 휴직사유가 분명하고 이를 증명할 수 있는 증빙서류 제출 후 ${company}의 승인을 얻어 휴직한 교직원의 휴직기간은 근속년수에 산입하고, 휴직기간 중 보수는 지급하지 않는다.`,
				],
			},
			{
				title: `복직`,
				content: [
					`휴직기간이 만료된 자 또는 휴직기간 중이라도 그 사유가 해소된 자는 7일 이내에 복직원을 제출하여야 한다.`,
					`일반휴직 제1항 제2호의 사유로 인해 휴직된 자가 복직원을 제출할 때에는 병원의 진단서(업무수행에 지장이 없다는 진단서)를 첨부하여야 한다.`,
					`제1항의 복직일 내에 정당한 사유없이 복직원을 미제출할 경우, 연장을 승인 받지 못하고도 복직원을 미제출한 경우와 휴직기간 중 ${company}의 허락없이 타사에 근무하거나 다른 사업을 한 경우에는 복직의사가 없는 것으로 간주하여 퇴직 처리한다.`,
					`휴직자가 복직원을 제출하고 복직할 경우 휴직전의 직무에 복직시킴을 원칙으로 한다. 다만, 해당 직무에 복직시키기가 곤란한 특별한 사유가 있을 때에는 다른 직무를 담당하게 할 수 있다.`,
				],
			},
			{
				title: `휴업수당`,
				content: [
					`${company}의 귀책사유로 휴업할 경우 평균임금의 100분의 70 또는 통상임금을 지급한다. 단, 부득이한 사유로 인하여 사업계속이 불가능하여 노동위원회의 승인을 받은 경우에는 이 기준에 미달하는 휴업수당을 지급할 수 있다.`,
				],
			},
			{
				title: `출장`,
				content: [
					`교직원이 평소의 근무지를 떠나 업무를 일정기간 수행할 필요가 있을 때는 서면 또는 구두의 출장명령을 받아야 한다.`,
					`출장명령을 받은 교직원은 안전한 업무수행을 위하여 최선을 다해야 하며 사적인 일로 안전을 그르치거나 시간을 낭비해서는 아니 된다.`,
					`출장 중 계획의 변경 등으로 일정 조정이 필요한 경우 ${company}에 연락하여 승인을 얻어야 한다.`,
					`교직원이 출장용무를 마친 때에는 ${company}에 지체 없이 구두 또는 서면으로 보고한다.`,
					`${companyUn} 행선지별 여비, 숙박ㆍ식비 등 출장 등에 따른 실비를 보상할 수 있다.`,
				],
			},
			{
				title: `임금제, 임금 원칙, 임금의 구성`,
				content: [
					`교직원의 임금은 월급제, 연봉제(포괄연봉제 포함), 계약제(일급ㆍ시급제 포함)로 하며, 직종 및 직위에 따라 근로계약으로 별도로 정할 수 있다.`,
					`교직원의 급여는 보육관련 규정의 호봉표를 기준으로 하여 ${company}의 상황 등을 고려하여 원장이 구체적으로 결정한다.`,
					`교직원에 대한 임금은 기본급 및 직책수당(이하 “통상임금”이라 한다)과 연장ㆍ야간ㆍ휴일근로수당 등 법정수당, 기타로 구성한다.`,
					`시나 구 등 지자체 및 국가기관에서 교직원에게 직접 지급하는 금품은 ${company}에서 지급하는 근로기준법상의 임금이 아니므로 임금의 구성항목에 포함되지 아니한다.`,
					`이 장에 없는 사항은 근로계약 또는 급여규정으로 따로 정할 수 있다.`,
				],
			},
			{
				title: `임금 계산 및 지급방법`,
				content: [
					`임금은 해당월 초일 기산하고 말일에 결산하여 교직원부담분의 공적보험료 등을 공제한 후 당월 25일에 교직원의 계좌로 이체하거나 직접 지급한다.`,
					`${companyUn} 결근에 대하여는 통상일급으로, 지각조퇴에 대하여는 통상시급으로 계산하여 공제할 수 있다.`,
					`${companyUn} 제42조의 1항 내지 2항의 임금을 지급할 때 근로기준법령이 정한 소정의 기재사항이 포함된 급여명세서를 교직원 각자에게 교부(서면 또는 전자적 방법 가능)한다.`,
				],
			},
			{
				title: `연장, 야간 및 휴일근로수당`,
				content: [
					`${companyUn} 교직원이 연장 및 야간근로(하오 10시부터 익일 상오 6까지 근로) 또는 8시간 이내의 휴일에 근로할 경우 통상임금의 50%를 가산하여 지급한다.`,
					`${companyUn} 8시간을 초과하는 휴일근로에 대해서는 통상임금의 100%를 가산하여 지급한다.`,
				],
			},
			{
				title: `상여금`,
				content: [
					`${companyUn} 교육 및 경영성과를 반영한 상여금을 지급할 수 있다.`,
					`상여금의 지급여부와 기준, 지급 시기는 ${companyGa} 정한다.`,
					`${companyGa} 상여금을 지급할 경우 지급일 현재 실근로(즉, 취업) 중인 교직원에 대해서만 지급한다.`,
				],
			},
			{
				title: `비상시 지급`,
				content: [
					`${companyUn} 교직원이 출산, 질병, 재해, 혼례 또는 부득이한 사유로 인한 비상사태에 충당하기 위하여 청구할 때에는 지급기일 전이라도 사전 근무에 대한 임금을 지급할 수 있다.`,
				],
			},
			{
				title: `퇴직의 종류와 퇴직일`,
				content: [
					`퇴직은 그 원인이 되는 사유에 따라 당연 퇴직, 의원퇴직, 정년퇴직, 해고 및 감원 등으로 구분하며, ${companyUn} 교직원의 다음 각 호의 어느 하나에 해당하는 사유가 발생된 때에는 퇴직 시킨다.`,
					[
						`당연 퇴직(사망하였을 때, 임원이 되었을 때)`,
						`정년퇴직(정년 연령이 되었을 때)`,
						`의원퇴직(퇴직을 원할 때)`,
						`이 규칙 제52조의 사유가 발생한 때`,
						`근로계약이 만료하여 계약갱신이 되지 아니하였을 때`,
						`교직원의 채용 결격사유가 입사 후에 발견된 때`,
						`이 규칙 제37조 제1항에 의한 휴직사유가 해소되지 아니하거나, 휴직기간을 초과하고도 연장되지 않았을 때 및 제39조에 의한 복직원을 제출하지 아니하였을 때`,
						`무단결근이 연속 5일 이상이면서 ${company}의 전화, 방문 또는 그에 상응하는 연락에도 불구하고 정당한 이유 없이 연락두절인 교직원은 근로의 의사가 없는 것으로 간주하여 징계 절차 없이 퇴직 처리함.`,
						`교직원 평가결과 기준에 도달하지 못하였을 때 재계약이 불가능함.`,
					],
					`제1항에 의한 퇴직의 퇴직일은 다음 각 호와 같다.`,
					[
						`교직원이 퇴직 일자를 명시한 사직원을 제출해 수리되었을 경우 그 날`,
						`교직원이 퇴직 일자를 명시하지 아니하고 사직원을 제출하였을 경우 이를 수리한 날(단, ${companyUn} 업무의 인수인계를 위하여 사직원을 제출한 날로부터 30일을 넘지 않는 범위 내에서 퇴직 일자를 지정하여 수리할 수 있다)`,
						`사망한 날`,
						`정년에 도달한 날이 속한 달의 말일`,
						`근로계약기간이 만료된 날`,
						`해고가 결정․통보된 경우 해고일`,
						`제1항 제8호의 경우 결근 6일 차가 된 날`,
					],
				],
			},
			{
				title: `정년, 정년 후 채용`,
				content: [
					`교직원(원장 예외)의 정년은 만 60세로 하고, ${companyUn} 해당 정년자를 정년 해당일이 속한 달의 다음 달 초일에 고용보험 상실처리 한다.`,
					`제1항의 규정에도 불구하고, 원장이 필요하다고 인정하는 교직원에 대하여는 해당 교직원과의 합의로 촉탁계약을 체결할 수 있다. 이 경우 촉탁직계약은 퇴사 후의 재 채용이다.`,
				],
			},
			{
				title: `퇴직 의사표시, 인수인계, 물품반납`,
				content: [
					`교직원은 퇴직하고자 할 경우에는 ${company}의 계속적인 업무진행을 위해 적어도 퇴직 30일 전에 사직원으로 퇴직의사를 표명해야 하고, 소정의 인수인계를 마친 후 퇴직한다. 이 경우 인수인계를 마쳤다는 것은 직무의 인수인계, 인식표 및 자료 내지 비밀번호 등의 반납, 대여물품 등 원 소유의 물건의 반납, ${company}에 대한 부채나 ${companyGa} 보증한 부채의 정산 등이 완료된 상황을 말한다.`,
					`사직서 수리 없는 결근은 무단결근으로 처리한다.`,
				],
			},
			{
				title: `퇴직정산, 퇴직급여`,
				content: [
					`${companyUn} 교직원 퇴직 시 잔여 임금 등의 정산금과 퇴직금(교직원이 1년 이상 근로하고 퇴직하였을 때에 한하며, 1년에 대하여 평균임금의 30일분)을 퇴직일로부터 14일 이내에 지급 한다. 단, 당사자 간의 합의에 의하여 지급기일을 연장할 수 있다.`,
					`${companyUn} 교직원들의 동의를 얻어 퇴직연금제도를 설정할 수 있고, 이 경우 관련 법령 및 규약 등에 따른다.`,
				],
			},
			{
				title: `해고 등의 제한`,
				content: [
					`${companyUn} 정당한 사유 없이 교직원을 해고, 휴직, 정직, 전직, 감봉, 기타 징벌을 행하지 아니한다.`,
					`${companyUn} 교직원이 업무상 부상 또는 질병의 요양을 위한 휴업기간과 그 후 30일간 또는 여성 교직원의 산전후휴가기간과 그 후 30일간 그리고 육아휴직기간은 해고치 못한다.`,
				],
			},
			{
				title: `해고사유`,
				content: [
					`${companyUn} 근로관계의 성립 기반을 훼손하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`경력∙학력의 사칭`,
						`입사서류의 위조`,
						`기타 입사 과정의 제 서류에 신원, 자격 등 중요한 사항을 허위로 기재함으로써 채용평가 및 심사 요소 중 주요 사항을 속이거나, 사기 또는 부정한 방법으로 채용되었거나 채용케 하였음이 판명되었을 경우`,
						`업무수행에 필요한 능력, 건강 등을 기망하거나 누락하여 신고함으로써 채용된 경우`,
						`업무의 정상적인 수행을 저해하는 질병 등을 기망하거나 누락하여 신고함으로써 채용된 경우`,
					],
					`${companyUn} 근로계약상 근로제공의 채무를 불이행하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`${company}의 책임 없는 사유로 소정근로일을 연속한 5일 이상 무단결근`,
						`월 중 소정근로일의 7일 이상 무단결근`,
						`정당한 사유 없는 지각·조퇴·외출의 합계가 월 10회 이상인 경우`,
						`정직기간 종료 후 3일 이내 출근하지 아니한 경우`,
						`근무시간 중 사적인 음주 행위가 2회 이상인 경우`,
					],
					`${companyUn} 직장의 규범적 질서를 위반하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`허위 보고가 2회 이상인 경우`,
						`교직원 본인 월급 대비 20% 미만의 경미한 ${company}의 재산상 손실을 발생시킨 경우가 3회 이상이거나 교직원 본인 월급 대비 50% 이상 100% 미만의 ${company}의 재산상 손실을 2회 이상 발생시킨 경우 또는 교직원 본인 월급대비 100% 이상의 ${company}의 재산상 손해를 입힌 경우`,
						`${company}의 명예 실추`,
						`공문서 혹은 서류의 기록 등을 고의적인 날조, 은폐, 제거, 삭제, 파기행위 또는 시도행위`,
						`제1심에서 집행유예 6개월 이상의 형사처벌을 받은 교직원`,
						`정당한 사유 없이 안전관리상의 지시, 명령에 위반하거나 불복한 교직원`,
						`각종 재해사고의 은폐, 허위보고, 태만으로 안전사고 사후처리를 지연시킨 교직원`,
						`${company}의 법인인감, 사용인감, 법인통장 등을 개인적인 목적으로 사용한 교직원`,
						`자신의 임금수준 및 임금구성항목을 외부 또는 타인에게 알리거나 동료교직원의 임금수준 및 임금구성항목을 알려고 한 행위가 2회 이상인 경우`,
						`국가기관 및 지방자치단체, 거래처 등의 협조관계나 거래관계를 단절시킨 원인이 된 경우가 2회 이상인 경우`,
						`아래 각 호의 복무의무를 위반한 경우`,
						[
							`제11조 제1항의 권고의무의 각 호를 합한 위반 행위가 6회 이상인 때`,
							`제11조 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 5회 이상인 때`,
							`제11조 제2항 제9호 내지 제17호의 강행의무의 각 호를 합한 위반 행위가 3회 이상인 때`,
							`제11조 제3항 제1호와 제2호의 금지행위의 위반 행위가 4회 이상인 때`,
							`제11조 제3항 제3호의 금지행위의 위반 행위가 3회 이상인 때`,
						],
						`다음 각 호에 대한 조사를 위한 ${company}의 출석요구에 불응한 교직원`,
						[
							`${company} 규정 위반`,
							`${company} 업무 감사`,
							`${company} 외부 감사`,
							`국세청의 세무조사`,
							`고용노동부∙사회보험기관의 현장조사`,
							`기타 정부관계기관의 각종 조사`,
						],
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 근로계약서 및 임금계약서 작성을 거부하거나 제출하지 아니하는 행위가 2회 이상 발생한 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 ${company}의 정당한 인사명령을 거부하는 행위가 2회 이상 발생한 경우`,
					],
					`${companyUn} 직무상 의무를 위반하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`직무상 법정 교육훈련을 회피하여 이수하지 못한 경우`,
						`근무시간 중 2회 이상 개인적인 업무를 수행한 경우`,
						`${company}의 고객을 상대로 개인적인 영업을 시도한 교직원`,
						`직무상 기한을 3회 이상 준수하지 못한 경우`,
						`본 규칙의 건강검진을 받지 않는 경우가 3회 이상인 경우`,
					],
					`${companyUn} 직장의 윤리적 질서를 위반하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`원장∙중간관리자∙선후배 동료 교직원에 대한 언어폭력∙욕설∙폭언`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 험담∙모욕∙성희롱`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 직장 내 괴롭힘이 2회 이상인 경우`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 협박`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 허위사실 또는 유언비어의 유포`,
						`도덕적으로 사회적 비난을 받을 수 있는 사생활(불륜∙성매매 등)을 한 경우`,
					],
					`${companyUn} 강행법규를 위반하는 다음 각 호의 행위를 한 교직원을 해고할 수 있다.`,
					[
						`원장∙중간관리자∙선후배 동료 교직원을 쌍방 폭행하는 행위가 2회 이상인 경우`,
						`원장∙중간관리자∙선후배 동료 교직원을 일방적으로 폭행하는 행위`,
						`직장 동료에게 상해를 가하는 행위`,
					],
					`${companyUn} 다음 각 호의 절도행위를 한 교직원을 해고할 수 있다.`,
					[
						`금품과 향응의 요구∙수수`,
						`고의적인 장부기록의 조작으로 공금을 착복∙유용∙횡령`,
						`${company}의 영업비밀, 대외비 및 기타 정보를 경쟁관계에 있는 다른 사업자 및 개인 등에 제공`,
						`${company}의 영업비밀, 대외비 및 기타 정보를 포털사이트 또는 언론기관 등에 유출`,
					],
					`${companyUn} 다음 각 호의 교직원을 해고할 수 있다.`,
					[
						`사회통념상 담당업무에 요구되는 객관적인 업무능력의 부족`,
						`3개월의 업무수행 능력이 동일·유사한 업무를 담당하는 교직원의 평균 업무수행 능력의 50% 이하로 떨어진 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 부서 간 업무량의 변화 또는 특정 부서 인${company}의 이직 또는 잉여인${company}의 조정으로 인해 같은 직무수준에서 이루어지는 당직 · 배치전환 · 직위 변경 등 인사명령을 거부하는 경우`,
						`수습기간에 대한 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 C등급이나 D등급을 받고 ${companyGa} 교직원의 업무능력 향상을 위한 업무를 지시하거나, 교육을 지정했음에도 이를 거부한 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 연속하여 4회를 받았을 때`,
					],
					`${companyUn} 사회통념상 근로관계를 계속할 수 없는 기타의 중대한 사유가 있는 교직원을 해고할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `해고 예고`,
				content: [
					`${companyGa} 교직원을 해고하고자 할 경우에 그 30일 전에 예고하거나 30일분의 통상임금을 지급하면서 예고 없이 해고할 수 있다.`,
					`제1항의 규정은 다음 각 호의 어느 하나에 해당한 교직원에게는 적용하지 아니한다.`,
					[
						`교직원이 계속 근로한 기간이 3개월 미만인 경우`,
						`천재ㆍ사변, 그 밖의 부득이한 사유로 사업을 계속하는 것이 불가능한 경우`,
						`교직원이 고의로 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우로서 ｢고용노동부령｣으로 정하는 사유에 해당하는 경우`,
					],
				],
			},
			{
				title: `사직의 권고`,
				content: [
					`직무수행 능력이나 업무실적이 부족, 교직원의 귀책사유로 강한 민원의 발생 등으로 계속근로가 부족하다고 판단되는 교직원에 대해 사직을 권고할 수 있다.`,
					`징계사유에 해당하는 교직원에게 징계를 묻는 대신 사직을 권고할 수 있다.`,
				],
			},
			{
				title: `표창`,
				content: [
					`${companyUn} 공을 가려 표창할 수 있다.`,
					[
						`품행이 방정하고 업무에 근면하여 타 교직원의 모범이 되었을 때`,
						`업무에 관하여 자기의 위험을 무릅쓰고 ${company}의 손실을 모면, 감소케 하였을 때`,
						`${company}에 유익한 제도개선을 하였을 때`,
						`${company}의 성과에 특별히 기여한 때`,
						`장기근속 등 기타 ${company}에 대하여 특히 공적이 있다고 인정될 때`,
					],
				],
			},
			{
				title: `표창 심의 및 결정`,
				content: [
					`원장은 소속부서장의 의견을 물어 표창여부, 표창의 종류, 방법 등을 결정하여 집행한다.`,
				],
			},
			{
				title: `징계, 소명기회부여`,
				content: [
					`징계권은 원장에게 있다.`,
					`${companyGa} 교직원을 징계하고자 할 경우에는 징계를 위한 소명 자리 3일 전에 교직원에게 징계사유를 알리고 소명의 기회를 부여한다. 단, 징계대상자가 서면으로 소명하고 구두소명을 원치 않는 경우에는 서면 소명으로, 소명을 원치 않는 경우에는 소명 없이 징계사유 및 여부를 판단할 수 있다.`,
					`원장은 징계 여부 및 징계 종류를 결정함에 있어서는 징계대상자의 소명에 관련자의 증언 및 증거 등을 고려한다.`,
					`원장은 징계 결정 시 서면으로 당해 교직원에게 통보한다.`,
				],
			},
			{
				title: `징계의 종류 및 방법`,
				content: [
					`징계는 경고, 견책, 감급, 정직, 해고로 구분하며, 그 내용은 다음 각 호와 같다.`,
					[
						`경고 : 구두로 시정을 경고할 수 있으며, 문서로 시정요구를 경고할 수 있다.`,
						`견책 : 시말서를 제출하고 그 잘못을 반성하게 한다.`,
						`감급 : 징계처분을 받은 교직원의 임금을 감액하는 것을 말하며, 그 범위는 1회의 액이 평균임금의 1일분의 반액을, 1임금지급기에 있어서의 임금총액의 10분의 1을 초과하지 못한다.`,
						`정직 : 징계처분을 받은 교직원의 출근을 정지시키고, 그 기간 동안 직무에 종사하지 못하게 하는 것(그 기간 중의 임금은 지급하지 아니한다)을 말하며, 그 상한을 5개월로 한다.`,
						`해고 : 교직원의 신분을 박탈하여 면직시키는 것을 말한다.`,
					],
					`원장은 그 비위가 경한 경우에 한하여 주위를 환기시키고자 해당 교직원에 대하여 징계절차 없이 직권경고를 할 수 있다.`,
					`징계대상자의 징계사유로 ${companyGa} 손해를 입은 경우, 원장은 징계대상자의 소명을 듣고 변상액을 정할 수 있다. 징계대상자가 변상 결정을 받아들이는 경우 징계 종류에 반영할 수 있다.`,
				],
			},
			{
				title: `경고사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 교직원에게 서면 경고장을 발부한다.`,
					`조퇴`,
					[
						`월 단위로 조퇴를 2회 한 때`,
						`정당한 사유 없이 매월 단위로 조퇴를 1회 한 때`,
						`위 가, 나를 포함하여 1년 총 조퇴일수가 6회에 다다른 경우`,
					],
					`지각`,
					[
						`매월 단위로 지각을 2회 한 때`,
						`정당한 사유 없이 매월 단위로 지각을 1회 한 때`,
						`위 가, 나를 포함하여 1년 총 지각 횟수가 6회에 다다른 경우`,
					],
					`외출`,
					[
						`매월 단위로 무단외출을 2회 한 때`,
						`허가된 외출시간을 위반한 외출을 2회 한 때`,
					],
					`복무의무`,
					[
						`제11조 제1항의 권고의무의 각 호를 합한 위반 행위가 3회인 때`,
						`제11조 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 2회인 때`,
						`제11조 제3항 제1호와 제2호의 금지행위의 위반 행위가 1회인 때`,
					],
					`기타 위와 유사한 사유가 인정될 때`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `정직사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 교직원을 정직으로 징계한다.`,
					[
						`월 2회 이상 무단결근한 경우`,
						`월 6회 이상 지각 조퇴 외출한 경우`,
						`교직원 본인 월급 대비 20% 미만의 경미한 ${company}의 재산상 손실을 발생시킨 경우가 2회 이상이거나 교직원 본인 월급 대비 50% 이상 100% 미만의 ${company}의 재산상 손실을 발생시킨 경우`,
						`견책 이상의 징계를 1년에 2회 이상, 통산하여 3회 이상 받은 자로서 개전의 정이 없다고 인정된 경우`,
						`교직원이 전보 또는 휴직한 때 사무인계의 해태 또는 불이행으로 손해가 발생한 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 3회 이상 받았을 때`,
						`부하 교직원에게 업무와 무관한 개인적인 일을 명령함으로써 부하교직원의 원 업무 수행을 심하게 방해한 때`,
						`직장 내 괴롭힘을 한 때`,
						`근무시간 중 사적인 음주 행위를 한 때`,
						`직무상 법정 교육훈련을 이수하지 못한 경우`,
						`근무시간 중 개인적인 업무를 수행한 경우`,
						`직무상 기한을 2회 이상 준수하지 못한 경우`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 경미한 언어폭력`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 경미한 험담`,
						`원장∙중간관리자∙선후배 동료 교직원에 대한 직장 내 괴롭힘`,
						`${company} 구성원 상호간의 경미한 이간질 행위`,
						`성희롱에 준하는 행위를 한 때`,
						`직장 내 성희롱 사건 처리 관련자가 사건 처리와 관련하여 알게 된 비밀을 누설한 경우`,
						`직장 상사 또는 동료 교직원을 (쌍방)폭행하는 행위`,
						`법인카드의 개인적 사용`,
						`시간 외 근로수당의 부당취득`,
						`사내정보를 이용한 부당이득`,
						`협력기관 및 회사 등에 정보유출`,
						`동료교직원의 임금수준 및 임금구성항목을 알려고 한 경우`,
						`거래처의 협조관계나 거래관계를 단절시킨 원인이 된 경우`,
						`본 규칙의 건강검진을 받지 않는 경우가 2회 이상인 경우`,
						`허위 보고를 한 때`,
						`사회통념상 담당업무에 요구되는 업무능력의 부족`,
						`3개월의 업무수행 능력이 동일·유사한 업무를 담당하는 교직원의 평균 업무수행 능력의 30% 이하로 떨어진 경우`,
						`제1심에서 집행유예 6개월 미만의 형사처벌을 받은 교직원`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 근로계약서 및 임금계약서 작성을 거부하거나 제출하지 아니하는 행위가 발생한 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 ${company}의 정당한 인사명령을 거부하는 행위가 발생한 경우`,
						`수습기간에 대한 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 C등급이나 D등급을 받은 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 연속하여 2회를 받았을 때`,
						`기타 위와 유사한 사유가 인정될 때`,
					],
					`복무의무를 위반한 경우`,
					[
						`제11조 제1항의 권고의무의 각 호를 합한 위반 행위가 5회 이상인 때`,
						`제11조 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 4회 이상인 때`,
						`제11조 제2항 제9호 내지 제17호의 강행의무의 각 호를 합한 위반 행위가 2회 이상인 때`,
						`제11조 제3항 제1호와 제2호의 금지행위의 위반 행위가 3회 이상인 때`,
						`제11조 제3항 제3호의 금지행위의 위반 행위가 2회 이상인 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `안전 및 보건`,
				content: [
					`안전 및 보건에 관하여 ${companyWa} 교직원은 ‘산업안전보건법’ 등 관계법규에 따른다.`,
					`${companyUn} 안전 및 보건에 관해 산업안전보건법령에 따른 제반 교육을 실시한다.`,
				],
			},
			{
				title: `안전관리, 보건관리`,
				content: [
					`${companyUn} 안전유지에 필요한 조치를 강구하고 교직원과 원아의 위험방지에 노력하며, 교직원은 안전관리 시책에 적극 협조 한다.`,
					`${companyUn} 안전보건관리자를 선임하여 교직원의 건강증진에 노력하고, 교직원은 보건관리 시책에 적극 협력한다.`,
				],
			},
			{
				title: `작업환경측정`,
				content: [
					`${companyUn} ${companyGa} 산업안전보건법에 의한 작업환경측정의 대상인 경우 법에 맞는 작업환경측정을 실시한다.`,
					`${companyUn} 작업환경측정 시 교직원 대표의 요구가 있을 때에는 교직원 대표를 입회시킨다.`,
					`${companyGa} 작업환경측정을 실시한 경우 그 결과를 교직원에게 알려주며 ${companyUn} 그 결과에 따라 당해 시설 및 설비의 설치 또는 개선, 건강진단 등 적절한 조치를 한다.`,
				],
			},
			{
				title: `화재발생 등의 조치`,
				content: [
					`교직원은 화재 기타 비상사태의 발생을 발견하거나 위험한 사태가 야기될 우려가 있는 경우에는 응급조치를 취하는 동시에 즉시 그 사실을 원장에게 보고하여야 한다.`,
				],
			},
			{
				title: `보호구의 지급 및 착용`,
				content: [
					`${companyUn} 교직원이 유해ㆍ위험작업으로부터 보호 받을 수 있도록 보호구를 지급하여야 하며 교직원은 작업시 ${company}에서 지급하는 보호구를 착용하여야 한다.`,
				],
			},
			{
				title: `위험기계기구의 방호조치`,
				content: [
					`교직원은 다음 각 호의 위험기계기구의 방호조치 사항을 준수하여야 한다.`,
					[
						`방호조치를 해제하고자 할 경우 소속부서 장의 허가를 받아 해체할 것`,
						`방호조치를 해체한 후 그 사유가 소멸한 때에는 지체없이 원상으로 회복시킬 것`,
						`방호조치의 기능이 상실된 것을 발견한 때에는 지체없이 소속 부서장에게 신고할 것`,
					],
				],
			},
			{
				title: `건강진단`,
				content: [
					`${companyUn} 교직원에 대하여 매년 1회 이상(사무분야 종사 교직원은 2년에 1회 이상)의 정기건강진단과 다음 각 호의 어느 하나의 건강진단을 실시하고 교직원은 특별한 사유가 없는 한 최대한 그에 협조한다. 단, 법이 강제하는 사항이 아닌 건강진단은 원 판단에 의해 생략할 수 있다.`,
					[
						`채용 시 건강진단`,
						`유해, 위험업무에 종사하는 교직원은 관련 법령에 따른 특수ㆍ수시ㆍ임시 건강진단`,
					],
					`${companyUn} 건강진단의 결과 교직원에 대하여 일정기간 취업의 금지, 배치부서의 전환, 요양의 권유 등 안전보건상 필요한 조치를 취할 수 있다.`,
					`교직원은 건강검진에 순응하여야 하며, 정당한 사유 없이 그 결과에 따른 ${company}의 조치를 거부하지 못한다.`,
				],
			},
			{
				title: `상병발생 등의 보고`,
				content: [
					`교직원은 부상을 당하거나 발병하였을 경우에는 즉시 적절한 조치를 취하는 동시에 관계자는 보고하여야 한다.`,
					`교직원은 업무상 질병 또는 위험한 전염병이 발생한 것을 알았을 때에는 즉시 ${company}에 보고하여야 한다.`,
					`교직원은 가족 중에 전염병 또는 이와 유사한 환자가 발생하였을 경우에는 즉시 ${company}에 보고 하여야 한다.`,
					`제1항 내지 제3항의 경우 ${companyUn} 필요하다고 인정할 때는 안전보건관리자로 하여금 적절한 조치를 취하게 할 수 있다.`,
				],
			},
			{
				title: `업무상 재해에 대한 보상, 업무 외 재해의 처리`,
				content: [
					`${companyUn} 교직원의 업무상 질병, 부상 또는 사망 시 산업재해보상보험법에 의거 재해보상을 행한다.`,
					`산업재해보상보험법의 적용을 받지 않는 업무상 부상 또는 질병에 대하여는 근로기준법이 정하는 바에 따라 ${companyGa} 보상한다.`,
					`${companyUn} 업무 외의 재해에 대하여는 이 규칙에 정한 보상책임을 포함한 어떠한 책임도 지지 아니한다.`,
				],
			},
			{
				title: `후생복지 시설, 식사`,
				content: [
					`${companyUn} 사기진작의 일환으로 휴게실 등을 갖추고 교직원이 자유로이 이용할 수 있도록 한다.`,
					`${companyUn} 소정근로시간 근무자에게 복리후생 차${company}에서 식사 또는 식비를 제공할 수 있다.`,
				],
			},
			{
				title: `근무복 등의 지급`,
				content: [
					`${companyUn} 다음 각 호의 물품을 대여하거나 지급할 수 있다.`,
					[`근무복, 근무화`, `기타 필요한 물품`],
				],
			},
			{
				title: `교육ㆍ훈련`,
				content: [
					`${companyUn} 교직원으로서의 필요한 지식을 높이고 기능을 습득하게 하기 위해 필요한 교육을 행한다. 이 경우 영유아보육[어린이집] / 유아교육법[유치원] 관련 법령이 정한 바에 따른 기준을 준수하고, 고용보험법령에 따라 각종 훈련 지원제도를 최대한 활용할 수 있다.`,
					`${companyUn} 관련법령에 따라 교직원을 대상으로 안전교육을 실시한다.`,
					`${companyUn} 필요에 따라 교직원을 각종 사외교육을 수강하게 할 수 있다.`,
					`교직원은 ${companyGa} 명하는 제1항 내지 제3항의 교육명령을 정당한 이유 없이 거부할 수 없다.`,
					`${company}의 필요에 의하여 명하는 교육시간에 대하여는 유급으로 한다.`,
					`교육훈련이수자의 권리ㆍ의무에 관한 사항은 따로 정할 수 있다.`,
				],
			},
			{
				title: `육아휴직`,
				content: [
					`${companyUn} 교직원이 임신 중인 여성 교직원이 모성을 보호하거나 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)를 양육하는 교직원에게 무급의 육아휴직을 허용해야 한다.`,
					`교직원은 육아휴직을 3회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 교직원이 모성보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
					`${companyUn} 육아휴직을 시작하려는 날의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 교직원에게 육아휴직을 허용하지 아니할 수 있다.`,
					`육아휴직 기간은 1년 이내로 한다.  단, 각호에 해당하는 교직원의 경우 6개월 이내에서 추가로 육아휴직을 사용할 수 있다.`,
					[
						`한부모가정`,
						`중증장애아동부모`,
						`부모 모두 3개월 이상 육아휴직을 사용한 경우`,
					],
					`${companyUn} 육아휴직을 이유로 해고나 그 밖의 불리한 처우를 하지 않으며 특히 육아휴직기간에는 해고하지 아니한다. 다만, 사업을 계속할 수 없는 경우에는 그러하지 아니하다.`,
					`${companyUn} 교직원이 육아휴직을 사용할 경우 고용보험법령이 정하는 육아휴직급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다.`,
					`기간제 교직원 또는 파견 교직원의 육아휴직 기간은 「기간제 및 단시간근로자 보호 등에 관한 법률」 제4조에 따른 사용기간 또는 「파견근로자 보호 등에 관한 법률」 제6조에 따른 근로자파견기간에서 제외한다.`,
					`교직원은 휴직개시예정일의 30일 전까지 육아휴직 대상인 영유아의 성명, 생년월일(임신 중인 여성교직원이 육아휴직을 신청하는 경우에는 영유아의 성명을 적지 않으며, 생년월일 대신 출산 예정일을 적어야 한다), 휴직개시예정일, 육아휴직을 종료하려는 날(이하 “휴직종료예정일”이라 한다), 육아휴직 신청 연월일, 신청인 등에 대한 사항을 신청서에 적어 ${company}에게 제출해야 한다.`,
					`제8항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 휴직개시예정일 7일 전까지 육아휴직을 신청할 수 있다.`,
					[
						`임신 중인 여성 교직원에게 유산 또는 사산의 위험이 있는 경우`,
						`출산 예정일 이전에 자녀가 출생한 경우`,
						`배우자의 사망, 부상, 질병 또는 신체적ㆍ정신적 장애나 배우자와의 이혼 등으로 해당 영유아를 양육하기 곤란한 경우`,
					],
					`${companyUn} 교직원이 제8항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 30일 이내에, 제9항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 7일 이내에 육아휴직 개시일을 지정하여 육아휴직을 허용해야 한다.`,
					`${companyUn} 육아휴직을 신청한 교직원에게 임신 중인 사실을 증명할 수 있는 서류나 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					`육아휴직 중인 교직원 중 임신 중인 경우 유산 또는 사산한 경우 임신중이 아닌 경우, 그 영유아가 사망한 경우 또는 그 영유아와 동거하지 않게 된 경우(영유아의 양육에 기여하지 않는 경우로 한정한다)에는 그 사유가 발생한 날부터 7일 이내에 그 사실을 ${company}에게 알려야 한다.`,
					`${companyUn} 제12항에 따라 육아휴직 중인 교직원으로부터 영유아의 사망 등에 대한 사실을 통지받은 경우에는 통지받은 날부터 30일 이내로 근무개시일을 지정하여 그 교직원에게 알려야 한다.`,
					`교직원은 다음 각 호의 어느 하나에 해당하는 날에 육아휴직이 끝난 것으로 본다.`,
					[
						`제12항에 따라 통지를 하고 제13항에 따른 근무개시일을 통지받은 경우에는 그 근무개시일의 전날`,
						`제12항에 따라 통지를 하였으나 제13항에 따른 근무개시일을 통지받지 못한 경우에는 제12항의 통지를 한 날부터 30일이 되는 날`,
						`제12항에 따른 통지를 하지 아니한 경우에는 영유아의 사망 등의 사유가 발생한 날부터 37일이 되는 날`,
					],
					`육아휴직 중인 교직원이 새로운 육아휴직을 시작하거나 「근로기준법」 제74조에 따른 출산전후휴가 또는 제63조에 따른 육아기 근로시간 단축(이하 “육아기 근로시간 단축”이라 한다)을 시작하는 경우에는 그 새로운 육아휴직, 출산전후휴가 또는 육아기 근로시간 단축 개시일의 전날에 육아휴직이 끝난 것으로 본다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										`변경전 : `,
										`① ${companyUn} 육아휴직을 하고자 하는 교직원의 신청에 의해 육아휴직을 무급의 육아휴직을 부여하며, 그 신청 또는 육아휴직을 이유로 당해 교직원에게 불리한 처우를 하지 아니한다.`,
										`② ${companyUn} 임신 중인 여성 교직원이 모성을 보호하거나 교직원이 만 8세 이만 8세 이하 또는 초등학교 2학년 이하의 자녀를 양육하기 위하여 육아휴직을 신청하는 경우 이를 허용한다. 단, 육아휴직을 개시하고자 하는 날 이전에 당해 사업에서의 계속 근로한 기간이 6개월 미만인 교직원의 경우에는 예외로 한다.`,
										`③ 육아휴직기간은 1년 이내로 한다.`,
										`④ 교직원은 육아휴직을 2회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 교직원이 모성보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
										`⑤ 육아휴직기간은 근속기간에 포함된다. 단, 기간제근로자 보호 등에 관한 법률과 파견근로자 보호 등에 관한 법률 상의 무기계약 전환에 있어서는 그러하지 아니하다.`,
										`⑥ ${companyUn} 연차휴가일수를 계산함에 있어 육아휴직기간은 출근한 것으로 본다.`,
										`변경후 : `,
										`① ${companyUn} 교직원이 임신 중인 여성 교직원이 모성을 보호하거나 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)를 양육하는 교직원에게 무급의 육아휴직을 허용해야 한다.`,
										`② 교직원은 육아휴직을 3회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 교직원이 모성보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
										`③ ${companyUn} 육아휴직을 시작하려는 날의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 교직원에게 육아휴직을 허용하지 아니할 수 있다.`,
										`④ 육아휴직 기간은 1년 이내로 한다.  단, 각호에 해당하는 교직원의 경우 6개월 이내에서 추가로 육아휴직을 사용할 수 있다.`,
										`1. 한부모가정`,
										`2. 중증장애아동부모`,
										`3. 부모 모두 3개월 이상 육아휴직을 사용한 경우`,
										`⑤ ${companyUn} 육아휴직을 이유로 해고나 그 밖의 불리한 처우를 하지 않으며 특히 육아휴직기간에는 해고하지 아니한다. 다만, 사업을 계속할 수 없는 경우에는 그러하지 아니하다.`,
										`⑥ ${companyUn} 교직원이 육아휴직을 사용할 경우 고용보험법령이 정하는 육아휴직급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다.`,
										`⑦ 기간제 교직원 또는 파견 교직원의 육아휴직 기간은 「기간제 및 단시간근로자 보호 등에 관한 법률」 제4조에 따른 사용기간 또는 「파견근로자 보호 등에 관한 법률」 제6조에 따른 근로자파견기간에서 제외한다.`,
										`⑧ 교직원은 휴직개시예정일의 30일 전까지 육아휴직 대상인 영유아의 성명, 생년월일(임신 중인 여성교직원이 육아휴직을 신청하는 경우에는 영유아의 성명을 적지 않으며, 생년월일 대신 출산 예정일을 적어야 한다), 휴직개시예정일, 육아휴직을 종료하려는 날(이하 “휴직종료예정일”이라 한다), 육아휴직 신청 연월일, 신청인 등에 대한 사항을 신청서에 적어 ${company}에게 제출해야 한다.`,
										`⑨ 제8항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 휴직개시예정일 7일 전까지 육아휴직을 신청할 수 있다.`,
										`1. 임신 중인 여성 교직원에게 유산 또는 사산의 위험이 있는 경우`,
										`2. 출산 예정일 이전에 자녀가 출생한 경우`,
										`3. 배우자의 사망, 부상, 질병 또는 신체적ㆍ정신적 장애나 배우자와의 이혼 등으로 해당 영유아를 양육하기 곤란한 경우`,
										`⑩ ${companyUn} 교직원이 제8항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 30일 이내에, 제9항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 7일 이내에 육아휴직 개시일을 지정하여 육아휴직을 허용해야 한다.`,
										`⑪ ${companyUn} 육아휴직을 신청한 교직원에게 임신 중인 사실을 증명할 수 있는 서류나 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
										`⑫ 육아휴직 중인 교직원 중 임신 중인 경우 유산 또는 사산한 경우 임신중이 아닌 경우, 그 영유아가 사망한 경우 또는 그 영유아와 동거하지 않게 된 경우(영유아의 양육에 기여하지 않는 경우로 한정한다)에는 그 사유가 발생한 날부터 7일 이내에 그 사실을 ${company}에게 알려야 한다.`,
										`⑬ ${companyUn} 제12항에 따라 육아휴직 중인 교직원으로부터 영유아의 사망 등에 대한 사실을 통지받은 경우에는 통지받은 날부터 30일 이내로 근무개시일을 지정하여 그 교직원에게 알려야 한다.`,
										`⑭ 교직원은 다음 각 호의 어느 하나에 해당하는 날에 육아휴직이 끝난 것으로 본다.`,
										`1. 제12항에 따라 통지를 하고 제12항에 따른 근무개시일을 통지받은 경우에는 그 근무개시일의 전날`,
										`2. 제12항에 따라 통지를 하였으나 제13항에 따른 근무개시일을 통지받지 못한 경우에는 제12항의 통지를 한 날부터 30일이 되는 날`,
										`3. 제12항에 따른 통지를 하지 아니한 경우에는 영유아의 사망 등의 사유가 발생한 날부터 37일이 되는 날`,
										`⑮ 육아휴직 중인 교직원이 새로운 육아휴직을 시작하거나 「근로기준법」 제74조에 따른 출산전후휴가 또는 제63조에 따른 육아기 근로시간 단축(이하 “육아기 근로시간 단축”이라 한다)을 시작하는 경우에는 그 새로운 육아휴직, 출산전후휴가 또는 육아기 근로시간 단축 개시일의 전날에 육아휴직이 끝난 것으로 본다.`,
										`⑯ 기타 필요한 사항은 별도로 정할 수 있다.`,
										`개정이유 : '2025년 2월 23일 남녀고용평등과 일·가정 양립 지원에 관한 법률 개정`,
									],
								},
						  ]
						: null,
			},
			{
				title: `육아기 근로시간 단축`,
				content: [
					`${companyUn} 교직원이 만 12세 이하 또는 초등학교 6학년 이하의 자녀를 양육하기 위하여 근로시간의 단축(이하 “육아기 근로시간 단축”이라 한다)을 신청하는 경우에 이를 허용해야 한다. 다만, 다음 각 호의 경우에는 그러하지 아니하다.`,
					[
						`단축개시예정일의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 교직원이 신청한 경우`,
						`${companyGa} 「직업안정법」 제2조의2제1호에 따른 직업안정기관(이하 “직업안정기관”이라 한다)에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 제외한다.`,
						`육아기 근로시간 단축을 신청한 교직원의 업무 성격상 근로시간을 분할하여 수행하기 곤란하거나 그 밖에 육아기 근로시간 단축이 정상적인 사업 운영에 중대한 지장을 초래하는 경우로서 {companyGa} 이를 증명하는 경우`,
					],
					`${companyGa} 전항의 근로시간 단축을 허락하지 아니하는 경우에는 해당 교직원에게 그 사유를 서면으로 통보하고 육아휴직을 사용하도록 하고, 그 밖의 조치를 통하여 지원할 수 있는지를 해당 교직원과 협의하여야 한다`,
					`${companyGa} 제1항의 규정에 따라 육아기 근로시간 단축을 허용하는 경우에 단축 후 근로시간은 주당 15시간 이상 35시간 이하로 한다.`,
					`육아기 근로시간 단축의 기간은 1년 이내로 한다. 다만, 제62조에 따라 육아휴직을 신청할 수 있는 교직원이 육아휴직 기간 중 사용하지 아니한 기간이 있으면 그 기간을 두배 가산한 기간 이내로 한다.`,
					`${companyUn} 육아기 근로시간 단축을 이유로 해당 교직원에게 해고나 그 밖의 불리한 처우를 하지 아니한다.`,
					`${companyUn} 육아기 근로시간 단축기간이 끝난 교직원에게 육아기 근로시간 단축 전과 같은 업무 또는 같은 수준의 임금을 지급하는 직무에 복귀시킨다.`,
					`${companyUn} 교직원이 육아기 근로시간 단축을 사용할 경우 고용보험법령이 정하는 육아기 근로시간 단축 급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다`,
					`교직원은 육아기 근로시간 단축을 시작하려는 날(이하 “단축개시예정일”이라 한다)의 30일 전까지 육아기 근로시간 단축기간 중 양육하는 대상인 자녀의 성명, 생년월일, 단축개시예정일, 육아기 근로시간 단축을 종료하려는 날(이하 “단축종료예정일”이라 한다), 육아기 근로시간 단축 중 근무개시시각 및 근무종료시각, 육아기 근로시간 단축 신청 연월일, 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
					`${companyUn} 교직원이 제8항에 따른 기한이 지난 뒤에 육아기 근로시간 단축을 신청한 경우에는 그 신청일부터 30일 이내로 육아기 근로시간 단축 개시일을 지정하여 육아기 근로시간 단축을 허용해야 한다.`,
					`${companyUn} 육아기 근로시간 단축을 신청한 교직원에게 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					`교직원은 육아기 근로시간 단축을 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 1개월(근로계약기간의 만료로 1개월 이상 근로시간 단축을 사용할 수 없는 기간제교직원에 대해서는 남은 근로계약기간을 말한다) 이상이 되어야 한다.`,
					`${companyUn} 육아기 근로시간을 단축하는 교직원에게 단축된 근로시간에 비례하여 적용하는 근로조건을 제외하고는 육아기 근로시간의 단축을 이유로 해당 교직원의 근로조건을 불리하게 처우하지 아니한다.`,
					`${companyUn} 육아기 근로시간을 단축한 교직원의 근로조건은 ${companyWa} 교직원간의 서면으로 정한다.`,
					`${companyUn} 육아기 근로시간을 단축한 교직원에게 단축된 근로시간 외에 연장근로를 요구할 수 없다. 다만, 그 교직원이 서면으로 청구하는 경우에는 주 12시간 이내에서 연장근로를 할 수 있다.`,
					// `${companyUn} 육아기 근로시간을 단축한 교직원의 평균임금은 그 교직원의 육아기 근로시간 단축기간을 제외하고 산정한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2025-02-23'
						? [
								{
									date: '2025년 2월 23일 개정',
									changes: [
										`변경전 : `,
										`① ${companyUn} 전조에 따라 육아휴직을 신청할 수 있는 남녀 교직원이 육아휴직 대신 근로시간의 단축(이하 “육아기 근로시간 단축”이라 한다)을 신청하는 경우에는 이를 허용한다. 다만, 대체인력 채용이 불가능한 경우, 정상적인 사업 운영에 중대한 지장을 초래하는 경우 등 남녀고용평등 및 일ㆍ가정 양립 지${company}에 관한 법령이 정하는 경우에는 그러하지 아니하다.`,
										`② ${companyGa} 육아기 근로시간 단축을 허용하지 아니하는 경우에는 해당 교직원에게 그 사유를 서면으로 통보하고 육아휴직을 사용하게 하거나 그 밖의 조치를 통하여 지원할 수 있는지를 해당 교직원과 협의하여야 한다.`,
										`③ ${companyGa} 해당 교직원에게 육아기 근로시간 단축을 허용하는 경우 단축 후 근로시간은 주당 15시간 이상이어야 하고 35시간을 넘어서는 아니 된다.`,
										`④ 교직원의 육아기 근로시간 단축의 기간은 1년 이내로 한다. 다만, 전조 제2항에 따라 육아휴직을 신청할 수 있는 교직원이 전조에 따른 육아휴직 기간 중 사용하지 아니한 기간이 있으면 그 기간을 가산한 기간 이내로 한다.`,
										`⑤ ${companyUn} 교직원이 육아기 근로시간 단축을 사용할 경우 고용보험 법령이 정하는 육아기 근로시간 단축 급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다.`,
										`⑥ 육아기 근로시간 단축 중의 근로조건은 관련 법령이 정하는 바에 따른다.`,
										`⑦ 육아기 근로시간 단축의 사용형태는 관련 법령이 정하는 바에 따르고, 관련 법령이 교직원에게 유리하게 개정되는 경우 그에 따른다.`,
										`변경후 : `,
										`① ${companyUn} 교직원이 만 12세 이하 또는 초등학교 6학년 이하의 자녀를 양육하기 위하여 근로시간의 단축(이하 “육아기 근로시간 단축”이라 한다)을 신청하는 경우에 이를 허용해야 한다. 다만, 다음 각 호의 경우에는 그러하지 아니하다.`,
										`1. 단축개시예정일의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 교직원이 신청한 경우`,
										`2. ${companyGa} 「직업안정법」 제2조의2제1호에 따른 직업안정기관(이하 “직업안정기관”이라 한다)에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 제외한다.`,
										`3. 육아기 근로시간 단축을 신청한 교직원의 업무 성격상 근로시간을 분할하여 수행하기 곤란하거나 그 밖에 육아기 근로시간 단축이 정상적인 사업 운영에 중대한 지장을 초래하는 경우로서 {companyGa} 이를 증명하는 경우`,
										`② ${companyGa} 전항의 근로시간 단축을 허락하지 아니하는 경우에는 해당 교직원에게 그 사유를 서면으로 통보하고 육아휴직을 사용하도록 하고, 그 밖의 조치를 통하여 지원할 수 있는지를 해당 교직원과 협의하여야 한다`,
										`③ ${companyGa} 제1항의 규정에 따라 육아기 근로시간 단축을 허용하는 경우에 단축 후 근로시간은 주당 15시간 이상 35시간 이하로 한다.`,
										`④ 육아기 근로시간 단축의 기간은 1년 이내로 한다. 다만, 제62조에 따라 육아휴직을 신청할 수 있는 교직원이 육아휴직 기간 중 사용하지 아니한 기간이 있으면 그 기간을 두배 가산한 기간 이내로 한다.`,
										`⑤ ${companyUn} 육아기 근로시간 단축을 이유로 해당 교직원에게 해고나 그 밖의 불리한 처우를 하지 아니한다.`,
										`⑥ ${companyUn} 육아기 근로시간 단축기간이 끝난 교직원에게 육아기 근로시간 단축 전과 같은 업무 또는 같은 수준의 임금을 지급하는 직무에 복귀시킨다.`,
										`⑦ ${companyUn} 교직원이 육아기 근로시간 단축을 사용할 경우 고용보험법령이 정하는 육아기 근로시간 단축 급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다`,
										`⑧ 교직원은 육아기 근로시간 단축을 시작하려는 날(이하 “단축개시예정일”이라 한다)의 30일 전까지 육아기 근로시간 단축기간 중 양육하는 대상인 자녀의 성명, 생년월일, 단축개시예정일, 육아기 근로시간 단축을 종료하려는 날(이하 “단축종료예정일”이라 한다), 육아기 근로시간 단축 중 근무개시시각 및 근무종료시각, 육아기 근로시간 단축 신청 연월일, 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
										`⑨ ${companyUn} 교직원이 제8항에 따른 기한이 지난 뒤에 육아기 근로시간 단축을 신청한 경우에는 그 신청일부터 30일 이내로 육아기 근로시간 단축 개시일을 지정하여 육아기 근로시간 단축을 허용해야 한다.`,
										`⑩ ${companyUn} 육아기 근로시간 단축을 신청한 교직원에게 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
										`⑪ 교직원은 육아기 근로시간 단축을 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 1개월(근로계약기간의 만료로 1개월 이상 근로시간 단축을 사용할 수 없는 기간제교직원에 대해서는 남은 근로계약기간을 말한다) 이상이 되어야 한다.`,
										`⑫ ${companyUn} 육아기 근로시간을 단축하는 교직원에게 단축된 근로시간에 비례하여 적용하는 근로조건을 제외하고는 육아기 근로시간의 단축을 이유로 해당 교직원의 근로조건을 불리하게 처우하지 아니한다.`,
										`⑬ ${companyUn} 육아기 근로시간을 단축한 교직원의 근로조건은 ${companyWa} 교직원간의 서면으로 정한다.`,
										`⑭ ${companyUn} 육아기 근로시간을 단축한 교직원에게 단축된 근로시간 외에 연장근로를 요구할 수 없다. 다만, 그 교직원이 서면으로 청구하는 경우에는 주 12시간 이내에서 연장근로를 할 수 있다.`,
										// `${companyUn} 육아기 근로시간을 단축한 교직원의 평균임금은 그 교직원의 육아기 근로시간 단축기간을 제외하고 산정한다.`,
										`⑮ 기타 필요한 사항은 별도로 정할 수 있다.`,
										`개정이유 : '2025년 2월 23일 남녀고용평등과 일·가정 양립 지원에 관한 법률 개정`,
									],
								},
						  ]
						: null,
			},
			{
				title: `가족돌봄휴직 등`,
				content: [
					`${companyUn} 교직원이 부모, 조부모, 배우자, 자녀, 손자녀 또는 배우자의 부모(이하 "가족"이라 한다)의 질병, 사고, 노령으로 인하여 그 가족을 돌보기 위한 휴직(이하 "가족돌봄휴직"이라 한다)을 신청하는 경우 무급으로 이를 허용하여야 한다. 다만, 대체인력 채용이 불가능한 경우, 정상적인 사업 운영에 중대한 지장을 초래하는 경우 등 남녀고용평등법 시행령으로 정하는 경우에는 그러하지 아니하다.`,
					`제1항 단서에 따라 가족돌봄휴직을 허용하지 아니하는 경우에는 해당 교직원에게 그 사유를 서면으로 통보하고, 다음 각 호의 어느 하나에 해당하는 조치를 하도록 노력하여야 한다.`,
					[
						`업무를 시작하고 마치는 시간 조정`,
						`연장근로의 제한`,
						`근로시간의 단축, 탄력적 운영 등 근로시간의 조정`,
						`그 밖에 사업장 사정에 맞는 지원조치`,
					],
					`가족돌봄휴직 기간은 연간 최장 90일로 하며, 이를 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 30일 이상이 되어야 한다.`,
					`${companyUn} 교직원이 신청하는 경우 남녀고용평등 및 일ㆍ가정양립 지${company}에 관한 법률이 정한 무급의 가족돌봄휴가를 준다. 그 요건 및 절차에 관해서는 관련 법령이 정한 바에 따르되, 그 사용한 기간은 가족돌봄휴직기간에 포함된다.`,
					`${companyUn} 가족돌봄휴직ㆍ휴가를 이유로 해당 교직원을 해고하거나 근로조건을 악화시키는 등 불리한 처우를 하여서는 아니 된다.`,
					`${companyUn} 가족돌봄휴직 기간은 근속기간에 포함한다. 다만, 근로기준법에 따른 평균임금 산정기간에서는 제외한다.`,
				],
			},
			{
				title: `가족돌봄 등을 위한 근로시간 단축 등`,
				content: [
					`${companyUn} 교직원이 다음 각 호의 어느 하나의 사유로 신청하는 경우 남녀고용평등 및 일ㆍ가정양립 지${company}에 관한 법률이 정한 바에 따라 가족돌봄 등을 위한 근로시간 단축을 무급으로 허용한다. 다만, 동법 시행령으로 정한 경우에는 그러하지 아니하다.`,
					[
						`교직원이 가족의 질병, 사고, 노령으로 인해 그 가족을 돌보기 위한 경우`,
						`교직원 자신의 질병이나 사고로 인한 부상 등의 사유로 자신의 건강을 돌보기 위한 경우`,
						`55세 이상의 교직원이 은퇴를 준비하기 위한 경우`,
						`교직원의 학업을 위한 경우`,
					],
					`제1항 단서에 따라 신청을 불허하는 경우에는 해당 교직원에게 그 사유를 서면으로 통보하고 휴직을 사용하게 하거나 그 밖의 조치를 통하여 지원할 수 있는지를 해당 교직원과 협의하여야 한다.`,
					`${companyGa} 제1항에 따라 해당 교직원에게 근로시간 단축을 허용하는 경우 단축 후 근로시간은 주당 15시간 이상이어야 하고 30시간을 넘어서는 아니 된다.`,
					`근로시간 단축의 기간은 1년 이내로 한다. 다만, 제1항 제1호부터 제3호까지의 어느 하나에 해당하는 교직원은 합리적 이유가 있는 경우에 추가로 2년의 범위 안에서 근로시간 단축의 기간을 연장할 수 있다.`,
					`${companyUn} 근로시간 단축을 이유로 해당 교직원에게 해고나 그 밖의 불리한 처우를 하여서는 아니 되고, 제2항에 따라 근로시간 단축을 하고 있는 직${company}에게 근로시간에 비례하여 적용하는 경우 외에는 가족돌봄 등을 위한 근로시간 단축을 이유로 그 근로조건을 불리하게 하여서는 아니 된다.`,
					`근로시간 단축의 신청방법 및 절차 등에 필요한 사항은 별도로 정한다.`,
				],
			},
			{
				title: `직장내성희롱의 금지와 조치`,
				content: [
					`${companyUn} 사업주, 상급자, 동료 교직원 또는 고객이 그 지위를 이용하거나 업무와 관련하여 다른 교직원에게 성적인 행동 등으로 또는 이를 조건으로 고용상의 불이익을 주거나 또는 성적 굴욕감을 유발하게 하여 고용환경을 악화시키지 않도록 한다.`,
					`${companyUn} 직장 내 성희롱을 한 자에 대한 부서 전환, 징계 기타 이에 준하는 조치를 하여야 하고, 직장 내 성희롱과 관련하여 그 피해 교직원에게 고용상의 불이익한 조치를 하여서는 아니 된다.`,
				],
			},
			{
				title: `직장내성희롱예방교육`,
				content: [
					`${companyUn} 직장 내 성희롱을 예방하고 교직원의 안전한 근로환경에서 일할 수 있는 여건 조성을 위하여 원장을 포함한 전 구성원을 대상으로 직장 내 성희롱의 예방을 위한 교육을 실시한다.`,
					`직장 내 성희롱 예방교육에는 다음 각 호의 내용이 포함되어야 한다.`,
					[
						`직장 내 성희롱에 관한 법령`,
						`직장 내 성희롱 예방에 대한 ${company}의 방침 등에 관한 사항`,
						`직장 내 성희롱 발생 시의 처리절차 및 조치기준`,
						`직장 내 성희롱 피해 교직원의 고충상담 및 구제절차`,
						`기타 직장 내 성희롱 예방에 필요한 사항`,
					],
				],
			},
			{
				title: `장애인인식개선`,
				content: [
					`${companyUn} 장애인인식개선에 노력한다.`,
					`${companyUn} 전 구성원을 대상으로 1년에 1회 이상 관련 법령에 부합하는 장애인인식개선교육을 실시한다.`,
				],
			},
			{
				title: `노사협의, 고충신고 및 처리`,
				content: [
					`직장내성희롱 피해 등 고충신고는 구두 또는 서면으로 할 수 있으며 그 처리는 가급적 신속히 처리하고 그 처리결과는 신청자에게 통보한다.`,
					`${companyUn} 직장 내 성희롱 피해 교직원으로부터 고충의 신고를 받은 때에는 그 자율적인 해결을 위하여 노력하여야 한다.`,
				],
			},
			{
				title: `직장 내 괴롭힘의 금지`,
				content: [
					`${company} 또는 교직원은 직장에서의 지위 또는 관계 등의 우위를 이용하여 업무상 적정범위를 넘어 다른 교직원에게 신체적ㆍ정신적 고통을 주거나 근무환경을 악화시키는 행위(이하 "직장 내 괴롭힘"이라 한다)를 하여서는 아니 된다.`,
				],
			},
			{
				title: `직장 내 괴롭힘 예방 교육`,
				content: [
					`${companyUn} 직장 내 괴롭힘 예방을 위한 교육(이하 “직장 내 괴롭힘 예방교육”이라 한다)을 1년에 1회 실시한다.`,
					`직장 내 괴롭힘 예방교육은 1시간으로 한다.`,
					`직장 내 괴롭힘 예방교육의 내용은 다음 각 호와 같다.`,
					[
						`직장 내 괴롭힘 행위의 정의`,
						`금지되는 직장 내 괴롭힘 행위`,
						`직장 내 괴롭힘 상담절차`,
						`직장 내 괴롭힘 사건처리절차`,
						`직장 내 괴롭힘 피해자 보호를 위한 조치`,
						`직장 내 괴롭힘 행위자에 대한 조치`,
						`그 밖에 직장 내 괴롭힘 예방을 위한 내용`,
					],
					`${companyUn} 직장 내 괴롭힘 예방교육의 주요 내용을 교직원들이 쉽게 확인할 수 있도록 조치한다.`,
				],
			},
			{
				title: `직장 내 괴롭힘 발생 시 조치`,
				content: [
					`사용자 또는 교직원은 직장에서의 지위 또는 관계 등의 우위를 이용하여 업무상 적정범위를 넘어 다른 교직원에게 신체적·정신적 고통을 주거나 근무환경을 악화시키는 행위(이하 "직장 내 괴롭힘"이라 한다)를 하여서는 아니 된다.`,
					`누구든지 직장 내 괴롭힘 발생 사실을 알게 된 경우 그 사실을 ${company}에 신고할 수 있다.`,
					`${companyUn} 제1항에 따른 신고를 접수하거나 직장 내 괴롭힘 발생 사실을 인지한 경우에는 지체 없이 그 사실 확인을 위한 조사를 실시해야 한다.`,
					`${companyUn} 제2항에 따른 조사 기간 동안 직장 내 괴롭힘과 관련하여 피해를 입은 교직원 또는 피해를 입었다고 주장하는 교직원(이하 "피해 교직원 등"이라 한다)을 보호하기 위하여 필요한 경우 해당 피해 교직원 등에 대하여 근무장소의 변경, 유급휴가 명령 등 적절한 조치를 하여야 한다. 이 경우 ${companyUn} 피해 교직원 등의 의사에 반하는 조치를 하여서는 아니 된다.`,
					`직장 내 괴롭힘 사건 처리 관련자는 사건 처리와 관련하여 알게 된 비밀을 철저히 지켜야 하며 이를 누설할 시 징계한다. 다만, 조사와 관련된 내용을 사업주에게 보고하거나 관계 기관의 요청에 따라 필요한 정보를 제공하는 경우는 제외한다.`,
					`${companyUn} 제2항에 따른 조사 결과 직장 내 괴롭힘 발생 사실이 확인된 때에는 피해 교직원이 요청하면 근무장소의 변경, 배치전환, 유급휴가 명령 등 적절한 조치를 하여야 한다.`,
					`${companyUn} 제2항에 따른 조사 결과 직장 내 괴롭힘 발생 사실이 확인된 때에는 지체 없이 행위자에 대하여 징계, 근무장소의 변경 등 필요한 조치를 하여야 한다. 이 경우 사용자는 징계 등의 조치를 하기 전에 그 조치에 대하여 피해 교직원의 의견을 들어야 한다.`,
					`${companyUn} 직장 내 괴롭힘 발생 사실을 신고한 교직원 및 피해 교직원 등에게 해고나 그 밖의 불리한 처우를 하여서는 아니 된다.`,
				],
			},
			{
				title: `규칙 외 사항`,
				content: [
					`이 규칙에서 정한 것 이외에는 노동관계 법령 기타 통상관례 또는 사회상규에 의한다.`,
				],
			},
			{
				title: `취업규칙의 변경`,
				content: [
					`이 규칙을 변경할 때에는 교직원의 과반수로 조직된 노동조합이 있는 경우 그 노동조합, 교직원의 과반수로 조직된 노동조합이 없는 경우 교직원의 과반수 의견을 청취하도록 한다. 다만, 취업규칙을 불리하게 변경하는 경우에는 그 동의를 받아야 한다.`,
				],
			},
			{
				title: `취업규칙의 비치`,
				content: [
					`${companyUn} 이 규칙을 ${company} 내의 사무실 등에 비치하여 교직원들이 자유롭게 열람할 수 있도록 한다.`,
				],
			},
			// {
			// 	title: `부칙(2017.03.01. 제정)`,
			// 	content: [`이 규칙은 2017년 03월 01일부터 시행한다.`],
			// },
			// {
			// 	title: `부칙(2022.03.01. 전면개정)`,
			// 	content: [`이 규칙은 2022년 03월 01일부터 시행한다.`],
			// },
			{
				title: `부칙( ${roeExcuteDueDate} 개정)`,
				content: [
					`이 규칙은 ${roeExcuteDueDate}부터 시행한다.`,
					`이 규정의 시행 이전에 임용된 교직원도 원칙적으로 이 규정을 준수하여야 한다.`,
					`사업의 원활한 운영을 위하여 필요한 경우 이 규칙에서 정한 사항의 세부사항이나, 이 규칙에서 정한 사항 이외에 교직원 근무 수칙 및 내부규정에 따라 정할 수 있으며, 이 경우 이 규칙과 동일한 효력을 가진다.`,
				],
			},
		];
		// if (['대한민', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사${company}의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사${company}의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사${company}의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사${company}의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사${company}의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사${company}의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }
		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [
		company,
		companyGa,
		companyWa,
		companyUn,
		roeExcuteDueDate,
		serviceStartDate,
		profile,
		finBaseDateMonth,
		finBaseDateDate,
		privateSchool,
	]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			{sections?.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + 1} // 동적으로 number 생성
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
