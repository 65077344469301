import React, { useRef, useState } from 'react';
import ClientReqListItem4InMonth from '../clientsReqDashboard4In/dashboardListItemDetail/ClientReqListItem4InMonth';
import ClientReqListItem4InDay from '../clientsReqDashboard4In/dashboardListItemDetail/ClientReqListItem4InDay';
import { Button, Input } from 'semantic-ui-react';
import ClientReqListItem4InBiz from './dashboardListItemDetail/ClientReqListItem4InBiz';

export default function ClientReqList4In({
	authLevel,
	lastPreventData,
	clientReqs4In,
	searchResults,
	searchTerm,
	companyName,
	handleChange,
	setSortCriterion,
	sortCriterion,
}) {
	const scrollContainerRef = useRef(null);

	const [showMonthWorkerNow, setShowMonthWorkerNow] = useState(true);
	const [showDayWorkerNow, setShowDayWorkerNow] = useState(true);

	const monthWorerDataNow = (searchResults) => {
		return searchResults.filter(
			(row) => !row.dayWorker && !row.isBizWorker && !row?.retiredDate
		);
	};

	const monthWorerDataRetired = (searchResults) => {
		// 필터링: 퇴사한 월간 근무자만 선택
		const filteredResults = searchResults.filter(
			(row) => !row.dayWorker && !row.isBizWorker && row?.retiredDate
		);
		// 정렬: 퇴사일 순으로 정렬
		filteredResults.sort(
			(a, b) => new Date(b.retiredDate) - new Date(a.retiredDate)
		);
		return filteredResults;
	};

	const bizWorerData = (searchResults) => {
		return searchResults.filter((row) => row.isBizWorker);
	};

	const dayWorerData = (searchResults) => {
		return searchResults.filter((row) => row.dayWorker);
	};

	const dayWorerDataNow = (searchResults) => {
		return searchResults.filter((row) => row.dayWorker && !row?.retiredDate);
	};

	const dayWorerDataRetired = (searchResults) => {
		const filteredResults = searchResults.filter(
			(row) => row.dayWorker && row?.retiredDate
		);
		filteredResults.sort(
			(a, b) => new Date(b.retiredDate) - new Date(a.retiredDate)
		);
		return filteredResults;
	};

	// 맨 위로 스크롤하는 함수
	const scrollToTop = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTop = 0;
		}
	};

	// 맨 아래로 스크롤하는 함수
	const scrollToBottom = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTop =
				scrollContainerRef.current.scrollHeight;
		}
	};

	return (
		<>
			<br />
			<hr />
			{authLevel > 10 && clientReqs4In?.length > 0 && (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '15px',
							marginBottom: '15px',
						}}>
						<br />
						<h4 style={{ margin: 0 }}>(전체 검색) 직원성명</h4>:{' '}
						<Input
							type='text'
							placeholder='Search'
							value={searchTerm}
							onChange={handleChange}
							width='30px'
							style={{ width: '100px', minWidth: '100px' }}
						/>{' '}
						<Button
							secondary
							onClick={() => {
								setSortCriterion((prev) => {
									// 기준이 'workerEnteredDate'이면 순서 토글, 아니면 새 기준으로 변경
									if (prev.key === 'name') {
										return {
											...prev,
											order: prev.order === 'asc' ? 'desc' : 'asc',
										};
									}
									return { key: 'name', order: 'asc' };
								});
							}}
							content={`이름${
								sortCriterion.key === 'name' && sortCriterion.order === 'asc'
									? '▲'
									: '▼'
							}`}
						/>
						<Button
							secondary
							onClick={() => {
								setSortCriterion((prev) => {
									// 기준이 'workerEnteredDate'이면 순서 토글, 아니면 새 기준으로 변경
									if (prev.key === 'workerEnteredDate') {
										return {
											...prev,
											order: prev.order === 'asc' ? 'desc' : 'asc',
										};
									}
									return { key: 'workerEnteredDate', order: 'asc' };
								});
							}}
							content={`입사일${
								sortCriterion.key === 'workerEnteredDate' &&
								sortCriterion.order === 'asc'
									? '▲'
									: '▼'
							}`}
						/>
						<h4 style={{ margin: 0 }}>월급직(정규/계약)</h4>
						<Button
							primary
							onClick={() => setShowMonthWorkerNow(!showMonthWorkerNow)}
							content={
								!showMonthWorkerNow
									? '월급직 재직자 보기'
									: '월급직 퇴사자 보기'
							}
						/>
						<Button primary onClick={scrollToTop}>
							맨 위로
						</Button>
						<Button secondary onClick={scrollToBottom}>
							맨 아래로
						</Button>
					</div>
					<hr />
				</>
			)}
			{clientReqs4In?.length !== 0 && (
				<>
					<div
						ref={scrollContainerRef}
						style={{ overflowY: 'auto', maxHeight: '1000px' }}>
						{showMonthWorkerNow && (
							<>
								{monthWorerDataNow(searchResults).map((searchResult, index) => (
									<ClientReqListItem4InMonth
										key={`${searchResult.id}-${index}`} // 고유한 key를 생성
										lastPreventData={lastPreventData}
										clientReq4In={searchResult}
										index={index}
										clientReqs4In={clientReqs4In}
										authLevel={authLevel}
										companyName={companyName}
									/>
								))}
							</>
						)}
						{!showMonthWorkerNow && (
							<>
								{monthWorerDataRetired(searchResults).length > 0 ? (
									<>
										{monthWorerDataRetired(searchResults).map(
											(searchResult, index) => (
												<ClientReqListItem4InMonth
													key={`${searchResult.id}-${index}`} // 고유한 key를 생성
													lastPreventData={lastPreventData}
													clientReq4In={searchResult}
													index={index}
													clientReqs4In={clientReqs4In}
													authLevel={authLevel}
													companyName={companyName}
												/>
											)
										)}
									</>
								) : (
									<h1>퇴사자는 없습니다.</h1>
								)}
							</>
						)}
						{dayWorerData(searchResults).length > 0 ? (
							<>
								<h4 style={{ marginTop: '50px' }}>단시간(일용/시급)</h4>
								<Button
									secondary
									onClick={() => setShowDayWorkerNow(!showDayWorkerNow)}
									content={
										!showDayWorkerNow
											? '일용/시급직 재직자 보기'
											: '일용/시급직 퇴사자 보기'
									}
								/>
								{showDayWorkerNow && (
									<>
										{dayWorerDataNow(searchResults).map(
											(searchResult, index) => (
												<ClientReqListItem4InDay
													key={`${searchResult.id}-${index}`} // 고유한 key를 생성
													clientReq4In={searchResult}
													index={index}
													clientReqs4In={clientReqs4In}
													authLevel={authLevel}
													companyName={companyName}
												/>
											)
										)}
									</>
								)}
								{!showDayWorkerNow && (
									<>
										{dayWorerDataRetired(searchResults).length > 0 ? (
											<>
												{dayWorerDataRetired(searchResults).map(
													(searchResult, index) => (
														<ClientReqListItem4InDay
															key={`${searchResult.id}-${index}`} // 고유한 key를 생성
															clientReq4In={searchResult}
															index={index}
															clientReqs4In={clientReqs4In}
															authLevel={authLevel}
															companyName={companyName}
														/>
													)
												)}
											</>
										) : (
											<h1>퇴사자는 없습니다.</h1>
										)}
									</>
								)}
							</>
						) : null}
						{bizWorerData(searchResults).length > 0 && (
							<>
								<h4 style={{ marginTop: '50px' }}>사업소득직</h4>
								{bizWorerData(searchResults).map((searchResult, index) => (
									<ClientReqListItem4InBiz
										key={`${searchResult.id}-${index}`} // 고유한 key를 생성
										clientReq4In={searchResult}
										index={index}
										clientReqs4In={clientReqs4In}
										authLevel={authLevel}
										companyName={companyName}
										bizIncomeWorker={searchResult.isBizWorker}
									/>
								))}
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}
