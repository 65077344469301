import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import { koreanHolidays } from '../../../../../app/common/util/util';
import { getWeekNumber } from '../../../../../app/common/util/dateUtil';

export default function ClientReqDashboard4InCalendarMonthCompany({
	selectedYear,
	selectedMonth,
	attendanceData,
	categories,
	activeCategory,
	onCategoryChange,
	workingDays,
	paidOffDay,
}) {
	// 카테고리별 테이블 렌더링
	const renderCategoryTable = (category) => {
		// 📌 [월간 데이터 렌더링] -----------------------------------------
		const firstDay = new Date(selectedYear, selectedMonth - 1, 1).getDay(); // 첫날 요일 (0: 일요일)
		const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate(); // 해당 월의 총 일수

		let weeks = [];
		let week = new Array(7).fill(null); // 한 주 배열 (일~토)
		let dateRow = new Array(7).fill(''); // 날짜 배열 (1~31일 표시)
		let dayCount = 1 - firstDay; // 첫 주는 요일에 맞춰 시작

		while (dayCount <= daysInMonth) {
			for (let i = 0; i < 7; i++) {
				if (dayCount > 0 && dayCount <= daysInMonth) {
					let currentDay = dayCount; // ✅ dayCount 복사 (ESLint 문제 해결)
					dateRow[i] = currentDay; // 날짜 표시

					// ✅ 해당 날짜의 모든 데이터를 배열로 가져오기
					const dataEntries = attendanceData
						.filter(
							(row) => row.month === `${selectedYear}년 ${selectedMonth}월`
						)
						.flatMap((row) =>
							row.days[currentDay - 1] ? [row.days[currentDay - 1]] : []
						);
					const filteredEntries =
						category === '전체'
							? dataEntries.flat() // 🔹 "전체"일 때 모든 데이터를 보여줌
							: dataEntries
									.flat()
									.filter(
										(entry) =>
											typeof entry === 'string' && entry.startsWith(category)
									);

					// ✅ 달력이 사라지지 않도록 빈 데이터라도 표시
					if (filteredEntries.length > 0) {
						week[i] = week[i]
							? [...week[i], ...filteredEntries]
							: [...filteredEntries];
					} else {
						week[i] = ['']; // ✅ 데이터가 없을 경우 '-'를 표시하여 빈칸 방지
					}
				} else {
					week[i] = []; // 빈칸도 배열로 유지
					dateRow[i] = ''; // 빈 날짜
				}
				dayCount++; // ✅ 반복문 이후 증가 (ESLint 문제 방지)
			}

			// 해당 주의 첫 실제 날짜(빈 칸 제외)를 찾고, 주 번호 계산
			const firstActualDay = dateRow.find((d) => d !== '');
			const weekStartDate = new Date(
				selectedYear,
				selectedMonth - 1,
				firstActualDay || 1
			);
			const currentWeekNumber = getWeekNumber(weekStartDate);

			weeks.push({
				weekNumber: currentWeekNumber,
				dates: [...dateRow],
				values: [...week],
			}); // ✅ 데이터 배열 유지

			week = new Array(7).fill([]); // 다음 주 초기화 (배열 유지)
			dateRow.fill(''); // 날짜 초기화
		}

		return (
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							style={{ minWidth: '100px', textAlign: 'center' }}>
							주(연도 기준)
						</Table.HeaderCell>
						{['일', '월', '화', '수', '목', '금', '토'].map((d, i) => {
							let headerText = d;
							let headerStyle = { minWidth: '100px', textAlign: 'center' };

							if (workingDays?.some((wd) => wd.day === d)) {
								headerText += '<br />(근무일)';
							} else if (d === paidOffDay) {
								headerText += '<br />(주휴일)';
								headerStyle = { ...headerStyle, color: 'red' }; // 주휴일 빨간색 표시
							}

							return (
								<Table.HeaderCell key={i} style={headerStyle}>
									<span dangerouslySetInnerHTML={{ __html: headerText }} />
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{weeks.map((week, index) => (
						<React.Fragment key={index}>
							<Table.Row>
								<Table.Cell
									rowSpan='2'
									style={{ textAlign: 'center', fontWeight: 'bold' }}>
									{week.weekNumber}주
								</Table.Cell>
								{week.dates.map((date, i) => {
									if (!date)
										return (
											<Table.Cell
												key={i}
												style={{ minHeight: '150px' }}></Table.Cell>
										); // 빈칸 처리

									const currentDate = new Date(
										selectedYear,
										selectedMonth - 1,
										date
									);
									currentDate.setHours(12, 0, 0, 0); // 🔹 한국 시간 기준으로 날짜 고정

									const formattedDate = currentDate.toISOString().split('T')[0];
									const dayOfWeek = currentDate.getDay();

									let style = { textAlign: 'center', minHeight: '150px' };
									if (koreanHolidays[selectedYear]?.includes(formattedDate)) {
										style = { ...style, color: 'red', fontWeight: 'bold' }; // 공휴일
									} else if (dayOfWeek === 0) {
										style = { ...style, color: 'red' }; // 일요일
									} else if (dayOfWeek === 6) {
										style = { ...style, color: 'blue' }; // 토요일
									}

									return (
										<Table.Cell key={i} style={style}>
											{date}
										</Table.Cell>
									);
								})}
							</Table.Row>
							<Table.Row>
								{week.values.map((entries, i) => (
									<Table.Cell
										key={i}
										style={{
											height: '50px', // ✅ 높이 강제 고정
											minHeight: '50px', // ✅ 최소 높이도 유지
											verticalAlign: 'top',
											padding: '5px',
											textAlign: 'center',
											whiteSpace: 'pre-wrap',
											overflow: 'hidden', // ✅ 내용이 넘칠 경우 숨김 (선택 사항)
										}}>
										{Array.isArray(entries) && entries.length > 0 ? (
											(() => {
												// 직원별 데이터 그룹화
												const groupedEntries = entries.reduce((acc, entry) => {
													const parts = entry.split(' '); // 공백을 기준으로 나눔
													if (parts.length < 2) return acc;

													const name = parts.pop(); // 마지막 단어를 직원 이름으로 추정
													const content = parts.join(' '); // 나머지는 해당 내용

													if (!acc[name]) {
														acc[name] = [];
													}
													acc[name].push(content);
													return acc;
												}, {});

												return Object.entries(groupedEntries).map(
													([name, contents], idx) => (
														<div
															key={idx}
															style={{ display: 'block', padding: '2px 0' }}>
															<span style={{ color: 'blue' }}>{name}</span> :{' '}
															{contents.join(', ')}
														</div>
													)
												);
											})()
										) : (
											<div style={{ minHeight: '20px', color: '#bbb' }}>-</div> // 데이터가 없을 경우 '-'
										)}
									</Table.Cell>
								))}
							</Table.Row>
						</React.Fragment>
					))}
				</Table.Body>
			</Table>
		);
	};

	return (
		<Tab
			menu={{ secondary: true, pointing: true }}
			activeIndex={
				categories.includes(activeCategory)
					? categories.indexOf(activeCategory) + 1 // "전체" 탭이 추가되었으므로 인덱스 조정
					: 0
			}
			onTabChange={(e, { activeIndex }) => {
				const newCategory =
					activeIndex === 0 ? '전체' : categories[activeIndex - 1]; // "전체" 탭 보정
				onCategoryChange(newCategory);
			}}
			panes={[
				{
					menuItem: '전체',
					render: () => <Tab.Pane>{renderCategoryTable('전체')}</Tab.Pane>,
				},
				...categories.map((category) => ({
					menuItem: category,
					render: () => <Tab.Pane>{renderCategoryTable(category)}</Tab.Pane>,
				})),
			]}
		/>
	);
}
