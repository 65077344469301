import axios from 'axios';
import pLimit from 'p-limit';
import { markEmailAsSent } from '../../firestore/firestoreService4In';
import captureAndUpload from './captureAndUpload';

const sendEmails = async (employees, options) => {
	const {
		isBulk,
		API_BASE_URL,
		isMounted,
		history,
		storageName,
		docPath,
		divId,
		finalAddress,
		controller,
		signal,
		setBulkEmailStatus,
	} = options;
	try {
		setBulkEmailStatus(null);

		// 이메일 주소 및 승인 상태 검증
		const employeesWithoutEmail = employees.filter(
			(emp) =>
				!emp.workerEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emp.workerEmail)
		);
		if (employeesWithoutEmail.length > 0) {
			const workerNames = employeesWithoutEmail
				.map((emp) => emp.workerName)
				.join(', ');
			alert(
				`🚨 이메일 등록 오류: ${workerNames}, 이메일 주소를 정확히 입력하세요.`
			);
			setBulkEmailStatus({
				success: false,
				message: '이메일이 없거나 문제가 있습니다.',
			});
			controller.abort();
			return;
		}

		if (storageName === 'paydocu') {
			const unapprovedEmployees = employees.filter(
				(emp) => !emp.isCompanyComformedTime
			);
			if (unapprovedEmployees.length > 0) {
				const workerNames = unapprovedEmployees
					.map((emp) => emp.workerName)
					.join(', ');
				alert(
					`🚨 승인되지 않은 사용자: ${workerNames}, 급여 승인을 먼저 진행하세요.`
				);
				setBulkEmailStatus({
					success: false,
					message: '승인되지 않은 직원이 있습니다. 급여 승인을 진행하세요.',
				});
				controller.abort();
				return;
			}
		}

		// 토큰 생성 요청
		const response = await axios.post(
			`${API_BASE_URL}/api/batchGenerateAccessTokens`,
			{ employees, docPath },
			{ signal }
		);

		if (!response.data || !Array.isArray(response.data.tokenUrls)) {
			throw new Error('백엔드 응답 오류');
		}

		const employeesWithTokens = employees.map((emp, index) => ({
			...emp,
			token: response.data.tokenUrls[index]?.url || null,
		}));

		const finalEmployees = employeesWithTokens.filter((emp) => emp.token);
		if (finalEmployees.length === 0) {
			setBulkEmailStatus({
				success: false,
				message: '유효한 직원이 없습니다.',
			});
			return;
		}

		// 동시 작업 제한 (필요시 bulk 발송도 1건씩 처리하도록 pLimit(1) 적용)
		const limit = isBulk ? pLimit(1) : (fn) => fn();

		let failedEmployees = [];

		await Promise.all(
			finalEmployees.map((emp) =>
				limit(async () => {
					try {
						const imageUrl = await captureAndUpload(
							emp.documentId,
							emp.workerId,
							emp.workerName,
							emp.workerEmail,
							emp.companyId,
							emp.companyName,
							emp.yearMonth,
							emp.isCompanyComformedTime,
							emp.token,
							history,
							docPath,
							divId,
							finalAddress,
							storageName
						);

						if (!imageUrl) throw new Error('이미지 업로드 실패');

						// targetEmail이 있다면 우선 사용 (없으면 workerEmail 사용)
						await axios.post(`${API_BASE_URL}/api/sendEmailApi`, {
							employees: [
								{
									documentId: emp.documentId,
									companyName: emp.companyName,
									workerName: emp.workerName,
									workerEmail: emp.targetEmail || emp.workerEmail,
									yearMonth: emp.yearMonth,
									imageUrl,
								},
							],
							storageName: storageName,
						});

						await markEmailAsSent(emp.documentId, storageName);
					} catch (error) {
						console.error(
							`🚨 ${emp.workerName}님에게 이메일 발송 실패:`,
							error
						);
						failedEmployees.push({
							workerName: emp.workerName,
							error: error.message || '알 수 없는 오류',
						});
					}
				})
			)
		);
		if (isMounted.current) {
			if (failedEmployees.length > 0) {
				setBulkEmailStatus({
					success: false,
					message: `${failedEmployees.length}건의 이메일 발송 실패`,
					details: failedEmployees,
				});
			} else {
				setBulkEmailStatus({
					success: true,
					message: '이메일 발송 완료',
				});
			}
		}
	} catch (error) {
		if (isMounted.current) {
			console.error('🚨 이메일 발송 오류:', error);
			setBulkEmailStatus({ success: false, message: '이메일 발송 실패' });
		}
	}
};

export default sendEmails;
