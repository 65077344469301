import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { realdb } from '../../../../../app/config/firebase';
import { ref, onValue, off, update } from 'firebase/database';
import {
	calculateWorkDuration,
	formattedTimeUnixTimestampToHHMMSS,
	formatTime,
	subtractBreakOverlap,
} from '../../../../../app/common/util/dateUtil';
import RealtimeCalendarCompany from './RealtimeCalendarCompany';

export default function RealtimeCalendarDisplayCompany({
	selectedYear,
	selectedMonth,
	companyId,
	workingDays,
	paidOffDay,
	useWorkingDays,
}) {
	const [groupedData, setGroupedData] = useState({});
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [editData, setEditData] = useState(null);

	const manageRight = true; // 근태 관리 권한
	// Firebase 데이터 연결
	useEffect(() => {
		if (!companyId) return;

		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}`;
		const attendanceRef = ref(realdb, path);

		let isMounted = true; // ✅ 컴포넌트가 마운트되었는지 여부를 추적

		const handleDataChange = (snapshot) => {
			if (!isMounted) return; // ✅ 언마운트된 경우 상태 업데이트 방지

			const rawData = snapshot.val() || {};
			const processedData = {};

			// 데이터 재구조화
			Object.entries(rawData).forEach(([workerId, workerData]) => {
				Object.entries(workerData).forEach(([attendanceId, attendance]) => {
					if (attendance.deleted) return;

					const day = new Date(parseInt(attendance.checkInTime))
						.getDate()
						.toString();

					if (!processedData[day]) processedData[day] = [];

					const checkInTime = parseInt(attendance.checkInTime);
					const checkOutTime = attendance.checkOutTime
						? parseInt(attendance.checkOutTime)
						: '';

					const breaks =
						attendance?.breakTimes?.map((breakItem) => ({
							startTime: parseInt(breakItem.startTime),
							endTime: breakItem.endTime ? parseInt(breakItem.endTime) : '',
						})) || [];

					const earlyLeave = attendance.earlyLeave
						? {
								startTime: parseInt(attendance.earlyLeave.startTime),
								endTime: attendance.earlyLeave.endTime
									? parseInt(attendance.earlyLeave.endTime)
									: '',
						  }
						: null;

					const outingTimes =
						attendance.outingTimes?.map((outing) => ({
							startTime: parseInt(outing.startTime),
							endTime: outing.endTime ? parseInt(outing.endTime) : '',
						})) || [];

					processedData[day].push({
						...attendance,
						id: attendanceId,
						workerId,
						workerName: attendance.workerName,
						checkIn: checkInTime,
						checkOut: checkOutTime,
						breaks: breaks ? breaks : [],
						earlyLeave: earlyLeave,
						outingTimes: outingTimes,
					});
				});
			});

			if (isMounted) {
				setGroupedData(processedData); // ✅ 마운트된 경우에만 상태 업데이트
			}
		};

		onValue(attendanceRef, handleDataChange);

		return () => {
			isMounted = false; // ✅ 언마운트 시 플래그 변경하여 상태 업데이트 차단
			off(attendanceRef, handleDataChange); // ✅ Firebase 구독 해제
		};
	}, [companyId, selectedYear, selectedMonth]);

	const handleDelete = (workerId, dataId) => {
		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${dataId}`;
		const attendanceRef = ref(realdb, path);

		update(attendanceRef, { deleted: true }) // deleted 플래그 설정
			.then(() => {
				console.log('데이터 삭제 처리 성공');
			})
			.catch((error) => {
				console.error('데이터 삭제 처리 실패:', error);
			});
	};

	let unsubscribeEdit; // 전역 혹은 컴포넌트 내부 변수로 선언

	const handleEdit = (workerId, dataId) => {
		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${dataId}`;
		const attendanceRef = ref(realdb, path);

		unsubscribeEdit = onValue(attendanceRef, (snapshot) => {
			const data = snapshot.val();
			setEditData({
				...data,
				id: dataId,
				workerId,
				// Firebase 필드명 → 컴포넌트 필드명 매핑
				checkIn: data.checkInTime,
				checkOut: data.checkOutTime,
				breaks:
					data?.breakTimes?.map((b) => ({
						startTime: Number(b.startTime),
						endTime: Number(b.endTime),
					})) || [],
			});
			setEditModalOpen(true);
		});
	};

	// 컴포넌트가 언마운트될 때 unsubscribe
	useEffect(() => {
		return () => {
			if (unsubscribeEdit) {
				unsubscribeEdit();
			}
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// 주요 수정 부분 (handleUpdate 함수 전체 재작성)
	const handleUpdate = async (workerId) => {
		try {
			// 1) 필수 필드 검증
			if (!editData || !workerId || !editData.id) {
				throw new Error('필수 데이터가 누락되었습니다');
			}

			// 2) 날짜 정보 추출 (checkInTime 기준)
			const checkInDate = new Date(editData.checkIn);
			const year = checkInDate.getFullYear();
			const month = checkInDate.getMonth() + 1;

			// 4) 업데이트 데이터 구조 정제
			const updateData = {
				checkInTime: editData?.checkIn,
				checkOutTime: editData?.checkOut,
				breakTimes: editData?.breaks ? editData?.breaks : [],
				earlyLeave: editData?.earlyLeave ? editData?.earlyLeave : null,
				outingTimes: editData?.outingTimes ? editData?.outingTimes : [],
			};

			// 5) Firebase 경로 생성
			const path = `attendance/${companyId}/${year}/${month}/${workerId}/${editData.id}`;
			const attendanceRef = ref(realdb, path);

			// 6) 업데이트 실행
			await update(attendanceRef, updateData);

			setEditModalOpen(false);
			console.log('성공적으로 업데이트 완료');
		} catch (error) {
			console.error('업데이트 실패 상세:', {
				error,
				sentData: editData,
				workerId,
			});
			alert(`수정 실패: ${error.message}`);
		}
	};
	// 일자별 데이터 렌더링
	const renderDayEntries = (day) => {
		const entries = groupedData[day] || [];
		return entries?.map((entry, idx) => {
			let lateTime = null;
			let earlyLeaveTime = null;

			// 🔹 새로운 출퇴근 기준 시간 (근로계약 상의 출퇴근 시간)
			const scheduledCheckIn = entry.contractWorkingStartTime;
			const scheduledCheckOut = entry.contractWorkingEndTime;
			const breakIntervals = entry.contractBreakTime || [];

			// 🔹 지각 계산 (예정 출근시간보다 늦게 출근했을 경우)
			if (entry.checkIn > scheduledCheckIn) {
				lateTime = subtractBreakOverlap(
					scheduledCheckIn,
					entry.checkIn,
					breakIntervals
				);
			}

			// 🔹 조기퇴근 계산 (예정 퇴근시간보다 일찍 퇴근했을 경우)
			if (entry?.earlyLeave?.startTime < scheduledCheckOut) {
				earlyLeaveTime = subtractBreakOverlap(
					entry?.earlyLeave?.startTime,
					scheduledCheckOut,
					breakIntervals
				);
			}
			return (
				<div
					key={entry.id}
					style={{
						marginBottom: '10px',
						borderBottom: '1px solid #eee',
						padding: '5px',
					}}>
					<div style={{ color: 'black' }}>
						<strong>▶ 성명: {entry.workerName || 'Unknown'}</strong>
					</div>
					<div style={{ color: 'green' }}>
						▶ 출근: {formattedTimeUnixTimestampToHHMMSS(entry.checkIn)}
					</div>

					{/* 🔹 지각 시간이 있으면 표시 */}
					{lateTime > 0 && (
						<div style={{ color: 'red', marginLeft: '15px' }}>
							⏳ 지각: {formatTime(lateTime)}
						</div>
					)}

					{/* 🔹 휴게 시간 표시 */}
					{entry?.breaks?.map((breakItem, breakIdx) => (
						<div key={breakIdx} style={{ marginLeft: '15px', color: '#666' }}>
							<div>
								⏸️ 휴게시작:{' '}
								{formattedTimeUnixTimestampToHHMMSS(breakItem.startTime)}
							</div>
							<div>
								▶️ 휴게종료:{' '}
								{formattedTimeUnixTimestampToHHMMSS(breakItem.endTime)}
							</div>
						</div>
					))}
					{/* 🔹 외출 시간 표시 */}
					{entry.outingTimes?.map((outing, outingIdx) => (
						<div
							key={outingIdx}
							style={{ marginLeft: '15px', color: 'purple' }}>
							<div>
								🚶‍♂️ 외출시작:{' '}
								{formattedTimeUnixTimestampToHHMMSS(outing?.startTime)}
							</div>
							<div>
								🚶‍♂️ 외출종료:{' '}
								{formattedTimeUnixTimestampToHHMMSS(outing?.endTime)}
							</div>
						</div>
					))}

					{/* 🔹 조기퇴근 시간이 있으면 표시 */}
					{earlyLeaveTime > 0 && (
						<div style={{ color: 'purple', marginLeft: '15px' }}>
							⏳ 조기퇴근: {formatTime(earlyLeaveTime)}
						</div>
					)}

					{/* 🔹 퇴근 시간 표시 */}
					{entry.checkOut && (
						<div style={{ color: 'blue' }}>
							▶ 퇴근:{' '}
							{entry.checkOut
								? formattedTimeUnixTimestampToHHMMSS(
										entry.checkOut,
										entry.checkIn
								  )
								: '퇴근 미기록'}
							<br />
							{manageRight && (
								<>
									<Button
										size='mini'
										color='red'
										onClick={() => handleDelete(entry?.workerId, entry.id)}>
										삭제
									</Button>
									<Button
										size='mini'
										color='blue'
										onClick={() => handleEdit(entry?.workerId, entry.id)}>
										수정
									</Button>
								</>
							)}
						</div>
					)}

					{/* 🔹 총 근무 시간 */}
					<div>
						총 근무시간:{' '}
						{calculateWorkDuration(
							entry.checkIn,
							entry.checkOut,
							entry.breaks,
							entry.outingTimes,
							entry.earlyLeave
						)}
					</div>

					<br />
				</div>
			);
		});
	};

	// 휴게 추가 버튼 핸들러
	const addBreakTime = () => {
		setEditData((prev) => ({
			...prev,
			breaks: [
				...(prev.breaks || []),
				{
					// breaks가 없을 경우 초기화
					startTime: Date.now(),
					endTime: Date.now(),
				},
			],
		}));
	};

	const removeBreakTime = (index) => {
		setEditData((prev) => ({
			...prev,
			breaks: prev.breaks.filter((_, i) => i !== index),
		}));
	};

	// 외출 시간 추가
	const addOutingTime = () => {
		const baseTime = editData.checkIn || Date.now();
		const newOuting = {
			startTime: new Date(baseTime).setHours(14, 0, 0, 0),
			endTime: new Date(baseTime).setHours(14, 30, 0, 0),
		};
		setEditData((prev) => ({
			...prev,
			outingTimes: [...(prev.outingTimes || []), newOuting],
		}));
	};

	// 외출 시간 삭제
	const removeOutingTime = (index) => {
		setEditData((prev) => ({
			...prev,
			outingTimes: prev.outingTimes.filter((_, i) => i !== index),
		}));
	};
	// 달력 생성

	// ✅ 로컬 타임존 변환 버전
	const formatUnixToDatetime = (timestamp) => {
		if (!timestamp) return '';
		const date = new Date(timestamp);
		const timezoneOffset = date.getTimezoneOffset() * 60000;
		const localDate = new Date(date - timezoneOffset);
		return localDate.toISOString().slice(0, 16);
	};

	return (
		<>
			<RealtimeCalendarCompany
				selectedYear={selectedYear}
				selectedMonth={selectedMonth}
				renderDayEntries={renderDayEntries}
				groupedData={groupedData}
				workingDays={workingDays}
				paidOffDay={paidOffDay}
				useWorkingDays={useWorkingDays}
			/>
			<Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
				<Modal.Header>근태 정보 수정</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Field>
							<label>출근 시간</label>
							<input
								type='datetime-local'
								value={formatUnixToDatetime(editData?.checkIn)}
								onChange={(e) => {
									const newDate = new Date(e.target.value);
									setEditData((prev) => ({
										...prev,
										checkIn: newDate.getTime(),
									}));
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>퇴근 시간</label>
							<input
								type='datetime-local'
								value={formatUnixToDatetime(editData?.checkOut)}
								onChange={(e) => {
									const newDate = new Date(e.target.value);
									if (newDate < new Date(editData.checkIn)) {
										alert('퇴근 시간은 출근 시간보다 빠를 수 없습니다');
										return;
									}
									setEditData((prev) => ({
										...prev,
										checkOut: newDate.getTime(),
									}));
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>휴게 시간</label>
							{(editData?.breaks || []).map(
								(
									breakItem,
									index // ✅ breaks가 없으면 빈 배열 처리
								) => (
									<div key={index} style={{ margin: '10px 0' }}>
										<input
											type='datetime-local'
											value={formatUnixToDatetime(breakItem.startTime)}
											// 휴게 시간 변경 핸들러 개선
											onChange={(e) => {
												const newDate = new Date(e.target.value);
												const updatedBreaks = [...(editData.breaks || [])]; // breaks가 없을 경우 대비
												updatedBreaks[index] = {
													...updatedBreaks[index],
													startTime: newDate.getTime(),
												};
												setEditData((prev) => ({
													...prev,
													breaks: updatedBreaks,
												}));
											}}
										/>
										<span style={{ margin: '0 5px' }}>~</span>
										<input
											type='datetime-local'
											value={formatUnixToDatetime(breakItem.endTime)}
											onChange={(e) => {
												const newDate = new Date(e.target.value);
												const updatedBreaks = [...editData.breaks];
												updatedBreaks[index].endTime = newDate.getTime();
												setEditData((prev) => ({
													...prev,
													breaks: updatedBreaks,
												}));
											}}
										/>
										<Button
											icon='trash'
											onClick={() => removeBreakTime(index)}
											style={{ marginLeft: 10 }}
										/>
									</div>
								)
							)}
							<Button primary onClick={addBreakTime} style={{ marginTop: 10 }}>
								휴게 추가
							</Button>
						</Form.Field>
						<Form.Field>
							<label>조퇴 시간</label>
							<input
								type='datetime-local'
								value={formatUnixToDatetime(editData?.earlyLeave?.startTime)}
								onChange={(e) => {
									const newDate = new Date(e.target.value);
									setEditData((prev) => ({
										...prev,
										earlyLeave: {
											...prev.earlyLeave,
											startTime: newDate.getTime(),
										},
									}));
								}}
							/>
						</Form.Field>

						{/* 외출 시간 추가 */}
						<Form.Field>
							<label>외출 시간</label>
							{editData?.outingTimes?.map((outing, index) => (
								<div key={index} style={{ margin: '10px 0' }}>
									<input
										type='datetime-local'
										value={formatUnixToDatetime(outing.startTime)}
										onChange={(e) => {
											const newDate = new Date(e.target.value);
											const updatedOutings = [...editData.outingTimes];
											updatedOutings[index].startTime = newDate.getTime();
											setEditData((prev) => ({
												...prev,
												outingTimes: updatedOutings,
											}));
										}}
									/>
									<span style={{ margin: '0 5px' }}>~</span>
									<input
										type='datetime-local'
										value={formatUnixToDatetime(outing.endTime)}
										onChange={(e) => {
											const newDate = new Date(e.target.value);
											const updatedOutings = [...editData.outingTimes];
											updatedOutings[index].endTime = newDate.getTime();
											setEditData((prev) => ({
												...prev,
												outingTimes: updatedOutings,
											}));
										}}
									/>
									<Button
										icon='trash'
										onClick={() => removeOutingTime(index)}
										style={{ marginLeft: 10 }}
									/>
								</div>
							))}
							<Button primary onClick={addOutingTime} style={{ marginTop: 10 }}>
								외출 추가
							</Button>
						</Form.Field>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button color='black' onClick={() => setEditModalOpen(false)}>
						취소
					</Button>
					<Button
						color='green'
						onClick={() => handleUpdate(editData?.workerId)}>
						수정
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
}
