import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Segment, Tab } from 'semantic-ui-react';

import {
	clearSelectedClientReq4In,
	listenToSelectedClientReq4In,
	setActiveTab,
} from '../clientReqActions4In';

import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../../../companyManage/companyActions';

import {
	addChildLeaveOfAbsenceToWorkerFirestore,
	addChildShortTimeToWorkerFirestore,
	addClientReq4InToFirestore,
	addLeaveOfAbsenceToWorkerFirestore,
	addMaternityLeaveToWorkerFirestore,
	addTotalWagesArrayToWorkerFirestore,
	listenToClientReq4InFromFirestore,
	updateChildLeaveOfAbsenceInWorkerFirestore,
	updateChildShortTimeInWorkerFirestore,
	updateClientReq4InInFirestore,
	updateLeaveOfAbsenceInWorkerFirestore,
	updateMaternityLeaveInWorkerFirestore,
} from '../../../../app/firestore/firestoreService4In';

import { decrypto } from '../../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import {
	checkNoUserInfo,
	isAgentF,
	lastPreventDateF,
	redirectToAppropriatePage,
} from '../../../../app/common/util/util';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CrwfWorkerBasicInfo from './crwfDetail/CrwfWorkerBasicInfo';
import CrwfWorkerBasicTimeInfo from './crwfDetail/CrwfWorkerBasicTimeInfo';
import CrwfWorkerBasicWageInfo from './crwfDetail/CrwfWorkerBasicWageInfo';
import CrwfWorkerRetireInfo from './crwfDetail/CrwfWorkerRetireInfo';
import ClientReqLastPreventData from '../clientsReqDashboard4In/ClientReqLastPreventData';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import axios from 'axios';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../app/config/firebase';
import { convertBreakTimes } from '../../../../app/common/util/dateUtil';

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.
// const API_BASE_URL = 'http://localhost:5000'; // 실제 서버 URL로 변경하세요.

export default function ClientReqWorkerForm({ match, history, location }) {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { yearMonth, paydocus, filter, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);
	const { clientReqs4In, workerRegistryType, selectedClientReq4In, activeTab } =
		useSelector((state) => state.clientReq4InStore);
	const [perEvalOrNot, setPerEvalOrNot] = useState('');
	const [sumOfWorkDays, setSumOfWorkDays] = useState('');
	const [eiOutDetailType, setEiOutDetailType] = useState('');
	const [workerYouth, setWorkerYouth] = useState(true);
	const [noticeFireOrNot, setNoticeFireOrNot] = useState(false);
	const [firedPaperOrNot, setFiredPaperOrNot] = useState(false);

	const [altWorker, setAltWorker] = useState(
		selectedClientReq4In?.originalWorkerLeaveStartDate ? true : false
	);
	const [openPensionPayType, setOpenPensionPayType] = useState(
		selectedClientReq4In?.pensionBaseWage ? true : false
	);

	const [openTeacherPensionPayType, setOpenTeacherPensionPayType] = useState(
		selectedClientReq4In?.teacherPensionBaseWage ? true : false
	);

	const [openHealthCarePayType, setOpenHealthCarePayType] = useState(
		selectedClientReq4In?.healthCareBaseWage ? true : false
	);
	const [openNoJobPayType, setOpenNoJobPayType] = useState(
		selectedClientReq4In?.noJobBaseWage ? true : false
	);
	const [openOldCarePayType, setOpenOldCarePayType] = useState(
		selectedClientReq4In?.oldCareBaseWage ? true : false
	);
	const [noJobPay, setNoJobPay] = useState(
		selectedClientReq4In?.noJobPayYesType ||
			selectedClientReq4In?.noJobPayNoType
			? true
			: false
	);
	const [retiredDate, setRetiredDate] = useState('');
	const [enterDate, setEnterDate] = useState(
		selectedClientReq4In?.workerEnteredDate
	);
	const [editingLeave, setEditingLeave] = useState(null);
	const [formValues, setFormValues] = useState({});

	const hostUid = currentUserProfile?.id;

	const lastPreventData = lastPreventDateF(clientReqs4In);
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const personalEvaluation =
		(clientInfo?.personalEvaluation
			? clientInfo?.personalEvaluation
			: currentUserProfile?.personalEvaluation) ===
		'BSC 기반 인사고과 기능 사용'
			? true
			: false;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const isMounted = useRef(true);

	useEffect(() => {
		if (checkNoUserInfo(currentUserProfile)) {
			redirectToAppropriatePage(currentUserProfile, history);
		}
	}, [currentUserProfile, history]);

	useEffect(() => {
		isMounted.current = true; // 컴포넌트가 마운트되었는지 추적하는 플래그

		if (!selectedClientReq4In?.id) return;

		const fetchData = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						'isWorker',
						yearMonth,
						selectedClientReq4In?.id,
						clientInfo
					)
				);
				if (isMounted.current) {
					// 컴포넌트가 여전히 마운트된 상태에서만 상태 업데이트
					// setSomeState(someData); // 예시로 상태 업데이트
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();

		return () => {
			isMounted.current = false; // 컴포넌트가 언마운트되면 isMounted를 false로 설정
			dispatch(clearPaydocu());
		};
	}, [dispatch, yearMonth, selectedClientReq4In?.id, clientInfo]);

	useEffect(() => {
		if (location.pathname !== '/createClientReq4In') return;
		dispatch(clearSelectedClientReq4In());
	}, [location.pathname, dispatch]);

	const data = selectedClientReq4In;
	const decryptoData = {};

	// eslint-disable-next-line no-unused-vars
	for (const prop in data) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? decrypto(data[prop], String(process.env.CRYPTO_KEY))
				: data[prop];
	}

	const isExceptTimeLawWorker = selectedClientReq4In?.isExceptTimeLawWorker
		? selectedClientReq4In?.isExceptTimeLawWorker
		: false;
	const isEtcWorker = selectedClientReq4In?.isEtcWorker
		? selectedClientReq4In?.isEtcWorker
		: false;
	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel
	);
	const agentType = clientInfo?.agentType
		? clientInfo?.agentType
		: currentUserProfile?.agentType;
	const isCoWorkSemusa =
		isAgent === '스폰서' && agentType === 'bs세무사' ? true : false;
	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;
	// 임금명세서 만들기용

	const agentUid = clientInfo?.agentUid
		? clientInfo?.agentUid
		: currentUserProfile?.agentUid;

	const maternityLeaveStartDateValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.startDate) ||
			  decryptoData?.maternityLeaveStartDate
			: '';
	const maternityLeaveEndDateValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.endDate) ||
			  decryptoData?.maternityLeaveEndDate
			: '';
	const maternityLeaveTypeValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.leaveType) ||
			  decryptoData?.maternityLeaveType
			: '';
	const childLeaveOfAbsenceStartDateValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.startDate) ||
			  decryptoData?.childLeaveOfAbsenceStartDate
			: '';
	const childLeaveOfAbsenceEndDateValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.endDate) ||
			  decryptoData?.childLeaveOfAbsenceEndDate
			: '';
	const childLeaveOfAbsenceTypeValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.leaveType) ||
			  decryptoData?.childLeaveOfAbsenceType
			: '';
	const childShortTimeStartDateValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.startDate) ||
			  decryptoData?.childShortTimeStartDate
			: '';
	const childShortTimeEndDateValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.endDate) ||
			  decryptoData?.childShortTimeEndDate
			: '';
	const childShortTimeTypeValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.leaveType) ||
			  decryptoData?.childShortTimeType
			: '';
	const leaveOfAbsenceStartDateValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.startDate) ||
			  decryptoData?.leaveOfAbsenceStartDate
			: '';
	const leaveOfAbsenceEndDateValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.endDate) ||
			  decryptoData?.leaveOfAbsenceEndDate
			: '';
	const leaveOfAbsenceTypeValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.leaveType) ||
			  decryptoData?.leaveOfAbsenceType
			: '';

	const initialValues = {
		companyName: decryptoData?.companyName || companyName,
		company: decryptoData?.company || {
			ceoName: '',
			addr: '',
			companyName: '',
		},
		over52Permission: decryptoData?.over52Permission || '',
		worker: decryptoData?.worker || {
			name: '',
			comNumber: '',
			email: '',
			bank: '',
			bankAccount: '',
			phone: '',
			address: '',
			ability: '',
			schoolGrade: '',
			companyExperience: '',
			militaryService: '',
			memo: '',
		},
		workerEnteredDate: decryptoData?.workerEnteredDate || '',
		workerSocialNumberFront: decryptoData?.workerSocialNumberFront || '',
		workerSocialNumberBack: decryptoData?.workerSocialNumberBack || '',
		workerTitles: decryptoData?.workerTitles || '',
		foreigner: decryptoData?.foreigner || {
			nationality: '',
			visaType: '',
			englishName: '',
			selfNoJobPay: '', // 1-1
		},

		workerDivisions: decryptoData?.workerDivisions || '',
		noJobPayDetail: decryptoData?.noJobPayDetail || '',
		workDetailField: decryptoData?.workDetailField || '',
		workField: decryptoData?.workField || '',

		// 1-1
		pensionIn: decryptoData?.pensionIn || '',
		healthIn: decryptoData?.healthIn || '',
		// 65세 이상 고용보험 설정
		endless65EmploymentInsurance:
			decryptoData?.endless65EmploymentInsurance || '',
		workerSupportFund: decryptoData?.workerSupportFund || '',
		pensionIn4PublicJob: decryptoData?.pensionIn4PublicJob || '',
		healthIn4Discount: decryptoData?.healthIn4Discount || '',
		healthCarePeople: decryptoData?.healthCarePeople || {
			familyRelation: '',
			familyName: '',
			handyCapFamily: '',
			foreignerFamily: '',
		},
		familySocialNumber: decryptoData?.familySocialNumber || '',
		healthCarePeopleComments: decryptoData?.healthCarePeopleComments || '',

		//WorkerFormStep1ContractType
		contractOldWorker: decryptoData?.contractOldWorker || '',
		contractEndDate: decryptoData?.contractEndDate || '',
		againContractStartDate: decryptoData?.againContractStartDate || '',
		traineeStartDate: decryptoData?.traineeStartDate || '',
		traineeEndDate: decryptoData?.traineeEndDate || '',
		oldRetireDate: decryptoData?.oldRetireDate || '',
		afterRetireAgainContractStartDate:
			decryptoData?.afterRetireAgainContractStartDate || '',
		afterRetireAgainContractEndDate:
			decryptoData?.afterRetireAgainContractEndDate || '',

		//CrwfWorkerBasicInfoSupportFund
		workerYouthType: decryptoData?.workerYouthType || '',
		altWorker: decryptoData?.altWorker || '',
		originalWorkerLeaveStartDate:
			decryptoData?.originalWorkerLeaveStartDate || '',
		originalWorkerLeaveEndDate: decryptoData?.originalWorkerLeaveEndDate || '',

		//WorkerFormStep1Hr
		workerPersonalEvaluation: decryptoData?.workerPersonalEvaluation || '',
		workerPersonalEvaluationNoReason:
			decryptoData?.workerPersonalEvaluationNoReason || '',
		workerPersonalEvaluationView:
			decryptoData?.workerPersonalEvaluationView || '',

		//CrwfWorkerBasicInfoChildLeaves
		maternityLeaveType: maternityLeaveTypeValue,
		maternityLeaveStartDate: maternityLeaveStartDateValue,
		maternityLeaveEndDate: maternityLeaveEndDateValue,

		childLeaveOfAbsenceType: childLeaveOfAbsenceTypeValue,
		childLeaveOfAbsenceStartDate: childLeaveOfAbsenceStartDateValue,
		childLeaveOfAbsenceEndDate: childLeaveOfAbsenceEndDateValue,

		childShortTimeType: childShortTimeTypeValue,
		childShortTimeStartDate: childShortTimeStartDateValue,
		childShortTimeEndDate: childShortTimeEndDateValue,

		leaveOfAbsenceType: leaveOfAbsenceTypeValue,
		leaveOfAbsenceStartDate: leaveOfAbsenceStartDateValue,
		leaveOfAbsenceEndDate: leaveOfAbsenceEndDateValue,

		//CrwfLaborContractTimeInfo
		paidOffdays: decryptoData?.paidOffdays || '',
		selectWorkDays1: decryptoData?.selectWorkDays1 || [],
		selectWorkDays1Info: decryptoData?.selectWorkDays1Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay1: decryptoData?.plusWorkDay1 || '',

		selectWorkDays2: decryptoData?.selectWorkDays2 || [],
		selectWorkDays2Info: decryptoData?.selectWorkDays2Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay2: decryptoData?.plusWorkDay2 || '',

		selectWorkDays3: decryptoData?.selectWorkDays3 || [],
		selectWorkDays3Info: decryptoData?.selectWorkDays3Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay3: decryptoData?.plusWorkDay3 || '',

		selectWorkDays4: decryptoData?.selectWorkDays4 || [],
		selectWorkDays4Info: decryptoData?.selectWorkDays4Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay4: decryptoData?.plusWorkDay4 || '',

		selectWorkDays5: decryptoData?.selectWorkDays5 || [],
		selectWorkDays5Info: decryptoData?.selectWorkDays5Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay5: decryptoData?.plusWorkDay5 || '',

		selectWorkDays6: decryptoData?.selectWorkDays6 || [],
		selectWorkDays6Info: decryptoData?.selectWorkDays6Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},

		//CrwfExceptTimeLawLaborContractTimeInfo
		selectWorkDaysETL1: decryptoData?.selectWorkDaysETL1 || [],
		selectWorkDaysETL1Info: decryptoData?.selectWorkDaysETL1Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL1: decryptoData?.plusWorkDayETL1 || '',

		selectWorkDaysETL2: decryptoData?.selectWorkDaysETL2 || [],
		selectWorkDaysETL2Info: decryptoData?.selectWorkDaysETL2Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL2: decryptoData?.plusWorkDayETL2 || '',

		selectWorkDaysETL3: decryptoData?.selectWorkDaysETL3 || [],
		selectWorkDaysETL3Info: decryptoData?.selectWorkDaysETL3Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL3: decryptoData?.plusWorkDayETL3 || '',

		selectWorkDaysETL4: decryptoData?.selectWorkDaysETL4 || [],
		selectWorkDaysETL4Info: decryptoData?.selectWorkDaysETL4Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL4: decryptoData?.plusWorkDayETL4 || '',

		selectWorkDaysETL5: decryptoData?.selectWorkDaysETL5 || [],
		selectWorkDaysETL5Info: decryptoData?.selectWorkDaysETL5Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL5: decryptoData?.plusWorkDayETL5 || '',

		selectWorkDaysETL6: decryptoData?.selectWorkDaysETL6 || [],
		selectWorkDaysETL6Info: decryptoData?.selectWorkDaysETL6Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL6: decryptoData?.plusWorkDayETL6 || '',
		selectWorkDaysETL7: decryptoData?.selectWorkDaysETL7 || [],
		expectYearly7thWorkDayCounts:
			decryptoData?.expectYearly7thWorkDayCounts || '',
		expectYearly7thDaysETLInfo: decryptoData?.expectYearly7thDaysETLInfo || {
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		expectWeeklyOverETRWorks: decryptoData?.expectWeeklyOverETRWorks || '',
		expectWeeklyNightETRWorks: decryptoData?.expectWeeklyNightETRWorks || '',
		annualLeavesIncludeOrNot: decryptoData?.annualLeavesIncludeOrNot || '',

		//CrwfEtcLaborContractTimeInfo
		etc: decryptoData?.etc || {
			paidOffdays: '',
			paidHolidayLBTime: '',
			weekLBTime: '',
			monthOWT: '',
			monthNWT: '',
			monthHoliBasicTime: '',
			monthOWTHoli: '',
			monthNWTHoli: '',
			workTimeComment: '',
		},

		//WorkerFormTimeComments
		workTimeComment: decryptoData?.workTimeComment || '',

		//WorkerFormTimeWhole
		expectWeeklyOverWorks: decryptoData?.expectWeeklyOverWorks || '',
		expectWeeklyNightWorks: decryptoData?.expectWeeklyNightWorks || '',
		expectYearlyHolidayWorks: decryptoData?.expectYearlyHolidayWorks || 0,
		expectYearlyHolidayWorksInfo:
			decryptoData?.expectYearlyHolidayWorksInfo || {
				workingStartTime: '',
				workingEndTimeNextDayOrNot: '',
				workingEndTime: '',
				dayBreakTimes: '',
				nightBreakTimes: '',
				breakTimeContents: '',
			},

		//CrwfWorkerBasicInfoMonthWageInfo
		totalMonthWageAmount: decryptoData?.totalMonthWageAmount || '',
		wageContractStartDate: decryptoData?.wageContractStartDate || '',
		wageContarctEndDate: decryptoData?.wageContarctEndDate || '',
		monthlyPay: decryptoData?.monthlyPay || {
			startToEndDate: '',
			payDay: '',
			payDayBeforeAfter: '',
		},

		//WorkerFormWageComments
		wageComment: decryptoData?.wageComment || '',

		//CrwfWorkerBasicInfoTax4In
		taxPayType: decryptoData?.taxPayType || '',
		numOfTaxFamily: decryptoData?.numOfTaxFamily || 1,
		numOfTaxFamily7To20: decryptoData?.numOfTaxFamily7To20 || 0,
		pensionPayType: decryptoData?.pensionPayType || '',
		pensionBaseWage: decryptoData?.pensionBaseWage || '',
		healthCarePayType: decryptoData?.healthCarePayType || '',
		healthCareBaseWage: decryptoData?.healthCareBaseWage || '',
		oldCarePayType: decryptoData?.oldCarePayType || '',
		oldCareBaseWage: decryptoData?.oldCareBaseWage || '',
		noJobBaseWage: decryptoData?.noJobBaseWage || '',
		noJobPayType: decryptoData?.noJobPayType || '',
		workAccidenceType: decryptoData?.workAccidenceType || '',
		teacherPensionPayType: decryptoData?.teacherPensionPayType || '',
		teacherPensionBaseWage: decryptoData?.teacherPensionBaseWage || '',
		//CrwfWorkerBasicInfoMonthWageOrd
		taxFreeWages: decryptoData?.taxFreeWages || {
			mealWage: '',
			carWage: '',
			childWage: '',
			studyWage: '',
			etc: '',
			etcComment: '',
		},
		ordinaryWage: decryptoData?.ordinaryWage || {
			title: '',
			job: '',
			license: '',
			monthlyBonus: '',
			longTerm: '',
			etc: '',
			etcComment: '',
			childManageStudy: '',
			childStudy: '',
			childCare: '',
			childActivity: '',
			yearlyBonus: '',
		},

		//CrwfWorkerBasicInfoMonthWageAver
		averageWageTF: decryptoData?.averageWageTF || {
			irregularAWage1: '',
			irregularAWCondition1: '',
			irregularAWName1: '',
			irregularAWage2: '',
			irregularAWCondition2: '',
			irregularAWName2: '',
			irregularAWage3: '',
			irregularAWCondition3: '',
			irregularAWName3: '',
			irregularAWage4: '',
			irregularAWCondition4: '',
			irregularAWName4: '',
		},

		averageWage: decryptoData?.averageWage || {
			irregularAWage1: '',
			irregularAWCondition1: '',
			irregularAWName1: '',
			irregularAWage2: '',
			irregularAWCondition2: '',
			irregularAWName2: '',
			irregularAWage3: '',
			irregularAWCondition3: '',
			irregularAWName3: '',
			irregularAWage4: '',
			irregularAWCondition4: '',
			irregularAWName4: '',
		},

		//어린이집 구청지급
		govSupport: decryptoData?.govSupport || {
			mealMoney: '',
			improveMoney: '',
			overtimeMoney: '',
			childcareMoney: '',
			disabledMoney: '',
			longtermMoney: '',
			improveEmvMoney: '',
		},

		//CrwfWorkerRetireInfo41
		retiredDate: decryptoData?.retiredDate || '', //일용직은 4대보험 설정부분에 위치함
		eiOutType: decryptoData?.eiOutType || '',
		eiOutDetailType: decryptoData?.eiOutDetailType || '',
		eiOutReason: decryptoData?.eiOutReason || '',

		retiredPaperType: decryptoData?.retiredPaperType || '',
		firedPaperType: decryptoData?.firedPaperType || '',
		judgeIlligalFire: decryptoData?.judgeIlligalFire || '',
		under3MonthWorker: decryptoData?.under3MonthWorker || '',
		noticeFire: decryptoData?.noticeFire || '',
		noticeFireException: decryptoData?.noticeFireException || '',
		retiredFundPaidDate: decryptoData?.retiredFundPaidDate || '',
		//CrwfWorkerRetireInfo42
		noJobPay: decryptoData?.noJobPay || '',
		noJobPayYesType: decryptoData?.noJobPayYesType || '',
		noJobPayNoType: decryptoData?.noJobPayNoType || '',
		pensionOutType: decryptoData?.pensionOutType || '',
		healthOutType: decryptoData?.healthOutType || '',
		comment4InsureOut: decryptoData?.comment4InsureOut || '',

		//CrwfWorkerRetireInfo43
		annualLeavesPay: decryptoData?.annualLeavesPay || '',
		compensationVacationPay: decryptoData?.compensationVacationPay || '',
		yearEndRefundPay: decryptoData?.yearEndRefundPay || '',
		severancePay: decryptoData?.severancePay || '',
		comment4InsureOutMemo: decryptoData?.comment4InsureOutMemo || '',
		isExceptTimeLawWorker: decryptoData?.isExceptTimeLawWorker || false,
		isEtcWorker: decryptoData?.isEtcWorker || false,
		isRegularWorekr: decryptoData?.isRegularWorekr || false,
	};

	const prevWorker = selectedClientReq4In?.worker.name;

	const breakTimeRegex =
		/^(\d{1,2}:\d{2}\s*~\s*\d{1,2}:\d{2})(\s*,\s*\d{1,2}:\d{2}\s*~\s*\d{1,2}:\d{2})*$/;

	const validationSchemaEmployee4In = Yup.object({
		isExceptTimeLawWorker: Yup.boolean(), // 컨텍스트 또는 폼 필드에 포함되어야 함
		isEtcWorker: Yup.boolean(), // 컨텍스트 또는 폼 필드에 포함되어야 함
		isRegularWorekr: Yup.boolean(), // 컨텍스트 또는 폼 필드에 포함되어야 함

		selectWorkDays1Info: Yup.object().shape({
			breakTimeContents: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string()
					// .matches(
					// 	breakTimeRegex,
					// 	'휴게시간 형식이 올바르지 않습니다. 예: 12:00~13:00, 14:00~14:20, 23:00~02:00'
					// )
					.required('필수 입력입니다.')
					// "0"인 경우는 형식 검증을 건너뛰고, 그 외에는 breakTimeRegex로 검증
					.test(
						'valid-break-time',
						'휴게시간 형식이 올바르지 않습니다. 예: 12:00~13:00, 14:00~14:20, 23:00~02:00',
						function (value) {
							if (value && value.trim() === '0') return true;
							const adjustedValue = value.replace(/24:/g, '00:'); // 24:00을 00:00으로 변환
							return breakTimeRegex.test(adjustedValue);
						}
					)
					.test(
						'break-time-consistency',
						'휴게시간 계산 결과와 일치하지 않습니다',
						function (value) {
							if (!value) return true; // 값 없으면 검증 생략

							const { dayBreakTimes, nightBreakTimes } = this.parent;
							const adjustedValue = value.replace(/24:/g, '00:'); // 변환 추가
							const intervals = convertBreakTimes(adjustedValue); // 수정된 값 전달
							let calculatedDay = 0;
							let calculatedNight = 0;

							intervals.forEach((interval) => {
								const duration = Math.round(
									(interval.endTime - interval.startTime) / (1000 * 60)
								);
								interval.isNightTime
									? (calculatedNight += duration)
									: (calculatedDay += duration);
							});

							if (
								calculatedDay !== parseInt(dayBreakTimes, 10) ||
								calculatedNight !== parseInt(nightBreakTimes, 10)
							) {
								return this.createError({
									message: `[계산 결과] 주간: ${calculatedDay}분, 야간: ${calculatedNight}분`,
									path: this.path,
								});
							}
							return true;
						}
					),
			}),

			workingStartTime: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string().required('필수 입력 항목입니다.'),
				otherwise: Yup.string().notRequired(),
			}),
			workingEndTimeNextDayOrNot: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			workingEndTime: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string().required('필수 입력 항목입니다.'),
				otherwise: Yup.string().notRequired(),
			}),
			dayBreakTimes: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			nightBreakTimes: Yup.string().when('$isRegularWorekr', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
		}),

		selectWorkDaysETL1Info: Yup.object().shape({
			breakTimeContents: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string()
					.required('필수 입력입니다.')
					// "0"인 경우는 형식 검증을 건너뛰고, 그 외에는 breakTimeRegex로 검증
					.test(
						'valid-break-time',
						'휴게시간 형식이 올바르지 않습니다. 예: 12:00~13:00, 14:00~14:20, 23:00~02:00',
						function (value) {
							if (value && value.trim() === '0') return true;
							const adjustedValue = value.replace(/24:/g, '00:'); // 24:00을 00:00으로 변환
							return breakTimeRegex.test(adjustedValue);
						}
					)
					.test(
						'break-time-consistency',
						'휴게시간 계산 결과와 일치하지 않습니다',
						function (value) {
							if (!value) return true; // 값 없으면 검증 생략

							const { dayBreakTimes, nightBreakTimes } = this.parent;
							const adjustedValue = value.replace(/24:/g, '00:'); // 변환 추가
							const intervals = convertBreakTimes(adjustedValue); // 수정된 값 전달

							let calculatedDay = 0;
							let calculatedNight = 0;

							intervals.forEach((interval) => {
								const duration = Math.round(
									(interval.endTime - interval.startTime) / (1000 * 60)
								);

								interval.isNightTime
									? (calculatedNight += duration)
									: (calculatedDay += duration);
							});

							if (
								calculatedDay !== parseInt(dayBreakTimes, 10) ||
								calculatedNight !== parseInt(nightBreakTimes, 10)
							) {
								return this.createError({
									message: `[계산 결과] 주간: ${calculatedDay}분, 야간: ${calculatedNight}분`,
									path: this.path,
								});
							}
							return true;
						}
					),
			}),

			shiftWork: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수 입력 항목입니다.'),
				otherwise: Yup.string().notRequired(),
			}),
			workingStartTime: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수 입력 항목입니다.'),
				otherwise: Yup.string().notRequired(),
			}),
			workingEndTimeNextDayOrNot: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			workingEndTime: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수 입력 항목입니다.'),
				otherwise: Yup.string().notRequired(),
			}),
			dayBreakTimes: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			nightBreakTimes: Yup.string().when('$isExceptTimeLawWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
		}),

		etc: Yup.object().shape({
			paidOffdays: Yup.string().when('$isEtcWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			paidHolidayLBTime: Yup.string().when('$isEtcWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			weekLBTime: Yup.string().when('$isEtcWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
			workTimeComment: Yup.string().when('$isEtcWorker', {
				is: true,
				then: Yup.string().required('필수항목'),
				otherwise: Yup.string().notRequired(),
			}),
		}),

		//직원 기초정보 ok
		worker: Yup.object({
			name: Yup.string().required('필수항목'),
			// email: Yup.string()
			// 	.email('유효한 이메일 형식이 아닙니다.') // 이메일 형식 체크 (예: '@'가 없으면 실패)
			// 	.required('이메일은 필수 입력 항목입니다.'),
		}),
		workerSocialNumberFront: Yup.string().required('필수항목'),
		workerSocialNumberBack: Yup.string().required('필수항목'),
		workerEnteredDate: Yup.string().required('필수항목'),
		workerTitles: Yup.string().required('필수항목'),
		workerDivisions: Yup.string().required('필수항목'),
		noJobPayDetail: Yup.string().required('필수항목'),
		workDetailField: Yup.string().required('필수항목'),

		// paidOffdays: Yup.string().when('isRegularWorekr', {
		// 	is: true,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),

		selectWorkDays1: Yup.array().when('isRegularWorekr', {
			is: true,
			then: Yup.array().min(1, '필수항목'),
			otherwise: Yup.array().notRequired(),
		}),
		selectWorkDaysETL1: Yup.array().when('isExceptTimeLawWorker', {
			is: true,
			then: Yup.array().min(1, '필수항목'),
			otherwise: Yup.array().notRequired(),
		}),

		totalMonthWageAmount: Yup.string().required('필수항목'),
		wageContractStartDate: Yup.string().required('필수항목'),
		wageContarctEndDate: Yup.string().required('필수항목'),
		monthlyPay: Yup.object({
			startToEndDate: Yup.string().required('필수항목'),
			payDay: Yup.string().required('필수항목'),
			payDayBeforeAfter: Yup.string().required('필수항목'),
		}),

		pensionOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		healthOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		eiOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		eiOutDetailType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		pensionIn: Yup.string().required('필수항목'),
		healthIn: Yup.string().required('필수항목'),

		taxPayType: Yup.string().required('필수항목'),
		numOfTaxFamily: Yup.number().required('필수항목'),
		numOfTaxFamily7To20: Yup.number().required('필수항목'),
		pensionPayType: Yup.string().required('필수항목'),
		pensionBaseWage: Yup.string().when('pensionPayType', {
			is: (value) =>
				!!value &&
				String(value).trim() === '기준소득월액(국민연금공단에 신고된 금액)',
			then: Yup.string().typeError('숫자를 입력하세요').required('필수항목'),
			otherwise: Yup.string().nullable(),
		}),

		healthCarePayType: Yup.string().required('필수항목'),
		healthCareBaseWage: Yup.string().when('healthCarePayType', {
			is: (value) => value === '보수월액(건강보험공단에 신고된 금액)',
			then: Yup.string().required('필수항목'),
			otherwise: Yup.string(),
		}),
		oldCarePayType: Yup.string().required('필수항목'),
		oldCareBaseWage: Yup.string().when('oldCarePayType', {
			is: (value) => value === '보수월액(건강보험공단에 신고된 금액)',
			then: Yup.string().required('필수항목'),
			otherwise: Yup.string(),
		}),
		noJobPayType: Yup.string().required('필수항목'),
		noJobBaseWage: Yup.string().when('noJobPayType', {
			is: (value) => value === '월평균보수월액(근로복지공단에 신고된 금액)',
			then: Yup.string().required('필수항목'),
			otherwise: Yup.string(),
		}),
		// traineeEndDate: Yup.string().when('traineeStartDate', {
		// 	is: (value) => !!value, // traineeStartDate에 값이 있을 때
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
		// maternityLeaveStartDate: Yup.string().when('maternityLeaveType', {
		// 	is: (value) => !!value, // maternityLeaveType에 값이 있을 때
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
		// maternityLeaveEndDate: Yup.string().when('maternityLeaveType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),

		// childLeaveOfAbsenceStartDate: Yup.string().when('childLeaveOfAbsenceType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
		// childLeaveOfAbsenceEndDate: Yup.string().when('childLeaveOfAbsenceType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),

		// childShortTimeStartDate: Yup.string().when('childShortTimeType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
		// childShortTimeEndDate: Yup.string().when('childShortTimeType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),

		// leaveOfAbsenceStartDate: Yup.string().when('leaveOfAbsenceType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
		// leaveOfAbsenceEndDate: Yup.string().when('leaveOfAbsenceType', {
		// 	is: (value) => !!value,
		// 	then: Yup.string().required('필수항목'),
		// 	otherwise: Yup.string().notRequired(),
		// }),
	});

	useFirestoreDoc({
		query: () =>
			match.params.id
				? listenToClientReq4InFromFirestore(match.params.id)
				: null,
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
		shouldExecute:
			match.params.id !== selectedClientReq4In?.id &&
			location.pathname !== '/createClientReq4In',
	});

	//탭 메뉴
	const handleTabChange = (e, { activeIndex }) => {
		dispatch(setActiveTab(activeIndex));
	};

	const now = new Date();
	const enteredDateC = new Date(
		enterDate || selectedClientReq4In?.workerEnteredDate
	);
	const initialRetiredDate = selectedClientReq4In?.retiredDate
		? selectedClientReq4In?.retiredDate
		: '';
	const retiredDateC = retiredDate ? new Date(retiredDate) : '';
	const currentYearMonth = `${now.getFullYear()}-${String(
		now.getMonth() + 1
	).padStart(2, '0')}`; // 현재 년도와 월을 "YYYY-MM" 형식으로 생성
	const enteredYearMonth = `${enteredDateC.getFullYear()}-${String(
		enteredDateC.getMonth() + 1
	).padStart(2, '0')}`;
	const retiredYearMonth = retiredDateC
		? `${retiredDateC.getFullYear()}-${String(
				retiredDateC.getMonth() + 1
		  ).padStart(2, '0')}`
		: '';
	const isWorker = currentUserProfile?.isWorker;

	function hasRetireDateChanged(initialRetiredDate, retiredDateC) {
		if (!initialRetiredDate) return true; // 기존 데이터가 없으면 변경된 것으로 간주
		if (!retiredDateC) return false; // 새로운 데이터가 없으면 변경되지 않은 것으로 간주
		const result =
			new Date(initialRetiredDate).getTime() !==
			new Date(retiredDateC).getTime()
				? true
				: false;
		return result;
	}
	const retiredDateChanged = hasRetireDateChanged(
		initialRetiredDate,
		retiredDateC
	);

	// 중첩된 에러 객체에서 모든 필드 경로를 추출하는 헬퍼 함수
	// 중첩 오류 평탄화 함수 수정
	const flattenErrors = (errors, prefix = '') => {
		return Object.keys(errors).reduce((acc, key) => {
			const path = prefix ? `${prefix}.${key}` : key;
			if (typeof errors[key] === 'string') {
				acc.push(path);
			} else if (typeof errors[key] === 'object' && errors[key] !== null) {
				acc = acc.concat(flattenErrors(errors[key], path));
			}
			return acc;
		}, []);
	};

	// 상위 탭과 해당 하위 탭들의 필드 매핑
	const tabHierarchy = {
		0: [
			// 기본인사정보 상위 탭
			'worker.name',
			'worker.email',
			'workerSocialNumberFront',
			'workerSocialNumberBack',
			'workerEnteredDate',
			'workerTitles',
			'workerDivisions',
			'noJobPayDetail',
			'workDetailField',
			'pensionIn',
			'healthIn',
			'traineeEndDate',
			'maternityLeaveStartDate',
			'maternityLeaveEndDate',
			'childLeaveOfAbsenceStartDate',
			'childLeaveOfAbsenceEndDate',
			'childShortTimeStartDate',
			'childShortTimeEndDate',
			'leaveOfAbsenceStartDate',
			'leaveOfAbsenceEndDate',
		],
		1: [
			// 근로시간정보 상위 탭
			'selectWorkDays1Info.breakTimeContents',
			'selectWorkDays1Info.workingStartTime',
			'selectWorkDays1Info.workingEndTimeNextDayOrNot',
			'selectWorkDays1Info.workingEndTime',
			'selectWorkDays1Info.dayBreakTimes',
			'selectWorkDays1Info.nightBreakTimes',
			'paidOffdays',
			'selectWorkDays1',
			// 감단직
			'selectWorkDaysETL1Info,shiftWork',
			'selectWorkDaysETL1Info.workingStartTime',
			'selectWorkDaysETL1Info.workingEndTimeNextDayOrNot',
			'selectWorkDaysETL1Info.workingEndTime',
			'selectWorkDaysETL1Info.dayBreakTimes',
			'selectWorkDaysETL1Info.nightBreakTimes',
			'selectWorkDaysETL1',
			'selectWorkDaysETL1Info.breakTimeContents',
			// 교대제
			'etc.paidOffdays',
			'etc.paidHolidayLBTime',
			'etc.weekLBTime',
			'etc.workTimeComment',
		],
		3: [
			// 임금정보 상위 탭

			'monthlyPay.startToEndDate',
			'monthlyPay.payDay',
			'monthlyPay.payDayBeforeAfter',
			'totalMonthWageAmount',
			'wageContractStartDate',
			'wageContarctEndDate',
			'taxPayType',
			'numOfTaxFamily',
			'numOfTaxFamily7To20',
			'pensionPayType',
			'pensionBaseWage',
			'healthCarePayType',
			'healthCareBaseWage',
			'oldCarePayType',
			'oldCareBaseWage',
			'noJobPayType',
			'noJobBaseWage',
		],
		4: [
			// 퇴직정보 상위 탭
			'pensionOutType',
			'healthOutType',
			'eiOutType',
			'eiOutDetailType',
		],
	};

	const fieldToTabMapping = {
		// 기본인사정보 (탭 0)
		'worker.name': 0,
		'worker.email': 0,
		workerSocialNumberFront: 0,
		workerSocialNumberBack: 0,
		workerEnteredDate: 0,
		workerTitles: 0,
		workerDivisions: 0,
		noJobPayDetail: 0,
		workDetailField: 0,
		pensionIn: 0,
		healthIn: 0,
		traineeEndDate: 0,
		maternityLeaveStartDate: 0,
		maternityLeaveEndDate: 0,
		childLeaveOfAbsenceStartDate: 0,
		childLeaveOfAbsenceEndDate: 0,
		childShortTimeStartDate: 0,
		childShortTimeEndDate: 0,
		leaveOfAbsenceStartDate: 0,
		leaveOfAbsenceEndDate: 0,

		// 근로시간정보 (탭 1)
		'selectWorkDays1Info.breakTimeContents': 1,
		'selectWorkDays1Info.workingStartTime': 1,
		'selectWorkDays1Info.workingEndTimeNextDayOrNot': 1,
		'selectWorkDays1Info.workingEndTime': 1,
		'selectWorkDays1Info.dayBreakTimes': 1,
		'selectWorkDays1Info.nightBreakTimes': 1,

		// 근로시간정보 (탭 1) - 감단직
		'selectWorkDaysETL1Info.shiftWork': 1,
		'selectWorkDaysETL1Info.workingStartTime': 1,
		'selectWorkDaysETL1Info.workingEndTimeNextDayOrNot': 1,
		'selectWorkDaysETL1Info.workingEndTime': 1,
		'selectWorkDaysETL1Info.dayBreakTimes': 1,
		'selectWorkDaysETL1Info.nightBreakTimes': 1,
		'selectWorkDaysETL1Info.breakTimeContents': 1,
		// 교대제
		'etc.paidOffdays': 1,
		'etc.paidHolidayLBTime': 1,
		'etc.weekLBTime': 1,
		'etc.workTimeComment': 1,

		paidOffdays: 1,
		selectWorkDays1: 1,

		// 임금정보 (탭 2)
		totalMonthWageAmount: 2,
		wageContractStartDate: 2,
		wageContarctEndDate: 2,
		'monthlyPay.startToEndDate': 2,
		'monthlyPay.payDay': 2,
		'monthlyPay.payDayBeforeAfter': 2,
		taxPayType: 2,
		numOfTaxFamily: 2,
		numOfTaxFamily7To20: 2,
		pensionPayType: 2,
		pensionBaseWage: 2,
		healthCarePayType: 2,
		healthCareBaseWage: 2,
		oldCarePayType: 2,
		oldCareBaseWage: 2,
		noJobPayType: 2,
		noJobBaseWage: 2,

		// 퇴직정보 (탭 3)
		pensionOutType: 3,
		healthOutType: 3,
		eiOutType: 3,
		eiOutDetailType: 3,
	};

	// TabSwitcher 컴포넌트 수정
	const TabSwitcher = () => {
		const { submitCount, errors } = useFormikContext();
		const { activeTab } = useSelector((state) => state.clientReq4InStore);
		const dispatch = useDispatch();
		const missingFields = useMemo(() => flattenErrors(errors), [errors]);

		useEffect(() => {
			if (submitCount > 0 && missingFields.length > 0) {
				const firstMissingField = missingFields[0];

				// 상위 탭 매핑 확인
				let targetTab = 0;

				// eslint-disable-next-line no-unused-vars
				for (const [tabIndex, fields] of Object.entries(tabHierarchy)) {
					if (fields.includes(firstMissingField)) {
						targetTab = parseInt(tabIndex);
						break;
					}
				}

				// 또는 기존 fieldToTabMapping 사용
				targetTab = fieldToTabMapping[firstMissingField] ?? 0;

				if (activeTab !== targetTab) {
					dispatch(setActiveTab(targetTab));
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		}, [submitCount, missingFields, activeTab, dispatch]);

		return null;
	};

	const fieldDisplayNames = {
		'worker.name': '1. 기본인사정보 > (1-1) 인사기초 > (1) 직원이름',
		workerEnteredDate: '1. 기본인사정보 > (1-1) 인사기초 > (2) 입사일',
		workerSocialNumberFront:
			'1. 기본인사정보 > (1-1) 인사기초 > (4-1) 주민등록증 앞번호',
		workerSocialNumberBack:
			'1. 기본인사정보 > (1-1) 인사기초 > (4-2) 주민등록증 뒷번호',
		workerTitles: '1. 기본인사정보 > (1-1) 인사기초 > (5) 직위/직책',
		workerDivisions: '1. 기본인사정보 > (1-1) 인사기초 > (6) 부서명',
		noJobPayDetail: '1. 기본인사정보 > (1-1) 인사기초 > (7) 직무',
		workDetailField: '1. 기본인사정보 > (1-1) 인사기초 > (7-1) 구체적 담당업무',
		'worker.email': '1. 기본인사정보 > (1-1) 인사기초 > (8) 직원 E-mail',
		pensionIn:
			'1. 기본인사정보 > (1-2) 4대보험 취득 > (5) 국민연금 자격취득 부호',
		healthIn:
			'1. 기본인사정보 > (1-2) 4대보험 취득 > (6) 건강보험 자격취득 부호',
		traineeEndDate:
			'1. 기본인사정보 > (1-3) 계약/정년촉탁/수습 설정  > (1) 수습종료일',
		maternityLeaveStartDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (12)-2 출산휴가 시작일',
		maternityLeaveEndDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (12)-3 출산휴가 종료일',
		childLeaveOfAbsenceStartDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (13)-2 육아휴직 시작일',
		childLeaveOfAbsenceEndDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (13)-3 육아휴직 종료일',
		childShortTimeStartDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (13)-5 육아기 단축근로 시작일',
		childShortTimeEndDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (13)-6 육아기 단축근로 종료일',
		leaveOfAbsenceStartDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (14)-2 기타 휴직 등 시작일',
		leaveOfAbsenceEndDate:
			'1. 기본인사정보 > (1-6) 산전후/육아휴직 등록  > (14)-3 기타 휴직 등 종료일',

		// 근로시간정보
		paidOffdays: '2. 근로시간정보 > (2-1) 근로시간 설정 > (1) 법정 유급 주휴일',
		selectWorkDays1:
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (2) 출퇴근이 같은 근무요일',
		'selectWorkDays1Info.workingStartTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (3) 출근시간',
		'selectWorkDays1Info.workingEndTimeNextDayOrNot':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (4) 퇴근일(당일 또는 익일)',
		'selectWorkDays1Info.workingEndTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (4) 퇴근시간',
		'selectWorkDays1Info.dayBreakTimes':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (6) 22시이전 주간근무 휴게시간 합계(분 단위)',
		'selectWorkDays1Info.nightBreakTimes':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (7) 22시~익일06시 야간근무 휴게시간 합계(분 단위)',
		'selectWorkDays1Info.breakTimeContents':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (8) 휴게시간 표시((6), (7)의 시간을 표기)',
		// 감단직
		'selectWorkDaysETL1Info.shiftWork':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-1-1) 격일제 여부',
		'selectWorkDaysETL1Info.workingStartTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-2) 출근시간',
		'selectWorkDaysETL1Info.workingEndTimeNextDayOrNot':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-3) 퇴근일(당일 또는 익일)',
		'selectWorkDaysETL1Info.workingEndTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-4) 퇴근시간',
		'selectWorkDaysETL1Info.dayBreakTimes':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-5) 22시이전 주간근무 휴게시간 합계(분 단위)',
		'selectWorkDaysETL1Info.nightBreakTimes':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-6) 22시~익일06시 야간근무 휴게시간 합계(분 단위)',
		'selectWorkDaysETL1Info.breakTimeContents':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-7) 휴게시간 표시((1-1-6), (1-1-7)의 시간을 표기)',
		selectWorkDaysETL1:
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1-1) 출퇴근이 같은 근무요일',
		// 교대제
		'etc.paidOffdays':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (1) 법정 유급 주휴일',
		'etc.paidHolidayLBTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (2) 1일 소정근로시간(주휴수당 근거 수당)',
		'etc.weekLBTime':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (3) 1주 소정근로시간',
		'etc.workTimeComment':
			'2. 근로시간정보 > (2-1) 근로시간 설정 > (9) 시업 및 종업, 휴게시간 등 실근무시간 설명(근로계약서 제5조(근무일, 시업/종업, 휴게) 제1항에 명시될 내용)',

		// 임금정보
		totalMonthWageAmount:
			'3. 임금정보) > (3-1) 임금(기본) > (1) 월급총액 (비과세 등 모든 수당을 포함한 총액)(원)',
		wageContractStartDate:
			'3. 임금정보) > (3-1) 임금(기본) > (2) 월급(연봉) 계약 시작일',
		wageContarctEndDate:
			'3. 임금정보) > (3-1) 임금(기본) > (3) 월급 계약 종료일',
		'monthlyPay.startToEndDate':
			'3. 임금정보) > (3-1) 임금(기본) > (4) 월급 산정 기간',
		'monthlyPay.payDay': '3. 임금정보) > (3-1) 임금(기본) > (5) 월급 지급일',
		'monthlyPay.payDayBeforeAfter':
			'3. 임금정보) > (3-1) 임금(기본) > (6) 월급날이 휴일인 경우 월급 지급일',

		taxPayType:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 근로소득세 공제 기준설정',
		numOfTaxFamily:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 근로소득세 관련 전체 부양가족수(본인포함)',
		numOfTaxFamily7To20:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 8세이상 20세이하 부양가족수',
		pensionPayType:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 국민연금 공제액 기준설정',
		pensionBaseWage:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 국민연금 기준보수월액(원)',
		healthCarePayType:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 건강보험 공제액 기준설정',
		healthCareBaseWage:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 건강보험료 평균보수월액(원)',
		oldCarePayType:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 요양보험 공제액 기준설정',
		oldCareBaseWage:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 요양보험료 평균보수월액(원)',
		noJobPayType:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 고용보험 공제액 기준설정',
		noJobBaseWage:
			'3. 임금정보) > (3-2) 근로소득세/4대보험 > 고용보험료 기준보수월액(원)',

		eiOutType: '4. 퇴직정보 > (4-1) 퇴직/사직/해고 >  고용보험 상실코드',
		eiOutDetailType:
			'4. 퇴직정보 > (4-1) 퇴직/사직/해고 >  고용 상실 구체적 사유',
		pensionOutType:
			'4. 퇴직정보 > (4-2) 실업급여/국민/건강 상실 >  국민연금 상실부호',
		healthOutType:
			'4. 퇴직정보 > (4-2) 실업급여/국민/건강 상실 >  건강보험 상실부호',
	};

	if (loading)
		return <LoadingComponent content='Loading Worker Info edit ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchemaEmployee4In}
				// validate={(values) => {
				// 	try {
				// 		validationSchemaEmployee4In.validateSync(values, {
				// 			context: {
				// 				isRegularWorekr: values.isRegularWorekr,
				// 				isExceptTimeLawWorker: values.isExceptTimeLawWorker,
				// 			},
				// 			abortEarly: false,
				// 		});
				// 		console.log('Validation passed');
				// 		return {};
				// 	} catch (err) {
				// 		const errors = {};
				// 		err.inner.forEach((error) => {
				// 			errors[error.path] = error.message;
				// 		});
				// 		return errors;
				// 	}
				// }}
				onSubmit={async (values, { setSubmitting }) => {
					isMounted.current = true; // 컴포넌트가 마운트되었는지 추적하는 플래그

					try {
						let documentId = null;
						if (
							selectedClientReq4In &&
							selectedClientReq4In?.worker.name === values?.worker.name
						) {
							documentId = await updateClientReq4InInFirestore(
								values,
								agentUid,
								clientInfo,
								selectedClientReq4In.id
							);
						} else {
							documentId = await addClientReq4InToFirestore(
								values,
								agentUid,
								clientInfo,
								''
							);
						}

						if (
							selectedClientReq4In?.worker?.name !== values?.worker?.name &&
							enteredYearMonth >= currentYearMonth &&
							!retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: true,
									beforeMonthWorker: false,
									workerRetiredYearMonth: '',
								})
								.catch((error) => {
									if (isMounted.current) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (
							selectedClientReq4In?.worker?.name !== values?.worker?.name &&
							enteredYearMonth < currentYearMonth &&
							!retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: false,
									beforeMonthWorker: true,
									workerRetiredYearMonth: '',
								})
								.catch((error) => {
									if (isMounted.current) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (
							selectedClientReq4In?.worker?.name === values?.worker?.name &&
							retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: false,
									beforeMonthWorker: false,
									workerRetiredYearMonth: retiredYearMonth,
								})
								.then(async (response) => {
									if (retiredDateChanged) {
										await updateDoc(
											doc(db, 'workers', selectedClientReq4In?.id),
											{
												retireInfo: deleteField(),
												retireInfoSign: deleteField(),
												isInsureOutRequest: deleteField(),
												isInsureOutRequestDate: deleteField(),
											}
										);
										if (initialRetiredDate && retiredDateChanged) {
											alert(
												'퇴직일자 변경으로 일할계산 정보, 퇴직정산, 4대보험 신청 정보가 변경됩니다.'
											);
											alert(
												'퇴직월 급여/퇴직정산을 다시 하신 후 4대보험 신고 Data를 다시 생성하세요.'
											);
										}
										if (isMounted.current) {
											history.push(
												`/manageClientRDWageTable/${
													selectedClientReq4In?.id
												}?yearMonth=${retiredDateC.getFullYear()}${String(
													retiredDateC.getMonth() + 1
												).padStart(2, '0')}`
											);
										}
									} else {
										if (isMounted.current) {
											history.push('/paydocus');
										}
									}
								})
								.catch((error) => {
									if (isMounted) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (editingLeave) {
							const { type, data } = editingLeave;
							const startDateKey =
								type === 'maternityLeave'
									? 'maternityLeaveStartDate'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceStartDate'
									: type === 'childShortTime'
									? 'childShortTimeStartDate'
									: 'leaveOfAbsenceStartDate';
							const endDateKey =
								type === 'maternityLeave'
									? 'maternityLeaveEndDate'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceEndDate'
									: type === 'childShortTime'
									? 'childShortTimeEndDate'
									: 'leaveOfAbsenceEndDate';
							const typeKey =
								type === 'maternityLeave'
									? 'maternityLeaveType'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceType'
									: type === 'childShortTime'
									? 'childShortTimeType'
									: 'leaveOfAbsenceType';

							const convertToFirestoreTimestamp = (date) => {
								if (typeof date === 'string' && !isNaN(Date.parse(date))) {
									const milliseconds = new Date(date).getTime();
									const seconds = Math.floor(milliseconds / 1000);
									return { seconds: seconds, nanoseconds: 0 };
								}
								return date;
							};

							const updatedValues = {
								[startDateKey]:
									convertToFirestoreTimestamp(values[startDateKey]) || null,
								[endDateKey]:
									convertToFirestoreTimestamp(values[endDateKey]) || null,
								[typeKey]: values[typeKey] || null,
							};

							if (type === 'maternityLeave') {
								await updateMaternityLeaveInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'childLeaveOfAbsence') {
								await updateChildLeaveOfAbsenceInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'childShortTime') {
								await updateChildShortTimeInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'leaveOfAbsence') {
								await updateLeaveOfAbsenceInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							}
						} else {
							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.maternityLeaveStartDate ||
									values?.maternityLeaveEndDate ||
									values?.maternityLeaveType) &&
								selectedClientReq4In?.maternityLeaveStartDate !==
									values?.maternityLeaveStartDate
							) {
								await addMaternityLeaveToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.childLeaveOfAbsenceStartDate ||
									values?.childLeaveOfAbsenceEndDate ||
									values?.childLeaveOfAbsenceType) &&
								selectedClientReq4In?.childLeaveOfAbsenceStartDate !==
									values?.childLeaveOfAbsenceStartDate
							) {
								await addChildLeaveOfAbsenceToWorkerFirestore(
									documentId,
									values
								);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.childShortTimeStartDate ||
									values?.childShortTimeEndDate ||
									values?.childShortTimeType) &&
								selectedClientReq4In?.childShortTimeStartDate !==
									values?.childShortTimeStartDate
							) {
								await addChildShortTimeToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.leaveOfAbsenceStartDate ||
									values?.leaveOfAbsenceEndDate ||
									values?.leaveOfAbsenceType) &&
								selectedClientReq4In?.leaveOfAbsenceStartDate !==
									values?.leaveOfAbsenceStartDate
							) {
								await addLeaveOfAbsenceToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								values?.totalMonthWageAmount
							) {
								await addTotalWagesArrayToWorkerFirestore(documentId, values);
							}
						}

						setEditingLeave(null);
						if (isMounted.current) {
							setSubmitting(false);
						}
						if (!initialRetiredDate || !retiredDateChanged) {
							if (isMounted.current) {
								history.push('/clientReqs4In');
							}
						}
						dispatch(clearSelectedClientReq4In());
						if (isMounted.current) {
							history.push('/clientReqs4In');
						}
					} catch (error) {
						if (isMounted.current) {
							console.error(error.message);
							toast.error(error.message);
							setSubmitting(false);
						}
					}

					return () => {
						isMounted.current = false; // 컴포넌트가 언마운트되면 isMounted를 false로 설정
					};
				}}
				enableReinitialize>
				{({ isSubmitting, dirty, values, submitCount, errors }) => {
					// submitCount가 0보다 크면 한 번 이상 제출 시도한 상태입니다.
					const missingFields = submitCount > 0 ? flattenErrors(errors) : [];
					return (
						<Form className='ui form'>
							<TabSwitcher
								activeTab={activeTab}
								setActiveTab={(activeIndex) =>
									dispatch(setActiveTab(activeIndex))
								}
							/>

							<Segment clearing>
								{lastPreventData && (
									<ClientReqLastPreventData
										lastPreventData={lastPreventData}
										authLevel={authLevel}
									/>
								)}

								<Tab
									menu={{ fluid: true, vertical: false, tabular: true }}
									panes={[
										{
											menuItem: '1. 기본인사정보',
											key: '기본인사정보',
											render: () => (
												<Tab.Pane>
													<CrwfWorkerBasicInfo
														isCoWorkSemusa={isCoWorkSemusa}
														setPerEvalOrNot={setPerEvalOrNot}
														perEvalOrNot={perEvalOrNot}
														setWorkerYouth={setWorkerYouth}
														workerYouth={workerYouth}
														values={values}
														personalEvaluation={personalEvaluation}
														currentUserProfile={currentUserProfile}
														setAltWorker={setAltWorker}
														altWorker={altWorker}
														authLevel={authLevel}
														setEnterDate={setEnterDate}
														prevWorker={prevWorker}
														over52Permission={values.over52Permission}
														selectedClientReq4In={selectedClientReq4In}
														setEditingLeave={setEditingLeave}
														setFormValues={setFormValues}
													/>
												</Tab.Pane>
											),
										},
										{
											menuItem: '2. 근로시간정보',
											key: '근로시간정보',
											render: () => (
												<Tab.Pane>
													<CrwfWorkerBasicTimeInfo
														isExceptTimeLawWorker={isExceptTimeLawWorker}
														isEtcWorker={isEtcWorker}
														workerRegistryType={workerRegistryType}
														values={values}
														selectedClientReq4In={selectedClientReq4In}
														setSumOfWorkDays={setSumOfWorkDays}
														authLevel={authLevel}
														currentUserProfile={currentUserProfile}
													/>
												</Tab.Pane>
											),
										},
										{
											menuItem: '3. 임금정보',
											key: '임금정보',
											render: () => (
												<Tab.Pane>
													<CrwfWorkerBasicWageInfo
														values={values}
														openPensionPayType={openPensionPayType}
														setOpenPensionPayType={setOpenPensionPayType}
														openHealthCarePayType={openHealthCarePayType}
														setOpenHealthCarePayType={setOpenHealthCarePayType}
														openNoJobPayType={openNoJobPayType}
														setOpenNoJobPayType={setOpenNoJobPayType}
														openOldCarePayType={openOldCarePayType}
														setOpenOldCarePayType={setOpenOldCarePayType}
														companyName={companyName}
														openTeacherPensionPayType={
															openTeacherPensionPayType
														}
														setOpenTeacherPensionPayType={
															setOpenTeacherPensionPayType
														}
													/>
												</Tab.Pane>
											),
										},
										{
											menuItem: '4. 퇴직정보',
											key: '퇴직정보',
											render: () => (
												<Tab.Pane>
													{authLevel >= 10 && (
														<CrwfWorkerRetireInfo
															numOfWorkers={numOfWorkers}
															selectedClientReq4In={selectedClientReq4In}
															retiredDate={retiredDate}
															eiOutDetailType={eiOutDetailType}
															setEiOutDetailType={setEiOutDetailType}
															noJobPay={noJobPay}
															setNoJobPay={setNoJobPay}
															noticeFireOrNot={noticeFireOrNot}
															setNoticeFireOrNot={setNoticeFireOrNot}
															firedPaperOrNot={firedPaperOrNot}
															setFiredPaperOrNot={setFiredPaperOrNot}
															paydocus={paydocus}
															workerEnteredDate={values.workerEnteredDate}
															workerRetiredDate={values.retiredDate}
															setRetiredDate={setRetiredDate}
															values={values}
														/>
													)}
												</Tab.Pane>
											),
										},
									]}
									activeIndex={activeTab}
									onTabChange={handleTabChange}
								/>
								<br />
								{selectedClientReq4In ? (
									<>
										{isExceptTimeLawWorker ? (
											<Button
												loading={isSubmitting}
												disabled={
													currentUserProfile?.email === 'freya0207@gmail.com' ||
													currentUserProfile?.email === 'paydac@naver.com'
														? false
														: !dirty || isSubmitting
												}
												type='submit'
												floated='right'
												positive
												content='저장'
												size='huge'
											/>
										) : (
											<Button
												loading={isSubmitting}
												disabled={
													currentUserProfile?.email === 'freya0207@gmail.com' ||
													currentUserProfile?.email === 'paydac@naver.com'
														? false
														: !dirty || isSubmitting
												}
												type='submit'
												floated='right'
												positive
												content='저장'
												size='huge'
											/>
										)}
										<Button
											disabled={isSubmitting}
											as={Link}
											to='/clientReqs4In'
											type='submit'
											floated='right'
											content='취소'
											size='huge'
										/>
									</>
								) : (
									<>
										<Button
											loading={isSubmitting}
											disabled={!dirty || isSubmitting || sumOfWorkDays > 6}
											type='submit'
											floated='right'
											positive
											content='저장'
											size='huge'
										/>
										<Button
											disabled={isSubmitting}
											as={Link}
											to='/clientReqs4In'
											type='submit'
											floated='right'
											content='취소'
											size='huge'
										/>
									</>
								)}
								{/* 누락된 필드 요약 영역 */}
								{submitCount > 0 && missingFields.length > 0 && (
									<div style={{ marginTop: '20px', color: 'red' }}>
										<h3>누락된 필수 항목:</h3>
										<ul>
											{[...missingFields]
												.sort((a, b) => {
													const displayA = fieldDisplayNames[a] || a;
													const displayB = fieldDisplayNames[b] || b;
													return displayA.localeCompare(displayB, undefined, {
														numeric: true,
													});
												})
												.map((field, index) => (
													<li key={index}>
														{fieldDisplayNames[field] || field}
													</li>
												))}
										</ul>
									</div>
								)}
							</Segment>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}
