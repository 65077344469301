import React, { useMemo, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { db } from '../../../app/config/firebase';
import { doc, writeBatch } from 'firebase/firestore';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';
import SortableTable from '../../../app/common/form/SortableTable';
import { roundUp, safeStringCompare } from '../../../app/common/util/util';
import { decrypto } from '../../../app/firestore/firestoreService';

export default function InsureManageListDayWorkerRetired({
	searchResults,
	companyName,
	authLevel,
	insureManageType,
	searchTerm,
}) {
	const [reportedRows, setReportedRows] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);
	const [showReported, setShowReported] = useState(false);

	async function handleReportSelected() {
		const updatePayload = {
			insureOutResponse: true,
			insureOutResponseDate: new Date(),
		};

		const batch = writeBatch(db);

		selectedRows.forEach((row) => {
			const workerRef = doc(db, 'workers', row.id);
			batch.update(workerRef, updatePayload);
			setReportedRows((prev) => ({
				...prev,
				[row.id]: true,
			}));
		});

		try {
			await batch.commit();
			alert('신고가 완료되었습니다.');
		} catch (error) {
			console.error('Error updating documents:', error);
		}
	}

	function exportSelectedToExcel() {
		const headers = [
			'성명',
			'주민(외국인)등록번호국내거소신고번호',
			'전화(지역번호)',
			'전화(국번)',
			'전화(뒷번호)',
			'국민연금상실일',
			'국민연금상실부호',
			'국민연금초일취득당월상실자납부여부',
			'건강보험상실일',
			'건강보험상실부호',
			'건강보험당해년도보수총액',
			'건강보험당해년도근무개월수',
			'건강보험전년도보수총액',
			'건강보험전년도근무개월수',
			'고용보험상실연월일',
			'고용보험상실사유구분코드',
			'고용보험구체적사유',
			'고용보험해당연도보수총액',
			'고용보험전년도보수총액',
			'산재보험상실연월일',
			'산재보험해당연도보수총액',
			'산재보험전년도보수총액',
		];

		const data = [headers];

		selectedRows
			.filter((row) => {
				const noPensionPay = row?.dayWorkerCalPension > 0 ? false : true;
				const noHealthCarePay = row?.dayWorkerCalHealthCare > 0 ? false : true;
				return !(noPensionPay && noHealthCarePay); // 둘 다 대상 아님인 경우 제외
			})
			.forEach((row) => {
				const retiredDate = row?.retiredDate;

				// retiredDate를 Date 객체로 변환
				const year = parseInt(retiredDate.substring(0, 4));
				const month = parseInt(retiredDate.substring(4, 6)) - 1; // month는 0부터 시작하므로 -1
				const day = parseInt(retiredDate.substring(6, 8));

				const retiredDateObj = new Date(year, month, day);

				// 다음 날로 변경
				retiredDateObj.setDate(retiredDateObj.getDate() + 1);

				// 다음 날의 형식을 "YYYYMMDD"로 포맷팅
				const retiredDateNextDay =
					retiredDateObj.getFullYear().toString() +
					(retiredDateObj.getMonth() + 1).toString().padStart(2, '0') +
					retiredDateObj.getDate().toString().padStart(2, '0');

				const noPensionPay = row?.dayWorkerCalPension > 0 ? false : true;
				const noHealthCarePay = row?.dayWorkerCalHealthCare > 0 ? false : true;
				const dataRow = [
					row.workerName,
					row.workerSocialNumberFront,
					'',
					'',
					'',
					noPensionPay ? '' : retiredDateNextDay,
					noPensionPay ? '' : '03',
					'',
					noHealthCarePay ? '' : retiredDateNextDay,
					noHealthCarePay ? '' : '01',
					noHealthCarePay ? '' : row.dayWorkerTaxWageSum,
					noHealthCarePay ? '' : '1',
					0,
					0,
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				];

				data.push(dataRow);
			});

		const worksheet = XLSX.utils.aoa_to_sheet(data);

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, '퇴사');
		XLSX.writeFile(workbook, `${companyName}_퇴사처리.xlsx`);
	}

	const formattedData = searchResults
		.map((result) => {
			const workerSocialNumberFront = result?.workerSocialNumberFront
				? decrypto(
						result.workerSocialNumberFront,
						String(process.env.CRYPTO_KEY)
				  )
				: '';
			const workerSocialNumberBack = result?.workerSocialNumberBack
				? decrypto(
						result.workerSocialNumberBack,
						String(process.env.CRYPTO_KEY)
				  )
				: '';
			return {
				id: result.workerId,
				agentWorkerName: result?.agentWorkerName,
				segment: '퇴사',
				companyName: companyName,
				companyBizNumber: result?.companyBizNumber,
				workerName: result?.worker,
				workerSocialNumberFront:
					workerSocialNumberFront + workerSocialNumberBack,

				registerDate: result?.isInsureOutRequestDate
					? format(new Date(result?.isInsureOutRequestDate), 'yyyy-MM-dd')
					: null,
				responseDate: result?.insureOutResponseDate
					? format(new Date(result?.insureOutResponseDate), 'yyyy-MM-dd')
					: null,
				enteredDate: result?.enteredDate,
				retiredDate: result?.retiredDate,
				dayWorkerTaxWageSum: roundUp(result?.dayWorkerTaxWageSum),
				workField: result?.workField,

				netAge: result?.netAge,

				foreigner: result?.nationality,
				englisName: result?.englishName,
				visa: result?.visaType,

				pensionOut: result?.pensionOut,
				dayWorkerCalPension: result?.dayWorkerCalPension,
				dayWorkerCalHealthCare: result?.dayWorkerCalHealthCare,
				healthOut: result?.healthOut,
				pension: result.dayWorkerCalPension > 0 ? '대상' : '대상아님',
				healthCare: result?.dayWorkerCalHealthCare > 0 ? '대상' : '대상아님',

				insureOutResponse: result?.insureOutResponse,
				insureOutResponseDate: result?.insureOutResponseDate,
			};
		})
		.filter((row) => {
			const isReported = row.insureOutResponse || reportedRows[row.id];
			const matchesSearchTerm =
				row.workerName?.includes(searchTerm) ||
				row.companyName?.includes(searchTerm);
			return showReported
				? isReported && matchesSearchTerm
				: !isReported && matchesSearchTerm;
		});

	const headers = useMemo(() => {
		const checkboxHeader = (
			<Checkbox
				checked={
					selectedRows.length === formattedData.length &&
					selectedRows.length > 0
				}
				onChange={(e, { checked }) => {
					if (checked) {
						setSelectedRows(formattedData);
					} else {
						setSelectedRows([]);
					}
				}}
			/>
		);

		if (authLevel >= 100) {
			return showReported
				? [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'responseDate', label: '처리일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		} else if (insureManageType === '4대보험 자체관리') {
			return showReported
				? [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '정보 등록일' },
						{ key: 'responseDate', label: '신고일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '정보등록일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		} else if (
			insureManageType === '4대보험 노무사 위탁관리' ||
			insureManageType === '4대보험 최상인업노무법인 위탁관리'
		) {
			return showReported
				? [
						// { key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						{ key: 'responseDate', label: '신고일 완료일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						// { key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		}
	}, [
		showReported,
		insureManageType,
		authLevel,
		selectedRows.length,
		formattedData,
	]);

	const sortFunctions = {
		registerDate: (a, b) =>
			new Date(b.registerDate).getTime() - new Date(a.registerDate).getTime(),
		responseDate: (a, b) =>
			new Date(b.responseDate).getTime() - new Date(a.responseDate).getTime(),
		enteredDate: (a, b) =>
			new Date(b.enteredDate).getTime() - new Date(a.enteredDate).getTime(),
		retiredDate: (a, b) =>
			new Date(b.retiredDate).getTime() - new Date(a.retiredDate).getTime(),

		agentWorkerName: (a, b) =>
			safeStringCompare(a.agentWorkerName, b.agentWorkerName),
		segment: (a, b) => safeStringCompare(a.segment, b.segment),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		companyBizNumber: (a, b) =>
			safeStringCompare(a.companyBizNumber, b.companyBizNumber),
		workerName: (a, b) => safeStringCompare(a.workerName, b.workerName),
		netAge: (a, b) => parseInt(a.netAge) - parseInt(b.netAge),
		taxWageSum: (a, b) => parseInt(a.taxWageSum) - parseInt(b.taxWageSum),

		pensionOutType: (a, b) =>
			parseInt(a.pensionOutType) - parseInt(b.pensionOutType),
		healthOutType: (a, b) =>
			parseInt(a.healthOutType) - parseInt(b.healthOutType),

		workField: (a, b) => safeStringCompare(a.workField, b.workField),
		foreigner: (a, b) => safeStringCompare(a.foreigner, b.foreigner),
		englishName: (a, b) => safeStringCompare(a.englishName, b.englishName),
		visa: (a, b) => safeStringCompare(a.visa, b.visa),

		pension: (a, b) => safeStringCompare(a.pension, b.pension),
		healthCare: (a, b) => safeStringCompare(a.healthCare, b.healthCare),
		action: (a, b) => {
			const isReportedA = a.insureOutResponse || reportedRows[a.id] ? 1 : 0;
			const isReportedB = b.insureOutResponse || reportedRows[b.id] ? 1 : 0;
			return isReportedA - isReportedB;
		},
	};

	const title = '퇴사한 직원 목록';

	const customRenderers = {
		checkbox: (row) => (
			<Checkbox
				checked={selectedRows.some((selected) => selected.id === row.id)}
				onChange={(e, { checked }) => {
					if (checked) {
						setSelectedRows((prev) => [...prev, row]);
					} else {
						setSelectedRows((prev) => prev.filter((r) => r.id !== row.id));
					}
				}}
			/>
		),
	};

	const noExcelButton = false;
	const buttonView =
		authLevel >= 100 || insureManageType === '4대보험 자체관리' ? true : false;
	return (
		<>
			<h3>퇴사 신고</h3>
			<Button
				color={showReported ? 'green' : 'blue'}
				onClick={() => setShowReported(!showReported)}>
				{showReported ? '퇴사 신고 전 정보 보기' : '퇴사 신고 완료 정보 보기'}
			</Button>
			{buttonView && (
				<>
					<Button color='blue' onClick={exportSelectedToExcel}>
						엑셀다운
					</Button>
					<Button color='red' onClick={handleReportSelected}>
						신고완료
					</Button>
				</>
			)}
			<SortableTable
				headers={headers}
				data={formattedData}
				sortFunctions={sortFunctions}
				customRenderers={customRenderers}
				title={title}
				noExcelButton={noExcelButton}
				enableSearch={true}
			/>
		</>
	);
}
