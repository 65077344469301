import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { realdb } from '../../../../../app/config/firebase';
import { ref, onValue, off, update } from 'firebase/database';
import {
	calculateWorkDuration,
	convertBreakTimes,
	formattedTimeUnixTimestampToHHMMSS,
	formatTime,
	subtractBreakOverlap,
} from '../../../../../app/common/util/dateUtil';
import RealtimeCalendar from './RealtimeCalendar';

export default function RealtimeCalendarDisplay({
	selectedYear,
	selectedMonth,
	companyId,
	workerId,
	workingDays,
	paidOffDay,
	useWorkingDays,
	workerName,
}) {
	const [groupedData, setGroupedData] = useState({});
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [editData, setEditData] = useState(null);
	const manageRight = false; // 근태 관리 권한

	// Firebase 데이터 연결
	useEffect(() => {
		if (!companyId || !workerId) return;

		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}`;
		const attendanceRef = ref(realdb, path);

		let isMounted = true; // ✅ 컴포넌트가 마운트되어 있는지 추적

		const handleDataChange = (snapshot) => {
			if (!isMounted) return; // ✅ 언마운트된 경우 상태 업데이트 방지

			const rawData = snapshot.val() || {};
			const processedData = {};

			Object.entries(rawData).forEach(([attendanceId, attendance]) => {
				if (attendance.deleted) return;

				const checkInTime = parseInt(attendance.checkInTime);
				const checkOutTime = attendance.checkOutTime
					? parseInt(attendance.checkOutTime)
					: '';

				const day = new Date(checkInTime).getDate().toString();

				if (!processedData[day]) {
					processedData[day] = [];
				}

				const breaks =
					attendance.breakTimes?.map((breakItem) => ({
						startTime: parseInt(breakItem.startTime),
						endTime: breakItem.endTime ? parseInt(breakItem.endTime) : '',
					})) || [];

				const earlyLeave = attendance.earlyLeave
					? {
							startTime: parseInt(attendance.earlyLeave.startTime),
							endTime: attendance.earlyLeave.endTime
								? parseInt(attendance.earlyLeave.endTime)
								: '',
					  }
					: null;

				const outingTimes =
					attendance.outingTimes?.map((outing) => ({
						startTime: parseInt(outing.startTime),
						endTime: outing.endTime ? parseInt(outing.endTime) : '',
					})) || [];

				processedData[day].push({
					checkIn: checkInTime,
					checkOut: checkOutTime,
					breaks: breaks,
					earlyLeave: earlyLeave,
					outingTimes: outingTimes,
					id: attendanceId,
					workerName: workerName,
				});
			});

			if (isMounted) {
				setGroupedData(processedData); // ✅ 컴포넌트가 마운트된 경우에만 상태 업데이트
			}
		};

		onValue(attendanceRef, handleDataChange);

		return () => {
			isMounted = false; // ✅ 언마운트될 때 플래그 설정
			off(attendanceRef, handleDataChange); // ✅ Firebase 구독 해제
		};
	}, [
		companyId,
		workerId,
		selectedYear,
		selectedMonth,
		useWorkingDays,
		workerName,
	]);

	const handleDelete = (dataId) => {
		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${dataId}`;
		const attendanceRef = ref(realdb, path);

		update(attendanceRef, { deleted: true }) // deleted 플래그 설정
			.then(() => {
				console.log('데이터 삭제 처리 성공');
			})
			.catch((error) => {
				console.error('데이터 삭제 처리 실패:', error);
			});
	};

	let unsubscribeEdit; // 전역 혹은 컴포넌트 내부 변수로 선언

	const handleEdit = (dataId) => {
		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${dataId}`;
		const attendanceRef = ref(realdb, path);

		unsubscribeEdit = onValue(attendanceRef, (snapshot) => {
			setEditData({ id: dataId, ...snapshot.val() });
			setEditModalOpen(true);
		});
	};

	// 컴포넌트가 언마운트될 때 unsubscribe
	useEffect(() => {
		return () => {
			if (unsubscribeEdit) {
				unsubscribeEdit();
			}
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleUpdate = () => {
		const path = `attendance/${companyId}/${selectedYear}/${selectedMonth}/${workerId}/${editData?.id}`;
		const attendanceRef = ref(realdb, path);

		update(attendanceRef, {
			checkInTime: editData?.checkInTime,
			checkOutTime: editData?.checkOutTime,
			breakTimes: editData?.breakTimes,
		})
			.then(() => {
				console.log('데이터 수정 성공');
				setEditModalOpen(false);
			})
			.catch((error) => {
				console.error('데이터 수정 실패:', error);
			});
	};

	// 일자별 데이터 렌더링
	const renderDayEntries = (day) => {
		if (useWorkingDays) {
			// workingDays 모드
			const currentDate = new Date(
				selectedYear,
				selectedMonth - 1,
				parseInt(day)
			);
			const dayOfWeek = currentDate.getDay();
			const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
			const dayName = daysOfWeek[dayOfWeek];

			const workingDay = workingDays?.find((wd) => wd.day === dayName);

			if (workingDay) {
				const scheduledCheckIn = new Date(currentDate);
				const [scheduledHours, scheduledMinutes] =
					workingDay.workingStartTime.split(':');
				scheduledCheckIn.setHours(scheduledHours, scheduledMinutes, 0, 0);

				const scheduledCheckOut = new Date(currentDate);
				const [scheduledEndHours, scheduledEndMinutes] =
					workingDay.workingEndTime.split(':');
				scheduledCheckOut.setHours(
					scheduledEndHours,
					scheduledEndMinutes,
					0,
					0
				);

				if (workingDay.workingEndTimeNextDayOrNot === '익일') {
					scheduledCheckOut.setDate(scheduledCheckOut.getDate() + 1);
				}

				// 휴게 시간 정보 변환
				let breaks = [];
				if (workingDay.breakTimeContents) {
					const [startTimeStr, endTimeStr] =
						workingDay.breakTimeContents.split('~');
					if (startTimeStr && endTimeStr) {
						const startTimeParts = startTimeStr.split(':');
						const endTimeParts = endTimeStr.split(':');
						breaks = [
							{
								startTime: new Date(currentDate).setHours(
									parseInt(startTimeParts[0]),
									parseInt(startTimeParts[1]),
									0,
									0
								),
								endTime: new Date(currentDate).setHours(
									parseInt(endTimeParts[0]),
									parseInt(endTimeParts[1]),
									0,
									0
								),
							},
						];
					}
				}

				const workDuration = calculateWorkDuration(
					scheduledCheckIn.getTime(),
					scheduledCheckOut.getTime(),
					breaks, // 휴게 시간 정보 전달
					[],
					null
				);

				return (
					<div
						style={{
							marginBottom: '10px',
							borderBottom: '1px solid #eee',
							padding: '5px',
						}}>
						<div>
							▶ 출근:{' '}
							{formattedTimeUnixTimestampToHHMMSS(scheduledCheckIn.getTime())}
						</div>
						<div>
							▶ 퇴근:{' '}
							{formattedTimeUnixTimestampToHHMMSS(scheduledCheckOut.getTime())}
						</div>
						<div>
							휴게시간:{' '}
							{breaks?.map(
								(breakItem) =>
									`${formattedTimeUnixTimestampToHHMMSS(
										breakItem.startTime
									)}~${formattedTimeUnixTimestampToHHMMSS(breakItem.endTime)}`
							)}
						</div>
						<div>총 근무시간: {workDuration}</div>
					</div>
				);
			} else {
				return (
					<div
						style={{
							marginBottom: '10px',
							borderBottom: '1px solid #eee',
							padding: '5px',
							color: 'gray',
						}}>
						근무일 아님
					</div>
				);
			}
		} else {
			// ======================================
			// 기존 모드 (useWorkingDays === false)
			// ======================================
			const entries = groupedData[day] || [];
			return entries?.map((entry, idx) => {
				const entryDate = new Date(entry.checkIn);
				const dayOfWeek = entryDate.getDay();
				const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
				const dayName = daysOfWeek[dayOfWeek];
				const workingDay = workingDays?.find((wd) => wd.day === dayName);

				let lateTime = null;
				let earlyLeaveTime = null;

				if (workingDay) {
					// 1) 예정 출근
					const scheduledCheckIn = new Date(entryDate);
					const [scheduledHours, scheduledMinutes] =
						workingDay.workingStartTime.split(':');
					scheduledCheckIn.setHours(scheduledHours, scheduledMinutes, 0, 0);

					// 2) 휴게시간 파싱
					const baseDay = new Date(entryDate);
					baseDay.setHours(0, 0, 0, 0); // '당일 0시'로 세팅
					const breakIntervals = convertBreakTimes(
						workingDay.breakTimeContents,
						baseDay
					);

					// 지각 계산
					if (entry.checkIn > scheduledCheckIn.getTime()) {
						// 지각 구간: [예정 출근, 실제 출근]
						lateTime = subtractBreakOverlap(
							scheduledCheckIn.getTime(),
							entry.checkIn,
							breakIntervals
						);
					}

					// 3) 예정 퇴근
					if (entry.checkOut && workingDay.workingEndTime) {
						let scheduledCheckOut = new Date(entryDate);
						const [scheduledEndHours, scheduledEndMinutes] =
							workingDay.workingEndTime.split(':');
						scheduledCheckOut.setHours(
							scheduledEndHours,
							scheduledEndMinutes,
							0,
							0
						);

						if (workingDay.workingEndTimeNextDayOrNot === '익일') {
							scheduledCheckOut.setDate(scheduledCheckOut.getDate() + 1);
						}

						// 조기퇴근 계산
						if (entry?.earlyLeave?.startTime < scheduledCheckOut.getTime()) {
							// 조기퇴근 구간: [실제퇴근, 예정퇴근]
							earlyLeaveTime = subtractBreakOverlap(
								entry?.earlyLeave?.startTime,
								scheduledCheckOut.getTime(),
								breakIntervals
							);
						}
					}
				}
				return (
					<div
						key={idx}
						style={{
							marginBottom: '10px',
							borderBottom: '1px solid #eee',
							padding: '5px',
						}}>
						<div style={{ color: 'green' }}>
							▶ 출근: {formattedTimeUnixTimestampToHHMMSS(entry.checkIn)}
						</div>
						{entry.breaks?.map((breakItem, breakIdx) => (
							<div key={breakIdx} style={{ marginLeft: '15px', color: '#666' }}>
								<div>
									⏸️ 휴게시작:{' '}
									{formattedTimeUnixTimestampToHHMMSS(breakItem.startTime)}
								</div>
								<div>
									▶️ 휴게종료:{' '}
									{formattedTimeUnixTimestampToHHMMSS(breakItem.endTime)}
								</div>
							</div>
						))}
						{entry.outingTimes?.map((outing, outingIdx) => (
							<div
								key={outingIdx}
								style={{ marginLeft: '15px', color: 'purple' }}>
								<div>
									🚶‍♂️ 외출시작:{' '}
									{formattedTimeUnixTimestampToHHMMSS(outing.startTime)}
								</div>
								<div>
									🚶‍♂️ 외출종료:{' '}
									{formattedTimeUnixTimestampToHHMMSS(outing.endTime)}
								</div>
							</div>
						))}
						{entry.earlyLeave && (
							<div style={{ color: 'red' }}>
								▶ 조퇴시작:{' '}
								{formattedTimeUnixTimestampToHHMMSS(entry.earlyLeave.startTime)}
							</div>
						)}
						{entry.checkOut && (
							<div style={{ color: 'blue' }}>
								▶ 퇴근:{' '}
								{entry.checkOut
									? formattedTimeUnixTimestampToHHMMSS(
											entry.checkOut,
											entry.checkIn
									  )
									: '퇴근 미기록'}{' '}
								<br />
								{manageRight && (
									<>
										<Button size='mini' onClick={() => handleDelete(entry.id)}>
											삭제
										</Button>
										<Button size='mini' onClick={() => handleEdit(entry.id)}>
											수정
										</Button>
									</>
								)}
							</div>
						)}
						<div>
							총 근무시간:{' '}
							{calculateWorkDuration(
								entry.checkIn,
								entry.checkOut,
								entry.breaks,
								entry.outingTimes,
								entry.earlyLeave
							)}
						</div>
						{lateTime && (
							<span style={{ color: 'red', marginLeft: '10px' }}>
								(지각: {formatTime(lateTime)})
							</span>
						)}
						<br />
						{earlyLeaveTime && (
							<span style={{ color: 'purple', marginLeft: '10px' }}>
								(조기퇴근: {formatTime(earlyLeaveTime)})
							</span>
						)}
					</div>
				);
			});
		}
	};

	// 보조 함수 추가
	const handleBreakTimeChange = (index, field, value) => {
		const [hours, minutes] = value.split(':').map(Number);
		const newBreaks = [...editData.breaks];
		const baseTime = new Date(newBreaks[index][field] || editData.checkIn);
		baseTime.setHours(hours, minutes, 0, 0);
		newBreaks[index][field] = baseTime.getTime();
		setEditData((prev) => ({ ...prev, breaks: newBreaks }));
	};

	const addBreakTime = () => {
		const baseTime = editData.checkIn || Date.now();
		const newBreak = {
			startTime: new Date(baseTime).setHours(12, 0, 0, 0),
			endTime: new Date(baseTime).setHours(13, 0, 0, 0),
		};
		setEditData((prev) => ({
			...prev,
			breaks: [...(prev.breaks || []), newBreak],
		}));
	};

	const removeBreakTime = (index) => {
		setEditData((prev) => ({
			...prev,
			breaks: prev.breaks.filter((_, i) => i !== index),
		}));
	};

	// 외출 시간 변경 핸들러
	const handleOutingChange = (index, field, value) => {
		const [hours, minutes] = value.split(':').map(Number);
		const newOutingTimes = [...editData.outingTimes];
		const baseTime = new Date(newOutingTimes[index][field] || editData.checkIn);
		baseTime.setHours(hours, minutes, 0, 0);
		newOutingTimes[index][field] = baseTime.getTime();
		setEditData((prev) => ({ ...prev, outingTimes: newOutingTimes }));
	};

	// 외출 시간 추가
	const addOutingTime = () => {
		const baseTime = editData.checkIn || Date.now();
		const newOuting = {
			startTime: new Date(baseTime).setHours(14, 0, 0, 0),
			endTime: new Date(baseTime).setHours(14, 30, 0, 0),
		};
		setEditData((prev) => ({
			...prev,
			outingTimes: [...(prev.outingTimes || []), newOuting],
		}));
	};

	// 외출 시간 삭제
	const removeOutingTime = (index) => {
		setEditData((prev) => ({
			...prev,
			outingTimes: prev.outingTimes.filter((_, i) => i !== index),
		}));
	};
	// 달력 생성
	return (
		<>
			<RealtimeCalendar
				selectedYear={selectedYear}
				selectedMonth={selectedMonth}
				renderDayEntries={renderDayEntries}
				groupedData={groupedData}
				workingDays={workingDays}
				paidOffDay={paidOffDay}
				useWorkingDays={useWorkingDays}
			/>

			<Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
				<Modal.Header>근태 정보 수정</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Field>
							<label>출근 시간</label>
							<input
								type='time'
								value={formattedTimeUnixTimestampToHHMMSS(editData?.checkIn)}
								onChange={(e) => {
									const newTime = e.target.value;
									const [hours, minutes] = newTime.split(':').map(Number);
									const newDate = new Date(editData.checkIn);
									newDate.setHours(hours, minutes, 0, 0);
									setEditData((prev) => ({
										...prev,
										checkIn: newDate.getTime(),
									}));
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>퇴근 시간</label>
							<input
								type='time'
								value={formattedTimeUnixTimestampToHHMMSS(editData?.checkOut)}
								onChange={(e) => {
									const newTime = e.target.value;
									const [hours, minutes] = newTime.split(':').map(Number);
									const newDate = new Date(
										editData.checkOut || editData.checkIn // 퇴근시간 없는 경우 출근시간 기준
									);
									newDate.setHours(hours, minutes, 0, 0);
									setEditData((prev) => ({
										...prev,
										checkOut: newDate.getTime(),
									}));
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>휴게 시간</label>
							{editData?.breaks?.map((breakItem, index) => (
								<div key={index} style={{ margin: '10px 0' }}>
									<input
										type='time'
										value={formattedTimeUnixTimestampToHHMMSS(
											breakItem.startTime
										)}
										onChange={(e) =>
											handleBreakTimeChange(index, 'startTime', e.target.value)
										}
									/>
									<span style={{ margin: '0 5px' }}>~</span>
									<input
										type='time'
										value={formattedTimeUnixTimestampToHHMMSS(
											breakItem.endTime
										)}
										onChange={(e) =>
											handleBreakTimeChange(index, 'endTime', e.target.value)
										}
									/>
									<Button
										icon='trash'
										onClick={() => removeBreakTime(index)}
										style={{ marginLeft: 10 }}
									/>
								</div>
							))}
							<Button primary onClick={addBreakTime} style={{ marginTop: 10 }}>
								휴게 추가
							</Button>
						</Form.Field>
						<Form.Field>
							<label>조퇴 시간</label>
							<input
								type='time'
								value={formattedTimeUnixTimestampToHHMMSS(
									editData?.earlyLeave?.startTime
								)}
								onChange={(e) => {
									const newTime = e.target.value;
									const [hours, minutes] = newTime.split(':').map(Number);
									const newDate = new Date(
										editData.earlyLeave?.startTime || editData.checkIn
									);
									newDate.setHours(hours, minutes, 0, 0);
									setEditData((prev) => ({
										...prev,
										earlyLeave: {
											...prev.earlyLeave,
											startTime: newDate.getTime(),
										},
									}));
								}}
							/>
						</Form.Field>

						{/* 외출 시간 추가 */}
						<Form.Field>
							<label>외출 시간</label>
							{editData?.outingTimes?.map((outing, index) => (
								<div key={index} style={{ margin: '10px 0' }}>
									<input
										type='time'
										value={formattedTimeUnixTimestampToHHMMSS(outing.startTime)}
										onChange={(e) =>
											handleOutingChange(index, 'startTime', e.target.value)
										}
									/>
									<span style={{ margin: '0 5px' }}>~</span>
									<input
										type='time'
										value={formattedTimeUnixTimestampToHHMMSS(outing.endTime)}
										onChange={(e) =>
											handleOutingChange(index, 'endTime', e.target.value)
										}
									/>
									<Button
										icon='trash'
										onClick={() => removeOutingTime(index)}
										style={{ marginLeft: 10 }}
									/>
								</div>
							))}
							<Button primary onClick={addOutingTime} style={{ marginTop: 10 }}>
								외출 추가
							</Button>
						</Form.Field>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button color='black' onClick={() => setEditModalOpen(false)}>
						취소
					</Button>
					<Button
						color='green'
						onClick={() => handleUpdate(editData?.workerId)}>
						수정
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
}
