import React from 'react';
import { Table } from 'semantic-ui-react';

export default function ClientRBizWorkerMonthWageTableBasicInfo({
	centerStyle,
	worker,
	decryptoData,

	isBizWorker,
	inputBizIncomeStyle,
	handelInputChange,
	bizIncomeValue,
	// bizDeductionBeforeTaxValue,
	// bizDeductionBeforeTaxCommentValue,
	bizDeductionAfterTaxValue,
	bizDeductionAfterTaxCommentValue,
	values,
	setBizIncomeValue,
	setBizDeductionAfterTaxValue,
}) {
	// 렌더링 시 포맷팅하여 보여주기
	const formattedBizIncome = values.bizIncome
		? new Intl.NumberFormat().format(Number(values.bizIncome))
		: '';
	const formattedBizDeductionAfterTax = values.bizDeductionAfterTax
		? new Intl.NumberFormat().format(Number(values.bizDeductionAfterTax))
		: '';
	const formattedBizDeductionAfterTaxComment =
		values.bizDeductionAfterTaxComment
			? values.bizDeductionAfterTaxComment
			: '';

	return (
		<>
			{isBizWorker && (
				<>
					<Table.Row>
						<Table.Cell style={centerStyle}>성명</Table.Cell>
						<Table.Cell style={centerStyle}>생년월일</Table.Cell>
						<Table.Cell style={centerStyle}>사업소득금액</Table.Cell>
						{/* <Table.Cell style={centerStyle}>세전공제금액</Table.Cell>
						{values?.bizDeductionBeforeTax && (
							<Table.Cell style={centerStyle}>세전공제설명</Table.Cell>
						)} */}
						<Table.Cell style={centerStyle}>세후공제금액</Table.Cell>
						<Table.Cell style={centerStyle}>세후공제설명</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell style={centerStyle}>{worker}</Table.Cell>
						<Table.Cell style={centerStyle}>
							{decryptoData?.workerSocialNumberFront}
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							<input
								style={inputBizIncomeStyle}
								name='bizIncome'
								value={formattedBizIncome}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
						{/* <Table.Cell style={centerStyle}>
							<input
								style={inputBizIncomeStyle}
								name='bizDeductionBeforeTax'
								value={bizDeductionBeforeTaxValue}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
						{values.bizDeductionBeforeTax && (
							<Table.Cell style={centerStyle}>
								<input
									style={inputBizIncomeStyle}
									name='bizDeductionBeforeTaxComment'
									value={bizDeductionBeforeTaxCommentValue}
									onChange={(e) => handelInputChange(e)}
									autoComplete='off'
								/>
							</Table.Cell>
						)} */}

						<Table.Cell style={centerStyle}>
							<input
								style={inputBizIncomeStyle}
								name='bizDeductionAfterTax'
								value={formattedBizDeductionAfterTax}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
							/>
						</Table.Cell>
						<Table.Cell style={centerStyle}>
							<input
								style={inputBizIncomeStyle}
								name='bizDeductionAfterTaxComment'
								value={formattedBizDeductionAfterTaxComment}
								onChange={(e) => handelInputChange(e)}
								autoComplete='off'
								placeholder='예, 대여금 반제'
							/>
						</Table.Cell>
					</Table.Row>
				</>
			)}
		</>
	);
}
