import React from 'react';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MySelectInput from '../../../app/common/form/MySelectInput';
import {
	agentType,
	authLevelValueNomu,
	authLevelValueSemu,
	// over52Permission,
	isAgentOrNot,
} from '../../../app/api/dropdownOption';
import { useSelector } from 'react-redux';
import MyNumberInput from '../../../app/common/form/MyNumberInput';
import MyDateInput from '../../../app/common/form/MyDateInput';
// import MyPureNumberInput from '../../../app/common/form/MyPureNumberInput';
import MyTextArea from '../../../app/common/form/MyTextArea';

export default function CustomerBasicForm({
	authLevel,
	isCoWorkSemusa,
	isCoWorkNomusa,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	return (
		<>
			<h3>고객기본(agent인 경우 agent 정보) 설정(관리자용)</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				{authLevel >= 50 ? (
					<>
						<div
							className='margin'
							style={{ width: '10%', marginRight: '3px' }}>
							<MyTextInput
								label='회원등급'
								name='authLevel'
								placeholder='회원등급'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput label='id' name='id' placeholder='id' />
						</div>
					</>
				) : isCoWorkSemusa ? (
					<div className='margin' style={{ width: '10%', marginRight: '3px' }}>
						<MySelectInput
							label='회원등급'
							name='authLevel'
							options={authLevelValueSemu}
						/>
					</div>
				) : isCoWorkNomusa ? (
					<div className='margin' style={{ width: '10%', marginRight: '3px' }}>
						<MySelectInput
							label='회원등급'
							name='authLevel'
							options={authLevelValueNomu}
						/>
					</div>
				) : null}
				<div className='margin' style={{ width: '23%', marginRight: '3px' }}>
					<MyNumberInput
						type='Number'
						label='동료 근로자의 1주일 소정근무일수(숫자)'
						placeholder='동종 유사 업무 정규직'
						name='normalWorkerWeekWorkDays'
						min='1'
						max='6'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='overFiveStartDay'
						label='연차 등 상시근로자 5인 적용일(다섯번째 직원 입사일)'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				{/* <div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyPureNumberInput
						label='출근일에 따라 지급하는 비과세 식대(평균임금-소정시간 80%이상 근로자)'
						name='perDayMealTaxFree'
						placeholder='식대 일당 0000 원'
						autoComplete='off'
					/>
				</div> */}
				<div className='margin' style={{ width: '99%', marginRight: '3px' }}>
					<MyTextArea
						label='급여아웃소싱 특이사항 기록'
						name='companyWageWorkComment'
						placeholder='급여아웃소싱 특이사항 기록'
						autoComplete='off'
						rows={3}
					/>
				</div>
				{(currentUserProfile?.email === 'freya0207@gmail.com' ||
					currentUserProfile?.email === 'paydac@naver.com') && (
					<>
						<div
							className='margin'
							style={{ width: '10%', marginRight: '3px' }}>
							<MyTextInput
								label='회원등급'
								name='authLevel'
								placeholder='회원등급'
							/>
						</div>
						<div
							className='margin'
							style={{ width: '32%', marginRight: '3px' }}>
							<MyTextInput label='상호' name='companyName' placeholder='상호' />
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput label='id' name='id' placeholder='id' />
						</div>

						{/* <div
							className='margin'
							style={{ width: '33.5%', marginRight: '3px' }}>
							<MySelectInput
								label='52시간 연장 특별/특례 사업장'
								name='over52permission'
								options={over52Permission}
							/>
						</div> */}

						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label='Agent 여부'
								name='isAgent'
								options={isAgentOrNot}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label='Agent 종류'
								name='agentType'
								options={agentType}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput label='Agent 코드' name='referralAgentCode' />
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyNumberInput label='기본 이용 가격' name='price' />
						</div>
					</>
				)}
			</div>
		</>
	);
}
