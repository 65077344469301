import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && amended.length > 0 && (
						<div className='no-print'>
							{amended.map((amendment, i) => (
								<div key={i} style={{ marginBottom: '10px' }}>
									<strong style={{ color: 'red' }}>{amendment.date}</strong>
									<ul>
										{amendment.changes.map((change, j) => (
											<li
												key={j}
												style={{
													color:
														change.includes('변경전') ||
														change.includes('변경후')
															? 'blue'
															: 'black',
												}}>
												{change}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe6WorkerProtectRules({
	// serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	companyWa,
	companyUl,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	previousSectionCount5,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `복리후생시설`,
				content: [
					`${companyUn} 사원의 건강유지와 복리증진을 위하여 ${company} 내의 각종 복리시설을 갖추고 무상으로 이용토록 노력한다.`,
					`해고된 사원은 ${company} 내의 각종 복리시설을 이용할 수 없다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `문화 및 체육활동`,
				content: [
					`${companyUn} 교육훈련, 문화 및 체육활동을 통하여 사원이 건전한 사회생활과 보람찬 직장생활을 할 수 있도록 노력한다.`,
					`${company}의 지배, 관리를 받는 상태에서 소정근로시간에 이루어지는 문화 및 체육활동은 근로한 것으로 본다.`,
					`${company}의 지배, 관리를 받지 않는 상태에서 종업시각 이후 및 휴일에 이루어지는 문화 및 체육활동은 근로한 것으로 보지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `환경개선 노력`,
				content: [
					`${companyUn} 쾌적한 근로환경의 조성을 위하여 사원의 성별, 연령 또는 신체적 조건 등의 특성에 따른 ${company} 환경의 개선에 노력한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `배치전환`,
				content: [
					`${companyUn} 경영상 필요성과 사원의 능력과 전문성을 고려하여 배치전환할 수 있다.`,
					`${companyUn} 다음 각 호의 배치전환을 시행할 수 있다.`,
					[
						`생산전직: 부서 간 업무량의 변화 또는 특정 부서 인원의 이직으로 인해 같은 직무수준에서 이루어지는 배치전환`,
						`대체전직: 업무량이 전반적으로 감소된 경우 잉여인원을 조정하는 배치전환`,
						`다능전직: 수행 가능한 기술과 능력의 범위를 확장하여 배치상의 융통성을 확대하는 배치전환`,
						`교대전직: 교대조 사이의 직무 배치전환`,
						`개선전직: 보다 적합한 직무조건과 환경으로 이동하는 배치전환`,
					],
					`${companyUn} 다음 각 호의 사원을 배치전환할 수 있다.`,
					[
						`영업담당사원으로서 사회통념상 요구되는 영업실적의 부진`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 연속하여 2회를 받았을 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `교육훈련`,
				content: [
					`${companyUn} 사원의 직무능력개발을 위하여 다음 각 호와 같이 ${company}에서 필요하다고 인정할 때 수시로 교육을 실시할 수 있다.`,
					[
						`일반교육: 신규 채용 시에 해당 사원에 대하여 ${company}의 경영방침, 취업규칙 및 안전보건관리에 필요한 내용의 교육`,
						`직무교육: 업무내용 변경 또는 특별히 필요하다고 인정되는 교육`,
						`직업능력개발훈련: 담당 직무의 수행에 필요한 관계 법령상의 교육 및 훈련`,
						`출장교육: 직업능력개발의 목적으로 국내외 출장형식의 교육`,
						`교양교육: 사원의 개인능력 개발과 자질향상, 취미개발 등 직무와 관련이 없는 순수교양교육`,
						`기타 ${company}에서 필요하다고 인정하는 교육`,
					],
					`${company}에서 실시하는 사원의 교육에 소요되는 경비는 ${companyGa} 부담(고용보험을 활용한 경우 포함.)한다.`,
					`교육훈련은 소정근로시간 중 또는 소정근로시간 이외에 실시하더라도 직무와 직접 관련이 있고, 관리감독의 강제성이 있을 경우에는 해당시간을 근로시간으로 간주한다.`,
					`${companyUn} 직업능력개발훈련을 받으려는 사원과 합의하여 소정근로시간 외의 시간에 직업능력개발훈련을 실시할 수 있다.`,
					`${companyUn} 소정근로시간 외의 교육훈련시간은 ${company}의 시설을 이용하거나 근무장소에서 시행하는 직업능력개발훈련의 경우를 제외하고는 연장근로와 야간근로에 해당하는 정상임금 및 가산수당을 지급하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `훈련계약과 의무재직기간 등`,
				content: [
					`${companyWa} 직업능력개발훈련(출장교육포함)을 받으려는 사원은 직업능력개발훈련에 따른 권리∙의무 등에 관하여 훈련계약을 체결할 수 있다.`,
					`제1항에 따른 훈련계약을 체결한 사원은 직업능력개발훈련을 이수한 후 직업능력개발훈련기간의 3배 기간을 ${companyGa} 지정한 업무에 종사하여야 한다. 단, 의무재직기간은 5년을 초과할 수 없다.`,
					`의무재직기간은 교육훈련 만료일로부터 기산하고 재직기간 만료일 이전에 재차 위탁교육을 받은 경우에는 그 의무재직기간을 합산한다.`,
					`제1항에 따른 훈련계약을 체결한 사원은 의무재직기간 도중에 본인의 의사에 의하여 퇴직할 경우에는 다음 기준에 따라 교육비용을 변상하여야 한다.`,
					`* 변상액 = 교육비용(=교육비+출장비+교제비) ⅹ [의무불이행기간 / 의무복무기간]`,
					`다음에 해당하는 자는 교육비용의 반환을 면제할 수 있다.`,
					[
						`${company}의 사정에 의하여 퇴직하는 자`,
						`정년퇴직자`,
						`본인 사망으로 퇴직처리 된 자`,
						`기타 면제사유가 인정되는 자`,
					],
					`제1항에 따른 훈련계약을 체결한 사원은 부정경쟁의 방지를 위하여 근로계약 종료 이후의 1년 까지는 다음과 같은 동종업종의 재취업을 하지 아니하고, 동종업종 회사를 직∙간접적으로 경영하지 아니하며, 만일 이를 위반하였을 경우 “부정경쟁방지 및 영업비밀보호에 관한 법률”에 따른 민∙형사상의 책임을 부담한다.`,
					[
						`근로계약 종료 시점 前後1년 이내에 신설된 동종업종기업`,
						`현재의 동종업종기업`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `안전보건교육`,
				content: [
					`${companyUn} 산업재해예방을 위하여 안전 및 보건에 관한 채용시 교육, 정기교육, 작업내용 변경시 교육, 유해위험작업사용시 특별안전교육 등 산업안전보건법령에 따른 제반 교육을 실시하며 사원은 이 교육에 성실하게 참여하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `위험기계∙기구의 방호조치`,
				content: [
					`사원은 다음 각 호의 위험기계∙기구의 방호조치 사항을 준수하여야 한다.`,
					[
						`방호조치를 해체하고자 할 경우 소속부서의 장의 허가를 받아 해체할 것`,
						`방호조치를 해체한 후 그 사유가 소멸한 때에는 지체없이 원상으로 회복시킬 것`,
						`방호장치의 기능이 상실된 것을 발견한 때에는 지체없이 소속부서의 장에게 신고할 것`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `안전보호장구의 착용`,
				content: [
					`${companyUn} 안전보호 장구가 필요한 사원에게 반드시 이를 지급해야 하고, 사원은 작업할 때 ${company}에서 지급하는 안전보호 장구를 착용한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `산업안전보건법 준수`,
				content: [
					`${companyUn} 이 규칙에서 정하지 아니한 사항에 대하여는 산업안전보건법에 따라 산업재해 예방을 위한 기준을 지켜 사원의 신체적 피로와 정신적 스트레스 등에 의한 건강장해를 예방하고 안전 및 보건을 유지∙증진시킨다.`,
					`사원은 산업안전보건법에서 정하는 사항과 그 외에 업무에 관련되는 안전보건에 관하여 상사로부터 지시받은 사항을 정확하게 이행하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `물질안전보건자료의 작성∙비치`,
				content: [
					`${companyUn} 사업장에서 사용하는 고용노동부령이 정하는 화학물질 및 화학물질을 함유한 제제에 대하여는 물질안전보건자료를 취급 사원이 쉽게 볼 수 있는 장소에 게시하거나 갖추어야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `작업환경측정`,
				content: [
					`${companyGa} 산업안전보건법상 작업환경측정 대상 사업장을 소유하고 있는 경우 작업환경측정 실시 주기 및 횟수, 방법 등은 산업안전보건법을 참조한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `건강진단`,
				content: [
					`사원은 산업안전보건법이 정하는 바에 따라 ${company} 또는 건강보험공단에서 지정한 일정에 지정 의료기관에서 매년 1회 일반건강진단을 받아야 한다. 단, 사무직은 매 2년에 1회 실시한다.`,
					`${companyUn} 산업안전보건법이 정하는 바에 따라 필요한 경우 특수∙수시∙임시건강진단 등을 실시한다.`,
					`건강진단 결과 전염의 우려가 있거나 입원가료 및 휴양할 필요가 있다고 인정하는 사원에 대하여는 휴직 및 기타 적절한 조치를 취한다.`,
					`${companyUn} 제1항과 제2항에 따른 사원의 건강진단에 소요되는 비용을 부담한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `업무상 재해`,
				content: [
					`사원이 업무상 부상∙질병에 걸리거나 사망한 경우에는 근로기준법∙산업재해보상보험법의 규정에 의하여 재해보상을 행한다.`,
					`산업재해보상법의 적용을 받지 않는 업무상 부상∙질병에 대하여는 근로기준법이 정하는 바에 따라 ${companyGa} 보상한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `다른 손해배상과의 관계`,
				content: [
					`전조의 업무상 재해로 인하여 산업재해보상보험법의 재해보상을 받게 될 사원이 동일한 사유에 대하여 민법 기타 법령에 의하여 재해보상에 상당한 금품을 받을 경우에는 그 가액의 한도 내에서 ${companyUn} 보상책임을 면한다.`,
					`업무상 재해로 인하여 관계 법령이 정하는 산재보상액을 받거나 받을 사원에게 ${companyUn} 보상금을 추가하여 지급할 수 있다. 이에 따른 보상이 행해졌을 때에는 사원은 민·형사상 및 행정상 이의를 제기하지 않는다.`,
					`업무상 재해에 대해서 ${companyUn} 보험료 전액을 납부하고 ${companyUl} 보험수익자로 하는 단체보험계약을 체결할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `업무외 재해`,
				content: [
					`${companyUn} 업무외 재해에 대해서는 본 장의 재해 보상을 적용하지 않는다.`,
					`${companyUn} 업무외 재해에 대해서 ${companyUn} 보험료 전액을 납부하고 ${companyUl} 보험수익자로 하는 단체보험계약을 체결할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `성희롱 예방 교육`,
				content: [
					`“직장 내 성희롱”이란 사업주ㆍ상급자 또는 사원이 직장 내의 지위를 이용하거나 업무와 관련하여 다른 사원에게 성적 언동 등으로 성적 굴욕감 또는 혐오감을 느끼게 하거나 성적 언동 또는 그 밖의 요구 등에 따르지 아니하였다는 이유로 근로조건 및 고용에서 불이익을 주는 것을 말한다.`,
					`직장 내 성희롱을 판단하기 위한 기준의 예시는 다음과 같다.`,
					[
						`성적인 언동의 예시`,
						[
							`육체적 행위`,
							[
								`입맞춤, 포옹 또는 뒤에서 껴안는 등의 신체적 접촉행위`,
								`가슴ㆍ엉덩이 등 특정 신체부위를 만지는 행위`,
								`안마나 애무를 강요하는 행위`,
							],
							`언어적 행위`,
							[
								`음란한 농담을 하거나 음탕하고 상스러운 이야기를 하는 행위(전화통화를 포함한다)`,
								`외모에 대한 성적인 비유나 평가를 하는 행위`,
								`성적인 사실 관계를 묻거나 성적인 내용의 정보를 의도적으로 퍼뜨리는 행위`,
								`성적인 관계를 강요하거나 회유하는 행위`,
								`회식자리 등에서 무리하게 옆에 앉혀 술을 따르도록 강요하는 행위`,
							],
							`시각적 행위`,
							[
								`음란한 사진ㆍ그림ㆍ낙서ㆍ출판물 등을 게시하거나 보여주는 행위(컴퓨터통신이나 팩시밀리 등을 이용하는 경우를 포함한다)`,
								`성과 관련된 자신의 특정 신체부위를 고의적으로 노출하거나 만지는 행위`,
							],
							`그 밖에 사회통념상 성적 굴욕감 또는 혐오감을 느끼게 하는 것으로 인정되는 언어나 행동`,
						],
						`고용에서 불이익을 주는 것의 예시`,
						[
							`채용탈락, 감봉, 승진탈락, 전직(轉職), 정직(停職), 휴직, 해고 등과 같이 채용 또는 근로조건을 일방적으로 불리하게 하는 것`,
							`성희롱 여부를 판단하는 때에는 피해자의 주관적 사정을 고려하되, 사회통념상 합리적인 사람이 피해자의 입장이라면 문제가 되는 행동에 대하여 어떻게 판단하고 대응하였을 것인가를 함께 고려하여야 하며, 결과적으로 위협적ㆍ적대적인 고용환경을 형성하여 업무능률을 떨어뜨리게 되는지를 검토하여야 한다.`,
						],
					],
					`사업주는 직장 내 성희롱을 예방하고 사원이 안전한 근로환경에서 일할 수 있는 여건을 조성하기 위하여 직장 내 성희롱의 예방을 위한 교육(이하 “성희롱 예방 교육”이라 한다)을 매년 실시하여야 한다.`,
					`제3항의 성희롱예방교육 실시 주기인 매년의 의미는 사원별로 각각의 입사일을 기준으로 매 1년 주기를 뜻한다.`,
					`(예시, ${companyUn} 매년 4월1일에 성희롱 예방교육을 실시하는데, 4월 2일에 입사한 사원은 해당년도 성희롱 예방교육자 명단에는 없다하더라도 다음해 4월 1일에 ${companyGa} 교육을 실시(매년 실시)하면, 해당 사원을 기준으로 보면 매년 성희롱예방교육을 받게된다.)`,
					`사업주 및 사원은 제3항에 따른 성희롱 예방 교육을 받아야 한다.`,
					`제3항의 성희롱 예방 교육에는 다음 각 호의 내용이 포함되어야 한다.`,
					[
						`직장 내 성희롱에 관한 법령`,
						`해당 사업장의 직장 내 성희롱 발생 시의 처리 절차와 조치 기준`,
						`해당 사업장의 직장 내 성희롱 피해 사원의 고충상담 및 구제 절차`,
						`그 밖에 직장 내 성희롱 예방에 필요한 사항`,
					],
					`제3항에 따른 성희롱 예방 교육은 사업의 규모나 특성 등을 고려하여 사원연수ㆍ조회ㆍ회의, 인터넷 등 정보통신망을 이용한 사이버 교육 등을 통하여 실시할 수 있다. 다만, 단순히 교육자료 등을 배포ㆍ게시하거나 전자우편을 보내거나 게시판에 공지하는 데 그치는 등 사원에게 교육 내용이 제대로 전달되었는지 확인하기 곤란한 경우에는 예방 교육을 한 것으로 보지 않는다.`,
					`사업주는 성희롱 예방 교육의 내용을 사원이 자유롭게 열람할 수 있는 장소에 항상 게시하거나 갖추어 두어 사원에게 널리 알려야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직장 내 성희롱 발생시 조치`,
				content: [
					`${companyUn} 성희롱이 발생할 경우 가해자에 대하여는 신속한 조치를 취하도록 하며 피해자가 상담, 고충의 제기 또는 관계기관에 진정한 것을 이유로 그 피해 사원에게 고용상 불이익한 조치를 하지 못한다.`,
					`${companyUn} 성희롱 예방을 위한 업무의 처리와 사원의 성희롱 피해 상담, 성희롱 사건의 조사 및 처리를 위하여 관리(인사)담당 부서에 성희롱 고충상담창구(이하 “고충상담창구”라 한다) 및 고충상담원을 두고 ${company} 내외에 적극 알려 이를 인지할 수 있도록 한다.`,
					`성희롱과 관련하여 상담을 원하는 사원은 서면, 전화, 온라인 및 방문 등의 방법으로 고충상담창구에 상담을 신청할 수 있다.`,
					`고충상담원은 상담의 신청을 받은 경우에 지체없이 상담에 응하여야 하며, 조사신청 등 처리절차를 안내하여야 한다.`,
					`성희롱 사건 신고, 조사, 심의 내용은 다음과 같다.`,
					[
						`피해자가 아닌 대리인 또는 제3자도 신고할 수 있고 피해자 동의 없이 신고할 수 있으며 ${companyUn} 신고 없이 인지된 사건도 신고사건에 준하여 처리하여야 한다.`,
						`${companyUn} 신고를 받는 즉시 성희롱 심의위원회를 구성하고 피해자가 요청하는 경우 피해자 보호를 위해 근무장소 변경, 유급휴가 명령 등 조치를 취하여야 한다.`,
						`${companyUn} 사건처리 과정에서 피해자가 요청하는 경우 근무 장소 변경, 유급휴가 명령 등 적절한 조치를 한다.`,
						`조사·심의는 신고 즉시 착수하여 15일 이내에 완료하며, 필요시 15일 연장할 수 있다.`,
						`행위자는 심의위원회에 출석하여 소명할 수 있다.`,
						`심의·의결 결과는 지체없이 피해자와 행위자에게 통보한다.`,
					],
					`행위자에 대한 징계 등 조치사항은 다음과 같다.`,
					[
						`징계위원회는 성희롱 심의위원회의 결정 등을 반영하여 성희롱 행위자의 징계를 결정한다. 단, 징계수준을 결정하기 전 피해자의 의견을 청취해야 한다.`,
						`징계위원회의 징계 결정은 사업주의 지시에 의해 지체 없이 시행한다.`,
						`직장 내 성희롱 확인 시 행위자는 징계하며 피해자와 분리배치한다.`,
						`행위자에 대한 징계는 직장 내 성희롱 행위의 양태 및 정도, 반복/지속성, 의도성, 피해자의 피해 정도, 행위자의 지위와 책임을 종합적으로 검토하여 공정하고 엄격하게 결정한다. 고의가 있고 비위의 정도가 중한 경우 반드시 정직 이상의 중징계한다.`,
						`행위자에 대하여 징계 외에도 피해자에 대한 접근금지, 교육이수명령 등 적절한 조치를 할 수 있다.`,
						`행위자가 재범이거나 위원회의 조치를 불이행하는 경우 정직이상의 중징계 또는 해고한다.`,
						`행위자가 피해자를 비난, 음해, 보복하는 경우 정직이상의 중징계 또한 해고한다.`,
						`직장 내 성희롱 사건 처리 관련자는 사건 처리와 관련하여 알게 된 비밀을 철저히 지켜야 하며 이를 누설할 시 징계한다. 다만, 조사와 관련된 내용을 사업주에게 보고하거나 관계 기관의 요청에 따라 필요한 정보를 제공하는 경우는 제외한다.`,
						`피해자가 요청하는 경우 근무 장소 변경, 유급휴가 명령 조치하며, 치료가 필요한 경우 지원한다.`,
						`피해자에게 정신적, 육체적 손상을 주는 행위를 하거나 이를 예방하여야 할 책임있는 지위에 있는 자로서 이를 방치하는 행위를 한 자는 행위자에 준하여 징계한다.`,
					],
					`사업주는 성희롱 발생 사실을 신고한 사원 및 피해 사원 등에게 다음 각 호의 어느 하나에 해당하는 불리한 처우를 하여서는 아니 된다.`,
					[
						`파면, 해임, 해고, 그 밖에 신분상실에 해당하는 불이익 조치`,
						`징계, 정직, 감봉, 강등, 승진 제한 등 부당한 인사조치`,
						`직무 미부여, 직무 재배치, 그 밖에 본인의 의사에 반하는 인사조치`,
						`성과평가 또는 동료평가 등에서 차별이나 그에 따른 임금 또는 상여금 등의 차별 지급`,
						`직업능력 개발 및 향상을 위한 교육훈련 기회의 제한`,
						`집단 따돌림, 폭행 또는 폭언 등 정신적ㆍ신체적 손상을 가져오는 행위를 하거나 그 행위의 발생을 방치하는 행위`,
						`그 밖에 신고를 한 사원 및 피해 사원 등의 의사에 반하는 불리한 처우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `고객등에 의한 성희롱 방지`,
				content: [
					`${companyUn} 고객 등 업무와 밀접한 관련이 있는 자의 성희롱 행위로 인하여 사원이 고충해소를 요청할 경우 근무장소 변경, 배치전환 등 가능한 조치를 취하도록 노력한다.`,
					`사업주는 사원이 제1항에 따른 피해를 주장하거나 고객 등으로부터의 성적 요구 등에 따르지 아니하였다는 것을 이유로 해고나 그 밖의 불이익한 조치를 하여서는 아니 된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직장 내 괴롭힘의 금지 및 예방교육`,
				content: [
					`${company} 또는 사원은 직장에서의 지위 또는 관계 등의 우위를 이용하여 업무상 적정범위를 넘어 다른 사원에게 신체적ㆍ정신적 고통을 주거나 근무환경을 악화시키는 다음의 행위(이하 “직장 내 괴롭힘”이라 한다)를 하여서는 아니 된다.`,
					[
						`신체에 대하여 폭행하거나 협박하는 행위`,
						`지속∙반복적인 욕설이나 폭언`,
						`다른 사원들 앞에서 또는 온라인상에서 모욕감을 주거나 개인사에 대한 소문을 퍼뜨리는 등 명예를 훼손하는 행위`,
						`합리적 이유 없이 반복적으로 개인 심부름 등 사적인 용무를 지시하는 행위`,
						`합리적 이유 없이 업무능력이나 성과를 인정하지 않거나 조롱하는 행위`,
						`집단적으로 따돌리거나, 정당한 이유 없이 업무와 관련된 중요한 정보 또는 의사결정 과정에서 배제하거나 무시하는 행위`,
						`정당한 이유 없이 상당기간 동안 근로계약서 등에 명시되어 있는 업무와 무관한 일을 지시하거나 근로계약서 등에 명시되어 있는 업무와 무관한 허드렛일만 시키는 행위`,
						`정당한 이유 없이 상당기간 동안 일을 거의 주지 않는 행위`,
						`그 밖에 업무의 적정 범위를 넘어 사원에게 신체적∙정신적 고통을 주거나 근무환경을 악화시키는 행위`,
						`기타 사회통념 또는 행정해석, 판례 등으로 직장 내 괴롭힘으로 인정되는 행위를 한 경우`,
					],
					`${companyUn} 직장 내 괴롭힘 예방을 위한 교육(이하 “직장 내 괴롭힘 예방교육”이라 한다)을 1년에 1회 이상 실시한다.`,
					`직장 내 괴롭힘 예방교육의 내용은 다음 각 호와 같다.`,
					[
						`직장 내 괴롭힘 행위의 정의`,
						`금지되는 직장 내 괴롭힘 행위`,
						`직장 내 괴롭힘 상담절차`,
						`직장 내 괴롭힘 사건처리절차`,
						`직장 내 괴롭힘 피해자 보호를 위한 조치`,
						`직장 내 괴롭힘 행위자에 대한 조치`,
						`그 밖에 직장 내 괴롭힘 예방을 위한 내용`,
					],
					`${companyUn} 직장 내 괴롭힘 예방교육의 주요 내용을 항상 게시하거나 사원들이 열람할 수 있도록 조치한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직장 내 괴롭힘 발생시 조치`,
				content: [
					`${companyUn} 직장 내 괴롭힘이 발생할 경우 가해자에 대하여는 신속한 조치를 취하도록 하며 피해자가 상담, 고충의 제기 또는 관계기관에 진정한 것을 이유로 그 피해 사원에게 고용상 불이익한 조치를 하지 못한다.`,
					`${companyUn} 직장 내 괴롭힘 예방을 위한 업무의 처리와 사원의 직장 내 괴롭힘 피해 상담, 직장 내 괴롭힘 사건의 조사 및 처리를 위하여 관리(인사)담당 부서에 직장 내 괴롭힘 고충상담창구(이하 “고충상담창구”라 한다) 및 고충상담원을 두고 ${company} 내외에 적극 알려 이를 인지할 수 있도록 한다.`,
					`직장 내 괴롭힘과 관련하여 상담을 원하는 사원은 서면, 전화, 온라인 및 방문 등의 방법으로 고충상담창구에 상담을 신청할 수 있다.`,
					`고충상담원은 상담의 신청을 받은 경우에 지체없이 상담에 응하여야 하며, 조사신청 등 처리절차를 안내하여야 한다.`,
					`직장 내 괴롭힘 사건 신고, 조사, 심의 내용은 다음과 같다.`,
					[
						`피해자가 아닌 대리인 또는 제3자도 신고할 수 있고 피해자 동의 없이 신고할 수 있으며 ${companyUn} 신고 없이 인지된 사건도 신고사건에 준하여 처리하여야 한다.`,
						`${companyUn} 신고를 받는 즉시 직장 내 괴롭힘 심의위원회를 구성하고 피해자가 요청하는 경우 피해자 보호를 위해 근무장소 변경, 유급휴가 명령 등 조치를 취하여야 한다.`,
						`${companyUn} 사건처리 과정에서 피해자가 요청하는 경우 근무 장소 변경, 유급휴가 명령 등 적절한 조치를 한다.`,
						`조사·심의는 신고 즉시 착수하여 15일 이내에 완료하며, 필요시 15일 연장할 수 있다.`,
						`행위자는 심의위원회에 출석하여 소명할 수 있다.`,
						`심의·의결 결과는 지체없이 피해자와 행위자에게 통보한다.`,
					],
					`행위자에 대한 징계 등 조치사항은 다음과 같다.`,
					[
						`징계위원회는 직장 내 괴롭힘 심의위원회의 결정 등을 반영하여 직장 내 괴롭힘 행위자의 징계를 결정한다. 단, 징계수준을 결정하기 전 피해자의 의견을 청취해야 한다.`,
						`징계위원회의 징계 결정은 사업주의 지시에 의해 지체 없이 시행한다.`,
						`직장 내 괴롭힘 확인 시 행위자는 징계하며 피해자와 분리배치한다.`,
						`행위자에 대한 징계는 직장 내 괴롭힘 행위의 양태 및 정도, 반복/지속성, 의도성, 피해자의 피해 정도, 행위자의 지위와 책임을 종합적으로 검토하여 공정하고 엄격하게 결정한다. 고의가 있고 비위의 정도가 중한 경우 반드시 정직 이상의 중징계한다.`,
						`행위자에 대하여 징계 외에도 피해자에 대한 접근금지, 교육이수명령 등 적절한 조치를 할 수 있다.`,
						`행위자가 재범이거나 위원회의 조치를 불이행하는 경우 정직이상의 중징계 또는 해고한다.`,
						`행위자가 피해자를 비난, 음해, 보복하는 경우 정직이상의 중징계 또한 해고한다.`,
						`직장 내 괴롭힘 사건 처리 관련자는 사건 처리와 관련하여 알게 된 비밀을 철저히 지켜야 하며 이를 누설할 시 징계한다. 다만, 조사와 관련된 내용을 사업주에게 보고하거나 관계 기관의 요청에 따라 필요한 정보를 제공하는 경우는 제외한다.`,
						`피해자가 요청하는 경우 근무 장소 변경, 유급휴가 명령 조치하며, 치료가 필요한 경우 지원한다.`,
						`피해자에게 정신적, 육체적 손상을 주는 행위를 하거나 이를 예방하여야 할 책임있는 지위에 있는 자로서 이를 방치하는 행위를 한 자는 행위자에 준하여 징계한다.`,
					],
					`사업주는 직장 내 괴롭힘 발생 사실을 신고한 사원 및 피해 사원 등에게 다음 각 호의 어느 하나에 해당하는 불리한 처우를 하여서는 아니 된다.`,
					[
						`파면, 해임, 해고, 그 밖에 신분상실에 해당하는 불이익 조치`,
						`징계, 정직, 감봉, 강등, 승진 제한 등 부당한 인사조치`,
						`직무 미부여, 직무 재배치, 그 밖에 본인의 의사에 반하는 인사조치`,
						`성과평가 또는 동료평가 등에서 차별이나 그에 따른 임금 또는 상여금 등의 차별 지급`,
						`직업능력 개발 및 향상을 위한 교육훈련 기회의 제한`,
						`집단 따돌림, 폭행 또는 폭언 등 정신적ㆍ신체적 손상을 가져오는 행위를 하거나 그 행위의 발생을 방치하는 행위`,
						`그 밖에 신고를 한 사원 및 피해 사원 등의 의사에 반하는 불리한 처우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `고객의 폭언 등에 대한 조치`,
				content: [
					`고객을 응대하는 업무를 주로 하는 사원이 고객으로부터 폭언, 폭행 등을 당한 경우 ${companyUn} 해당 사원의 업무를 일시적으로 중단 또는 전환하거나 일정시간 휴게시간을 연장, 건강장해 관련 치료 및 상담 지원, 고소 및 고발 또는 손해배상 청구 등을 하는데 필요한 조치 등을 취한다.`,
					`${companyUn} 고객응대 사원의 건강장해를 예방하기 위하여 폭언 등을 하지 않도록 요청하는 문구 게시 또는 음성 안내를 하고, 고객응대 매뉴얼을 구비하여 고객이 폭언 등 부적절한 행동을 하였을 때 사원이 자신을 보호하기 위하여 어떠한 방어 행동을 할 수 있는지를 주지시키는 고객응대 업무 매뉴얼과 건강장해 예방 관련 교육 등의 필요한 조치를 하여야 한다.`,
					`일시적인 업무의 중단으로도 사원의 건강장해가 해소되지 않을 때에는 ${companyUn} 사원의 업무를 전환시켜야 한다.`,
					`${companyUn} 사원이 고객의 폭언 등으로 인한 피해 복구를 위한 요구를 하였다는 이유로 해고나 그 밖에 불리한 처우를 하여서는 아니 된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `임신 중인 사원의 보호`,
				content: [
					`${companyUn} 임신 중인 사원의 보호를 위하여 다음 각 호를 준수한다.`,
					[
						`임신 중인 여성 사원에 대하여 시간외 근로를 시키지 않는다.`,
						`임신 중인 여성 사원이 청구하면 해당 사원을 쉬운 종류의 업무에 배치∙전환시킨다.`,
						`${companyUn} 임신 중인 사원을 경미한 업무로 전환시킬 수 있다.`,
						`${companyUn} 임산부보호휴가 또는 유산∙사산보호휴가의 사용을 종료한 사원에게 해당 휴가의 사용 전과 동일한 업무 또는 동등한 수준의 임금을 지급하는 직무에 복귀시킨다.`,
					],
					`${companyUn} 임신 중인 사원을 도덕∙보건상 유해∙위험한 사업에 배치하지 않는다.`,
					`${companyUn} 임산 중인 사원을 오후 10시부터 오전 6시까지의 시간 및 휴일에 근로시키지 못한다. 다만, 임신 중인 사원의 명시적인 청구가 있는 경우로서 고용노동부장관의 인가를 받으면 그러하지 아니하다.`,
					`${companyUn} 제3항의 경우 노동부장관의 인가를 받기 전에 사원의 건강 및 모성 보호를 위하여 그 시행 여부와 방법 등에 관하여 사원대표와 성실하게 협의하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `산후 1년 미만 여성 사원의 보호`,
				content: [
					`산후 1년 미만 사원의 경우에 다음 각 호와 같이 근로시간을 제한한다.`,
					[
						`산후 1년 미만 사원에 대하여 1일 2시간, 주간 6시간, 연간 150시간을 초과하는 연장근로를 시키지 못 한다.`,
						`산후 1년 미만인 사원에게 야간 및 휴일근로를 시키지 못한다. 단, 본인의 동의와 노동부 장관의 인가가 있는 경우에는 그러하지 아니하다.`,
					],
					`${companyUn} 제1항의 경우 노동부장관의 인가를 받기 전에 산후 1년 미만 사원의 건강 및 모성 보호를 위하여 그 시행 여부와 방법 등에 관하여 사원대표와 성실하게 협의하여야 한다.`,
					`${companyUn} 산후 1년 미만 사원을 도덕∙보건상 유해∙위험한 사업에 배치하지 않는다.`,
					`${companyUn} 생후 1년 미만의 유아를 가진 여성 사원이 휴게시간 외에 1일 2회 각 30분씩의 유급 수유시간을 청구하면 허락하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `여성 사원 보호`,
				content: [
					`${companyUn} 18세 이상 여성 사원에게 야간근로[오후 10시부터 오전 6시까지의 근로] 및 휴일근로를 시키려면, 해당 사원의 동의를 받아야 한다.`,
					`${companyUn} 임산부가 아닌 18세 이상의 여성 사원을 임신∙출산에 관한 기능에 유해∙위험한 사업에 사용하지 못한다.`,
					`${companyUn} 남녀차별을 금하고, 남녀고용평등과 일∙가정양립지원에 관한 법률에 의거한 남녀균등처우에 노력한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `연소 사원의 보호`,
				content: [
					`${companyUn} 18세 미만 사원을 오후 10시부터 오전 6시까지의 시간 및 휴일에 근로시키지 못한다. 다만, 18세 미만 사원의 명시적인 청구가 있는 경우로서 고용노동부장관의 인가를 받으면 그러하지 아니하다.`,
					`${companyUn} 제1항의 경우 노동부장관의 인가를 받기 전에 연소 사원의 건강 및 모성 보호를 위하여 그 시행 여부와 방법 등에 관하여 사원대표와 성실하게 협의하여야 한다.`,
					`${companyUn} 18세 미만 사원을 다음 각 호의 어느 하나에 해당하는 도덕∙보건상 유해·위험한 사업에 사용하지 못한다.`,
					[
						`「산업보건기준에 관한 규칙」 제69조제2호 및 제3호에 따른 고압작업 및 잠수작업`,
						`「건설기계관리법」, 「도로교통법」 등에서 18세 미만인 자에 대하여 운전·조종면허 취득을 제한하고 있는 직종 또는 업종의 운전·조종업무`,
						`「청소년보호법」 등 다른 법률에서 18세 미만 청소년의 고용이나 출입을 금지하고 있는 직종이나 업종`,
						`교도소 또는 정신병원에서의 업무`,
						`소각 또는 도살의 업무`,
						`유류를 취급하는 업무(단, 주유업무는 제외)`,
						`2-브로모프로판을 취급하거나 노출될 수 있는 업무`,
						`그 밖에 고용노동부장관이 산업재해보상보험및예방심의위원회의 심의를 거쳐 지정하여 고시하는 업무`,
					],
					`${companyUn} 18세 미만인 사원에 대하여는 그 연령을 증명하는 가족관계기록사항에 관한 증명서와 친권자 또는 후견인의 동의서를 ${company}에 갖추어 두어야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `질병 사원의 근로금지`,
				content: [
					`${companyUn} 다음 각 호의 어느 하나에 해당하는 사원에 대해서는 근로를 금지하고, 강제휴직을 부여하여야 한다.`,
					[
						`전염될 우려가 있는 질병에 걸린 사원. 다만, 전염을 예방하기 위한 조치를 한 경우에는 그러하지 아니하다.`,
						`조현병, 마비성 치매에 걸린 사원`,
						`심장∙신장∙폐 등의 질환이 있는 사람으로서 근로에 의하여 병세가 악화될 우려가 있는 사원`,
						`제1호부터 제3호까지의 규정에 준하는 질병으로서 고용노동부장관이 정하는 질병에 걸린 사원`,
					],
					`${companyUn} 제1항에 따라 근로를 금지하거나 근로를 다시 시작하도록 하는 경우에는 미리 보건관리자(의사인 보건관리자만 해당한다), 산업보건의 또는 건강진단을 실시한 의사의 의견을 들어야 한다.`,
					`${companyUn} 다음 각 호의 어느 하나에 해당하는 사원을 해당 유해물질 또는 방사선을 취급하거나 해당 유해물질의 분진∙증기 또는 가스가 발산되는 업무 또는 해당 업무로 인하여 사원의 건강을 악화시킬 우려가 있는 업무에 종사하도록 해서는 안 된다.`,
					[
						`일반건강검진 및 특수건강검진에 따른 건강진단 결과 유기화합물∙금속류 등의 유해물질에 중독된 사원`,
						`유기화합물∙금속류 등의 유해물질에 중독될 우려가 있다고 의사가 인정하는 사원`,
						`진폐의 소견이 있는 사원`,
						`방사선에 피폭된 사원`,
					],
					`${companyUn} 다음 각 호의 어느 하나에 해당하는 질병이 있는 사원을 고기압 업무에 종사하도록 해서는 안 된다.`,
					[
						`감압증이나 그 밖에 고기압에 의한 장해 또는 그 후유증`,
						`결핵, 급성상기도감영, 진폐, 폐기종, 그 밖의 호흡기계의 질병`,
						`빈혈증, 심장판막증, 관상동맥경화증, 고혈압증, 그 밖의 혈액 또는 순환기계의 질병`,
						`정신신경증, 알코을중독, 신경통, 그 밖의 정신신경계의 질병`,
						`메니에르씨병, 중이염, 그 밖의 이관협착을 수반하는 귀 질환`,
						`관절염, 류마티스, 그 밖의 운동기계의 질병`,
						`천식, 비만증, 바세도우씨병, 그 밖에 알레르기성∙내분비계∙물질대사 또는 영양장해 등과 관련된 질병`,
					],
					`${companyUn} 제3항 및 제4항의 사유가 발생한 사원을 경영상 필요성과 사원의 능력과 전문성을 고려하여 배치전환하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }
		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [companyUl, companyWa, company, companyUn, companyGa]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제6장 사원 보호제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4 +
							previousSectionCount5
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
