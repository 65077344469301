import React from 'react';

export default function ClientDashNewFunction({
	setOpenWhatsNew,
	openWhatsNew,
}) {
	return (
		<div
			onClick={() => setOpenWhatsNew(!openWhatsNew)}
			style={{ cursor: 'pointer' }}>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				* 24년 09월 15일 : SafeHR 서비스 시작합니다.
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				24년 12월 20일 : 직원List에 2025년 최저임금 부족액 표시
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				24년 12월 29일 : 2025년 변경 노동법 안내
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				24년 12월 29일 : 2025년 건강보험 상한액 반영 표시
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 1일 : 직원별 근로시간 정보, 임금정보 조회 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 1일 : 계약기간 만료일이 있는 경우 월급 계약 만료일에 표시 기능
				추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 3일 : 사용자의 근로계약서, 임금계약서 특약 입력 기능 추가 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				* 25년 1월 5일 : 직원 List 이름순, 입사일순 정렬 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 13일 : 년도별 직원 전체 연차 현황 조회 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 13일 : 년도별 직원 전체 연차 현황 조회 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 15일 : 회사 임의 부여 연차일수 추가 연차계산 로직 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 17일 : 연차사용일, 결근일, 지각/조퇴/외출 등 근태 내역 명확히
				DB에 저장
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 18일 : 연차사용 반차, 반반차 등 소수점 사용 가능하도록 로직
				추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 1월 19일 : 월급직 임금계약서에 주휴수당 금액과 계산방법 표시 기능
				추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				* 25년 1월 21일 : 연도별 퇴사자 연차 계산 로직 추가 및 연차대장 개발
				추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 03일 : 임금명세서 이메일 발송 기능 개발 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 06일 : 계약직 근로자 계약종료 7일전 이메일 알림 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 10일 : 시급직/일용직/사업소득직 급여복사 및 시급직 엑셀업로드
				기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 11일 : 전원합의체 판례에 따라 근로계약서, 임금계약서, 취업규칙
				등 통상임금 정의 및 설명에 "고정성" 모두 삭제
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 13일 : 일용직 건보/국민 취득신고 상실신고 엑셀파일 완성
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 14일 : 4대보험 퇴직 신고시 실업급여 신고 여부 표시
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 16일 : 결근/주휴공제/지각등/연차 일자(요일)포함 데이터 정비
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 17일 : 2025년 2월23일 변경 노동법 취업규칙 반영
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 18일 : 2025년 2월23일 변경 노동법 어린이집 취업규칙 반영
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 18일 : SafeHR 사용메뉴얼 다운로드 기능 추가
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 19일 ~ 25일 : SafeHR 직원 출퇴근 근태관리 시스템 개발
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 2월 26일 ~ 2월28일 : SafeHR 직원 출퇴근 근태관리 회사 시스템 개발
			</h3>
			<h3 style={{ marginBottom: '-20px', color: 'blue' }}>
				25년 3월1일 : 입력시 정규식으로 입력값 검증 기능 추가
			</h3>
			<h3 style={{ marginBottom: '20px', color: 'blue' }}>
				* 25년 3월14일 : 연간 통상 상여금 통상임금 반영 로직 추가
			</h3>
			<button
				style={{
					fontSize: '15px',
					color: 'black',
					height: '30px',
					width: '200px',
					background: 'white',
				}}
				onClick={() => setOpenWhatsNew(!openWhatsNew)}>
				내용 닫기
			</button>
			<hr />
		</div>
	);
}
