import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && amended.length > 0 && (
						<div className='no-print'>
							{amended.map((amendment, i) => (
								<div key={i} style={{ marginBottom: '10px' }}>
									<strong style={{ color: 'red' }}>{amendment.date}</strong>
									<ul>
										{amendment.changes.map((change, j) => (
											<li
												key={j}
												style={{
													color:
														change.includes('변경전') ||
														change.includes('변경후')
															? 'blue'
															: 'black',
												}}>
												{change}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe3WorkerRules({
	serviceStartDate,
	profile,
	authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `근로자위원 자격`,
				content: [
					`근로자위원에 입후보하고자 하는 사원은 당해 사업장의 근로자여야 한다.`,
				],
				amended:
					serviceStartDate < '2021-11-19'
						? [
								{
									date: '2022년 12월 11일 개정',
									changes: [
										'변경전 : 근로자위원에 입후보하고자 하는 사원은 당해 사업장의 사원 10인 이상의 추천(복수추천 가능)을 받아야 한다.',
										'변경후 : 근로자위원에 입후보하고자 하는 사원은 당해 사업장의 근로자여야 한다.',
										'개정이유 : 근로자 참여 증진 및 협력에 관한 법률 시행령 제3조 (근로자를 대표하는 위원의 입후보 자격) 변경',
									],
								},
						  ]
						: null,
			},
			{
				title: `근로자위원 선출`,
				content: [
					`근로자위원은 직접∙비밀∙무기명투표에 의하여 선출한다. 다만, 사업 또는 사업장의 특수성으로 인하여 부득이하다고 인정되는 경우에는 작업부서별로 사원수에 비례하여 근로자위원을 선출할 사원(이하 이 조에서 “위원선거인”이라 한다)를 선출하고 위원선거인 과반수의 직접∙비밀∙무기명투표에 의하여 근로자위원을 선출할 수 있다.`,
					`근로자위원 선출시 2명 이상 후보의 득표수가 같을 때에는 장기근속자, 연장자 순으로 당선자를 결정한다.`,
					`근로자위원은 본인들 중 1명을 근로자 위원 대표로 선출하여, 그로 하여금 회의소집을 요구하게 할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `보궐선거`,
				content: [
					`근로자위원에 결원이 생긴 때에는 결원이 발생한 날부터 30일 이내에 보궐선거를 실시한다.`,
					`제①항에 불구하고 제13조에 의한 근로자위원으로 선출되지 못한 사원 중 다수 득표자순에 의한 차점자 명부를 작성∙보관하고 근로자위원의 결원을 보궐선거 없이 명부상 서열에 따라 충원할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections?.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, setSectionCount]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};
	return (
		<>
			<div className='jang sectionup'>제3장 근로자위원 선출</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + previousSectionCount2 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
