import React, { useState, useEffect, useMemo } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && amended.length > 0 && (
						<div className='no-print'>
							{amended.map((amendment, i) => (
								<div key={i} style={{ marginBottom: '10px' }}>
									<strong style={{ color: 'red' }}>{amendment.date}</strong>
									<ul>
										{amendment.changes.map((change, j) => (
											<li
												key={j}
												style={{
													color:
														change.includes('변경전') ||
														change.includes('변경후')
															? 'blue'
															: 'black',
												}}>
												{change}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe1GeneralRules({
	company, // 이 부분 수정
	serviceStartDate,
	allOpen,
	setSectionCount, // 조항 수를 설정하는 함수 전달
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `목적`,
				content: [
					`본 규칙은 근로기준법과 노동관계법령에 의거하여 ${company}에서 근무하는 근로자(이하 "사원"이라 한다)의 근로조건과 복무원칙을 정하여 사원의 기본적 생활과 권익을 보장하고, ${company}의 기본질서유지와 발전을 목적으로 한다.`,
				],
			},
			{
				title: `적용범위`,
				content: [
					`이 규칙은 제3조의 사원에 대하여 적용한다.`,
					`기간제 및 단시간근로자의 보호 등에 관한 법률과 파견근로자 보호 등에 관한 법률의 적용을 받는 사원은 해당 법률을 적용한다.`,
					`일용직 사원, 촉탁직 사원, 감시·단속직 사원 등 기타 임시로 고용된 사원 중 기간제 및 단시간근로자의 보호 등에 관한 법률과 파견근로자 보호 등에 관한 법률의 적용을 받지 아니하는 사원의 근로조건은 별도로 정할 수 있다.`,
					`제3조 제4항에 따른 초단시간 사원에 대해서는 근로기준법 제18조에 따라 본 규칙의 휴일조항과 연차유급휴가조항을 적용하지 아니하고, 근로자퇴직급여 보장법 제4조에 의거 퇴직금제도를 적용하지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2024-01-05'
						? [
								{
									date: '2024년 1월 5일 개정',
									changes: [
										'변경전 : ④⑤ 신설',
										'변경후 :',
										'④ 제3조 제4항에 따른 초단시간 사원에 대해서는 근로기준법 제18조에 따라 본 규칙의 휴일조항과 연차유급휴가조항을 적용하지 아니하고, 근로자퇴직급여 보장법 제4조에 의거 퇴직금제도를 적용하지 아니한다.',
										'⑤ 기타 필요한 사항은 별도로 정할 수 있다.',
										'개정이유 : 근로기준법 및 근로자퇴직급여 보장법에 따라 휴일, 휴가, 퇴직금 적용제외 되는 초단시간 근로자에 대한 내용 명시',
									],
								},
						  ]
						: null,
			},
			{
				title: `사원의 정의`,
				content: [
					`이 규칙에서 사원이라 함은 제2장에서 정하는 바에 의하여 ${company}에 사원으로 채용된 자를 말하며, 사원의 자격은 ${company}에 첫 출근한 날로부터 발생한다.`,
					'이 규칙에서 사원대표라 함은 근로기준법 제24조 3항에 나오는 `근로자대표`를 뜻하며, 사원의 과반수로 조직된 노동조합이 없는 경우에는 사원의 과반수를 대표하는 자를 말한다.',
					`이 규칙에서 단시간 사원이라 함은 근로기준법 제2조 제1항 제9호 "1주 동안의 소정근로시간이 ${company}에서 같은 종류의 업무에 종사하는 통상 근로자(사원)의 1주 동안의 소정근로시간에 비하여 짧은 사원"을 말한다.`,
					`이 규칙에서 초단시간 사원이라 함은 전항의 단시간 사원 중 4주동안(4주 미만으로 근로하는 경우에는 그 기간)을 평균하여 1주 동안의 소정근로시간이 15시간 미만인 사원을 말한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < '2024-01-05'
						? [
								{
									date: '2024년 1월 5일 개정',
									changes: [
										'변경전 : ③④⑤ 신설',
										`변경후 : `,
										`③ 이 규칙에서 단시간 사원이라 함은 근로기준법 제2조 제1항 제9호 "1주 동안의 소정근로시간이 ${company}에서 같은 종류의 업무에 종사하는 통상 근로자(사원)의 1주 동안의 소정근로시간에 비하여 짧은 사원"을 말한다.`,
										'④ 이 규칙에서 초단시간 사원이라 함은 전항의 단시간 사원 중 4주 동안(4주 미만으로 근로하는 경우에는 그 기간)을 평균하여 1주 동안의 소정근로시간이 15시간 미만인 사원을 말한다.',
										'⑤ 기타 필요한 사항은 별도로 정할 수 있다.',
										'개정이유 : 단시간 및 초단시간 사원에 대한 정의 명시',
									],
								},
						  ]
						: null,
			},
		];

		// if (['대한민', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }
		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, serviceStartDate]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제1장 총 칙</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + 1} // 동적으로 number 생성
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
