import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import { koreanHolidays } from '../../../../../app/common/util/util';

export default function ClientReqDashboard4InCalendarYear({
	selectedYear,
	attendanceData,
	categories,
	activeCategory,
	onCategoryChange,
}) {
	// 카테고리별 테이블 렌더링
	const renderCategoryTable = (category) => {
		const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

		const getDaysInMonth = (year, month) => {
			return new Date(year, month + 1, 0).getDate();
		};

		// calculateColumnSums 함수 내부 수정
		const calculateColumnSums = (data = [], category) => {
			const sums = Array(32).fill(0); // 0~31일(32개) 처리

			data.forEach((month) => {
				(month.days || []).forEach((entries, dayIndex) => {
					// dayIndex: 0(1일) ~ 30(31일), 31(종합)
					const targetIndex = dayIndex < 31 ? dayIndex : 31; // 31번 인덱스는 종합

					sums[targetIndex - 1] = entries
						.filter(
							(entry) => category === '전체' || entry.startsWith(category)
						)
						.reduce(
							(sum, entry) => sum + (parseFloat(entry.split(' ')[1]) || 0),
							0
						);
				});
			});

			return sums;
		};

		const columnSums = calculateColumnSums(attendanceData, category);
		const holidays = koreanHolidays[selectedYear] || [];

		const filteredData = (attendanceData || []).map((month) => ({
			// ✅ null check
			...month,
			days: (month.days || []).map((dayEntries) => {
				// ✅ 2차원 배열 검증
				const validEntries = Array.isArray(dayEntries) ? dayEntries : [];

				if (category === '전체') {
					return validEntries;
				} else {
					return validEntries.filter((entry) => entry.startsWith(category)); // 선택한 카테고리만 필터링
				}
			}),
		}));
		const formatDateLocal = (date) => {
			const yyyy = date.getFullYear();
			const mm = String(date.getMonth() + 1).padStart(2, '0');
			const dd = String(date.getDate()).padStart(2, '0');
			return `${yyyy}-${mm}-${dd}`;
		};
		return (
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>구분</Table.HeaderCell>
						{Array.from({ length: 31 }, (_, i) => (
							<Table.HeaderCell key={i}>{i + 1}일</Table.HeaderCell>
						))}
						{category !== '전체' && <Table.HeaderCell>합계</Table.HeaderCell>}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{filteredData
						.filter((row) => !(row.month === '합계' && category === '전체')) // ✅ "전체" 선택 시 "합계" 행 제거
						.map((row, index) => {
							const daysInMonth = getDaysInMonth(
								parseInt(selectedYear, 10),
								index
							);
							const isTotalRow = row.month === '합계';

							return (
								<React.Fragment key={index}>
									<Table.Row>
										<Table.Cell rowSpan={!isTotalRow ? '2' : '1'}>
											{row.month}
										</Table.Cell>
										{Array.from({ length: 31 }, (_, i) => {
											const date = new Date(
												parseInt(selectedYear, 10),
												index,
												i + 1
											);
											const formattedDate = formatDateLocal(date);
											const dayOfWeek = date.getDay();
											let style = {};
											if (holidays.includes(formattedDate)) {
												style = { color: 'red' };
											} else if (dayOfWeek === 0) {
												style = { color: 'red' };
											} else if (dayOfWeek === 6) {
												style = { color: 'blue' };
											}

											return (
												// 요일 표시 셀 렌더링 부분
												<Table.Cell key={i} style={style}>
													{isTotalRow
														? i === 32 // 32번째 열(인덱스 31)
															? row.aggregate[category] || '0' // 종합 표시
															: columnSums[i] !== null &&
															  columnSums[i] !== undefined
															? columnSums[i]
															: '0'
														: i < daysInMonth
														? daysOfWeek[dayOfWeek]
														: ''}
												</Table.Cell>
											);
										})}
										{isTotalRow && category !== '전체' && (
											<Table.Cell>
												<b>{row.aggregate[category] || 0}</b>
											</Table.Cell>
										)}
									</Table.Row>
									{!isTotalRow && (
										<Table.Row>
											{Array.from({ length: 31 }, (_, i) => (
												// 데이터 부분
												<Table.Cell key={i}>
													{i < daysInMonth
														? (() => {
																const entries = Array.isArray(row.days[i])
																	? row.days[i]
																	: typeof row.days[i] === 'string'
																	? row.days[i].split(', ')
																	: [];
																return entries
																	.filter(
																		(entry) =>
																			category === '전체' ||
																			entry.startsWith(category)
																	)
																	.map(
																		(
																			entry,
																			idx // 개별 항목 매핑
																		) => (
																			<div
																				key={idx}
																				style={{ display: 'block' }}>
																				{entry}
																			</div>
																		)
																	);
														  })()
														: ''}
												</Table.Cell>
											))}
											{!isTotalRow && category !== '전체' && (
												<Table.Cell>
													{row.aggregate && row.aggregate[category]
														? row.aggregate[category]
														: ''}
												</Table.Cell>
											)}
										</Table.Row>
									)}
								</React.Fragment>
							);
						})}
				</Table.Body>
			</Table>
		);
	};

	return (
		<Tab
			menu={{ secondary: true, pointing: true }}
			activeIndex={
				categories.includes(activeCategory)
					? categories.indexOf(activeCategory) + 1 // "전체" 탭이 추가되었으므로 인덱스 조정
					: 0
			}
			onTabChange={(e, { activeIndex }) => {
				const newCategory =
					activeIndex === 0 ? '전체' : categories[activeIndex - 1]; // "전체" 탭 보정
				onCategoryChange(newCategory);
			}}
			panes={[
				{
					menuItem: '전체',
					render: () => <Tab.Pane>{renderCategoryTable('전체')}</Tab.Pane>,
				},
				...categories.map((category) => ({
					menuItem: category,
					render: () => <Tab.Pane>{renderCategoryTable(category)}</Tab.Pane>,
				})),
			]}
		/>
	);
}
