// import { Field, FieldArray } from 'formik';
import React from 'react';
import { Tab } from 'semantic-ui-react';

import MyTextArea from '../../../../../app/common/form/MyTextArea';
import CrwfWorkerBasicInfoMonthWageInfo from './CrwfWorkerBasicInfoMonthWageInfo';
import CrwfWorkerBasicInfoTax4In from './CrwfWorkerBasicInfoTax4In';
import WorkerFormWageComments from './WorkerFormWageComments';
import CrwfWorkerBasicInfoMonthWageOrd from './CrwfWorkerBasicInfoMonthWageOrd';
import CrwfWorkerBasicInfoMonthWageAver from './CrwfWorkerBasicInfoMonthWageAver';
import CrwfWorkerBasicInfoMonthMoney from './CrwfWorkerBasicInfoMonthMoney';

export default function CrwfWorkerBasicWageInfo({
	values,
	openPensionPayType,
	setOpenPensionPayType,
	openHealthCarePayType,
	setOpenHealthCarePayType,
	openNoJobPayType,
	setOpenNoJobPayType,
	openOldCarePayType,
	setOpenOldCarePayType,
	companyName,
}) {
	const childCompany = companyName?.includes('어린이집') ? true : false;

	const MenuItemContent31 = () => (
		<>
			<span style={{ color: 'blue' }}>(3-1) 임금(기본)</span>
			<span style={{ color: 'red' }}>[필수]</span>
		</>
	);

	const MenuItemContent32 = () => (
		<>
			<span style={{ color: 'blue' }}>(3-2) 근로소득세/4대보험</span>
			<span style={{ color: 'red' }}>[필수]</span>
		</>
	);

	const MenuItemContent33 = () => (
		<>
			<span>(3-3)통상임금</span>
			<span style={{ color: 'blue' }}>/비과세</span>
		</>
	);

	const MenuItemContent34 = () => (
		<>
			<span>(3-4) 평균임금</span>
			<span style={{ color: 'blue' }}>/비과세</span>
		</>
	);

	const MenuItemContent35 = () => (
		<>
			<span>(3-5) 구청지급</span>
			<span style={{ color: 'blue' }}>(어린이집용)</span>
		</>
	);

	const panes = [];

	panes.push(
		{
			menuItem: {
				content: <MenuItemContent31 />,
				key: '임금(기본)설계[필수]',
			},
			render: () => (
				<>
					<CrwfWorkerBasicInfoMonthWageInfo values={values} />
					<WorkerFormWageComments MyTextArea={MyTextArea} />
				</>
			),
		},
		{
			menuItem: {
				content: <MenuItemContent32 />,
				key: '근로소득세 및 4대보험 설정',
			},
			render: () => (
				<CrwfWorkerBasicInfoTax4In
					values={values}
					openPensionPayType={openPensionPayType}
					setOpenPensionPayType={setOpenPensionPayType}
					openHealthCarePayType={openHealthCarePayType}
					setOpenHealthCarePayType={setOpenHealthCarePayType}
					openNoJobPayType={openNoJobPayType}
					setOpenNoJobPayType={setOpenNoJobPayType}
					openOldCarePayType={openOldCarePayType}
					setOpenOldCarePayType={setOpenOldCarePayType}
				/>
			),
		},
		{
			menuItem: { content: <MenuItemContent33 />, key: '통상임금설계' },
			render: () => (
				<CrwfWorkerBasicInfoMonthWageOrd companyName={companyName} />
			),
		},
		{
			menuItem: { content: <MenuItemContent34 />, key: '평균임금설계' },
			render: () => <CrwfWorkerBasicInfoMonthWageAver />,
		}
	);
	// childCompany가 true일 때만 해당 pane 추가
	if (childCompany) {
		panes.push({
			menuItem: { content: <MenuItemContent35 />, key: '구청지급금품' },
			render: () => <CrwfWorkerBasicInfoMonthMoney companyName={companyName} />,
		});
	}

	return (
		<>
			<Tab panes={panes} />
		</>
	);
}
