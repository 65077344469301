import React from 'react';
import { Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import {
	dayOrdIncludeComment4,
	dayOrdIncludeComment5,
	numberToCommaString,
	roundUp,
} from '../../../../app/common/util/util';

function ClientWageTableTop6EndExplain({
	values,
	numOfWorkers,
	weekLBTime,
	isExceptTimeLawWorker,
	paidHolidayLBTime,
	sumOfCOWDay,
	monthOrdinaryWage,
	mTPCTime,
	today,
	worker,
	monthLBTime,
	hourOrdinaryWage,
	yearlyBonus,
}) {
	return (
		<>
			{numOfWorkers >= 5 ? (
				<>
					<Table.Row>
						{weekLBTime < 15 ? (
							<Table.Cell
								textAlign='center'
								colSpan='9'>{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
								monthLBTime
							)}시간[(1주 소정근로시간 ${numberToCommaString(
								weekLBTime
							)})] / 7일 * 365일 /12개월(초단시간 근로자로 유급주휴시간 없음.)`}</Table.Cell>
						) : (
							<>
								{isExceptTimeLawWorker ? (
									<Table.Cell textAlign='center' colSpan='9'>
										{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
											monthLBTime
										)}시간 
                                    [(1주 기본근로시간 ${numberToCommaString(
																			weekLBTime
																		)})] / 7일 * 365일 /12개월(근기법 제63조에 따라 유급주휴시간 없음.)
                                    `}{' '}
										<br />
										{monthLBTime === 209
											? '(위 계산값은 약 208.56시간이지만 고용노동부 최저임금 고시에 의거 209로 계산함)'
											: null}
									</Table.Cell>
								) : (
									<Table.Cell textAlign='center' colSpan='9'>
										{`월 통상임금 산정기준 시간수 : ${numberToCommaString(
											monthLBTime
										)}시간 
                                    [(1주 소정근로시간 ${numberToCommaString(
																			weekLBTime
																		)}) + (유급주휴시간 ${numberToCommaString(
											paidHolidayLBTime
										)})] / 7일 * 365일 /12개월
                                    `}{' '}
										<br />
										{monthLBTime === 209
											? '(위 계산값은 약 208.56시간이지만 고용노동부 최저임금 고시에 의거 209로 계산함)'
											: null}
									</Table.Cell>
								)}
							</>
						)}
					</Table.Row>

					<Table.Row>
						<Table.Cell textAlign='center' colSpan='9'>
							{`통상시급 : ${numberToCommaString(hourOrdinaryWage)}원`}
							{dayOrdIncludeComment5(
								sumOfCOWDay,
								monthOrdinaryWage,
								monthLBTime,
								paidHolidayLBTime,
								'',
								yearlyBonus
							)}
						</Table.Cell>
					</Table.Row>
					{values.absentDays > 0 ||
					values.absentPaidHoliDays > 0 ||
					values.absentTimes > 0 ? (
						<>
							<Table.Row>
								<Table.Cell textAlign='center' colSpan='9'>
									* 공제일수 계산방법 : 민법상 역월 기준으로 중도에 입퇴사하는
									경우와 결근시 해당 월 일수의 다소를 불문하고, 월 통상임금을
									해당 월의 일수(예, 28일인 달은 28일, 29일인 달은 29일, 30일인
									달은 30일, 31일인 달은 31일)로 일할 계산한 일급에 직원이
									해당월에 재직한 일수를 곱하는 방법으로 계산(실제 1일
									소정근로시간에 대한 일급(주휴수당)보다 작은 금액임). 단,
									전체월급에 약정연장/약정야간/약정휴일근로수당(이하
									"약정초과수당"이라 한다.)이 있는 경우는 월 통상임금에
									약정초과수당을 더한 월 임금을 일할 계산.
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign='center' colSpan='9'>
									** 공제시간수 계산방법 : 소정근로시간 중 지각, 조퇴, 외출 등이
									있는 경우 해당 시간에 시급을 곱하여 계산.
								</Table.Cell>
							</Table.Row>
						</>
					) : null}
				</>
			) : (
				<>
					<Table.Row>
						<Table.Cell textAlign='center' colSpan='9'>{`
                              월 통상임금 산정기준 시간수 : 월 전체 ${numberToCommaString(
																roundUp(mTPCTime)
															)} 
                              시간(5인미만 사업장은 근로기준법 제50조 근로시간과, 동법 제56조 연장야간휴일근로 
                              가산임금이 적용되지 아니하므로 1개월의 총 시간을 뜻함.)
                            `}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell textAlign='center' colSpan='9'>
							{`통상시급 : ${numberToCommaString(hourOrdinaryWage)}원 `}
							{dayOrdIncludeComment4(
								sumOfCOWDay,
								monthOrdinaryWage,
								mTPCTime,
								paidHolidayLBTime,
								'',
								yearlyBonus
							)}
						</Table.Cell>
						\
					</Table.Row>
					{values.absentDays > 0 ||
					values.absentPaidHoliDays > 0 ||
					values.absentTimes > 0 ? (
						<>
							<Table.Row>
								<Table.Cell textAlign='center' colSpan='9'>
									* 공제일수 계산방법 : 민법상 역월 기준으로 중도에 입퇴사하는
									경우와 결근시 해당 월 일수의 다소를 불문하고, 월 통상임금을
									해당 월의 일수(예, 28일인 달은 28일, 29일인 달은 29일, 30일인
									달은 30일, 31일인 달은 31일)로 일할 계산한 일급에 직원이
									해당월에 재직한 일수를 곱하는 방법으로 계산(실제 1일
									소정근로시간에 대한 일급(주휴수당)보다 작은 금액임).
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign='center' colSpan='9'>
									** 공제시간수 계산방법 : 소정근로시간 중 지각, 조퇴, 외출 등이
									있는 경우 해당 시간에 시급을 곱하여 계산.
								</Table.Cell>
							</Table.Row>
						</>
					) : null}
				</>
			)}
			<Table.Row width='100%' align='center'>
				<Table.Cell textAlign='center' colSpan='9' align='center'>
					{format(today, 'yyyy-MM-dd')}
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					colSpan='9'
					align='center'>{`임금명세서를 교부받았음을 확인합니다. : ${worker} (서명 또는 인)`}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					colSpan='9'
					align='left'>{`근로기준법 제48조 제2항 및 2021.11.19.시행 임금명세서 교부 의무 설명자료(2021.11 고용노동부)에 따라 본 명세서는 ${worker}에게 교부된 것으로 본다.`}</Table.Cell>
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop6EndExplain;
