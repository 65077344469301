import React, { useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

import {
	numberToCommaString,
	sumOfdayWorkerPlusDeductWorkerCompanyLoansCom,
	sumOfdayWorkerPlusDeductWorkRegionTaxsCom,
	sumOfdayWorkerPlusDeductDurusCom,
	sumOfdayWorkerPlusMinus4InssCom,
	sumOfdayWorkerDeductWagesCom,
	sumOftotalDayWorkerHoliWagesCom,
	sumOftotalDayWorkerOverWorkDayWagesCom,
	sumOfdayWorkerDeductEtcWage2sCom,
	sumOfdayWorkerDeductEtcWage1sCom,
	sumOfdayWorkerDedectTaxEtcsCom,
	sumOfdayWorkerDeductEtcWage3sCom,
	sumOfdayWorkerTotalMonthWageAmountsCom,
	sumOfdayWorkerCAWage7sCom,
	sumOfnumDayWorkerNWagesCom,
	sumOftotalDayWorkerWagesCom,
	sumOfnumDayWorkerHBwagesCom,
	sumOfnumDayWorkerHOwagesCom,
	sumOfdayWorkerCAWage5sCom,
	sumOfdayWorkerCAWage6sCom,
	sumOfnumDayWorkerOWagesCom,
	sumOfnumDayWorkerHNwagesCom,
	sumOfdayWorkerAbsentTimeWagesCom,
	sumOfdayWorkerCalTaxsSumCom,
	sumOfdayWorkerCalRegionTaxsSumCom,
	sumOfdayWorkerCalPensionsSumCom,
	sumOfdayWorkerCalHealthCaresSumCom,
	sumOfdayWorkerCalOldCaresSumCom,
	sumOfdayWorkerCalNoJobInsurancesSumCom,
	sumOfdayWorkerPlusDeductWorkTaxsCom,
	sumOfdayWorkerPlusDeductOldCaresCom,
	sumOfdayWorkerPlusDeductHealthCaresCom,
	roundUp,
	sumOfdayWorkerMonthWagesWorker,
	sumOfdayWorkerMonthDeductedWagesWorker,
	sumOftotalTimeWorkerWagesCom,
	sumOfnumOWageForTWCom,
	sunOfnumNWageForTWCom,
	sumOfnumHBWageForTWCom,
	sumOfnumHOWageForTWCom,
	sumOfnumOffWageForTWCom,
	sumOfnumOffOWageForTWCom,
	sumOftotalPaidHoliTimeWagesNotIncludeCom,
	sumOfTotalTimeWorkerPaidHoliWagesCom,
	sumOfTimeWorkerSpecialBonusCom,
	sumOfDayWorkerSpecialBonusCom,
	sumOftotalTimeDayWorkerPaidHoliDaysCom,
	sumOftotalTimeDayWorkerRealWorkingDaysCom,
} from '../../../app/common/util/util';

export default function PaydocusDayWorkerTableList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	companyName,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.companyInfo?.companyName?.includes(searchTerm) ||
				paydocu?.info?.year?.includes(searchTerm)
			);
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	const totalDayWorkerWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.totalDayWorkerWage
	);
	const totalDayWorkerWageValue = totalDayWorkerWage.find((a) => a);

	const totalDayWorkerHoliWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.totalDayWorkerHoliWage
	);
	const totalDayWorkerHoliWageValue = totalDayWorkerHoliWage.find((a) => a);

	const totalDayWorkerOverWorkDayWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.totalDayWorkerOverWorkDayWage
	);
	const totalDayWorkerOverWorkDayWageValue = totalDayWorkerOverWorkDayWage.find(
		(a) => a
	);

	const dayWorkerCAWage5 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWage5
	);
	const dayWorkerCAWage5Value = dayWorkerCAWage5.find((a) => a);

	const dayWorkerCAWage6 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWage6
	);
	const dayWorkerCAWage6Value = dayWorkerCAWage6.find((a) => a);

	const dayWorkerCAWage7 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWage7
	);
	const dayWorkerCAWage7Value = dayWorkerCAWage7.find((a) => a);

	const dayWorkerCAWEName5 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWEName5
	);
	const dayWorkerCAWEName5Value = dayWorkerCAWEName5.find((a) => a);

	const dayWorkerCAWEName6 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWEName6
	);
	const dayWorkerCAWEName6Value = dayWorkerCAWEName6.find((a) => a);

	const dayWorkerCAWEName7 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerCAWEName7
	);
	const dayWorkerCAWEName7Value = dayWorkerCAWEName7.find((a) => a);

	const numDayWorkerOWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numDayWorkerOWage
	);
	const numDayWorkerOWageValue = numDayWorkerOWage.find((a) => a);

	//시급직 연장수당
	const numOWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numOWageForTW
	);
	const numOWageForTWValue = numOWageForTW.find((a) => a);
	//시급직 야간수당
	const numNWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numNWageForTW
	);
	const numNWageForTWValue = numNWageForTW.find((a) => a);
	//시급직 휴무일수당
	const numOffWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numOffWageForTW
	);
	const numOffWageForTWValue = numOffWageForTW.find((a) => a);

	//시급직 휴무연장수당
	const numOffOWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numOffOWageForTW
	);
	const numOffOWageForTWValue = numOffOWageForTW.find((a) => a);

	//시급직 휴일기본
	const numHBWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numHBWageForTW
	);
	const numHBWageForTWValue = numHBWageForTW.find((a) => a);

	//시급직 휴일연장
	const numHOWageForTW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numHOWageForTW
	);
	const numHOWageForTWValue = numHOWageForTW.find((a) => a);

	const numDayWorkerNWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numDayWorkerNWage
	);
	const numDayWorkerNWageValue = numDayWorkerNWage.find((a) => a);

	const numDayWorkerHBwage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numDayWorkerHBwage
	);
	const numDayWorkerHBwageValue = numDayWorkerHBwage.find((a) => a);

	const numDayWorkerHOwage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numDayWorkerHOwage
	);
	const numDayWorkerHOwageValue = numDayWorkerHOwage.find((a) => a);

	const numDayWorkerHNwage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numDayWorkerHNwage
	);
	const numDayWorkerHNwageValue = numDayWorkerHNwage.find((a) => a);

	const dayWorkerAbsentTimeWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerAbsentTimeWage
	);
	const dayWorkerAbsentTimeWageValue = dayWorkerAbsentTimeWage.find((a) => a);

	const dayWorkerPlusDeductHealthCare = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerPlusDeductHealthCare
	);
	const dayWorkerPlusDeductHealthCareValue = dayWorkerPlusDeductHealthCare.find(
		(a) => a
	);

	const dayWorkerPlusDeductOldCare = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerPlusDeductOldCare
	);
	const dayWorkerPlusDeductOldCareValue = dayWorkerPlusDeductOldCare.find(
		(a) => a
	);

	const dayWorkerPlusDeductWorkTax = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerPlusDeductWorkTax
	);
	const dayWorkerPlusDeductWorkTaxValue = dayWorkerPlusDeductWorkTax.find(
		(a) => a
	);

	const dayWorkerPlusDeductWorkRegionTax = searchResults.map(
		(searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerPlusDeductWorkRegionTax
	);
	const dayWorkerPlusDeductWorkRegionTaxValue =
		dayWorkerPlusDeductWorkRegionTax.find((a) => a);

	const dayWorkerPlusDeductDuru = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerPlusDeductDuru
	);
	const dayWorkerPlusDeductDuruValue = dayWorkerPlusDeductDuru.find((a) => a);

	const dayWorkerPlusMinus4Ins = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerPlusMinus4Ins
	);
	const dayWorkerPlusMinus4InsValue = dayWorkerPlusMinus4Ins.find((a) => a);

	const dayWorkerPlusDeductWorkerCompanyLoan = searchResults.map(
		(searchResult) =>
			searchResult?.dayWorkerInfo?.dayWorkerPlusDeductWorkerCompanyLoan
	);
	const dayWorkerPlusDeductWorkerCompanyLoanValue =
		dayWorkerPlusDeductWorkerCompanyLoan.find((a) => a);

	const dayWorkerDeductEtcName1 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerDeductEtcName1
	);
	const dayWorkerDeductEtcName1Value = dayWorkerDeductEtcName1.find((a) => a);

	const dayWorkerDeductEtcName2 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerDeductEtcName2
	);
	const dayWorkerDeductEtcName2Value = dayWorkerDeductEtcName2.find((a) => a);

	const dayWorkerDeductEtcName3 = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerDeductEtcName3
	);
	const dayWorkerDeductEtcName3Value = dayWorkerDeductEtcName3.find((a) => a);

	const dayWorkerDedectTaxEtc = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerDedectTaxEtc
	);
	const dayWorkerDedectTaxEtcValue = dayWorkerDedectTaxEtc.find((a) => a);

	const dayWorkerDeductWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerDeductWage
	);
	const dayWorkerDeductWageValue = dayWorkerDeductWage.find((a) => a);

	const totalTimeWorkerWage = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.totalTimeWorkerWage
	);
	const totalTimeWorkerWageValue = totalTimeWorkerWage.find((a) => a);

	const timeWorkerSpecialBonus = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.timeWorkerSpecialBonus
	);
	const timeWorkerSpecialBonusValue = timeWorkerSpecialBonus.find((a) => a);

	const dayWorkerSpecialBonus = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerSpecialBonus
	);
	const dayWorkerSpecialBonusValue = dayWorkerSpecialBonus.find((a) => a);

	const numPaidHoliTimeW = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.numPaidHoliTimeW
	);
	const numPaidHoliTimeWValue = numPaidHoliTimeW.find((a) => a);
	//일용직, 시급직 겸용
	const dayWorkerTotalMonthWageAmount = searchResults.map(
		(searchResult) => searchResult?.dayWorkerInfo?.dayWorkerTotalMonthWageAmount
	);
	const dayWorkerTotalMonthWageAmountValue = dayWorkerTotalMonthWageAmount.find(
		(a) => a
	);

	const yearMonth = searchResults.map(
		(searchResult) => searchResult?.info?.yearMonth
	);
	const yearMonthValue =
		yearMonth.find((a) => a) ||
		new Date().getFullYear() + (new Date().getMonth() + 1);

	const thisYear = new Date().getFullYear();
	const thisMonth = new Date().getMonth() + 1;
	const year = yearMonthValue.toString().substring(0, 4) || thisYear; // 문자열로 변환 후 첫 4자리 추출
	const month = yearMonthValue.toString().substring(4, 6) || thisMonth; // 문자열로 변환 후 4번째부터 2자리 추출

	// Excel 파일로 내보내기 함수
	const exportToExcel = () => {
		const workSheet = XLSX.utils.table_to_sheet(
			document.getElementById('table-to-xls')
		);
		const workBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			workBook,
			workSheet,
			`${companyName} 일용직 임금대장`
		);
		XLSX.writeFile(
			workBook,
			`${companyName} ${year}년 ${month} 일용직 임금대장.xlsx`
		);
	};

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button
						onClick={exportToExcel}
						color='blue'>{`${year}년 ${month}월 임금대장 엑셀파일 전환`}</Button>

					{!currentUserProfile.ceoName ? (
						<Table border='1' width='100%' align='center' id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>사번</Table.HeaderCell>
									<Table.HeaderCell>직원명</Table.HeaderCell>
									<Table.HeaderCell>공제 전 임금(월)</Table.HeaderCell>
									<Table.HeaderCell>공제 후 임금(원)</Table.HeaderCell>
									<Table.HeaderCell>회사등록일</Table.HeaderCell>
									<Table.HeaderCell>상세보기</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{paydocus.map((workerData) => (
									<Table.Row key={workerData.id}>
										<Table.Cell>{workerData?.info?.yearMonth}</Table.Cell>
										<Table.Cell>
											{workerData?.workerInfo?.workerComNumber}
										</Table.Cell>
										<Table.Cell>{workerData?.workerInfo?.worker}</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												roundUp(
													workerData?.dayWorkerInfo
														?.dayWorkerTotalMonthWageAmount
												)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												roundUp(workerData?.dayWorkerInfo?.dayWorkerDeductWage)
											)}
										</Table.Cell>
										<Table.Cell>
											{workerData?.createdAt
												? format(
														new Date(workerData?.createdAt),
														'yyyy-MM-dd h:mm a'
												  )
												: null}
										</Table.Cell>
										<Table.Cell>
											<Button
												as={Link}
												to={`/paydocuDetailedPage/${workerData?.id}`}
												color='orange'
												floated='right'>
												임금명세서 보기
											</Button>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
							<tfoot>
								<Table.Row>
									<Table.Cell>합계</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											roundUp(sumOfdayWorkerMonthWagesWorker(paydocus))
										)}
										원
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											roundUp(sumOfdayWorkerMonthDeductedWagesWorker(paydocus))
										)}
										원
									</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							</tfoot>
						</Table>
					) : (
						<div
							style={{
								overflowX: 'auto',
								width: '100%',
								whiteSpace: 'nowrap',
								borderCollapse: 'collapse',
							}}>
							<Table
								style={{ textAlign: 'center', width: '100%', border: '1' }}
								id='table-to-xls'>
								<Table.Header>
									<Table.Row>
										{authLevel < 100 ? null : (
											<Table.HeaderCell>회사명</Table.HeaderCell>
										)}
										<Table.HeaderCell>년월</Table.HeaderCell>

										<Table.HeaderCell>사번</Table.HeaderCell>
										<Table.HeaderCell>부서명</Table.HeaderCell>
										<Table.HeaderCell>구분</Table.HeaderCell>
										<Table.HeaderCell>직원명</Table.HeaderCell>
										<Table.HeaderCell>입사일</Table.HeaderCell>
										<Table.HeaderCell>마지막근무일</Table.HeaderCell>
										<Table.HeaderCell>실근무일수</Table.HeaderCell>
										<Table.HeaderCell>유급주휴일수</Table.HeaderCell>
										{(totalDayWorkerWageValue || totalTimeWorkerWageValue) && (
											<Table.HeaderCell>
												(일용)평일임금
												<br />
												/(시급)기본임금
											</Table.HeaderCell>
										)}
										{(totalDayWorkerHoliWageValue || numPaidHoliTimeWValue) && (
											<Table.HeaderCell>
												(일용)휴일임금
												<br />
												/(시급)주휴수당
											</Table.HeaderCell>
										)}
										{totalDayWorkerOverWorkDayWageValue && (
											<Table.HeaderCell>
												(일용)휴무일임금
												<br />
												(시급)해당없음
											</Table.HeaderCell>
										)}
										{dayWorkerCAWEName5Value && (
											<Table.HeaderCell>
												{dayWorkerCAWEName5Value}
											</Table.HeaderCell>
										)}
										{dayWorkerCAWEName6Value && (
											<Table.HeaderCell>
												{dayWorkerCAWEName6Value}
											</Table.HeaderCell>
										)}
										{dayWorkerCAWEName7Value && (
											<Table.HeaderCell>
												{dayWorkerCAWEName7Value}
											</Table.HeaderCell>
										)}
										{(numDayWorkerOWageValue || numOWageForTWValue) && (
											<Table.HeaderCell>추가연장수당</Table.HeaderCell>
										)}
										{(numDayWorkerNWageValue || numNWageForTWValue) && (
											<Table.HeaderCell>추가야간수당</Table.HeaderCell>
										)}
										{(numDayWorkerHBwageValue || numHBWageForTWValue) && (
											<Table.HeaderCell>추가휴일기본</Table.HeaderCell>
										)}
										{(numDayWorkerHOwageValue || numHOWageForTWValue) && (
											<Table.HeaderCell>추가휴일연장</Table.HeaderCell>
										)}
										{numDayWorkerHNwageValue && (
											<Table.HeaderCell>추가휴일야간</Table.HeaderCell>
										)}
										{numOffWageForTWValue && (
											<Table.HeaderCell>
												(일용)해당없음 <br />
												/(시급)휴무기본연장
											</Table.HeaderCell>
										)}
										{numOffOWageForTWValue && (
											<Table.HeaderCell>
												(일용)해당없음 <br />
												/(시급)휴무연장
											</Table.HeaderCell>
										)}
										{dayWorkerAbsentTimeWageValue && (
											<Table.HeaderCell>결근시간</Table.HeaderCell>
										)}
										{(timeWorkerSpecialBonusValue ||
											dayWorkerSpecialBonusValue) && (
											<Table.HeaderCell>특별수당</Table.HeaderCell>
										)}
										{dayWorkerTotalMonthWageAmountValue && (
											<Table.HeaderCell>임금총액</Table.HeaderCell>
										)}
										<Table.HeaderCell>소득세</Table.HeaderCell>
										<Table.HeaderCell>지방소득세</Table.HeaderCell>
										<Table.HeaderCell>국민연금</Table.HeaderCell>
										<Table.HeaderCell>건강보험</Table.HeaderCell>
										<Table.HeaderCell>요양보험</Table.HeaderCell>
										<Table.HeaderCell>고용보험</Table.HeaderCell>
										{dayWorkerPlusDeductHealthCareValue && (
											<Table.HeaderCell>건강보험료정산</Table.HeaderCell>
										)}
										{dayWorkerPlusDeductOldCareValue && (
											<Table.HeaderCell>요양보험료정산</Table.HeaderCell>
										)}
										{dayWorkerPlusDeductWorkTaxValue && (
											<Table.HeaderCell>소득세정산</Table.HeaderCell>
										)}
										{dayWorkerPlusDeductWorkRegionTaxValue && (
											<Table.HeaderCell>지방소득세정산</Table.HeaderCell>
										)}
										{dayWorkerPlusDeductWorkerCompanyLoanValue && (
											<Table.HeaderCell>가불공제</Table.HeaderCell>
										)}
										{dayWorkerPlusDeductDuruValue && (
											<Table.HeaderCell>두루누리공제</Table.HeaderCell>
										)}
										{dayWorkerPlusMinus4InsValue && (
											<Table.HeaderCell>공제 조정</Table.HeaderCell>
										)}
										{dayWorkerDeductEtcName1Value && (
											<Table.HeaderCell>
												{dayWorkerDeductEtcName1Value}
											</Table.HeaderCell>
										)}
										{dayWorkerDeductEtcName2Value && (
											<Table.HeaderCell>
												{dayWorkerDeductEtcName2Value}
											</Table.HeaderCell>
										)}
										{dayWorkerDeductEtcName3Value && (
											<Table.HeaderCell>
												{dayWorkerDeductEtcName3Value}
											</Table.HeaderCell>
										)}
										<Table.HeaderCell>공제계</Table.HeaderCell>
										<Table.HeaderCell>실수령액</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{searchResults.map((searchResult, index) =>
										(searchResult?.companyInfo?.agentUid === id ||
											searchResult.companyInfo.companyId === id ||
											currentUserProfile?.authLevel >= 100) &&
										searchResult?.workerInfo?.dayWorker ? (
											<Table.Row key={searchResult.id}>
												{authLevel < 100 ? null : (
													<Table.Cell>
														{searchResult?.companyInfo?.companyName}
													</Table.Cell>
												)}
												<Table.Cell>{searchResult?.info?.yearMonth}</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workerComNumber}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workerDivisions}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.isTimeWorker ? '시급직' : '일급직'}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.worker}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workerEnteredDate
														? format(
																new Date(
																	searchResult?.workerInfo?.workerEnteredDate
																),
																'yyyy-MM-dd'
														  )
														: ''}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workerRetiredDate
														? format(
																new Date(
																	searchResult?.workerInfo?.workerRetiredDate
																),
																'yyyy-MM-dd'
														  )
														: ''}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.workerInfo?.workDates?.length}
												</Table.Cell>
												<Table.Cell>
													{searchResult?.dayWorkerInfo?.paidHoliWageDays}
												</Table.Cell>
												{searchResult?.dayWorkerInfo?.daylyTimePay
													? totalTimeWorkerWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.totalTimeWorkerWage
																	)
																)}
															</Table.Cell>
													  )
													: totalDayWorkerWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.totalDayWorkerWage
																	)
																)}
															</Table.Cell>
													  )}
												{searchResult?.dayWorkerInfo?.daylyTimePay ? (
													searchResult?.dayWorkerInfo?.week14OverBLTime ===
													'주휴수당 포함' ? (
														<Table.Cell>
															{numberToCommaString(
																roundUp(
																	searchResult?.dayWorkerInfo
																		?.totalTimeWorkerPaidHoliWage
																)
															)}
														</Table.Cell>
													) : (
														<Table.Cell>
															{numberToCommaString(
																roundUp(
																	searchResult?.dayWorkerInfo?.numPaidHoliTimeW
																)
															)}
														</Table.Cell>
													)
												) : (
													totalDayWorkerHoliWageValue && (
														<Table.Cell>
															{numberToCommaString(
																roundUp(
																	searchResult?.dayWorkerInfo
																		?.totalDayWorkerHoliWage
																)
															)}
														</Table.Cell>
													)
												)}

												{totalDayWorkerOverWorkDayWageValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.totalDayWorkerOverWorkDayWage
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerCAWage5Value && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.dayWorkerCAWage5
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerCAWage6Value && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.dayWorkerCAWage6
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerCAWage7Value && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.dayWorkerCAWage7
															)
														)}
													</Table.Cell>
												)}
												{(numDayWorkerOWageValue || numOWageForTWValue) && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.numDayWorkerOWage ||
																	searchResult?.dayWorkerInfo?.numOWageForTW
															)
														)}
													</Table.Cell>
												)}
												{(numDayWorkerNWageValue || numNWageForTWValue) && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.numDayWorkerNWage ||
																	searchResult?.dayWorkerInfo?.numNWageForTW
															)
														)}
													</Table.Cell>
												)}
												{(numDayWorkerHBwageValue || numHBWageForTWValue) && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.numDayWorkerHBwage ||
																	searchResult?.dayWorkerInfo?.numHBWageForTW
															)
														)}
													</Table.Cell>
												)}
												{(numDayWorkerHOwageValue || numHOWageForTWValue) && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.numDayWorkerHOwage ||
																	searchResult?.dayWorkerInfo?.numHOWageForTW
															)
														)}
													</Table.Cell>
												)}
												{numDayWorkerHNwageValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.numDayWorkerHNwage
															)
														)}
													</Table.Cell>
												)}
												{numOffWageForTWValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.numOffWageForTW
															)
														)}
													</Table.Cell>
												)}
												{numOffOWageForTWValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo?.numOffOWageForTW
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerAbsentTimeWageValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerAbsentTimeWage !== '' &&
																	searchResult?.dayWorkerInfo
																		?.dayWorkerAbsentTimeWage !== undefined
																	? searchResult?.dayWorkerInfo
																			?.dayWorkerAbsentTimeWage * -1
																	: 0 // 결근시간이 없는 경우에도 칸을 유지하기 위해 0을 표시
															)
														)}
													</Table.Cell>
												)}
												{timeWorkerSpecialBonusValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.timeWorkerSpecialBonus !== '' &&
																	searchResult?.dayWorkerInfo
																		?.timeWorkerSpecialBonus !== undefined
																	? searchResult?.dayWorkerInfo
																			?.timeWorkerSpecialBonus
																	: 0 // 특별수당이 없는 경우에도 칸을 유지하기 위해 0을 표시
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerSpecialBonusValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerSpecialBonus !== '' &&
																	searchResult?.dayWorkerInfo
																		?.dayWorkerSpecialBonus !== undefined
																	? searchResult?.dayWorkerInfo
																			?.dayWorkerSpecialBonus
																	: 0 // 특별수당이 없는 경우에도 칸을 유지하기 위해 0을 표시
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerTotalMonthWageAmountValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerTotalMonthWageAmount
															)
														)}
													</Table.Cell>
												)}

												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedTax !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedTax
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedTax
																: searchResult?.dayWorkerInfo?.dayWorkerCalTax
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedRegionTax !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedRegionTax
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedRegionTax
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalRegionTax
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedPension !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedPension
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedPension
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalPension
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedHealthCare !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedHealthCare
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedHealthCare
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalHealthCare
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedOldCare !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedOldCare
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedOldCare
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalOldCare
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo
																?.dayWorkerUserFixedNoJobInsurance !== '' &&
																!isNaN(
																	searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedNoJobInsurance
																)
																? searchResult?.dayWorkerInfo
																		?.dayWorkerUserFixedNoJobInsurance
																: searchResult?.dayWorkerInfo
																		?.dayWorkerCalNoJobInsurance
														)
													)}
												</Table.Cell>

												{dayWorkerPlusDeductHealthCareValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerPlusDeductHealthCare
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerPlusDeductOldCareValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerPlusDeductOldCare
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerPlusDeductWorkTaxValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerPlusDeductWorkTax
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerPlusDeductWorkRegionTaxValue && (
													<Table.Cell>
														{numberToCommaString(
															roundUp(
																searchResult?.dayWorkerInfo
																	?.dayWorkerPlusDeductWorkRegionTax
															)
														)}
													</Table.Cell>
												)}
												{dayWorkerPlusDeductWorkerCompanyLoanValue && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo
															?.dayWorkerPlusDeductWorkerCompanyLoan
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerPlusDeductWorkerCompanyLoan ===
																			0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerPlusDeductWorkerCompanyLoan
																	)
															  )
															: null}
													</Table.Cell>
												)}
												{dayWorkerPlusDeductDuruValue && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo
															?.dayWorkerPlusDeductDuru
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerPlusDeductDuru === 0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerPlusDeductDuru
																	)
															  )
															: null}
													</Table.Cell>
												)}
												{dayWorkerPlusMinus4InsValue && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo?.dayWorkerPlusMinus4Ins
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerPlusMinus4Ins === 0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerPlusMinus4Ins
																	)
															  )
															: null}
													</Table.Cell>
												)}
												{dayWorkerDeductEtcName1Value && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo
															?.dayWorkerDeductEtcWage1
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerDeductEtcWage1 === 0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerDeductEtcWage1
																	)
															  )
															: null}
													</Table.Cell>
												)}
												{dayWorkerDeductEtcName2Value && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo
															?.dayWorkerDeductEtcWage2
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerDeductEtcWage2 === 0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerDeductEtcWage2
																	)
															  )
															: null}
													</Table.Cell>
												)}
												{dayWorkerDeductEtcName3Value && (
													<Table.Cell>
														{searchResult?.dayWorkerInfo
															?.dayWorkerDeductEtcWage3
															? numberToCommaString(
																	roundUp(
																		searchResult?.dayWorkerInfo
																			?.dayWorkerDeductEtcWage3 === 0
																			? 0
																			: searchResult?.dayWorkerInfo
																					?.dayWorkerDeductEtcWage3
																	)
															  )
															: null}
													</Table.Cell>
												)}
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo?.dayWorkerDedectTaxEtc
														)
													)}
												</Table.Cell>
												<Table.Cell>
													{numberToCommaString(
														roundUp(
															searchResult?.dayWorkerInfo?.dayWorkerDeductWage
														)
													)}
												</Table.Cell>
											</Table.Row>
										) : null
									)}
								</Table.Body>
								<Table.Body>
									<Table.Row>
										{(sumOftotalDayWorkerWagesCom(paydocus) ||
											sumOftotalTimeWorkerWagesCom(paydocus)) > 0 && (
											<>
												{authLevel < 100 ? (
													<Table.Cell colSpan='7'>합계</Table.Cell>
												) : (
													<Table.Cell colSpan='8'>합계</Table.Cell>
												)}
											</>
										)}
										{
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOftotalTimeDayWorkerRealWorkingDaysCom(paydocus)
													)
												)}
											</Table.Cell>
										}
										{
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOftotalTimeDayWorkerPaidHoliDaysCom(paydocus)
													)
												)}
											</Table.Cell>
										}

										{(totalDayWorkerWageValue || totalTimeWorkerWageValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOftotalDayWorkerWagesCom(paydocus) +
															sumOftotalTimeWorkerWagesCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{(totalDayWorkerHoliWageValue ||
											totalTimeWorkerWageValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOftotalDayWorkerHoliWagesCom(paydocus) +
															sumOfTotalTimeWorkerPaidHoliWagesCom(paydocus) +
															sumOftotalPaidHoliTimeWagesNotIncludeCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{totalDayWorkerOverWorkDayWageValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOftotalDayWorkerOverWorkDayWagesCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{dayWorkerCAWage5Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCAWage5sCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerCAWage6Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCAWage6sCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerCAWage7Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCAWage7sCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(numDayWorkerOWageValue || numOWageForTWValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfnumDayWorkerOWagesCom(paydocus) +
															sumOfnumOWageForTWCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{(numDayWorkerNWageValue || numNWageForTWValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfnumDayWorkerNWagesCom(paydocus) +
															sunOfnumNWageForTWCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{(numDayWorkerHBwageValue || numHBWageForTWValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfnumDayWorkerHBwagesCom(paydocus) +
															sumOfnumHBWageForTWCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{(numDayWorkerHOwageValue || numHOWageForTWValue) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfnumDayWorkerHOwagesCom(paydocus) +
															sumOfnumHOWageForTWCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{numDayWorkerHNwageValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfnumDayWorkerHNwagesCom(paydocus))
												)}
											</Table.Cell>
										)}

										{numOffWageForTWValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfnumOffWageForTWCom(paydocus))
												)}
											</Table.Cell>
										)}
										{numOffOWageForTWValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfnumOffOWageForTWCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerAbsentTimeWageValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerAbsentTimeWagesCom(paydocus) * -1
													)
												)}
											</Table.Cell>
										)}
										{timeWorkerSpecialBonusValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfTimeWorkerSpecialBonusCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerSpecialBonusValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfDayWorkerSpecialBonusCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerTotalMonthWageAmountValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerTotalMonthWageAmountsCom(paydocus)
													)
												)}
											</Table.Cell>
										)}

										{(sumOfdayWorkerCalTaxsSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalTaxsSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCalTaxsSumCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(sumOfdayWorkerCalRegionTaxsSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalRegionTaxsSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCalRegionTaxsSumCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(sumOfdayWorkerCalPensionsSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalPensionsSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCalPensionsSumCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(sumOfdayWorkerCalHealthCaresSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalHealthCaresSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCalHealthCaresSumCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(sumOfdayWorkerCalOldCaresSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalOldCaresSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerCalOldCaresSumCom(paydocus))
												)}
											</Table.Cell>
										)}
										{(sumOfdayWorkerCalNoJobInsurancesSumCom(paydocus) === 0 ||
											sumOfdayWorkerCalNoJobInsurancesSumCom(paydocus)) && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerCalNoJobInsurancesSumCom(paydocus)
													)
												)}
											</Table.Cell>
										)}

										{dayWorkerPlusDeductHealthCareValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerPlusDeductHealthCaresCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusDeductOldCareValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerPlusDeductOldCaresCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusDeductWorkTaxValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerPlusDeductWorkTaxsCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusDeductWorkRegionTaxValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerPlusDeductWorkRegionTaxsCom(paydocus)
													)
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusDeductWorkerCompanyLoanValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(
														sumOfdayWorkerPlusDeductWorkerCompanyLoansCom(
															paydocus
														)
													)
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusDeductDuruValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerPlusDeductDurusCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerPlusMinus4InsValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerPlusMinus4InssCom(paydocus))
												)}
											</Table.Cell>
										)}

										{dayWorkerDeductEtcName1Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerDeductEtcWage1sCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerDeductEtcName2Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerDeductEtcWage2sCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerDeductEtcName3Value && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerDeductEtcWage3sCom(paydocus))
												)}
											</Table.Cell>
										)}

										{dayWorkerDedectTaxEtcValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerDedectTaxEtcsCom(paydocus))
												)}
											</Table.Cell>
										)}
										{dayWorkerDeductWageValue && (
											<Table.Cell>
												{numberToCommaString(
													roundUp(sumOfdayWorkerDeductWagesCom(paydocus))
												)}
											</Table.Cell>
										)}
									</Table.Row>
								</Table.Body>
							</Table>
						</div>
					)}
				</>
			)}
		</>
	);
}
