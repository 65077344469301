import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { Grid, Loader } from 'semantic-ui-react';
import ClientReqList4In from './ClientReqList4In';
import { getWorkerUserProfile } from '../../../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../../../profiles/profileActions';
import { dataFromSnapshotIdDate } from '../../../../app/firestore/firestoreService';
import { josaGaType, lastPreventDateF } from '../../../../app/common/util/util';
import { clearClientReqs4In, fetchClientReqs4In } from '../clientReqActions4In';
import useScrollRestoration from '../../../../app/hooks/useScrollRestoration';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import ClientReqDashboard4InWorker from '../clientsReqDashboard4In/dashboardDetail/ClientReqDashboard4InWorker';
import { format } from 'date-fns';
import ClientReqInfoDashboard4In from './ClientReqInfoDashboard4In';

export default function ClientReqDashboard4In() {
	const { saveScrollPosition, restoreScrollPosition } = useScrollRestoration();
	const dispatch = useDispatch();
	const history = useHistory();
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const { filter, clientInfo } = useSelector((state) => state.companyReducer);

	// const { paydocus  } = useSelector(state => state.companyReducer);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [sortCriterion, setSortCriterion] = useState('name'); // 정렬 기준 상태 추가
	const [activeIndex, setActiveIndex] = useState(-1);

	const handleAccordionClick = (index) => {
		setActiveIndex(activeIndex === index ? -1 : index);
	};
	const isWorker = currentUserProfile?.isWorker;
	const isDayWorker = currentUserProfile?.isDayWorker;
	const isBizWorker = currentUserProfile?.isBizWorker;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const laborContractId = currentUserProfile?.paydocuWorkerUid;
	const companyNameC = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: companyNameC
		: companyNameC;

	const companyNameUn = companyName ? josaGaType(companyName) : null;

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		if (currentUserProfile) {
			dispatch(fetchClientReqs4In(filter)).then(() => {});
		}
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter, currentUserProfile]);

	useEffect(() => {
		if (currentUserProfile) {
			// currentUserProfile이 존재할 때만 실행
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) =>
					dispatch(
						listenToWorkerUsers(
							snapshot.docs.map((docSnapshot) =>
								dataFromSnapshotIdDate(docSnapshot)
							)
						)
					),
				error: (error) => console.log(error),
			});
			return () => {
				unSubscribe();
			};
		}
	}, [dispatch, currentUserProfile]); // 의존성 배열에 currentUserProfile 추가

	const sortClientReqs = (data) => {
		if (sortCriterion.key === 'name') {
			return data?.sort((a, b) => {
				return sortCriterion.order === 'asc'
					? a?.worker?.name?.localeCompare(b?.worker?.name)
					: b?.worker?.name?.localeCompare(a?.worker?.name);
			});
		} else if (sortCriterion.key === 'workerEnteredDate') {
			return data?.sort((a, b) => {
				const dateA = new Date(a?.workerEnteredDate || '1970-01-01');
				const dateB = new Date(b?.workerEnteredDate || '1970-01-01');
				return sortCriterion.order === 'asc' ? dateA - dateB : dateB - dateA;
			});
		}
		return data; // 기본값
	};

	useEffect(() => {
		// 검색 조건 적용
		const searched = clientReqs4In?.filter(
			(item) =>
				item?.companyName?.includes(searchTerm) ||
				companyName?.includes(searchTerm) ||
				item?.worker?.name?.includes(searchTerm)
		);

		// 정렬 적용
		const sorted = sortClientReqs(searched);
		setSearchResults(sorted);
	}, [clientReqs4In, searchTerm, companyName, sortCriterion]); // eslint-disable-line react-hooks/exhaustive-deps

	const noUserInfo =
		currentUserProfile?.ceoName || currentUserProfile?.workerInputCompanyName
			? false
			: true;

	useEffect(() => {
		function noUserInfoF() {
			alert(`회원가입 후 회원 정보를 입력해야 사용가능합니다.`);
			currentUserProfile?.id
				? history.push(`/profile/${currentUserProfile?.id}`)
				: history.push('/pricePolicyPaper');
		}
		noUserInfo && noUserInfoF();
	}, [noUserInfo, history, currentUserProfile?.id]);

	useEffect(() => {
		restoreScrollPosition();

		return () => {
			saveScrollPosition();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// finBaseDate가 Firestore 타임스탬프 형식인지 확인하고 변환하는 함수
	const toDateIfTimestamp = (timestamp) => {
		// "seconds" 속성을 확인하여 Firestore 타임스탬프 형식인지 판단
		if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp) {
			return new Date(timestamp.seconds * 1000);
		}
		// 이미 Date 객체거나 다른 형태인 경우 그대로 반환
		return timestamp;
	};

	const lastPreventData = lastPreventDateF(clientReqs4In);
	const realCount = true;
	const lastPreventData6 = lastPreventData ? lastPreventData[6] : null;
	const today = new Date();
	const formatteTodayDate = format(toDateIfTimestamp(today), 'yyyy-MM-dd');
	const lastPreventDataShow =
		lastPreventData6 && (formatteTodayDate <= lastPreventData6 ? true : false);

	function reloadToClientReqs4InInfo() {
		history.push('/clientReqs4InInfo');
	}

	function reloadToClientReqs4InNewYearLawInfo() {
		history.push('/clientReqs4InNewYearLawInfo');
	}

	return (
		<>
			{isWorker ? (
				<ClientReqDashboard4InWorker
					companyNameUn={companyNameUn}
					isDayWorker={isDayWorker}
					isBizWorker={isBizWorker}
					laborContractId={laborContractId}
					history={history}
					companyId={currentUserProfile?.companyId}
					workerName={currentUserProfile?.displayName}
				/>
			) : (
				<>
					<PaydocuShortCut
						filter={filter}
						clientReqs4In={clientReqs4In}
						realCount={realCount}
					/>
					<Grid>
						<Grid.Column width={16}>
							<>
								{authenticated && (
									<>
										<ClientReqInfoDashboard4In
											activeIndex={activeIndex}
											handleAccordionClick={handleAccordionClick}
											reloadToClientReqs4InInfo={reloadToClientReqs4InInfo}
											reloadToClientReqs4InNewYearLawInfo={
												reloadToClientReqs4InNewYearLawInfo
											}
											lastPreventData={lastPreventData}
											lastPreventDataShow={lastPreventDataShow}
											Link={Link}
										/>
										<ClientReqList4In
											authLevel={authLevel}
											lastPreventData={lastPreventData}
											clientReqs4In={clientReqs4In}
											searchResults={searchResults}
											searchTerm={searchTerm}
											// paydocus={paydocus}
											companyName={companyName}
											handleChange={handleChange}
											setSortCriterion={setSortCriterion}
											sortCriterion={sortCriterion}
										/>
									</>
								)}
							</>
						</Grid.Column>
						<Grid.Column width={16}>
							<Loader active={loading} />
						</Grid.Column>
					</Grid>
				</>
			)}
		</>
	);
}
