// 12월05일-공통
import React from 'react';
import {
	healthCarePayTypeOption,
	noJobPayTypeOption,
	oldCarePayTypeOption,
	pensionPayTypeOption,
	taxPayTypeOption,
	workAccidenceTypeOption,
} from '../../../../../app/api/dropdownOption';

import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
// import { Icon, Label } from 'semantic-ui-react';
// import { useDispatch } from 'react-redux';
// import { openModal } from '../../../../../app/common/modals/modalReducer';

export default function CrwfWorkerBasicInfoTax4In({
	values,
	dayWorker,
	openPensionPayType,
	setOpenPensionPayType,
	openHealthCarePayType,
	setOpenHealthCarePayType,
	openNoJobPayType,
	setOpenNoJobPayType,
	openOldCarePayType,
	setOpenOldCarePayType,
}) {
	// ---------

	// const dispatch = useDispatch();

	// function workerRegStep3_1YTOpen() {
	// 	dispatch(openModal({ modalType: 'WorkerRegStep3_1' }));
	// }

	return (
		<div style={{ marginBottom: '-10px', marginTop: '-10px' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '13px',
				}}>
				{dayWorker ? (
					<span style={{ color: 'teal' }}>4대보험 설정(필요시 사용)</span>
				) : (
					<>
						<span style={{ color: 'red' }}>* </span>
						<span style={{ color: 'blue', fontSize: '1.1rem' }}>
							근로소득세 및 4대보험 설정(임금명세서 이용시 필수)
						</span>
						{/* <span style={{ fontSize: '8px' }}>
							(설명은 오른쪽 동영상을 참조하세요.)
						</span> */}
					</>
				)}
				{/* <span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '10px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => workerRegStep3_1YTOpen()}>
						<Icon size='big' name='youtube' />
						세금 및 4대보험 설정
					</Label>
				</span> */}
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				{dayWorker ? (
					<>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyDateInput
								name='retiredDate'
								label='퇴직일 등(마지막 근무일)'
								placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
								timeCaption='time'
								dateFormat='yyyy-MM-dd'
								autoComplete='off'
							/>
						</div>
					</>
				) : (
					<>
						<div className='margin' style={{ width: '33%' }}>
							<MySelectInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											근로소득세 공제 기준설정
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								options={taxPayTypeOption}
								placeholder='80% / 100% / 120%'
								name='taxPayType'
							/>
						</div>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											근로소득세 관련 전체 부양가족수(본인포함)
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								name='numOfTaxFamily'
								autoComplete='off'
								placeholder='숫자만 입력(없으면 1)'
							/>
						</div>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											8세이상 20세이하 부양가족수
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								name='numOfTaxFamily7To20'
								autoComplete='off'
								placeholder='숫자만 입력(없으면 0)'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>국민연금 공제액 기준설정</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						onChangeCallback={setOpenPensionPayType}
						options={pensionPayTypeOption}
						placeholder='공제안함 / 당월보수 / 기준보수'
						name='pensionPayType'
					/>
				</div>
				{(values?.pensionBaseWage ||
					openPensionPayType ===
						'기준소득월액(국민연금공단에 신고된 금액)') && (
					<>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											국민연금 기준보수월액(원)
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
										<span style={{ color: 'teal' }}>
											{' '}
											- 재직자는 매년 7월 1회 변경이 원칙임.
										</span>
									</>
								}
								name='pensionBaseWage'
								autoComplete='off'
								placeholder='단, 보수가 30%이상 변경시에는 즉시 변경신고하세요.'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>건강보험 공제액 기준설정</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						onChangeCallback={setOpenHealthCarePayType}
						options={healthCarePayTypeOption}
						placeholder='공제안함 / 당월보수 / 평균보수'
						name='healthCarePayType'
					/>
				</div>
				{(values?.healthCareBaseWage ||
					openHealthCarePayType === '보수월액(건강보험공단에 신고된 금액)') && (
					<>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											건강보험료 평균보수월액(원)
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								name='healthCareBaseWage'
								autoComplete='off'
								placeholder='당월 과세 임금이 아닌 평균 보수월액으로 공제하는 경우'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>요양보험 공제액 기준설정</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						onChangeCallback={setOpenOldCarePayType}
						options={oldCarePayTypeOption}
						placeholder='공제안함 / 당월보수 / 평균보수'
						name='oldCarePayType'
					/>
				</div>
				{(values?.oldCareBaseWage ||
					openOldCarePayType === '보수월액(건강보험공단에 신고된 금액)') && (
					<>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											요양보험료 평균보수월액(원)
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								name='oldCareBaseWage'
								autoComplete='off'
								placeholder='당월 과세 임금이 아닌 평균 보수월액으로 공제하는 경우'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>고용보험 공제액 기준설정</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						onChangeCallback={setOpenNoJobPayType}
						options={noJobPayTypeOption}
						placeholder='공제안함 / 당월보수 / 기준보수'
						name='noJobPayType'
					/>
				</div>
				{(values?.noJobBaseWage ||
					openNoJobPayType ===
						'월평균보수월액(근로복지공단에 신고된 금액)') && (
					<>
						<div className='margin' style={{ width: '33%' }}>
							<MyNumberInput
								label={
									<>
										<span style={{ color: 'blue' }}>
											고용보험료 기준보수월액(원)
										</span>
										<span style={{ color: 'red' }}>[필수]</span>
									</>
								}
								name='noJobBaseWage'
								autoComplete='off'
								placeholder='당월 과세 임금이 아닌 기준 보수월액으로 공제하는 경우'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%' }}>
					<MySelectInput
						label='산재보험 가입 여부 설정'
						// onChangeCallback={setOpenNoJobPayType}
						options={workAccidenceTypeOption}
						placeholder='미가입 / 가입'
						name='workAccidenceType'
					/>
				</div>
			</div>
		</div>
	);
}
