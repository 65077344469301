import React, { useState, useMemo } from 'react';
import {
	josaGaType,
	josaUnType,
	josaWaType,
} from '../../../../../app/common/util/util';
import ClientReqDLCTail from './ClientReqDLCTail';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
	withoutNumberContent, // 추가된 프로퍼티
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{item}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{withoutNumberContent && (
						<ul>
							<li style={{ listStyleType: 'none', color: 'blue' }}>
								{withoutNumberContent}
							</li>
						</ul>
					)}
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function ClientReqDLCDWageBiz({
	currentUserProfile,
	selectedClientReq4In,
}) {
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const company = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.workerInfputCompanyName
		? currentUserProfile?.workerInfputCompanyName
		: selectedClientReq4In?.companyName;
	const companyUn = josaUnType(company);
	const companyGa = josaGaType(company);
	const companyWa = josaWaType(company);

	const privateAcademyCompany = company?.includes('학원') ? true : false;

	const worker = selectedClientReq4In?.worker?.name;
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);
	const workerWa = josaWaType(worker);

	const workerEnteredDate = new Date(selectedClientReq4In?.workerEnteredDate);
	const workerEndDate = new Date(selectedClientReq4In?.workerEndDate);

	const workerEnteredDateStr = workerEnteredDate.toLocaleDateString();
	const workerEndDateStr = workerEndDate.toLocaleDateString();

	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `계약의 목적`,
				content: [
					`${workerUn} ${companyGa} 제공하는 장소 ${company}에서 ${workerGa} 강의하는 수강생에 대하여 성실히  지도 및 관리하고, ${companyUn} ${worker}에게 본 계약에 따라 합의된 수강료를 배분하는 것을 목적으로 한다.`,
				],
			},
			{
				title: `계약기간`,
				content: [
					`본 계약의 기간은  ${workerEnteredDateStr}부터 ${workerEndDateStr}까지로 한다.`,
					`전항에 따른 계약기간이 종료되면 당사자간의 별도의 계약이 있는 경우를 제외하고 본 계약은 만료일 다음날로 종료된다.`,
				],
			},
			{
				title: `수강료 배분`,
				content: [
					`배분 대상 수강료 : ${workerGa} 전담하여 지도하고 관리하는 수강생이 학원에 매월 납부하는 수강료`,
					`수강료 배분 원칙 비율 : ${companyWa}과 ${workerUn} 공동 파트너 정신에 입각하여 원칙적으로 제1항의 수강료를 5 : 5로 배분한다. 단, 배분률이 5:5라고 해서 원생에 대한 ${worker}의 수강료 외의 어떠한 사적 이익(개인 과외, 본인이 운영하는 학원에 수강생을 보내는 행위 등)을 보장하는 것은 아니며, 만약 ${workerGa} 사적이익을 취했을 경우 그로 인해 발생한 ${company}의 손해를 배상하는 것에 동의한다.`,
					`${companyGa} ${worker}에게 배분하는 전체 수강료는 다음의 경비를 포함하므로 ${companyUn} ${worker}에게 제5항의 지급시기에 아래 경비를 제외하고 지급한다.`,
					[
						`카드 수수료 : ${workerGa} 배분받는 수강료에 대한 카드 수수료`,
						`본인 차량 주차비 :  ${workerGa} 자기차량을 주차하는 경우 주차비`,
						`조교 인건비 : ${workerUn} 본인이 지도하고 관리하는 수강생을 위한 조교 사용시 그 조교 인건비의 70% `,
						`손해배상 예치금 : ${workerGa} 배분받는 수강료의 10%의 손해배상 예치금(계약 종료시 계약기간동안 ${workerGa} ${company}에게 손해를 입히지 않은 경우 예치금 전액 또는 손해액이 예치금을 초과하지이 않는 부분이 있는 경우는 그 부분을 ${companyUn} 본 계약 종료월로부터 4개월이되는 달의 말일(수강생 이전 확인 기간포함.)에 ${worker}에게 지급함.)`,
					],
					`사업소득세 관련 3.3% 세금 : ${companyWa} ${workerUn} 독립된 파트너 관계이며, ${workerUn} 세법 측면에서 보면 사업자등록의 주체이기 때문에 ${worker}에게 수강료를 배분할 때 ${workerGa} 배분받는 수강료에 대해 3.3%의 사업소득세 원천징수하여 국세청에 납부한다.`,
					`배분 시기 : ${companyUn} ${worker}에게 매월 10일에 전월 초일부터 말일에 입금된 수강료를 배분한다. 단, 제3항의 경비 및 예치금과 제4항의 사업소득세 그리고 기타 ${companyWa} ${workerGa} 합의한 금품은 추가로 제외하고 지급한다.`,
				],
				withoutNumberContent: `* ${workerUn} 근로자가 아니기 때문에 배분 수강료에서 각종 경비 및 사업소득세 공제에 대해 동의합니다.  동의자 : ${worker} (서명)`, // 원번호 없이 표시할 내용
			},
			{
				title: `${company}의 권리와 의무`,
				content: [
					`${workerUn} 학원의 원활한 운영을 위해, 소속 강사 및 파트너 강사들의 강의실과 강의일정 조률, 그리고 고객인 수강생의 학습권을 보장을 위해 필요한 강의실, 강의시간 등의 결정 및 조정 권한을 보유한다.`,
					`${companyUn} ${worker}에게 배분 수강료를 성실하게 지급하며, 최대한 ${worker} 본인이 강의하는 수강생 교육과 관리를 원활하게 할 수 있도록 최대한 지원한다.`,
					`${companyWa} ${workerUn} 수강생 및 학부모의 의견을 주기적으로 취합하여 전반적인 학원 서비스 개선하여 ${companyWa} ${worker}의 공동 발전에 이바지해야 한다.`,
				],
			},
			{
				title: `${worker}의 권리와 의무 및 법률상 지위 확인`,
				content: [
					`${workerUn} ${workerGa} 지도하는 수강생이 납부하는 제3조에 따라 배분된 수강료를 청구할 권한을 보유한다.`,
					`${workerUn} ${companyWa} 동등한 파트너 관계로 근로관계에 따른 관리감독 대상이 되는 근로자가 아니므로 자율에 따라 본인이 독립적으로 강의 및 수강생 관리 업무를 수행하며, 또한 근로자를 전제로 한 노동관계법령 및 4대보험 등에 관련된 법적 지위를 주장할 수 없다.`,
					`${workerUn} ${companyWa}의 공동 발전을 위해 전조 제3항 본인이 강의하고 관리하는 수강생 및 학부모 의견을 청취(본인 또는 조교를 통해 수집) ${company}에게 제공하고, ${companyGa} 취합한 내용을 ${workerWa} 공유한다.`,
					`${workerUn} 강의와 관련하여 필요한 권한을 가지는 것을 원칙으로 하되, 수강생에 대한 강의 및 지도 관리 방식의 변경 등이 있는 경우에는 사전에 ${companyWa} 협의하여야 한다.`,
				],
				withoutNumberContent: `* ${worker} 본인은 ${company}의 관리를 받는 사용종속 하에 있는 소속 근로자 강사 제안을 거부했음을 확인하고, 본인의 의사에 따라 본 계약서의 내용에 따른 독립사업자 계약에 동의함을 확인합니다.  동의자 : ${worker} (서명)`,
			},
			{
				title: `업무의 대체성`,
				content: [
					`${workerUn} 파트너 강사로 수강생 입장에서 ${companyWa} 본인을 신뢰하여 수강료를 지불하는 바, 수강생 및 학부모가 동의하지 않는 이상 본인이 맡은 강의를 본인이 수행함을 원칙으로 한다.`,
					`다만, 사업 활동 중 질병, 불의의 사고 발생 등 부득이한 사유로 인해 연속된 3일 이내의 일시적으로 강의를 하지 못할 경우에는 1주일 정도 사전에 ${companyWa} 협의 후 교육자 소양을 지닌 제3자로 하여금 대체하게 할 수 있다.`,
				],
				withoutNumberContent: `* 대체 강의 진행 시 ${companyWa}의 협의 및 제3자 대체에 동의합니다. 동의자 : ${worker} (서명)`, // 원번호 없이 표시할 내용
			},
			{
				title: `공동 발전을 위한 협조`,
				content: [
					`${workerUn} ${companyGa} 제공하는 교육자료 및 정보를 습득하여 ${company}의 교육철학 및 방침, 교육 내용을 참고하여 본인이 지도, 관리하는 수강생의 학습역량 향상에 최선을 다해야 하며, ${worker} 본인의 실적향상과 ${company}의 경영향상을 도모할 수 있도록 협조하여야 한다.`,
				],
			},
			{
				title: `강의 일정 협조`,
				content: [
					`${companyUn} 수강생과 ${worker} 그리고 다른 파트너 강사 및 소속 강사들의 공동 발전을 위해 학원 전체적 강의 일정에 대한 결정권한을 가지며, ${workerUn} ${companyGa} 제공하는 강의 일정을 참조하여 매월 본인의 강의 계획서를 ${company}에게 제공한다.`,
					`${workerUn} 강의 계획서에 따라 강의 및 기타 수강생 지도 및 관리업무를 진행하며, 이에 부수한 업무의 내용 및 수행 과정은 자유로운 의사에 따르되, ${company}의 운영상 필요가 있는 변경사항 등은 ${companyWa} 협의하여 결정한다.`,
					`${companyUn} ${workerWa}의 협의를 통해서 적절한 강의 진도 및 수준 등을 조정할 수 있으며, ${workerGa} 강의한 수강생 수 및 그들의 수강현황 및 지도 및 관리 내용(본인 또는 본인의 조교가 취합한 내용)을 매월 수강료 배분 전에 ${company}에게 제공한다.`,
				],
			},
			{
				title: `비밀유지, 경업금지 및 고객정보 활용 간주 및 손해배상액 충당`,
				content: [
					`${workerUn} 본 계약에 의한 계약기간 및 계약종료 후 3년 동안 용역업무 수행과 관련하여 지득한 고객정보(수강생 및 학부모의 개인정보 일체)나 업업비밀(수업방법, 교재, 학원운영 노하우, 인력구성, 인력운영방법 등 경영영업비밀 일체)을 외부에 누설하지 않아야 하며, ${workerGa} 고객정보나 영업비밀을 ${worker} 본인이 이용하거나 제3자에게 누설할 경우에 발생하는 모든 손해를 배상해야 한다.`,
					`${workerUn} 제3조 제2항 단서를 반드시 준수해야 한다. 즉, ${workerUn} ${company}의 수강생을 유치할 목적으로 개별 접촉을 하지 않으며, 계약해지 이후 동종 업종을 경영할 경우 ${company}의 영업장소에서 반경 1km이내에서는 행해서는 안 되며, 만약 본 조항 위반으로 인해 ${company}에게 손해가 발생할 경우 그 손해를 배상해야 한다.`,
					`위 1항과 2항 외에 쌍방이 서로에 대해 손해배상이 발생하면 이를 배상한다.`,
					`${workerGa} ${company}에게 손해를 발생시킨 경우 제3조 제3항 제4호의 손해배상 예치금에서 손해를 먼저 충당하고 동 손해배상 예치금을 초과하는 손해의 경우 ${workerUn} 추가로 ${company}에게 손해를 배상한다.`,
					`제2항 에 따라 ${workerGa} ${company}의 영업장소에서 1Km반경 이외에서 영업을 하더라도 ${company}의 고객정보(수강생 및 학부모의 개인정보 등)를 수집, 활용하여 ${company}의 수강생을 유치하는 경우에는 당연히 ${company}에게 손해를 배상해야 하는데, ${workerGa} ${company}의 고객정보를 이용했는지 여부의 다툼을 줄이기 위해 ${workerGa} 본 계약을 해지한 이후 3개월 내로 ${company}의 수강생이 “을” 영업장으로 옮긴 경우 그 고객에 ${company}에게 교육받는 기간의 1/2에 해당하는 만큼의 미래 수강료(“갑”에서 1년간 교육받는 수강생의 경우 미래 6개월의 수강료)를 ${workerUn} ${company}에게 손해로 배상한다.`,
				],
				withoutNumberContent: `* 고객정보 활용 간주 손해배상 동의자 : ${worker} (인)`, // 원번호 없이 표시할 내용
			},
			{
				title: `쌍방 통지 의무`,
				content: [
					`${companyUn} 경영상의 사유에 따라서 불가피하게 사업을 폐지하거나 축소할 경우, 사전에 사실을 ${worker}에게 통지하여야 한다.`,
					`${workerUn} 육체적, 정신적 개인사정 등으로 전조의 일시적 강의 중단이 아닌 경우 등 파트너 강사로써 본인의 수강생을 지도하거나 관리하지 못하는 경우에는 최소한 1주일 전까지는 ${company}에게 이를 통지해야 하고, ${companyUn} ${workerGa} 지도하고 관리하던 수강생의 학습권을 보호을 위해 수강생 및 학부모에게 다른 강사를 배치하는 등 적절한 조치를 취해야 한다.`,
					`기타 ${companyWa} ${workerUn} 신상변동 등 일반적으로 사회통념상 공지해야 할 필요가 있다고 판단되는 경우, 쌍방에게 그 사실을 통지하여야 한다.`,
				],
			},
			{
				title: `계약 종료`,
				content: [
					`${companyWa} ${workerUn} 다음 각 항의 해지 사유가 발생한 경우 계약을 해지할 수 있다.`,
					[
						`${companyWa} ${workerGa} 합의한 제2조의 계약기간이 만료된 경우`,
						`${companyGa} 경영상의 사유에 따라서 불가피하게 사업을 폐지하거나 축소할 경우`,
						`${worker}의 개인적인 사유로 정상적인 계약의 수행이 불가능한 경우`,
						`${workerGa} 중대한 귀책사유로 수강생수가 전월 대비 50% 이상 감소된 경우`,
						`${workerGa} 고의 또는 과실로 ${company}의 신용과 명예를 훼손하였을 경우`,
						`${workerGa} 본 계약 제3조 2항 단서에 해당하는 사적이익을 취한 경우`,
						`${worker}의 정신적, 육체적 장애로 계약사항에 대한 수행이 불가능하거나 형사상의 실행을 선고 받은 경우`,
					],
				],
			},
			{
				title: `계약해지에 따른 위약금`,
				content: [
					`상호합의 되지 않은 계약해지에 따른 책임을 아래와 같이 둔다.`,
					[
						`제11조 계약 종료 사항이 아닌 사유로 ${company}의 일방적인 계약해지 시 ${companyUn} ${worker}에게 계약해지 이전 2개월분의 ${worker}에게 배분하는 수강료를 위약금으로 지불한다. 단, 제11조에 명시한 계약해지 사유 시에는 ${companyUn} ${worker}에게 위약금을 지불하지 아니 한다.`,
						`${workerUn} 제2조의 계약기간 내에 정당한 이유(${companyGa} 동의하는 경우를 포함한다.) 일방적인 계약해지로 또는 제3조 단서의 행위가 있을시 ${workerUn} 계약해지 이전 2개월분의 ${worker}의 배분 수수료를 ${company}에게 위약금으로 지불한다.`,
					],
				],
			},
			{
				title: `강의 교재 저작권`,
				content: [
					`${companyUn} ${workerGa} 학원 수강생 교육을 위해 ${workerGa} 본 계약기간 내에 개발한 교재에 대한 저작권을 공유한다. 단, ${workerGa} ${companyWa}의 계약기간과 상관없이 개발한 교재에 대해서는 그러하지 아니한다.`,
				],
			},
			{
				title: `기타`,
				content: [
					`${workerUn} ${companyGa} ${worker}의 조교 인건비의 30%를 공동 보전하는 만큼 본 계약이 종료되는 경우 관리하던 수강생과 수강생의 학습상황 등 효율적인 강의를 위한 재반 사항을 “을” 또는 ${worker}의 조교를 통해 ${company}에게 또는 ${companyGa} 지정하는 자에게 성실히 인계하여야 한다.`,
					`본 계약에 관한여 법률적인 문제가 발생하였을 때 소송사건의 관할은 ${company}의 소재지 관할법원으로 한다.`,
					`${workerUn} 수강생이 납부하는 수강료의 50%를 공동 분배받는 사업 파트너이기 때문에 근로기준법, 4대보험 법령 등 노동관계법을 적용하지 아니하는 것을 명확히 확인한다.`,
				],
				withoutNumberContent: `* 근로자가 아니기 때문에 노동법 및 4대보험이 적용되지 않음을 확인함. 확인자 : ${worker} (인)`, // 원번호 없이 표시할 내용
			},
		];

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [
		company,
		worker,
		companyGa,
		companyWa,
		workerUn,
		workerGa,
		workerWa,
		companyUn,
		workerEnteredDateStr,
		workerEndDateStr,
	]);

	const [openSections, setOpenSections] = useState({});

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<Table width='100%' align='center'>
				<Table.Body>
					<Table.Row>
						<Table.Cell align='center'>
							<h1 style={{ textAlign: 'center', margin: '10px' }}>
								{privateAcademyCompany && '파트너 강사 '} 위임 계약서
							</h1>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<div className='jau__title sectionup'>
				<ul>
					<li>
						{companyWa} {workerUn} 파트너 강사가 지도하는 수강생에 대해 학원
						운영 파트너로써 아래의 내용의 파트너 강사 계약을 체결한다.
					</li>
				</ul>
			</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + 1} // 동적으로 number 생성
						title={section?.title}
						content={section?.content}
						isOpen={!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
						withoutNumberContent={section?.withoutNumberContent} // 동의자 처리
					/>
				</div>
			))}

			<ClientReqDLCTail
				worker={worker}
				currentUserProfile={currentUserProfile}
				selectedClientReq4In={selectedClientReq4In}
			/>
			<br />
			<br />
			<hr />
		</>
	);
}
