import React from 'react';
import {
	selfNoJobPayTypeOption,
	endless65EmploymentInsuranceType,
} from '../../../../../app/api/dropdownOption';
import {
	getForeignerFromSocialNumber,
	selectValidVisaNoJobPay,
} from '../../../../../app/common/util/util';

export default function WorkerFormStep11({
	MyTextInput,
	MySelectInput,
	pensionIn4Type,
	healthIn4Type,
	// workerSupportFundYesOrNot,
	MyTextArea,
	pensionIn4PublicJobType,
	healthIn4DiscountType,
	values,
}) {
	const isForiegner = getForeignerFromSocialNumber(
		values?.workerSocialNumberBack
	);
	const visaType = values?.foreigner?.visaType;
	const isOpenSelectNoJobPay =
		isForiegner && selectValidVisaNoJobPay?.includes(visaType);

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'flex-end',
				marginBottom: '-10px',
				marginTop: '-10px',
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '13px',
				}}>
				<span style={{ color: 'teal' }}>4대보험 입사 신고 정보</span>
			</div>
			<>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>국민연금 자격취득 부호</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						name='pensionIn'
						placeholder='예) 18세이상 당연취득'
						options={pensionIn4Type}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label={
							<>
								<span style={{ color: 'blue' }}>건강보험 자격취득 부호</span>
								<span style={{ color: 'red' }}>[필수]</span>
							</>
						}
						name='healthIn'
						placeholder='05 직장가입자 변경'
						options={healthIn4Type}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='65세 입사자 고용보험 설정'
						name='endless65EmploymentInsurance'
						placeholder='신청'
						options={endless65EmploymentInsuranceType}
					/>
				</div>
				<div className='margin' style={{ width: '34%', marginRight: '3px' }}>
					<MySelectInput
						label='국민연금 특수직종 부호'
						name='pensionIn4PublicJob'
						placeholder='광원(광부)/부원(선원)인 경우 선택'
						options={pensionIn4PublicJobType}
					/>
				</div>

				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label='건강보험 보험료 감면 부호'
						name='healthIn4Discount'
						placeholder='11 해외근무(전액)'
						options={healthIn4DiscountType}
					/>
				</div>
				{isOpenSelectNoJobPay && (
					<>
						<div
							className='margin'
							style={{ width: '100%', marginRight: '3px', marginTop: '50px' }}>
							<span style={{ color: 'teal' }}>
								입사자가 외국인 근로자인 경우
							</span>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label='외국인 실업급여 임의가입여부'
								placeholder='H2 등 대상자 경우 선택'
								name='foreigner.selfNoJobPay'
								options={selfNoJobPayTypeOption}
							/>
						</div>
						<div>
							* 외국인 제출 서류 : 외국인등록증 사본, 외국인등록 사실증명,
							국내거소신고증사본(또는 국내거소신고사실증명)
						</div>
					</>
				)}
				<div
					className='margin'
					style={{ width: '100%', marginRight: '3px', marginTop: '50px' }}>
					<span style={{ color: 'teal' }}>입사자의 건강보험 피부양자 관리</span>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='본인과의 관계'
						name='healthCarePeople.familyRelation'
						placeholder='예) 자녀'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='피부양자 성명'
						name='healthCarePeople.familyName'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='피부양자 주민등록번호'
						name='familySocialNumber'
						placeholder='외국인 경우 등록번호 또는 국내거소신고번호'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '49%', marginRight: '3px' }}>
					<MyTextInput
						label='피부양자가 장애인/국가유공자/보훈보상대상자인 경우(종별부호와 등록일)'
						name='healthCarePeople.handyCapFamily'
						placeholder='예) 지체장애인, 등록일 : 2022년1월1일'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '49%', marginRight: '3px' }}>
					<MyTextInput
						label='외국인인 경우(국적과 체류자격(비자종류), 체류기간)'
						name='healthCarePeople.ForeignerFamily'
						placeholder='예) 미국, D10(구직), 2022년1월1일부터 2022년12월31일까지'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
					<MyTextArea
						multiple
						label='피부양자가 여러명인 경우'
						placeholder='위 순서대로 관계, 성명, 주민번호, 장애인, 외국인 등 정보를 1명씩 서술해 주세요.'
						name='healthCarePeopleComments'
						rows='5'
					/>
				</div>
				<div>
					* 피부양자 제출 서류 : 가족관계증명서(주민등록등본으로 확인되는 경우는
					주민등록등복), 장애인/유공자/보훈대상인 경우 해당 증명서, 외국인인
					경우 외국인등록증 사본, 외국인등록 사실증명, 국내거소신고증사본(또는
					국내거소신고사실증명)
				</div>
			</>
		</div>
	);
}
