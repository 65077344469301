import React, { useEffect, useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import ReactSelect from 'react-select';

import {
	generateDateOptions,
	koreanHolidays,
	typeOfAbsentTime,
} from '../../../../app/common/util/util';

function ClientWageTableTop3TimeExplain({
	values,
	worker,
	centerStyle,
	payPaperAddMoneyYTOpen,
	centerStyleBlue,
	underInOut5daysWorker,
	centerStyleInOutBack,
	perDayMealTaxFree,
	centerStyleTeal,
	isRetireMonth,
	setValues,
	divideAnnualLeavesDay,
	selectedPaydocuFirst,
	paidOffDays, // string
	nonPaidOffDaysArray, // array
	numOfWorkers,
}) {
	const currentYear = values?.thisYear;
	const currentMonth = Number(values?.thisMonth) - 1;
	// 연도에 맞는 공휴일 배열 가져오기
	const yearHolidays = numOfWorkers >= 5 ? koreanHolidays[currentYear] : [];
	// 예시 공휴일 (실제 데이터에 맞게 수정)

	const [dateOptions, setDateOptions] = useState([]);

	useEffect(() => {
		const opts = generateDateOptions(currentYear, currentMonth, yearHolidays);
		setDateOptions(opts);
	}, [currentYear, currentMonth]); // eslint-disable-line react-hooks/exhaustive-deps

	// ========= 결근 일자 (Absent Dates) =========
	// 로컬 state 및 부모 state(values.absentDates) 업데이트
	const [absentSelectedOptions, setAbsentSelectedOptions] = useState([]);
	// selectedPaydocuFirst가 변경될 때 values.absentDates 업데이트
	useEffect(() => {
		if (
			Array.isArray(selectedPaydocuFirst?.absentMinusWage?.absentDates) &&
			selectedPaydocuFirst?.absentMinusWage?.absentDates
		) {
			const initialSelectedOptions = dateOptions.filter((option) =>
				selectedPaydocuFirst?.absentMinusWage?.absentDates.includes(
					option.value
				)
			);
			setAbsentSelectedOptions(initialSelectedOptions);
			setValues((prevValues) => ({
				...prevValues,
				absentDates: selectedPaydocuFirst?.absentMinusWage?.absentDates,
			}));
		}
	}, [selectedPaydocuFirst?.absentMinusWage?.absentDates]); // eslint-disable-line react-hooks/exhaustive-deps

	// values.absentDays가 변경될 때 초기화 (조건 추가)
	useEffect(() => {
		if (values.absentDays) {
			setValues((prevValues) => ({ ...prevValues, absentDates: [] }));
			setAbsentSelectedOptions([]);
		}
	}, [values.absentDays]); // eslint-disable-line react-hooks/exhaustive-deps

	// 최대 선택 개수를 해당 월의 전체 근무일수로 설정
	const maxAbsentCount =
		values.absentDays !== undefined ? values.absentDays : Infinity;
	const handleAbsentChange = (newValue) => {
		if (newValue && newValue.length > maxAbsentCount) {
			alert(`최대 ${maxAbsentCount}개까지만 선택 가능합니다!`);
			return;
		}
		setAbsentSelectedOptions(newValue || []);
		setValues((prevValues) => ({
			...prevValues,
			absentDates:
				Array.isArray(newValue) && newValue
					? newValue?.map((option) => option.value)
					: [],
		}));
	};

	// ========= 주휴공제 일자 (Holiday Dates) =========
	// 별도의 로컬 state와 부모 state(values.absentPaidHoliDates)
	const [holiSelectedOptions, setHoliSelectedOptions] = useState([]);
	// selectedPaydocuFirst가 변경될 때 초기값 설정
	useEffect(() => {
		if (
			Array.isArray(
				selectedPaydocuFirst?.absentMinusWage?.absentPaidHoliDates
			) &&
			selectedPaydocuFirst?.absentMinusWage?.absentPaidHoliDates
		) {
			const initialSelectedOptions = dateOptions.filter((option) =>
				selectedPaydocuFirst?.absentMinusWage?.absentPaidHoliDates.includes(
					option.value
				)
			);

			setHoliSelectedOptions(initialSelectedOptions);
			setValues((prevValues) => ({
				...prevValues,
				absentPaidHoliDates:
					selectedPaydocuFirst?.absentMinusWage?.absentPaidHoliDates,
			}));
		}
	}, [selectedPaydocuFirst?.absentMinusWage?.absentPaidHoliDates]); // eslint-disable-line react-hooks/exhaustive-deps

	// values.absentPaidHoliDays가 변경될 때 초기화 (조건 추가)
	useEffect(() => {
		if (values.absentPaidHoliDays) {
			setValues((prevValues) => ({ ...prevValues, absentPaidHoliDates: [] }));
			setHoliSelectedOptions([]);
		}
	}, [values.absentPaidHoliDays]); // eslint-disable-line react-hooks/exhaustive-deps

	// 만약 최대 선택 개수가 필요하다면 (예: values.absentPaidHoliDays가 숫자라면)
	const maxHoliCount =
		values.absentPaidHoliDays !== undefined
			? values.absentPaidHoliDays
			: Infinity;
	const handleHoliChange = (newValue) => {
		if (newValue && newValue.length > maxHoliCount) {
			alert(`최대 ${maxHoliCount}개까지만 선택 가능합니다!`);
			return;
		}
		setHoliSelectedOptions(newValue || []);
		setValues((prevValues) => ({
			...prevValues,
			absentPaidHoliDates:
				Array.isArray(newValue) && newValue
					? newValue?.map((option) => option.value)
					: [],
		}));
	};

	// ========= 사용연차 일자 (Used Annual Leave Dates) =========
	// 두 가지 경우:
	// (1) divideAnnualLeavesDay가 true → 각 항목별로 날짜 선택(단일 선택)
	// (2) divideAnnualLeavesDay가 false → 하나의 다중 선택 컴포넌트 사용
	const [annualLeaveSelectedOptions, setAnnualLeaveSelectedOptions] = useState(
		[]
	);

	useEffect(() => {
		if (selectedPaydocuFirst?.usedAnnualLeaveDates) {
			let initialSelectedOptions;

			// 데이터가 문자열 배열(["03", "18"])인지 객체 배열([{ date: "03", content: "1" }])인지 확인
			if (
				Array.isArray(selectedPaydocuFirst?.usedAnnualLeaveDates) &&
				typeof selectedPaydocuFirst.usedAnnualLeaveDates[0] === 'string'
			) {
				// 문자열 배열이면 dateOptions에서 매칭하여 변환
				initialSelectedOptions =
					selectedPaydocuFirst?.usedAnnualLeaveDates?.map((date) => {
						const foundOption = dateOptions.find((opt) => opt.value === date);
						return foundOption || { value: date, label: `${date}일` }; // label이 없으면 기본값
					});
			} else {
				// 객체 배열이면 { value, label } 형식으로 변환
				initialSelectedOptions =
					selectedPaydocuFirst?.usedAnnualLeaveDates?.map((entry) => {
						const foundOption = dateOptions.find(
							(opt) => opt.value === entry?.date
						);
						return {
							value: entry?.date,
							label: foundOption?.label || `${entry?.date}일`, // label이 없으면 기본값
						};
					});
			}

			setAnnualLeaveSelectedOptions(initialSelectedOptions);
			setValues((prevValues) => ({
				...prevValues,
				usedAnnualLeaveDates: selectedPaydocuFirst?.usedAnnualLeaveDates,
			}));
		}
	}, [selectedPaydocuFirst?.usedAnnualLeaveDates]); // eslint-disable-line react-hooks/exhaustive-deps

	// divideAnnualLeavesDay가 변경될 때 초기화 (조건 추가)
	useEffect(() => {
		if (!divideAnnualLeavesDay) {
			setValues((prevValues) => ({ ...prevValues, usedAnnualLeaveDates: [] }));
			setAnnualLeaveSelectedOptions([]);
		}
	}, [divideAnnualLeavesDay]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!values?.usedAnnualLeaves) {
			setValues((prevValues) => ({ ...prevValues, usedAnnualLeaveDates: [] }));
			setAnnualLeaveSelectedOptions([]);
		}
	}, [values?.usedAnnualLeaves]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleUsedAnnualLeaveChange = (newValue, isDivided) => {
		setValues((prevValues) => {
			let updatedDetails;

			if (isDivided) {
				// ✅ 연차 분할 사용 (date + content 포함)
				updatedDetails =
					Array.isArray(newValue) && newValue
						? newValue?.map((option) => ({
								date: option?.date || option?.value,
								content: option?.content || '1', // 기본 content 값 설정
						  }))
						: [];
			} else {
				// ✅ 연차 분할 사용하지 않음 (date만 저장)
				updatedDetails =
					Array.isArray(newValue) && newValue
						? newValue?.map((option) => ({
								date: option?.date || option?.value,
								content: '1', // 기본 content 값 설정
						  }))
						: [];
			}

			return { ...prevValues, usedAnnualLeaveDates: updatedDetails };
		});

		setAnnualLeaveSelectedOptions(newValue || []);
	};

	// ========= 공제시간 (Absent Time) =========
	// 각 항목별 날짜와 종류를 react-select로 변환
	// 날짜 선택: options = dateOptions
	// 종류 선택: typeOptions (typeOfAbsentTime 배열을 객체 배열로 변환)
	const typeOptions = typeOfAbsentTime?.map((type) => ({
		value: type,
		label: type,
	}));

	// 별도의 로컬 state와 부모 state(values.absentTimeDates)
	const [absentTimeSelectedOptions, setAbsentTimeSelectedOptions] = useState(
		[]
	);

	useEffect(() => {
		if (
			Array.isArray(selectedPaydocuFirst?.absentMinusWage?.absentTimeDates) &&
			selectedPaydocuFirst?.absentMinusWage?.absentTimeDates
		) {
			const initialSelectedOptions =
				selectedPaydocuFirst?.absentMinusWage?.absentTimeDates.map((entry) => ({
					date: entry?.date,
					type: entry?.type,
					content: entry?.content,
				}));
			setAbsentTimeSelectedOptions(initialSelectedOptions);
			setValues((prevValues) => ({
				...prevValues,
				absentTimeDates: selectedPaydocuFirst?.absentMinusWage?.absentTimeDates,
			}));
		} else {
			// 배열이 아닐 경우 기본값 설정
			setAbsentTimeSelectedOptions([]);
			setValues((prevValues) => ({
				...prevValues,
				absentTimeDates: [],
			}));
		}
	}, [selectedPaydocuFirst?.absentMinusWage?.absentTimeDates]); // eslint-disable-line react-hooks/exhaustive-deps

	// values.absentTimes가 변경될 때 초기화 (조건 추가)
	useEffect(() => {
		if (!values.absentTimes) {
			setValues((prevValues) => ({
				...prevValues,
				absentTimeDates: Array.isArray(prevValues.absentTimeDates)
					? prevValues.absentTimeDates
					: [],
			}));
			setAbsentTimeSelectedOptions([]);
		}
	}, [values.absentTimes]); // eslint-disable-line react-hooks/exhaustive-deps

	const handelAbsentTimeChange = (index, field, value) => {
		setValues((prevValues) => {
			const updatedAbsentTimeDates = [...prevValues.absentTimeDates];
			updatedAbsentTimeDates[index] = {
				...updatedAbsentTimeDates[index],
				[field]: value,
			};

			return { ...prevValues, absentTimeDates: updatedAbsentTimeDates };
		});
		setAbsentTimeSelectedOptions((prevOptions) => {
			const updatedOptions = [...prevOptions];
			updatedOptions[index] = { ...updatedOptions[index], [field]: value };
			return updatedOptions;
		});
	};

	const handleAddAbsentTime = () => {
		const newEntry = { date: '', type: '', content: '' };

		setValues((prevValues) => ({
			...prevValues,
			absentTimeDates: Array.isArray(prevValues.absentTimeDates)
				? [...prevValues.absentTimeDates, newEntry]
				: [newEntry], // 만약 undefined/null이면 새 배열 생성
		}));

		setAbsentTimeSelectedOptions((prevOptions) =>
			Array.isArray(prevOptions) ? [...prevOptions, newEntry] : [newEntry]
		);
	};

	const handelRemoveAbsentTime = (index) => {
		setValues((prevValues) => ({
			...prevValues,
			absentTimeDates: prevValues.absentTimeDates.filter((_, i) => i !== index),
		}));

		setAbsentTimeSelectedOptions((prevOptions) =>
			prevOptions.filter((_, i) => i !== index)
		);
	};

	// ✅ paidOffDays는 "일" 같은 단일 문자열
	const paidOffDay = Array.isArray(paidOffDays)
		? paidOffDays.length > 0
			? paidOffDays[0]?.trim()
			: ''
		: paidOffDays?.trim();
	// ✅ dateOptions에서 "일"에 해당하는 날짜(value)만 추출
	const paidOffDayValues = dateOptions
		.filter((opt) => opt.label.includes(`(${paidOffDay})`)) // ✅ label이 "(일)"을 포함하는 경우 필터링
		?.map((opt) => opt.value); // ✅ 해당 날짜 값(value)만 추출

	// ✅ 주휴공제(주휴감소)에는 주휴일(일요일)만 포함
	const filteredPaidHoliOptions = dateOptions.filter((opt) =>
		paidOffDayValues.includes(opt.value)
	);

	// ✅ remainingDays에 해당하는 날짜(value)만 추출
	const remainingDayValues = dateOptions
		.filter((opt) =>
			nonPaidOffDaysArray?.some((day) => opt.label.includes(`(${day})`))
		)
		?.map((opt) => opt.value); // ✅ 해당 날짜 값(value)만 추출

	// ✅ 결근, 연차, 공제시간에서는 주휴일 + remainingDays에 해당하는 날짜 제외
	const filteredOptionsWorkingDay = dateOptions.filter(
		(opt) =>
			!paidOffDayValues.includes(opt.value) &&
			!remainingDayValues.includes(opt.value)
	);

	// ✅ absentTimeDates에서 date 값만 추출
	const selectedAbsentTimeDates =
		Array.isArray(values?.absentTimeDates) && values?.absentTimeDates
			? values?.absentTimeDates?.map((entry) => entry.date || '')
			: [];

	// ✅ absentDates는 숫자로 되어 있으므로, 문자열로 변환하여 사용 (ex. "3", "8")
	const selectedAbsentDates =
		Array.isArray(values?.absentDates) && values?.absentDates
			? values.absentDates.map((opt) => String(opt))
			: [];

	// ✅ absentPaidHoliDates도 숫자로 되어 있으므로, 문자열 변환 필요 (ex. "4", "7")
	const selectedHoliDates =
		Array.isArray(values?.absentPaidHoliDates) && values?.absentPaidHoliDates
			? values?.absentPaidHoliDates?.map((opt) => String(opt))
			: [];

	// ✅ usedAnnualLeaveDates는 객체이므로 date 값을 추출
	const selectedAnnualLeaveDates =
		Array.isArray(values?.usedAnnualLeaveDates) && values?.usedAnnualLeaveDates
			? values?.usedAnnualLeaveDates?.map((entry) => entry.date || '')
			: [];

	// ✅ 전체 선택된 날짜 목록 (중복 제거)
	const selectedDates = [
		...new Set([
			...selectedAbsentTimeDates,
			...selectedAbsentDates,
			...selectedHoliDates,
			...selectedAnnualLeaveDates,
		]),
	];

	const filteredOptions = Array.isArray(selectedDates)
		? filteredOptionsWorkingDay.filter(
				(opt) => !selectedDates.includes(opt?.value)
		  )
		: filteredOptionsWorkingDay;

	// ========= 렌더링 =========
	return (
		<>
			<Table.Row>
				{/* 결근 일자 */}
				{values.absentDays ||
				(values.absentDates && values.absentDates.length > 0) ? (
					<Table.Cell textAlign='center' colSpan='2' className='gubun'>
						<h3>결근 일자</h3>
						<ReactSelect
							id='absentDates'
							name='absentDates'
							options={filteredOptions}
							isMulti
							value={absentSelectedOptions}
							onChange={handleAbsentChange}
							placeholder='결근일자'
							closeMenuOnSelect={false}
						/>
						<div style={{ marginTop: '1rem' }}>
							선택:{' '}
							{absentSelectedOptions && absentSelectedOptions.length > 0
								? absentSelectedOptions
										?.map((opt) => {
											const dateValue = opt.value; // date 또는 value 사용
											const labelValue =
												dateOptions.find((d) => d.value === dateValue)?.label ||
												dateValue;
											return `${labelValue}`;
										})
										.join(', ')
								: '없음'}
						</div>
					</Table.Cell>
				) : null}

				{/* 주휴공제 일자 */}
				{values.absentPaidHoliDays ||
				(values.absentPaidHoliDates &&
					values.absentPaidHoliDates.length > 0) ? (
					<Table.Cell textAlign='center' colSpan='2' className='gubun'>
						<h3>주휴공제 일자</h3>
						<ReactSelect
							id='absentPaidHoliDates'
							name='absentPaidHoliDates'
							options={filteredPaidHoliOptions}
							isMulti
							value={holiSelectedOptions}
							onChange={handleHoliChange}
							placeholder='주휴공제 일자'
							closeMenuOnSelect={false}
							style={{ width: '90%' }} // 폭 조정
						/>
						<div style={{ marginTop: '1rem' }}>
							선택:{' '}
							{holiSelectedOptions && holiSelectedOptions.length > 0
								? holiSelectedOptions
										?.map((opt) => {
											const dateValue = opt.value; // date 또는 value 사용
											const labelValue =
												dateOptions.find((d) => d.value === dateValue)?.label ||
												dateValue;
											return `${labelValue}`;
										})
										.join(', ')
								: '없음'}
						</div>
					</Table.Cell>
				) : null}

				{/* 공제시간 */}
				{values.absentTimes ||
				(Array.isArray(values.absentTimeDates) &&
					values.absentTimeDates.length > 1) ? (
					<Table.Cell textAlign='center' colSpan='2' className='gubun'>
						<h3>공제시간</h3>
						{Array.isArray(values.absentTimeDates) &&
						values.absentTimeDates.length > 0 ? (
							values.absentTimeDates?.map((entry, index) => (
								<div
									key={`${entry?.date}-${index}`}
									style={{ marginBottom: '10px' }}>
									{/* 첫 번째 줄: 날짜, 종류, 시간 */}
									{/* 첫 번째 줄: 날짜, 종류 선택 */}
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											gap: '10px', // 두 셀렉트박스 간격 조정
											width: '85%', // 부모 div가 전체 너비를 차지하도록 설정
										}}>
										{/* 날짜 선택 */}
										<ReactSelect
											id={`absentDate-${index}`}
											name={`absentDate-${index}`}
											options={filteredOptions}
											value={
												dateOptions.find((opt) => opt.value === entry?.date) ||
												null
											}
											onChange={(selectedOption) =>
												handelAbsentTimeChange(
													index,
													'date',
													selectedOption ? selectedOption.value : ''
												)
											}
											placeholder='날짜'
											isClearable
											styles={{
												control: (base) => ({
													...base,
													flex: 1, // 동일한 너비 설정
													minWidth: '0', // 너무 커지는 것 방지
												}),
											}}
										/>
										{/* 종류 선택 */}
										<ReactSelect
											id={`absentType-${index}`}
											name={`absentType-${index}`}
											options={typeOptions}
											value={
												typeOptions.find((opt) => opt.value === entry.type) ||
												null
											}
											onChange={(selectedOption) =>
												handelAbsentTimeChange(
													index,
													'type',
													selectedOption ? selectedOption.value : ''
												)
											}
											placeholder='종류'
											isClearable
											styles={{
												control: (base) => ({
													...base,
													flex: 1, // 동일한 너비 설정
													minWidth: '0', // 너무 커지는 것 방지
												}),
											}}
										/>
									</div>

									{/* 두 번째 줄: 삭제 버튼 */}
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											gap: '10px', // 요소 간 간격 조정
											width: '85%', // 부모 div가 전체 너비를 차지하도록 설정
										}}>
										{/* 시간 입력 */}
										<input
											type='number'
											name={`absentContent-${index}`}
											placeholder='0.5'
											value={entry?.content || ''}
											onChange={(e) =>
												handelAbsentTimeChange(index, 'content', e.target.value)
											}
											style={{
												width: '40%', // 고정된 너비 설정
												maxWidth: '50%', // 너무 넓어지는 것 방지
												padding: '5px', // 내부 여백 추가
												textAlign: 'center', // 숫자 중앙 정렬
												border: '1px solid #ccc', // 테두리 스타일 추가
												borderRadius: '5px', // 둥근 모서리 적용
											}}
										/>
										<span>시간</span>
										<button
											onClick={() => handelRemoveAbsentTime(index)}
											style={{
												padding: '5px 10px',
												backgroundColor: 'red',
												color: '#fff',
												border: 'none',
												borderRadius: '5px',
												cursor: 'pointer',
											}}>
											삭제
										</button>
									</div>
								</div>
							))
						) : (
							<div style={{ marginTop: '1rem' }}>
								<p>공제시간 항목이 없습니다.</p>
							</div>
						)}

						<button
							onClick={(e) => {
								e.preventDefault();
								handleAddAbsentTime(e);
							}}
							style={{
								marginTop: '10px',
								padding: '5px 10px',
								backgroundColor: '#007bff',
								color: '#fff',
								border: 'none',
								borderRadius: '5px',
								cursor: 'pointer',
							}}>
							항목 추가
						</button>

						<div style={{ marginTop: '1rem' }}>
							선택:{' '}
							{absentTimeSelectedOptions && absentTimeSelectedOptions.length > 0
								? absentTimeSelectedOptions
										?.map((opt) => {
											const dateValue = opt?.date || '날짜 없음';
											const typeValue = opt.type || '종류 없음';
											const labelValue =
												opt.label ||
												dateOptions.find((d) => d.value === dateValue)?.label ||
												dateValue;
											return `${labelValue} (${typeValue}: ${
												opt?.content || '0'
											}시간)`;
										})
										.join(', ')
								: '없음'}
						</div>
					</Table.Cell>
				) : null}

				{/* 사용연차 일자 */}
				{values.usedAnnualLeaves ||
				(Array.isArray(values.usedAnnualLeaveDates) &&
					values.usedAnnualLeaveDates.length > 1) ? (
					<Table.Cell textAlign='center' colSpan='2' className='gubun'>
						<h3>사용연차 일자</h3>
						{divideAnnualLeavesDay ? (
							// ✅ 연차를 분할 사용하는 경우 (각 날짜별 content 입력)
							<>
								{Array.isArray(values.usedAnnualLeaveDates) &&
									values.usedAnnualLeaveDates?.map((entry, index) => {
										// 이미 선택된 날짜 목록 생성
										const selectedDates = values.usedAnnualLeaveDates?.map(
											(item) => (typeof item === 'string' ? item : item.date)
										);

										// 사용 가능한 옵션 필터링 (이미 선택된 날짜 제거)
										const availableOptions = filteredOptions.filter(
											(opt) => !selectedDates.includes(opt.value)
										);

										return (
											<div
												key={`${entry?.date}-${index}`}
												style={{ marginBottom: '10px' }}>
												{/* 날짜 선택 */}
												<ReactSelect
													id={`annualLeaveDate-${index}`}
													name={`annualLeaveDate-${index}`}
													options={availableOptions}
													value={
														dateOptions.find(
															(opt) =>
																opt.value ===
																(typeof entry === 'string'
																	? entry
																	: entry?.date)
														) || null
													}
													onChange={(selectedOption) =>
														handleUsedAnnualLeaveChange(
															[
																...values.usedAnnualLeaveDates.slice(0, index),
																{
																	...entry,
																	date: selectedOption
																		? selectedOption.value
																		: '',
																},
																...values.usedAnnualLeaveDates.slice(index + 1),
															],
															true
														)
													}
													placeholder='날짜'
													isClearable
													style={{ width: '100%' }}
												/>
												{/* 연차 사용량 입력 */}
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: '5px',
													}}>
													<input
														type='number'
														name={`annualLeaveContent-${index}`}
														placeholder='연차는 1, 반차는 0.5'
														value={entry?.content}
														onChange={(e) =>
															handleUsedAnnualLeaveChange(
																[
																	...values.usedAnnualLeaveDates.slice(
																		0,
																		index
																	),
																	{
																		...entry,
																		content: e.target.value,
																	},
																	...values.usedAnnualLeaveDates.slice(
																		index + 1
																	),
																],
																true
															)
														}
														style={{
															width: '35%',
															marginRight: '10px',
															textAlign: 'center',
														}}
														max='1'
														min='0'
														step='0.25'
													/>
													<span>일</span>
													<button
														onClick={() =>
															handleUsedAnnualLeaveChange(
																values.usedAnnualLeaveDates.filter(
																	(_, i) => i !== index
																),
																true
															)
														}
														style={{
															marginLeft: '10px',
															padding: '5px 10px',
															backgroundColor: 'red',
															color: '#fff',
															border: 'none',
															borderRadius: '5px',
															cursor: 'pointer',
														}}>
														삭제
													</button>
												</div>
											</div>
										);
									})}
								<button
									onClick={(e) => {
										e.preventDefault();
										handleUsedAnnualLeaveChange(
											[
												...values.usedAnnualLeaveDates,
												{ date: '', content: '1' },
											],
											true
										);
									}}
									style={{
										marginTop: '10px',
										padding: '5px 10px',
										backgroundColor: '#007bff',
										color: '#fff',
										border: 'none',
										borderRadius: '5px',
										cursor: 'pointer',
									}}>
									항목 추가
								</button>
							</>
						) : (
							// ✅ 연차를 분할 사용하지 않는 경우 (다중 선택)
							<ReactSelect
								id='usedAnnualLeaveDates'
								name='usedAnnualLeaveDates'
								options={filteredOptions}
								isMulti
								value={
									annualLeaveSelectedOptions?.map((opt) => ({
										value: opt.date || opt.value,
										label:
											dateOptions.find(
												(d) => d.value === (opt.date || opt.value)
											)?.label ||
											opt.date ||
											opt.value,
									})) || []
								}
								onChange={(selectedOptions) => {
									handleUsedAnnualLeaveChange(selectedOptions, false);
								}}
								placeholder='사용연차일자를 선택하세요'
								closeMenuOnSelect={false}
							/>
						)}
						<div style={{ marginTop: '1rem' }}>
							선택:{' '}
							{annualLeaveSelectedOptions &&
							annualLeaveSelectedOptions.length > 0
								? annualLeaveSelectedOptions
										?.map((opt) => {
											const dateValue = opt?.date || opt?.value;
											const labelValue =
												opt.label ||
												dateOptions.find((d) => d.value === dateValue)?.value ||
												dateValue;
											return `${labelValue}: ${opt?.content || '1'}일`;
										})
										.join(', ')
								: '없음'}
						</div>
					</Table.Cell>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span
						style={{
							fontSize: '1.2rem',
							color: 'darkcyan',
							fontWeight: 'bold',
						}}>{`3-1. ${worker}의 추가 지급금액 내역`}</span>
					<span
						style={{
							position: 'absolute',
							right: 0,
							marginRight: '30px',
							cursor: 'pointer',
						}}>
						<span
							style={{ color: 'red' }}
							onClick={() => payPaperAddMoneyYTOpen()}>
							<Icon size='big' name='youtube' />
							상여금, 연차수당 등 설명
						</span>
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9' style={{ color: 'blue' }}>
					* 정기적, 일률적으로 지급되는 상여금[통상임금]과, 년간 미사용
					연차수당은 퇴직금 등 평균임금 계산시 3/12로 계산되고,{' '}
					<span style={{ color: 'teal' }}>
						{' '}
						근로제공과 관련없는 특별 상여금, 퇴직 미사용 연차수당(퇴직월에
						표시됨.)은 평균임금에서 제외됩니다.
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					상여금 <br /> [통상임금]
				</Table.Cell>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleBlue}>
					지급 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
					개인실적 <br /> 인센티브
				</Table.Cell>
				{underInOut5daysWorker ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleInOutBack}>
						지급 조정
						<br />
						(일할 보정)
						<br />
						(과오납 등)
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center' className='gubun' style={centerStyle}>
						지급 조정
						<br />
						(과오납 등)
					</Table.Cell>
				)}
				<Table.Cell textAlign='center'>
					{perDayMealTaxFree ? (
						<div>
							식대 지급
							<br />
							출근일수
						</div>
					) : (
						''
					)}
				</Table.Cell>
				<Table.Cell textAlign='center'>
					전년 미사용 <br /> 연차 <span style={{ color: 'red' }}>(일수)</span>
				</Table.Cell>
				<Table.Cell textAlign='center'></Table.Cell>
				<Table.Cell
					textAlign='center'
					className='gubun'
					style={centerStyleTeal}>
					기타금품 <br /> 특별상여금
				</Table.Cell>
				{isRetireMonth ? (
					<Table.Cell
						textAlign='center'
						className='gubun'
						style={centerStyleTeal}>
						퇴직 미사용
						<br />
						연차 <span style={{ color: 'red' }}>(일수)</span>
					</Table.Cell>
				) : (
					<Table.Cell textAlign='center'></Table.Cell>
				)}
			</Table.Row>
		</>
	);
}

export default ClientWageTableTop3TimeExplain;
