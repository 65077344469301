import React from 'react';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import { josaGaType } from '../../../../../app/common/util/util';

export default function CrwfWorkerBasicInfoMonthMoney({ companyName }) {
	const compnayGa = josaGaType(companyName);

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				<span style={{ color: 'red' }}>* </span>
				<span style={{ color: 'blue' }}>
					<span style={{ color: 'teal', fontSize: '16px' }}>
						{compnayGa} 지급하는 임금이 아닌 행정기관에서 지급하는 금품
					</span>
				</span>
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-1) 정액급식비'
						name='govSupport.mealMoney'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-2) 처우개선비'
						name='govSupport.improveMoney'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-3) 시간외수당'
						name='govSupport.overtimeMoney'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-4) 영유아반수당'
						name='govSupport.childcareMoney'
						placeholder='예) 소정근로 80% 이상 출근시 지급, 전직원 지각이 없을 경우 지급, 전직원 개인 목표달성시 지급 등'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-5) 장애통합수당'
						name='govSupport.disabledMoney'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-6) 장기근속수당'
						name='govSupport.longtermMoney'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='(9-7) 근무환경개선비'
						name='govSupport.improveEmvMoney'
						autoComplete='off'
					/>
				</div>
			</div>
		</>
	);
}
