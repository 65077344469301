import React from 'react';
import { Table } from 'semantic-ui-react';
import { generateCalendar } from '../../../../../app/common/util/dateUtil';
import { useMediaQuery } from 'react-responsive';
import '../../../../../styles/Calendar.css';

export default function RealtimeCalendar({
	selectedYear,
	selectedMonth,
	renderDayEntries,
	groupedData,
	workingDays,
	paidOffDay,
	useWorkingDays,
}) {
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const isCompany = true;

	const calendarData = generateCalendar({
		selectedYear,
		selectedMonth,
		renderDayEntries,
		isMobile,
		groupedData,
		workingDays,
		paidOffDay,
		useWorkingDays,
		isCompany,
	});
	// const { monthData } = calendarData ?? {};

	return (
		<div className={`calendar-container ${isMobile ? 'mobile' : 'desktop'}`}>
			{/* {!useWorkingDays && monthData && (
				<Segment
					raised
					className='monthly-summary-segment'
					style={{ maxWidth: '100%' }}>
					<Header
						as='h3'
						dividing
						style={{ marginBottom: '10px' }}
						color='blue'>
						{selectedYear}년 {selectedMonth}월 월간 근로 시간 요약
					</Header>
					<Statistic.Group widths='7' size='mini'>
						{monthData.totalWorkTime && (
							<Statistic>
								<Statistic.Label>전체 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.totalWorkTime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.lawBaseTimes && (
							<Statistic>
								<Statistic.Label>소정 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.lawBaseTimes}</Statistic.Value>
							</Statistic>
						)}

						{monthData.overtime && (
							<Statistic>
								<Statistic.Label>연장 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.overtime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.holidayWork && (
							<Statistic>
								<Statistic.Label>휴일 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.holidayWork}</Statistic.Value>
							</Statistic>
						)}

						{monthData.nightWork && (
							<Statistic>
								<Statistic.Label>야간 근로 시간</Statistic.Label>
								<Statistic.Value>{monthData.nightWork}</Statistic.Value>
							</Statistic>
						)}

						{monthData.totalLateTime && (
							<Statistic>
								<Statistic.Label>월간 지각 시간</Statistic.Label>
								<Statistic.Value>{monthData.totalLateTime}</Statistic.Value>
							</Statistic>
						)}

						{monthData.totalEarlyLeaveTime && (
							<Statistic>
								<Statistic.Label>월간 조기퇴근 시간</Statistic.Label>
								<Statistic.Value>
									{monthData.totalEarlyLeaveTime}
								</Statistic.Value>
							</Statistic>
						)}
					</Statistic.Group>
				</Segment>
			)} */}
			{!isMobile ? (
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								style={{ minWidth: '100px', textAlign: 'center' }}>
								주(연도 기준)
							</Table.HeaderCell>
							{['일', '월', '화', '수', '목', '금', '토'].map((d, i) => {
								let headerText = d;
								let headerStyle = { minWidth: '100px', textAlign: 'center' };

								if (d === paidOffDay) {
									headerText += '<br />(주휴일)';
									headerStyle = { ...headerStyle, color: 'red' };
								}
								return (
									<Table.HeaderCell key={i} style={headerStyle}>
										<span dangerouslySetInnerHTML={{ __html: headerText }} />
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
					</Table.Header>
					<Table.Body>{calendarData.weeks}</Table.Body>
				</Table>
			) : (
				<div className='calendar-mobile'>{calendarData.weeks}</div>
			)}
		</div>
	);
}
