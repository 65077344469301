import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Card, Table, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

const CardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	align-items: flex-start; /* 카드 상단 정렬 */
`;

const StyledCard = styled(Card)`
	width: 320px;
	height: 250px;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s;
	cursor: pointer;
	border-radius: 8px;
	background-color: #e0f7fa !important; /* Force the background color */

	&:hover {
		transform: scale(1.4); /* Scale the card by 1.5 times */
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
		z-index: 10; /* Bring the hovered card to the front */
	}
`;

const StyledCardHeader = styled(Card.Header)`
	font-size: 1.3rem; /* Increased size */
	margin-bottom: 30px;
`;

export default function PricePolicyPaper() {
	const history = useHistory();

	async function goBack() {
		history.goBack();
	}

	const pricingData = [
		{ employees: '5인 미만', systemPrice: 50000, settingPrice: 200000 },
		{ employees: '10인 미만', systemPrice: 80000, settingPrice: 300000 },
		{ employees: '30인 미만', systemPrice: 150000, settingPrice: 500000 },
		{ employees: '50인 미만', systemPrice: 350000, settingPrice: 700000 },
		{ employees: '100인 미만', systemPrice: 500000, settingPrice: 1000000 },
		{
			employees: '100인 이상',
			systemPrice: '800,000 + 100명당 추가 300,000',
			settingPrice: '별도 협의',
		},
	];

	const pricingData1 = [
		{
			category: '10인 미만',
			description: '추가 1인당 가격 0원',
			price: '10,000원',
		},
		{
			category: '10인 이상',
			description: '추가 1인당 가격 1,500원',
			price: '10,000원',
		},
	];

	const StyledCardDescription = styled(Card.Description)`
		margin-left: 15px; /* 본문 텍스트 왼쪽 여백 추가 */
	`;

	return (
		<>
			<Button color='blue' onClick={() => history.push('/clientReqs4In')}>
				메인페이지
			</Button>
			<Button color='facebook' onClick={() => goBack()}>
				이전페이지
			</Button>
			<br />
			<br />
			<br />
			<Grid>
				<Grid.Column width={16}>
					<br />
					<br />
					<CardContainer>
						<StyledCard
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => (window.location.href = '/clientReqs4In')} // Replace '/' with your initial route
						>
							{' '}
							<img
								src='/SafeHR_favicon.png'
								alt='Company Logo'
								style={{ width: '150px', height: 'auto' }}
							/>
						</StyledCard>
						<StyledCard bgcolor='#e0f7fa'>
							<StyledCardHeader>근태관리</StyledCardHeader>
							<StyledCardDescription>
								- 직원 로그인을 통한 직원별 출퇴근 기록 <br />
								- 직원별 휴게/외출/조퇴 기록 <br />
								- 요일별 지각 자동 기록
								<br />
								- 요일별 소정근로시간 자동기록 <br />
								- 요일별 연장근로시간 자동기록 <br />
								- 주별 실근로시간 자동계산 <br />
								- 주별 52시간제 위반 표시 <br />
							</StyledCardDescription>
						</StyledCard>
						<StyledCard>
							<StyledCardHeader>고용안정지원금 후보 안내</StyledCardHeader>
							<Card.Description>
								- 감원방지기간 자동계산 <br />
								- 육아휴직 사용자 지원금 후보자 안내
								<br />
								- 2024 청년도약 장려금 후보자 안내
								<br />
								- 산전후휴가 대체인력 지원금 후보자 안내
								<br />- 육아기근로시간단축 대체인력지원금 후보자안내
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>근로계약서</StyledCardHeader>
							<Card.Description>
								- 소정근로시간, 휴일, 휴가 표시 <br />
								- 정규직, 수습직, 계약직 <br />
								- 정년이후 촉탁직, 단시간직 <br />
								- 초단시간직, 연소근로제 <br />
								- 일용직, 시급직 <br />
								- 월 단위 일당직 <br />
								- 2주단위 탄력적 근로시간제 <br />
								- 간주근로 시간제 <br />* 위 근로계약서 모두 자동 생성
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>임금(연봉) 계약서</StyledCardHeader>
							<Card.Description>
								- 통상임금 표시 <br />
								- 평균임금 표시 <br />
								- 약정 법정 수당 표시 <br />
								- 임금의 구성항목 자동 생성 <br />
								- 임금의 계산방법 자동 생성 <br />- 임금의 지급방법 자동 생성
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>임금명세서</StyledCardHeader>
							<Card.Description>
								- 통상임금/평균임금/기타금품 구분 표시 <br />
								- 통상시급 표시 <br />
								- 통상임금 기준 시간 수 계산법 표시 <br />
								- 중도입퇴사 자동일할 임금계산 <br />
								- 연장/야간/휴일 임금 자동 계산 <br />
								- 미사용 연차수당 자동 계산 <br />
								- 퇴직 건보 정산 자동 계산 <br />
								- 퇴직금 정산 자동 계산 <br />- 실수령액(Net) 자동 계산기
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>4대보험 관리</StyledCardHeader>
							<Card.Description>
								- 연령에 따른 4대보험 가입여부 자동 판단 <br />
								- 근로시간에 따른 4대보험 가입여부 자동 판단 <br />
								- 임금액에 따른 4대보험 가입여부 자동 판단 <br />
								- 근무일수에 따른 4대보험 가입여부 자동 판단 <br />
								- 외국인 비자에 따른 4대보험 가입여부 자동 판단 <br />
								- 일용/시급직 근로내역 신고 <br />
								- 월급직 입퇴사 신고 파일 다운로드 <br />
								- 일용직 국민연금 입퇴사 신고 파일 다운로드 <br />
								- 일용직 건강보험 입퇴사 신고 파일 다운로드 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>연차 관리</StyledCardHeader>
							<Card.Description>
								- 입사일 연차와 회계년도 연차 비교 확인 <br />
								- 4개년 연차 발생과 소멸 현황 안내 <br />
								- 월 단위 연차 자동 계산 <br />
								- 연 단위 연차 자동 계산 <br />
								- 회계년도 비례연차 자동 계산 <br />
								- 연차 수당 지급시 잔여 연차 자동 계산 <br />
								- 연차 휴가 사용시 잔여 연차 자동 계산 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>인트라넷 서비스</StyledCardHeader>
							<Card.Description>
								- 근로계약서 교부 <br />
								- 임금계약서 교부 <br />
								- 임금명세서 교부 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>인사서식 서비스</StyledCardHeader>
							<Card.Description>
								* 기본 서식 <br />
								- 근로자명부 <br />
								- 정보보호서약서 <br />
								- 개인정보보호동의서 <br />
								- 영업비밀보호서약서 <br />
								- 복무서약서 <br />
								<br /> * 맞춤 서식(별도 협의) <br />
							</Card.Description>
						</StyledCard>

						{/* <StyledCard>
							<StyledCardHeader>규정서비스</StyledCardHeader>
							<Card.Description>
								- 일반 취업규칙 <br />
							</Card.Description>
						</StyledCard> */}
					</CardContainer>
					<div>제정일 : 2024년 9월 15일</div>

					<br />
					<>
						<span style={{ fontSize: '25px', color: 'blue' }}>
							{' '}
							SafeHR의 가격정책
						</span>
						<br />
						<h2>B to C 서비스 가격</h2>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell textAlign='center' width={8}>
										<div>
											<span style={{ fontSize: '1.3rem', color: 'teal' }}>
												(정규직, 비정규직, 사업소득직 포함 회사 직원 수)
											</span>
											<br />
										</div>
									</Table.HeaderCell>
									<Table.HeaderCell textAlign='center'>
										가격 (월)
									</Table.HeaderCell>
									<Table.HeaderCell textAlign='center'>
										세팅 서비스 가격 (최초 1회)
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{pricingData.map((item, index) => (
									<Table.Row key={index}>
										<Table.Cell textAlign='center'>{item.employees}</Table.Cell>
										<Table.Cell textAlign='center'>
											{item.systemPrice.toLocaleString()}원
										</Table.Cell>
										<Table.Cell textAlign='center'>
											{typeof item.settingPrice === 'number'
												? item.settingPrice.toLocaleString() + '원'
												: item.settingPrice}
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
						<Divider />
						<h2>B to B 서비스 가격</h2>
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell textAlign='center' width={8}>
										<span style={{ fontSize: '1.3rem', color: 'teal' }}>
											(정규직, 비정규직, 사업소득직 포함 회사 직원 수)
										</span>
									</Table.HeaderCell>
									<Table.HeaderCell textAlign='center' width={4}>
										가격 (월)
									</Table.HeaderCell>
									<Table.HeaderCell textAlign='center' width={4}>
										직원 1인당 가격 추가 가격 (월)
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{pricingData1.map((item, index) => {
									return (
										<Table.Row key={index}>
											<Table.Cell textAlign='center'>
												{item.category}
											</Table.Cell>
											<Table.Cell textAlign='center'>{item.price}</Table.Cell>
											<Table.Cell textAlign='center'>
												{item.description}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</>
				</Grid.Column>
			</Grid>
			<br />
			<Button color='facebook' onClick={() => goBack()}>
				이전페이지
			</Button>
		</>
	);
}
