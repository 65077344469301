import { getAuth } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';

const captureAndUpload = async (
	documentId,
	workerId,
	workerName,
	workerEmail,
	companyId,
	companyName,
	yearMonth = null,
	isCompanyComformedTime = null,
	token,
	history,
	docPath,
	divId,
	finalAddress,
	storageName
) => {
	const db = getFirestore();
	const storage = getStorage();

	const auth = getAuth();
	const user = auth.currentUser;

	if (!user) {
		alert('🚨 Firebase Storage: 인증되지 않은 사용자입니다.');
		return null;
	}

	// 기존 history.push 대신 숨김 iframe을 생성하여 개별 문서를 로드
	const iframe = document.createElement('iframe');
	iframe.style.position = 'absolute';
	iframe.style.left = '-9999px';
	iframe.style.top = '0';
	// 캡처할 화면 크기에 맞게 사이즈 지정 (필요에 따라 조정)
	iframe.style.width = '1920px';
	iframe.style.height = '1080px';
	iframe.src = `/${docPath}/${documentId}?token=${token}`;
	document.body.appendChild(iframe);

	// iframe 내 해당 요소(divId)가 로드될 때까지 대기
	await new Promise((resolve, reject) => {
		iframe.onload = () => {
			const checkElement = () => {
				const element = iframe.contentWindow.document.getElementById(divId);
				if (element) {
					resolve();
				} else {
					setTimeout(checkElement, 100);
				}
			};
			checkElement();
		};
		setTimeout(() => reject(new Error('iframe 로딩 타임아웃')), 10000);
	});

	let imageUrl = null;
	try {
		const element = iframe.contentWindow.document.getElementById(divId);
		if (!element) {
			throw new Error(`🚨 wageTable 요소를 찾을 수 없습니다 (ID: ${divId})`);
		}
		// html2canvas를 통해 캡처
		const canvas = await html2canvas(element);
		const imageBlob = await new Promise((resolve) =>
			canvas.toBlob(resolve, 'image/png')
		);
		if (!imageBlob) throw new Error('🚨 이미지 Blob 생성 실패');

		const fileName = `${storageName}Images/${documentId}_${uuidv4()}.png`;
		const storageRef = ref(storage, fileName);
		const metadata = {
			contentType: 'image/png',
			customMetadata: {
				uploadedBy: user.uid,
				companyId,
			},
		};

		await uploadBytes(storageRef, imageBlob, metadata);
		imageUrl = await getDownloadURL(storageRef);

		// Firestore에 메타데이터 저장
		await addDoc(collection(db, `${storageName}StorageFiles`), {
			storageName,
			documentId,
			workerId,
			workerName,
			workerEmail,
			companyId,
			companyName,
			imageUrl,
			uploadedBy: user.uid,
			uploadTimestamp: new Date().toISOString(),
			isCompanyComformedTime,
			yearMonth,
		});
	} catch (error) {
		console.error('🚨 이미지 업로드 또는 Firestore 저장 실패:', error);
	} finally {
		// iframe 제거 후, 필요시 원래 주소로 이동
		document.body.removeChild(iframe);
		history.push(`/${finalAddress}`);
	}
	return imageUrl;
};

export default captureAndUpload;
