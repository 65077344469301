import React from 'react';
import { Table } from 'semantic-ui-react';
import {
	absentDaysLawOverWageText,
	absentDayWageText,
	childCompanyCOWTitleName,
	daysOfInterInAbusentLawOverWageText,
	daysOfInterInAbusentOrdinaryWageText,
	daysOfInterOutAbusentLawOverWageText,
	daysOfInterOutAbusentOrdinaryWageText,
	holiBasicTimeText,
	holiNightTimeText,
	holiOverTimeText,
	isShortTimeRegularWorkerF,
	nightTimeText,
	numberToCommaString,
	overTimeText,
	roundDown,
	roundUp,
	workingDaysInWeekF,
} from '../../../app/common/util/util';

export default function PaydocuPayDetailRegularWorker({
	isBizWorker,
	companyName,

	perDayMealTaxFree,
	plusIrregularDayMealTFDays,
	plusIrregularDayMealTFWage,

	absentDaysOrdinaryWage,
	absentPaidHoliDaysOrdinaryWage,
	absentTimeWage,
	numUserFixedCAWageTF1,
	numUserFixedCAWageTF2,
	numUserFixedCAWageTF3,
	numUserFixedCAWageTF4,

	numUserFixedCAWage1,
	numUserFixedCAWage2,
	numUserFixedCAWage3,
	numUserFixedCAWage4,

	monthOWTText,
	monthNWTText,
	monthHoliBasicTimeText,
	monthOWTHoliText,
	monthNWTHoliText,

	isExceptTimeLawWorker,
	gumakStyle,
	numAbsentTimes,

	maternityDeductWage,
	maternityFinalDedectOrdWage,
	maternityFinalDedectLawOverWage,

	annualLeavesIncludeMonthWage,

	workDayOTimePaidWage,
	workDayNTimePaidWage,
	holiBTimePaidWage,
	holiOTimePaidWage,
	holiNTimePaidWage,
	sumOfLawOverWage,
	absentDaysLawOverWage,
	plusRegularBonus,
	plusRegularBonusComment,
	plusIrregularBonus,
	plusIrregularBonusComment,
	plusUnusedAnnualLeavesWage,
	plusUnusedAnnualLeavesWageComment,
	plusLastYearUnusedAnnualLeavesWage,
	plusLastYearUnusedAnnualLeavesWageComment,
	plusRetiredUnusedAnnualLeaves,
	plusSpecialRandomWage,
	plusLastMonthAverWage,
	plusLastMonthAverWageComment,
	plusPersonalIncentive,
	plusPersonalIncentiveComment,

	selectedPaydocu,

	weekLBTime,
	numOfWorkers,
	hourOrdinaryWage,
	paidHolidayLBTime,

	monthOWT,
	monthNWT,
	monthHoliBasicTime,
	monthOWTHoli,
	monthNWTHoli,

	plusMonthOWT,
	plusMonthNWT,
	plusMonthHoliBasicTime,
	plusMonthOWTHoli,
	plusMonthNWTHoli,

	monthBasicWage,
	cTFMealWage,
	cTFCarWage,
	cTFChildWage,
	cTFStudyWage,
	cTFEtc,
	cOWTitle,
	cOWJob,
	cOWLicense,
	cOWLongTerm,
	cOWMonthlyBonus,
	cOWEtc,

	cOWChildManageStudyWage,
	cOWChildManageStudyWageComment,
	cOWChildStudyWage,
	cOWChildStudyWageComment,
	cOWChildCareWage,
	cOWChildCareWageComment,
	cOWChildActivityWage,
	cOWChildActivityWageComment,

	cAWage1,
	cAWage2,
	cAWage3,
	cAWage4,

	cAWageTF1,
	cAWENameTF1,
	cAWageTF1Comment,
	cAWageTF2,
	cAWENameTF2,
	cAWageTF2Comment,
	cAWageTF3,
	cAWENameTF3,
	cAWageTF3Comment,
	cAWageTF4,
	cAWENameTF4,
	cAWageTF4Comment,

	userFixedCAWageTF1,
	userFixedCAWageTF1Name,
	userFixedCAWageTF1Comment,
	userFixedCAWageTF2,
	userFixedCAWageTF2Name,
	userFixedCAWageTF2Comment,
	userFixedCAWageTF3,
	userFixedCAWageTF3Name,
	userFixedCAWageTF3Comment,
	userFixedCAWageTF4,
	userFixedCAWageTF4Name,
	userFixedCAWageTF4Comment,

	userFixedCAWage1,
	userFixedCAWage1Name,
	userFixedCAWage1Comment,
	userFixedCAWage2,
	userFixedCAWage2Name,
	userFixedCAWage2Comment,
	userFixedCAWage3,
	userFixedCAWage3Name,
	userFixedCAWage3Comment,
	userFixedCAWage4,
	userFixedCAWage4Name,
	userFixedCAWage4Comment,

	cAWage5,
	cAWEName5,
	cAWage5Comment,
	cAWage6,
	cAWEName6,
	cAWage6Comment,
	cAWage7,
	cAWEName7,
	cAWage7Comment,

	monthBasicWageComment,
	cTFMealWageComment,
	cTFCarWageComment,
	cTFChildWageComment,
	cTFStudyWageComment,
	cTFEtcComment,
	cOWTitleComment,
	cOWJobComment,
	cOWLicenseComment,
	cOWLongTermComment,
	cOWMonthlyBonusComment,
	cOWEtcComment,
	cAWage1Comment,
	cAWage2Comment,
	cAWage3Comment,
	cAWage4Comment,

	daysOfInterInAbusent,
	daysOfInterOutAbusent,
	daysOfInterInAbusentOrdinaryWage,
	daysOfInterInAbusentLawOverWage,
	daysOfInterOutAbusentOrdinaryWage,
	daysOfInterOutAbusentLawOverWage,

	absentDays,
	absentPaidHoliDays,
	daysInMonth,
	monthOrdinaryWage,
	cAWEName1,
	cAWEName2,
	cAWEName3,
	cAWEName4,
	selectedClientReq4In,
}) {
	const commentOfBasicWageOfKimDukJong =
		selectedPaydocu?.workerInfo.workerId === 'KZqrsx01pSxMLZBcRHYK'
			? '통상임금 중 기본 근로(직무)에 대한 임금(숙소지원비 100,000원 포함)'
			: null;

	const workingDaysInWeek = workingDaysInWeekF(
		selectedClientReq4In?.selectWorkDays1?.length ||
			selectedClientReq4In?.selectWorkDaysETL1?.length ||
			0,
		selectedClientReq4In?.selectWorkDays2?.length ||
			selectedClientReq4In?.selectWorkDaysETL2?.length ||
			0,
		selectedClientReq4In?.selectWorkDays3?.length ||
			selectedClientReq4In?.selectWorkDaysETL3?.length ||
			0,
		selectedClientReq4In?.selectWorkDays4?.length ||
			selectedClientReq4In?.selectWorkDaysETL4?.length ||
			0,
		selectedClientReq4In?.selectWorkDays5?.length ||
			selectedClientReq4In?.selectWorkDaysETL5?.length ||
			0,
		selectedClientReq4In?.selectWorkDays6?.length ||
			selectedClientReq4In?.selectWorkDaysETL6?.length ||
			0,
		selectedClientReq4In?.selectWorkDays7?.length ||
			selectedClientReq4In?.selectWorkDaysETL7?.length ||
			0
	);
	const isShortTimeRegularWorker = isShortTimeRegularWorkerF(
		selectedClientReq4In?.lawBase?.weekLBTime
	);

	const ordinaryDayWorkingTime =
		selectedClientReq4In?.lawBase?.weekLBTime / workingDaysInWeek;

	const absentDayWageTextValue = absentDayWageText(
		monthOrdinaryWage,
		daysInMonth,
		absentDays,
		isShortTimeRegularWorker,
		ordinaryDayWorkingTime,
		hourOrdinaryWage
	);

	const absentDaysLawOverWageTextValue = absentDaysLawOverWageText(
		sumOfLawOverWage,
		daysInMonth,
		absentDays,
		isShortTimeRegularWorker,
		workingDaysInWeek
	);

	return (
		<>
			{monthBasicWage ? (
				<>
					{isBizWorker ? (
						<Table.Row>
							<Table.Cell textAlign='center' className='gubun'>
								사업소득
							</Table.Cell>
							<Table.Cell textAlign='center' className='hangmok'>
								약정 금액
							</Table.Cell>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(monthBasicWage)}
							</Table.Cell>
							<Table.Cell colSpan='6' className='sulmung'>
								위임(또는 도급)된 업무에 수행에 대한 보상
							</Table.Cell>
						</Table.Row>
					) : (
						<Table.Row>
							<Table.Cell textAlign='center' className='gubun'>
								통상임금{' '}
								{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
									<>
										{' '}
										<br /> (주휴포함)
									</>
								)}
							</Table.Cell>
							<Table.Cell textAlign='center' className='hangmok'>
								기본급
							</Table.Cell>
							<Table.Cell className='gumak' style={gumakStyle}>
								{numberToCommaString(monthBasicWage)}
							</Table.Cell>
							<Table.Cell colSpan='6' className='sulmung'>
								{commentOfBasicWageOfKimDukJong ?? monthBasicWageComment}
							</Table.Cell>
						</Table.Row>
					)}
				</>
			) : null}
			{cTFMealWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						식대
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cTFMealWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cTFMealWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cTFCarWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						차량유지비
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cTFCarWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cTFCarWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cTFChildWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						육아수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cTFChildWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cTFChildWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cTFStudyWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						연구수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cTFStudyWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cTFStudyWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cTFEtc ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						그외 비과세 통상임금계
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cTFEtc)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cTFEtcComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWTitle ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{childCompanyCOWTitleName(companyName)}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWTitle)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWTitleComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWJob ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						직무수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWJob)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWJobComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWLicense ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						자격(면허)수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWLicense)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWLicenseComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWLongTerm ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						장기근속수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWLongTerm)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWLongTermComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWMonthlyBonus ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						월정기상여금
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWMonthlyBonus)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWMonthlyBonusComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWEtc ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금{' '}
						{weekLBTime < 15 || isExceptTimeLawWorker ? null : (
							<>
								{' '}
								<br /> (주휴포함)
							</>
						)}
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						그외 통상수당 합계
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWEtc)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWEtcComment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{cOWChildManageStudyWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						관리 및 연구수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWChildManageStudyWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWChildManageStudyWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWChildStudyWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						연구수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWChildStudyWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWChildStudyWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWChildCareWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						취약보육수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWChildCareWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWChildCareWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cOWChildActivityWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						활동보조수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cOWChildActivityWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cOWChildActivityWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{daysOfInterInAbusentOrdinaryWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						중도입사공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(daysOfInterInAbusentOrdinaryWage)}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{daysOfInterInAbusentOrdinaryWageText(
							monthOrdinaryWage,
							daysInMonth,
							daysOfInterInAbusent
						)}
					</Table.Cell>
				</Table.Row>
			) : null}
			{daysOfInterOutAbusentOrdinaryWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						중도퇴사공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(daysOfInterOutAbusentOrdinaryWage)}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{daysOfInterOutAbusentOrdinaryWageText(
							monthOrdinaryWage,
							daysInMonth,
							daysOfInterOutAbusent
						)}
					</Table.Cell>
				</Table.Row>
			) : null}
			{absentDaysOrdinaryWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						결근공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(absentDaysOrdinaryWage)}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{absentDayWageTextValue}
					</Table.Cell>
				</Table.Row>
			) : null}
			{absentPaidHoliDaysOrdinaryWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						주휴공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(absentPaidHoliDaysOrdinaryWage)}
						</span>
					</Table.Cell>
					<Table.Cell
						colSpan='6'
						className='sulmung'>{`1주 소정근로 ${numberToCommaString(
						paidHolidayLBTime
					)}시간 * 시간급 통상임금 ${numberToCommaString(
						hourOrdinaryWage
					)}원 * 주휴 공제 일수 ${absentPaidHoliDays}일`}</Table.Cell>
				</Table.Row>
			) : null}
			{absentTimeWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						지각 등 <br /> 시간공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(absentTimeWage)}
						</span>
					</Table.Cell>
					<Table.Cell
						colSpan='6'
						className='sulmung'>{`지각, 조퇴, 외출 등 공제 ${numAbsentTimes}시간 * 시급 ${numberToCommaString(
						hourOrdinaryWage
					)} `}</Table.Cell>
				</Table.Row>
			) : null}
			{maternityFinalDedectOrdWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						통상임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						출산전후휴가
						<br />
						공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(roundUp(maternityFinalDedectOrdWage))}
						</span>
					</Table.Cell>
					<Table.Cell
						colSpan='6'
						className='sulmung'>{`출산전후 휴가 통상임금 공제분(회사에서 지급한 유급임금 : ${numberToCommaString(
						roundUp(maternityDeductWage)
					)}원보다 많이 공제된 경우는 일할계산임) `}</Table.Cell>
				</Table.Row>
			) : null}
			{(monthOWT || plusMonthOWT) > 0 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numOfWorkers >= 5 ? '시간외수당' : '초과수당'}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(workDayOTimePaidWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{monthOWTText
							? monthOWTText
							: overTimeText(
									monthOWT,
									plusMonthOWT,
									hourOrdinaryWage,
									numOfWorkers
							  )}
					</Table.Cell>
				</Table.Row>
			) : null}
			{(monthNWT || plusMonthNWT) > 0 && numOfWorkers >= 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						야간수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(workDayNTimePaidWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{monthNWTText
							? monthNWTText
							: nightTimeText(
									monthNWT,
									plusMonthNWT,
									hourOrdinaryWage,
									numOfWorkers
							  )}
					</Table.Cell>
				</Table.Row>
			) : null}
			{(monthHoliBasicTime || plusMonthHoliBasicTime) > 0 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						휴일 기본수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(holiBTimePaidWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{monthHoliBasicTimeText
							? monthHoliBasicTimeText
							: holiBasicTimeText(
									monthHoliBasicTime,
									plusMonthHoliBasicTime,
									hourOrdinaryWage,
									numOfWorkers
							  )}
					</Table.Cell>
				</Table.Row>
			) : null}
			{(monthOWTHoli || plusMonthOWTHoli) > 0 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						휴일 연장수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(holiOTimePaidWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{monthOWTHoliText
							? monthOWTHoliText
							: holiOverTimeText(
									monthNWTHoli,
									plusMonthOWTHoli,
									hourOrdinaryWage,
									numOfWorkers
							  )}
					</Table.Cell>
				</Table.Row>
			) : null}
			{(monthNWTHoli || plusMonthNWTHoli) > 0 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						휴일 야간수당
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(holiNTimePaidWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{monthNWTHoliText
							? monthNWTHoliText
							: holiNightTimeText(
									monthNWTHoli,
									plusMonthNWTHoli,
									hourOrdinaryWage,
									numOfWorkers
							  )}
					</Table.Cell>
				</Table.Row>
			) : null}
			<Table.Row>
				{annualLeavesIncludeMonthWage ? (
					<>
						<Table.Cell textAlign='center' className='gubun'>
							평균임금
						</Table.Cell>
						<Table.Cell textAlign='center' className='hangmok'>
							유급연차수당
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(Math.ceil(annualLeavesIncludeMonthWage))}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							년간 15일의 유급연차휴가수당을 12개월로 나눈 1달분의 연차수당
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+plusIrregularDayMealTFDays > 0 ? (
					<>
						<Table.Cell textAlign='center' className='gubun'>
							평균임금
						</Table.Cell>
						<Table.Cell textAlign='center' className='hangmok'>
							출근식대
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(Math.ceil(plusIrregularDayMealTFWage))}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{plusIrregularDayMealTFDays +
								'일 *' +
								perDayMealTaxFree +
								'원(출근일 소정근로시간의 80% 이상 근무시 해당일에 지급하는 식대 - 비과세 한도(200,000원))'}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			{daysOfInterInAbusentLawOverWage && numOfWorkers >= 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						중도입사공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(daysOfInterInAbusentLawOverWage)}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{daysOfInterInAbusentLawOverWageText(
							sumOfLawOverWage,
							daysInMonth,
							daysOfInterInAbusent
						)}
					</Table.Cell>
				</Table.Row>
			) : null}
			{daysOfInterOutAbusentLawOverWage && numOfWorkers >= 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						중도퇴사공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(daysOfInterOutAbusentLawOverWage)}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{daysOfInterOutAbusentLawOverWageText(
							sumOfLawOverWage,
							daysInMonth,
							daysOfInterOutAbusent
						)}
					</Table.Cell>
				</Table.Row>
			) : null}
			{absentDaysLawOverWage && numOfWorkers >= 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						결근공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(roundUp(absentDaysLawOverWage))}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{absentDaysLawOverWageTextValue}
					</Table.Cell>
				</Table.Row>
			) : null}
			{maternityFinalDedectLawOverWage && numOfWorkers >= 5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						출산전후휴가
						<br />
						약정법정수당공제
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						<span style={{ color: 'red' }}>
							-{numberToCommaString(roundDown(maternityFinalDedectLawOverWage))}
						</span>
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						출산전후휴가시 약정법정 수당 일할 공제분
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWageTF1 !== 0 && cAWageTF1 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWageTF1 === 0 ? '' : cAWENameTF1}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWageTF1 === 0
								? ''
								: numUserFixedCAWageTF1 > 0
								? numUserFixedCAWageTF1
								: cAWageTF1
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWageTF1 === 0 ? '' : cAWageTF1Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWageTF2 !== 0 && cAWageTF2 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWageTF2 === 0 ? '' : cAWENameTF2}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWageTF2 === 0
								? ''
								: numUserFixedCAWageTF2 > 0
								? numUserFixedCAWageTF2
								: cAWageTF2
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWageTF2 === 0 ? '' : cAWageTF2Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWageTF3 !== 0 && cAWageTF3 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWageTF3 === 0 ? '' : cAWENameTF3}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWageTF3 === 0
								? ''
								: numUserFixedCAWageTF3 > 0
								? numUserFixedCAWageTF3
								: cAWageTF3
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWageTF3 === 0 ? '' : cAWageTF3Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWageTF4 !== 0 && cAWageTF4 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWageTF4 === 0 ? '' : cAWENameTF4}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWageTF4 === 0
								? ''
								: numUserFixedCAWageTF4 > 0
								? numUserFixedCAWageTF4
								: cAWageTF4
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWageTF4 === 0 ? '' : cAWageTF4Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWage1 !== 0 && cAWage1 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWage1 === 0 ? '' : cAWEName1}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWage1 === 0
								? ''
								: numUserFixedCAWage1 > 0
								? numUserFixedCAWage1
								: cAWage1
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWage1 === 0 ? '' : cAWage1Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWage2 !== 0 && cAWage2 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWage2 === 0 ? '' : cAWEName2}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWage2 === 0
								? ''
								: numUserFixedCAWage2 > 0
								? numUserFixedCAWage2
								: cAWage2
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWage2 === 0 ? '' : cAWage2Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWage3 !== 0 && cAWage3 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWage3 === 0 ? '' : cAWEName3}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWage3 === 0
								? ''
								: numUserFixedCAWage3 > 0
								? numUserFixedCAWage3
								: cAWage3
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWage3 === 0 ? '' : cAWage3Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{numUserFixedCAWage4 !== 0 && cAWage4 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{numUserFixedCAWage4 === 0 ? '' : cAWEName4}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							numUserFixedCAWage4 === 0
								? ''
								: numUserFixedCAWage4 > 0
								? numUserFixedCAWage4
								: cAWage4
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{numUserFixedCAWage4 === 0 ? '' : cAWage4Comment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{plusRegularBonus ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						상여금
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusRegularBonus)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusRegularBonusComment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{plusIrregularBonus ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						비정기상여금
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusIrregularBonus)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusIrregularBonusComment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{plusUnusedAnnualLeavesWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						미사용연차
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusUnusedAnnualLeavesWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusUnusedAnnualLeavesWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{plusLastYearUnusedAnnualLeavesWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						미사용연차
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusLastYearUnusedAnnualLeavesWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusLastYearUnusedAnnualLeavesWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}

			{plusLastMonthAverWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						과오납 급여
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusLastMonthAverWage)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusLastMonthAverWageComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{plusPersonalIncentive ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						개인실적 인센티브
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusPersonalIncentive)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{plusPersonalIncentiveComment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cAWage5 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{cAWEName5}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cAWage5)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cAWage5Comment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cAWage6 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{cAWEName6}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cAWage6)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cAWage6Comment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{cAWage7 ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						월정액
						<br />
						평균임금
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						{cAWEName7}
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(cAWage7)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						{cAWage7Comment}
					</Table.Cell>
				</Table.Row>
			) : null}
			{plusSpecialRandomWage ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						기타금품
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						특별상여금 <br /> (PS 등)
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(plusSpecialRandomWage)}
					</Table.Cell>
					<Table.Cell
						colSpan='6'
						className='sulmung'>{`근로제공과 관련없는 경영성과급, 특별상여금, Profit Sharing, 기타 은혜적 금품(평균임금에서 제외)`}</Table.Cell>
				</Table.Row>
			) : null}
			{plusRetiredUnusedAnnualLeaves ? (
				<Table.Row>
					<Table.Cell textAlign='center' className='gubun'>
						기타금품
					</Table.Cell>
					<Table.Cell textAlign='center' className='hangmok'>
						퇴직미사용연차
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							roundUp(
								plusRetiredUnusedAnnualLeaves *
									paidHolidayLBTime *
									hourOrdinaryWage
							)
						)}
					</Table.Cell>
					<Table.Cell
						colSpan='6'
						className='sulmung'>{`퇴직년도에 발생한 연차 중 퇴직일까지 사용하지 못한 연차 (${plusRetiredUnusedAnnualLeaves})일 * 1일 소정근로시간 (${paidHolidayLBTime})시간 * '통상시급 (${hourOrdinaryWage})원(평균임금에서 제외)`}</Table.Cell>
				</Table.Row>
			) : null}
		</>
	);
}
