import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { db } from '../../../app/config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';
import SortableTable from '../../../app/common/form/SortableTable';
import {
	checkVisaNoJobPayType,
	checkVisaPensionType,
	commaStringToNumber,
	noJobPayDetailF,
	roundUp,
	safeStringCompare,
} from '../../../app/common/util/util';
import { decrypto } from '../../../app/firestore/firestoreService';

export default function InsureManageList({
	workersOut,
	workersIn,
	searchResults,
	setSearchResults,
	searchTerm,
	companyName,
	authLevel,
	insureManageType,
	isAgent,
}) {
	// 모달 상태 관리
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentHealthCarePeople, setCurrentHealthCarePeople] = useState(null);
	const [familyRelation, setFamilyRelation] = useState(null);
	const [handyCapFamily, setHandyCapFamily] = useState(null);
	const [healthCarePeopleComments, setHealthCarePeopleComments] =
		useState(null);
	const [reportedRows, setReportedRows] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);
	const [showReported, setShowReported] = useState(false);

	const workers = useMemo(() => {
		let mergedWorkers = [];

		const workersOutMap = workersOut.reduce((acc, worker) => {
			acc[worker.id] = worker;
			return acc;
		}, {});

		workersIn.forEach((workerIn) => {
			const matchingWorkerOut = workersOutMap[workerIn.id];

			if (!matchingWorkerOut) {
				// workersOut에 일치하는 ID를 가진 원소가 없을 때
				mergedWorkers.push(workerIn);
			} else if (
				workerIn.isInsureInRequest &&
				matchingWorkerOut.isInsureInRequest &&
				matchingWorkerOut.retireInfo?.isInsureOutRequest
			) {
				// workersIn에 isInsureInRequest가 있고, workersOut에 해당하는 원소도 isInsureInRequest와 retireInfo?.isInsureOutRequest 둘 다 true일 때
				mergedWorkers.push(matchingWorkerOut);
			}
		});

		workersOut.forEach((workerOut) => {
			if (!workersIn.find((workerIn) => workerIn.id === workerOut.id)) {
				mergedWorkers.push(workerOut);
			}
		});

		return mergedWorkers;
	}, [workersOut, workersIn]);
	useEffect(() => {
		if (!workers) return;

		// searchTerm이 비어 있으면 모든 데이터를 반환
		const searchedWorkers = searchTerm
			? workers.filter((worker) => {
					const workerName = worker?.worker?.name?.toLowerCase().trim();
					const workerCompanyName = worker?.companyName?.toLowerCase().trim();
					const searchName = searchTerm.toLowerCase().trim();
					// 직원 이름 또는 회사 이름이 검색어를 포함하는 경우
					return insureManageType === '4대보험 자체관리'
						? workerName.includes(searchName) ||
								workerCompanyName.includes(searchName)
						: (workerName.includes(searchName) ||
								workerCompanyName.includes(searchName)) &&
								worker?.insureManageType === '4대보험 위탁관리';
			  })
			: workers; // searchTerm이 비어 있으면 전체 데이터 반환
		setSearchResults(searchedWorkers);
	}, [searchTerm, workers, companyName, insureManageType, setSearchResults]);

	// 부양가족 정보를 입력 받는 모달
	const HealthCarePeopleModal = () => {
		return (
			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<Modal.Header>부양가족 정보</Modal.Header>
				<Modal.Content>
					{/* 여기에 부양가족 정보 입력 폼 추가 */}
					<p>부양가족 이름: {currentHealthCarePeople}</p>
					<p>관계: {familyRelation}</p>
					<p>장애여부: {handyCapFamily}</p>
					<p>추가정보: {healthCarePeopleComments}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => setIsModalOpen(false)}>닫기</Button>
				</Modal.Actions>
			</Modal>
		);
	};

	const handleCancelReport = async (id, segment) => {
		const workerRef = doc(db, 'workers', id);
		let updatePayload = {};

		if (segment === '입사') {
			updatePayload.insureInResponse = false;
			updatePayload.insureInResponseDate = null;
		} else if (segment === '퇴사') {
			updatePayload.insureOutResponse = false;
			updatePayload.insureOutResponseDate = null;
		} else {
			console.error('Unknown segment:', segment);
			return;
		}

		try {
			await updateDoc(workerRef, updatePayload);
			alert('신고가 취소되었습니다.');
		} catch (error) {
			console.error('Error updating document:', error);
		}

		setReportedRows((prev) => {
			const updatedRows = { ...prev };
			delete updatedRows[id];
			return updatedRows;
		});
	};

	const handleReportComplete = async (id, segment) => {
		const workerRef = doc(db, 'workers', id);
		let updatePayload = {};

		if (segment === '입사') {
			updatePayload.insureInResponse = true;
			updatePayload.insureInResponseDate = new Date();
		} else if (segment === '퇴사') {
			updatePayload.insureOutResponse = true;
			updatePayload.insureOutResponseDate = new Date();
		} else {
			console.error('Unknown segment:', segment);
			return;
		}

		try {
			await updateDoc(workerRef, updatePayload);
			alert('신고가 완료되었습니다.'); // 성공 메시지
		} catch (error) {
			console.error('Error updating document:', error); // 오류 처리
		}

		setReportedRows((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	function sameMonthInOut(enteredDateV, retiredDateV) {
		// Date 객체로 변환
		const enteredDate = new Date(enteredDateV);
		const retiredDate = new Date(retiredDateV);

		// 같은 달이고, enteredDate가 1일인 경우
		if (
			enteredDate.getFullYear() === retiredDate.getFullYear() &&
			enteredDate.getMonth() === retiredDate.getMonth() &&
			enteredDate.getDate() === 1
		) {
			return 2;
		} else {
			return '';
		}
	}

	function retiredLeaveDateF(retiredDateV) {
		const retiredDate = new Date(retiredDateV);
		retiredDate.setDate(retiredDate.getDate() + 1);
		return retiredDate.toISOString().split('T')[0].replace(/-/g, '');
	}

	function enteredFirstDayF(enteredDateV) {
		const enteredDate = new Date(enteredDateV);
		return enteredDate.getDate() === 1 ? 1 : 2;
	}

	function enteredDateF(enteredDateV) {
		const enteredDate = new Date(enteredDateV);
		return enteredDate.toISOString().split('T')[0].replace(/-/g, '');
	}

	function contractEndYearMonthF(contractEndDate) {
		if (contractEndDate === null) return '';
		const contractEndDateV = new Date(contractEndDate);
		const fullYearMonthDay = contractEndDateV
			.toISOString()
			.split('T')[0]
			.replace(/-/g, '');
		const contractEndYearMonth = fullYearMonthDay.slice(0, 6);
		return contractEndYearMonth;
	}

	function pensionInF(pensionInV) {
		if (!pensionInV) return 1;
		return +pensionInV.match(/\d+/);
	}

	function pensionOutF(input) {
		// 입력값을 숫자로 변환 (숫자가 아니라면 NaN 반환)
		const number = parseInt(input);

		// 숫자가 유효하고 10보다 작은 경우, '0'을 앞에 붙여서 문자열로 반환
		if (!isNaN(number) && number < 10) {
			return `0${number}`;
		}

		// 그 외의 경우, 숫자를 문자열로 그대로 반환
		return number.toString();
	}

	function healthInF(healthInV) {
		if (!healthInV) return '07';
		return healthInV.match(/\d+/);
	}

	function healthCarePeopleF(healthCarePeople) {
		const resuslt = healthCarePeople ? 'Y' : 'N';
		return resuslt;
	}
	function contractWorkerF(contractEndDate) {
		const result = contractEndDate ? '1' : '2';
		return result;
	}

	function exportIndividualToExcel(row) {
		let segment = row.segment; // '입사' 또는 '퇴사'
		let worksheet;
		const enteredFirstDayV = enteredFirstDayF(row.enteredDate);
		const enteredDateV = enteredDateF(row.enteredDate);
		const pensionInV = pensionInF(row.pensionIn);
		const pensionOutV = pensionOutF(row.pensionOutType);
		const healthCarePeopleV = healthCarePeopleF(row.healthCarePeople);
		const healthInV = healthInF(row.healthIn);
		const noJobPayDetailV = noJobPayDetailF(row.noJobPayDetail);
		const contractWorkerV = contractWorkerF(row.contractEndDate);
		const contractYearMonthV = contractEndYearMonthF(row.contractEndDate);
		const checkVisaNoJobPayTypeV = checkVisaNoJobPayType(
			row.visa,
			row.selfNoJobPay
		);
		const checkVisaPensionTypeV = checkVisaPensionType(row.visa);

		if (segment === '입사') {
			if (!row.noJobPayDetail) {
				alert(
					'직원등록 => 1. 기본인사정보 => (1-1) 인사기초[필수] => (7) 직무를 입력 후 다운가능합니다.'
				);
				return; // or some default value
			}
			let data;
			let merges = [];
			// First row: 가입자정보
			const header1 = [
				'가입자정보',
				'',
				'',
				'',
				'',
				'',
				'국민연금(소득월액상이사유는 국민연금 소속 직원이 접수하는 경우에만 입력합니다.)',
				'',
				'',
				'',
				'',
				'',
				'',
				'건강보험',
				'',
				'',
				'',
				'',
				'',
				'',
				'고용보험',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'산재보험',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'비고',
				'',
				'',
			];

			// Second row: Header row
			const header2 = [
				'*주민등록번호',
				'*성명',
				'*대표자여부',
				'영문성명(외국인)',
				'국적',
				'체류자격',
				'*소득월액',
				'*자격취득일',
				'*취득월납부여부',
				'*자격취득부호',
				'특수직종부호',
				'소득월액상이사유(1.국외근로수당 , 2.사후정산)',
				'직역연금구분(1.직역연금가입자, 2.직역연금수급권자, 0.없음)',
				'*피부양자신청',
				'*보수월액',
				'*자격취득일',
				'*자격취득부호',
				'보험료/감면부호',
				'공무원/교직원(회계명)',
				'공무원/교직원(직종명)',
				'*월평균보수',
				'*자격취득일',
				'*직종부호',
				'*주소정근로시간',
				'보험료부과구분(부호)',
				'보험료부과구분(사유)',
				'*계약직여부',
				'계약종료년월',
				'*월평균보수',
				'*자격취득일',
				'직종부호',
				'주소정근로시간',
				'보험료부과구분(부호)',
				'보험료부과구분(사유)',
				'계약직여부',
				'계약종료년월',
				'오류메세지',
				'경고메세지',
			];
			// Third row: Data row
			const noPensionPay =
				(row.foreigner && !checkVisaPensionTypeV) ||
				row.pension === '대상아님' ||
				row.pensionPayType === '국민연금료 공제안함';
			const noHealthCarePay =
				row.healthCare === '대상아님' ||
				row.healthCarePayType === '건강보험료 공제안함';
			const noNoJobPay =
				(row.foreigner && !checkVisaNoJobPayTypeV) ||
				row.noJob === '대상아님' ||
				row.noJobPayType === '고용보험료 공제안함';
			const noWorkAccidence = row.workAccidenceType === '산재보험 미가입';

			const dataRow = [
				row.workerSocialNumberFront,
				row.workerName,
				'N',
				row.englisName,
				row.foreigner,
				row.visa,

				noPensionPay ? '' : row.taxWageSum,
				noPensionPay ? '' : enteredDateV,
				noPensionPay ? '' : enteredFirstDayV,
				noPensionPay ? '' : pensionInV,
				noPensionPay ? '' : row.pensionIn4PublicJob,
				'',
				'',

				noHealthCarePay ? '' : healthCarePeopleV,
				noHealthCarePay ? '' : row.taxWageSum,
				noHealthCarePay ? '' : enteredDateV,
				noHealthCarePay ? '' : healthInV,
				'',
				'',
				'',

				noNoJobPay ? '' : row.taxWageSum,
				noNoJobPay ? '' : enteredDateV,
				noNoJobPay ? '' : noJobPayDetailV,
				noNoJobPay ? '' : row.weekLBTime,
				'',
				'',
				noNoJobPay ? '' : contractWorkerV,
				noNoJobPay ? '' : contractYearMonthV,

				noWorkAccidence ? '' : row.taxWageSum,
				noWorkAccidence ? '' : enteredDateV,
				noWorkAccidence ? '' : noJobPayDetailV,
				noWorkAccidence ? '' : row.weekLBTime,
				'',
				'',
				noWorkAccidence ? '' : contractWorkerV,
				noWorkAccidence ? '' : contractYearMonthV,
			];

			data = [header1, header2, dataRow];

			merges = [
				{ s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
				{ s: { c: 6, r: 0 }, e: { c: 12, r: 0 } },
				{ s: { c: 13, r: 0 }, e: { c: 19, r: 0 } },
				{ s: { c: 20, r: 0 }, e: { c: 27, r: 0 } },
				{ s: { c: 28, r: 0 }, e: { c: 35, r: 0 } },
				{ s: { c: 36, r: 0 }, e: { c: 37, r: 0 } },
			];

			worksheet = XLSX.utils.aoa_to_sheet(data);
			worksheet['!merges'] = merges;
		} else if (segment === '퇴사') {
			const sameMonthInOutV = sameMonthInOut(row.enteredDate, row.retiredDate);
			const retiredLeaveDate = retiredLeaveDateF(row.retiredDate);

			const headers = [
				'성명',
				'주민(외국인)등록번호국내거소신고번호',
				'전화(지역번호)',
				'전화(국번)',
				'전화(뒷번호)',
				'국민연금상실일',
				'국민연금상실부호',
				'국민연금초일취득당월상실자납부여부',
				'건강보험상실일',
				'건강보험상실부호',
				'건강보험당해년도보수총액',
				'건강보험당해년도근무개월수',
				'건강보험전년도보수총액',
				'건강보험전년도근무개월수',
				'고용보험상실연월일',
				'고용보험상실사유구분코드',
				'고용보험구체적사유',
				'고용보험해당연도보수총액',
				'고용보험전년도보수총액',
				'산재보험상실연월일',
				'산재보험해당연도보수총액',
				'산재보험전년도보수총액',
			];

			const noPensionPay =
				(row.foreigner && !checkVisaPensionTypeV) ||
				row.pension === '대상아님' ||
				row.pensionPayType === '국민연금료 공제안함';
			const noHealthCarePay =
				row.healthCare === '대상아님' ||
				row.healthCarePayType === '건강보험료 공제안함';
			const noNoJobPay =
				(row.foreigner && !checkVisaNoJobPayTypeV) ||
				row.noJob === '대상아님' ||
				row.noJobPayType === '고용보험료 공제안함';
			const noWorkAccidence = row.workAccidenceType === '산재보험 미가입';

			const excelData = [
				row.workerName,
				row.workerSocialNumberFront,
				'',
				'',
				'',
				noPensionPay ? '' : retiredLeaveDate,
				noPensionPay ? '' : pensionOutV,
				noPensionPay ? '' : sameMonthInOutV,
				noHealthCarePay ? '' : retiredLeaveDate,
				noHealthCarePay ? '' : row.healthOutType,
				noHealthCarePay ? '' : row.thisYearTaxWagePaydocu,
				noHealthCarePay ? '' : row.workedMonthThisYear,
				noHealthCarePay ? '' : row.lastYearTaxWagePaydocu,
				noHealthCarePay ? '' : row.workedMonthLastYear,
				noNoJobPay ? '' : retiredLeaveDate,
				noNoJobPay ? '' : row.noPayReason,
				noNoJobPay ? '' : row.noPayDetailType,
				noNoJobPay ? '' : row.thisYearTaxWagePaydocu,
				noNoJobPay ? '' : row.lastYearTaxWagePaydocu,
				noWorkAccidence ? '' : retiredLeaveDate,
				noWorkAccidence ? '' : row.thisYearTaxWagePaydocu,
				noWorkAccidence ? '' : row.lastYearTaxWagePaydocu,
			];

			worksheet = XLSX.utils.aoa_to_sheet([headers, excelData]); // 단일 행의 데이터를 처리
		}

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, segment);
		XLSX.writeFile(workbook, `${segment}_${row.workerName}.xlsx`);
	}

	const headers = useMemo(() => {
		if (authLevel >= 100 || isAgent) {
			return showReported
				? [
						{ key: 'cancelReport', label: '신고취소' },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'responseDate', label: '처리일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ]
				: [
						{ key: 'action', label: '신고완료' },
						{ key: 'excelDownload', label: '엑셀다운' },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						// { key: 'responseDate', label: '처리일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ];
		} else if (insureManageType === '4대보험 자체관리') {
			return showReported
				? [
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '정보 등록일' },
						{ key: 'responseDate', label: '신고일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ]
				: [
						{ key: 'segment', label: '신고 구분' },
						{ key: 'action', label: '신고 완료처리' },
						{ key: 'excelDownload', label: '엑셀다운' },
						{ key: 'registerDate', label: '정보등록일' },
						// { key: 'responseDate', label: '신고일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ];
		} else if (
			insureManageType === '4대보험 위탁관리' ||
			insureManageType === '4대보험 최상인업노무법인 위탁관리' ||
			isAgent
		) {
			return showReported
				? [
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						{ key: 'responseDate', label: '신고일 완료일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ]
				: [
						// { key: 'action', label: '신고완료' },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						// { key: 'responseDate', label: '신고일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'retiredDate', label: '퇴사일' },
						{ key: 'noJobPay', label: '실업급여 요청' },
						{ key: 'contractEndDate', label: '계약종료일' },
						{ key: 'weekLBTime', label: '1주소정' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'noPayReason', label: '고용상실사유' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
						{ key: 'noJob', label: '고용보험' },
						{ key: 'noWorkAccidence', label: '산재보험' },
				  ];
		}
	}, [showReported, insureManageType, authLevel, isAgent]);

	const sortFunctions = {
		registerDate: (a, b) =>
			new Date(b.registerDate).getTime() - new Date(a.registerDate).getTime(),
		responseDate: (a, b) =>
			new Date(b.responseDate).getTime() - new Date(a.responseDate).getTime(),
		enteredDate: (a, b) =>
			new Date(b.enteredDate).getTime() - new Date(a.enteredDate).getTime(),
		contractEndDate: (a, b) =>
			new Date(b.contractEndDate).getTime() -
			new Date(a.contractEndDate).getTime(),
		retiredDate: (a, b) =>
			new Date(b.retiredDate).getTime() - new Date(a.retiredDate).getTime(),
		noJobPay: (a, b) => safeStringCompare(a.noJobPay, b.noJobPay),
		agentWorkerName: (a, b) =>
			safeStringCompare(a.agentWorkerName, b.agentWorkerName),
		segment: (a, b) => safeStringCompare(a.segment, b.segment),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		companyBizNumber: (a, b) =>
			safeStringCompare(a.companyBizNumber, b.companyBizNumber),
		workerName: (a, b) => safeStringCompare(a.workerName, b.workerName),
		netAge: (a, b) => parseInt(a.netAge) - parseInt(b.netAge),
		weekLBTime: (a, b) => parseInt(a.weekLBTime) - parseInt(b.weekLBTime),
		taxWageSum: (a, b) => parseInt(a.taxWageSum) - parseInt(b.taxWageSum),
		thisYearTaxWagePaydocu: (a, b) =>
			parseInt(a.thisYearTaxWagePaydocu) - parseInt(b.thisYearTaxWagePaydocu),
		lastYearTaxWagePaydocu: (a, b) =>
			parseInt(a.lastYearTaxWagePaydocu) - parseInt(b.lastYearTaxWagePaydocu),
		lastYearTaxWageUnder7Paydocu: (a, b) =>
			parseInt(a.lastYearTaxWageUnder7Paydocu) -
			parseInt(b.lastYearTaxWageUnder7Paydocu),
		lastYearTaxWageOver7Paydocu: (a, b) =>
			parseInt(a.lastYearTaxWageOver7Paydocu) -
			parseInt(b.lastYearTaxWageOver7Paydocu),
		noPayReason: (a, b) => safeStringCompare(a.noPayReason, b.noPayReason),
		noPayDetailType: (a, b) =>
			safeStringCompare(a.noPayDetailType, b.noPayDetailType),
		noPayDetailReason: (a, b) =>
			safeStringCompare(a.noPayDetailReason, b.noPayDetailReason),
		noPayInsureYesType: (a, b) =>
			parseInt(a.noPayInsureYesType) - parseInt(b.noPayInsureYesType),
		pensionOutType: (a, b) =>
			parseInt(a.pensionOutType) - parseInt(b.pensionOutType),
		healthOutType: (a, b) =>
			parseInt(a.healthOutType) - parseInt(b.healthOutType),
		workedMonthThisYear: (a, b) =>
			parseInt(a.workedMonthThisYear) - parseInt(b.workedMonthThisYear),
		workedMonthLastYear: (a, b) =>
			parseInt(a.workedMonthLastYear) - parseInt(b.workedMonthLastYear),
		workField: (a, b) => safeStringCompare(a.workField, b.workField),
		foreigner: (a, b) => safeStringCompare(a.foreigner, b.foreigner),
		englishName: (a, b) => safeStringCompare(a.englishName, b.englishName),
		visa: (a, b) => safeStringCompare(a.visa, b.visa),
		selfNoJobPay: (a, b) => safeStringCompare(a.selfNoJobPay, b.selfNoJobPay),
		healthCarePeople: (a, b) =>
			safeStringCompare(a.healthCarePeople, b.healthCarePeople),
		pension: (a, b) => safeStringCompare(a.pension, b.pension),
		healthCare: (a, b) => safeStringCompare(a.healthCare, b.healthCare),
		noJob: (a, b) => safeStringCompare(a.noJob, b.noJob),
		workAccidence: (a, b) =>
			safeStringCompare(a.workAccidence, b.workAccidence),
		action: (a, b) => {
			// "완료후"는 1, "완료전"은 0으로 매핑
			const isReportedA =
				(a.segment === '퇴사' && a.insureOutResponse) ||
				(a.segment === '입사' && a.insureInResponse) ||
				reportedRows[a.id]
					? 1
					: 0;

			const isReportedB =
				(b.segment === '퇴사' && b.insureOutResponse) ||
				(b.segment === '입사' && b.insureInResponse) ||
				reportedRows[b.id]
					? 1
					: 0;

			// "완료전"이 먼저 오도록 오름차순 정렬
			return isReportedA - isReportedB;
		},
	};

	const formattedData = searchResults
		.flatMap((result) => {
			let rows = [];
			const workerSocialNumberFront = result?.workerSocialNumberFront
				? decrypto(
						result.workerSocialNumberFront,
						String(process.env.CRYPTO_KEY)
				  )
				: '';
			const workerSocialNumberBack = result?.workerSocialNumberBack
				? decrypto(
						result.workerSocialNumberBack,
						String(process.env.CRYPTO_KEY)
				  )
				: '';

			const healthCarePeopleComments = result?.healthCarePeopleComments
				? decrypto(
						result?.healthCarePeopleComments,
						String(process.env.CRYPTO_KEY)
				  )
				: '';
			if (result?.isInsureInRequest) {
				rows.push({
					agentWorkerName: result?.agentWorkerName,
					segment: '입사',
					companyName: result?.companyName,
					companyBizNumber: result?.companyBizNumber,
					workerName: result?.worker.name,
					insureManageType: result?.insureManageType,
					workerSocialNumberFront:
						workerSocialNumberFront + workerSocialNumberBack,

					registerDate: result?.isInsureInRequestDate
						? format(new Date(result?.isInsureInRequestDate), 'yyyy-MM-dd')
						: null,
					responseDate: result?.insureInResponseDate
						? format(new Date(result?.insureInResponseDate), 'yyyy-MM-dd')
						: null,
					enteredDate: result?.workerEnteredDate
						? format(new Date(result.workerEnteredDate), 'yyyy-MM-dd')
						: null,
					numOfWorkers:
						typeof result?.numOfWorkers === 'string'
							? result?.numOfWorkers
							: result?.numOfWorkers?.toString(),
					weekLBTime:
						result?.lawBase?.weekLBTime >= 40
							? 40
							: roundUp(result?.lawBase?.weekLBTime),
					taxWageSum: roundUp(result?.calWorkWage?.taxWageSum),
					workField: result?.workField,
					contractEndDate: result?.contractEndDate
						? format(new Date(result?.contractEndDate), 'yyyy-MM-dd')
						: null,
					netAge: result?.netAge,

					foreigner: result?.foreigner?.nationality,
					englisName: result?.foreigner?.englishName,
					visa: result?.foreigner?.visaType,
					selfNoJobPay: result?.foreigner?.selfNoJobPay,

					healthCarePeople: result?.healthCarePeople?.familyName,
					familyRelation: result?.healthCarePeople?.familyRelation,
					handyCapFamily: result?.healthCarePeople?.handyCapFamily,
					healthCarePeopleComments: healthCarePeopleComments,
					pensionIn: result?.pensionIn,
					pensionIn4PublicJob: result?.pensionIn4PublicJob,
					pension:
						result?.pensionText ===
							'만60세이상 또는 월 소정60시간미만(월 220만원 미만), 중도입사자인 경우 , (일용/시급 8일미만, 퇴사월)국민연금료 납부의무 없음.' ||
						result?.pensionPayType === '국민연금료 공제안함'
							? '대상아님'
							: '대상',
					pensionPayType: result?.pensionPayType,

					healthIn: result?.healthIn,
					healthCare:
						result?.healthCareText ===
							'8일미만 , 소정근로 60시간 미만, 중도입사자는 건강보험료를 납부하지 않습니다.' ||
						result?.healthCarePayType === '건강보험료 공제안함'
							? '대상아님'
							: '대상',
					healthCarePayType: result?.healthCarePayType,

					noJobPayDetail: result?.noJobPayDetail,
					noJobPayType: result?.noJobPayType,
					workAccidenceType: result?.workAccidenceType,
					noJob:
						result?.netAgeEntered >= 65
							? '대상'
							: result?.noJobText ===
									'계약기간이 3개월 미만의 1주 소정근로 15시간(또는 월 60시간)미만 또는 65세 이상인 경우 공제안함' ||
							  result?.noJobText ===
									'중도입사자, 계약기간이 3개월 미만의 1주 소정근로 15시간(또는 월 60시간)미만 또는 65세 이상인 경우 공제안함' ||
							  result?.noJobPayType === '고용보험료 공제안함'
							? '대상아님'
							: '대상',
					noWorkAccidence:
						result?.workAccidenceType === '산재보험 미가입'
							? '대상아님'
							: '대상',
					insureInResponse: result?.insureInResponse,
					id: result.id,
				});
			}
			if (result?.retireInfo?.isInsureOutRequest === true) {
				rows.push({
					agentWorkerName: result?.agentWorkerName,
					segment: '퇴사',
					insureManageType: insureManageType,

					registerDate: result?.retireInfo.registerDate
						? format(
								new Date(result?.retireInfo.registerDate.toDate()),
								'yyyy-MM-dd'
						  )
						: null,
					responseDate: result?.insureOutResponseDate
						? format(new Date(result?.insureOutResponseDate), 'yyyy-MM-dd')
						: null,
					companyName: result?.companyName,
					companyBizNumber: result?.companyBizNumber,
					workerName: result?.worker.name,
					workerSocialNumberFront:
						workerSocialNumberFront + workerSocialNumberBack,

					enteredDate: result?.workerEnteredDate
						? format(new Date(result.workerEnteredDate), 'yyyy-MM-dd')
						: null,
					retiredDate: result?.retireInfo.retiredDate
						? format(
								new Date(result?.retireInfo.retiredDate.toDate()),
								'yyyy-MM-dd'
						  )
						: null,
					// weekLBTime: "해당없음",
					// taxWageSum: "해당없음",
					// workField: "해당없음",
					thisYearTaxWagePaydocu: commaStringToNumber(
						result?.retireInfo?.thisYearTaxWagePaydocu
					),
					lastYearTaxWagePaydocu: commaStringToNumber(
						result?.retireInfo?.lastYearTaxWagePaydocu
					),
					lastYearTaxWageUnder7Paydocu: commaStringToNumber(
						result?.retireInfo?.lastYearTaxWageUnder7Paydocu
					),
					lastYearTaxWageOver7Paydocu: commaStringToNumber(
						result?.retireInfo?.lastYearTaxWageOver7Paydocu
					),

					noPayReason: result?.eiOutType,
					noPayDetailType: result?.eiOutDetailType,
					noPayDetailReason: result?.eiOutReason,
					noPayInsureYesType: result?.noJobPayYesType,
					pensionOutType: result?.pensionOutType,
					healthOutType: result?.healthOutType,

					workedMonthThisYear: result?.healthCareRecal?.workedMonthThisYear,
					workedMonthLastYear: result?.healthCareRecal?.workedMonthLastYear,

					contractEndDate: result?.contractEndDate
						? format(new Date(result?.contractEndDate), 'yyyy-MM-dd')
						: null,
					insureOutResponse: result?.insureOutResponse,
					id: result.id,
					pension:
						result?.pensionText ===
							'만60세이상 또는 월 소정60시간미만(월 220만원 미만), 중도입사자인 경우 , (일용/시급 8일미만, 퇴사월)국민연금료 납부의무 없음.' ||
						result?.pensionPayType === '국민연금료 공제안함'
							? '대상아님'
							: '대상',
					pensionPayType: result?.pensionPayType,

					healthCare:
						result?.healthCareText ===
							'8일미만 , 소정근로 60시간 미만, 중도입사자는 건강보험료를 납부하지 않습니다.' ||
						result?.healthCarePayType === '건강보험료 공제안함'
							? '대상아님'
							: '대상',
					healthCarePayType: result?.healthCarePayType,
					noJobPay:
						result?.noJobPay === '1. 실업급여 신청' ? '대상' : '대상아님',
					noJobPayDetail: result?.noJobPayDetail,
					noJobPayType: result?.noJobPayType,
					noJob:
						result?.netAgeEntered >= 65
							? '대상'
							: result?.noJobText ===
									'계약기간이 3개월 미만의 1주 소정근로 15시간(또는 월 60시간)미만 또는 65세 이상인 경우 공제안함' ||
							  result?.noJobText ===
									'중도입사자, 계약기간이 3개월 미만의 1주 소정근로 15시간(또는 월 60시간)미만 또는 65세 이상인 경우 공제안함' ||
							  result?.noJobPayType === '고용보험료 공제안함'
							? '대상아님'
							: '대상',
					noWorkAccidence:
						result?.workAccidenceType === '산재보험 미가입'
							? '대상아님'
							: '대상',
				});
			}
			return rows;
		})
		.filter((row) => {
			const isReported =
				(row.segment === '퇴사' && row.insureOutResponse) ||
				(row.segment === '입사' && row.insureInResponse) ||
				reportedRows[row.id];
			return showReported ? isReported : !isReported;
		});

	const title = '회사 목록';

	const customRenderers = {
		checkbox: (row) => (
			<Checkbox
				checked={selectedRows.some((selected) => selected.id === row.id)}
				onChange={(e, { checked }) => {
					if (checked) {
						setSelectedRows((prev) => [...prev, row]);
					} else {
						setSelectedRows((prev) => prev.filter((r) => r.id !== row.id));
					}
				}}
			/>
		),

		cancelReport: (row) => {
			if (row.registerDate) {
				return (
					<Button
						color='orange'
						onClick={() => handleCancelReport(row.id, row.segment)}>
						신고취소
					</Button>
				);
			}
			return null; // 처리요청일이 없으면 버튼 표시 안함
		},

		action: (row) => {
			const isReportedFromData =
				(row.segment === '퇴사' && row.insureOutResponse) ||
				(row.segment === '입사' && row.insureInResponse);
			const isReportedFromUI = reportedRows[row.id];

			const isReported = isReportedFromData || isReportedFromUI;

			return (
				<Button
					color={isReported ? 'grey' : 'red'}
					onClick={() => handleReportComplete(row.id, row.segment)}>
					{isReported ? '후' : '확인'}
				</Button>
			);
		},

		excelDownload: (row) => {
			return (
				<Button
					color={row.segment === '입사' ? 'blue' : 'green'}
					onClick={() => exportIndividualToExcel(row)}>
					{row.segment}
				</Button>
			);
		},

		healthCarePeople: (row) => {
			if (row?.healthCarePeople) {
				return (
					<>
						<Button
							color='blue'
							onClick={() => {
								setCurrentHealthCarePeople(row?.healthCarePeople);
								setFamilyRelation(row?.familyRelation);
								setHandyCapFamily(row?.handyCapFamily);
								setHealthCarePeopleComments(row?.healthCarePeopleComments);
								setIsModalOpen(true);
							}}>
							해당
						</Button>
					</>
				);
			}
			return null;
		},
	};

	const noExcelButton = false;

	return (
		<>
			<HealthCarePeopleModal />
			<Button color='teal' onClick={() => setShowReported(!showReported)}>
				{showReported ? '신고 전 정보 보기' : '신고 완료 정보 보기'}
			</Button>
			<SortableTable
				headers={headers}
				data={formattedData}
				sortFunctions={sortFunctions}
				customRenderers={customRenderers}
				title={title}
				noExcelButton={noExcelButton}
				enableSearch={true}
			/>
		</>
	);
}
